import { TransactionResults } from '../../interface/transaction-product.interface';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material';
import {
  FlexCenter,
  OrderHistoryAccordionColumn,
  OrderHistoryAccordionRow,
  OrderHistoryAccordionRowProductName,
  OrderHistoryAccordionRowProductNameContainer,
  OrderHistoryAccordionSummaryStyles,
  OrderHistoryStatusIcon,
  OrderHistoryStatusInformation,
  OrderHistorySummaryOfCosts,
  SharedOrderHistoryAccordionContentRequestRefund,
  SharedOrderHistoryTableAccordionContainer,
  SharedOrderHistoryTableCellWidth,
  sharedOrderHistoryTableDownloadReceiptStyle,
  sharedOrderHistoryTableDownloadReport,
  sharedOrderHistoryTableJitStyle,
  SharedOrderHistoryTablePaddings,
  sharedOrderHistoryTableRemovePaddingAndMargins,
  SharedViewedBox
} from './SharedOrderHistoryTable.style';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment';
import { OrderHistoryAccordionTable } from '../OrderHistoryAccordionTable/OrderHistoryAccordionTable';
import { FC } from 'react';
import Img from '../Primitive/Img';
import paidGreen from '../../assets/images/order-completed-icon.svg';
import refundIcon from '../../assets/images/refund-icon.svg';
import pendingIcon from '../../assets/images/pending-icon.svg';
import errorIcon from '../../assets/images/error-paid-icon.svg';
import fulfilledIcon from '../../assets/images/fulfilled-icon.svg';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '../../utils/utils';
import { OrderStatus, OrderStatuses } from '../../interface/common.interfaces';
import { theme } from '../../styles/theme';
import { purpleTooltip, whiteTooltip } from '../../styles/tooltip';
import InfoIcon from '../../assets/images/info-icon.svg';
import DownloadBlueSvgIcon from '../../assets/images/download-blue.svg';

type SharedOrdersSingleTableRowProps = {
  orders: TransactionResults[];
  selectedProduct: (id: number) => void;
  accordionHeaders: string[];
  downloadReceipt: (transactionId?: number) => void;
};

const receiptAvailableForStatuses: OrderStatus[] = [
  OrderStatuses.PAID,
  OrderStatuses.PARTIALLY_REFUNDED,
  OrderStatuses.FULFILLED,
  OrderStatuses.REFUNDED
];

export const SharedOrdersSingleTableRow: FC<SharedOrdersSingleTableRowProps> = ({
  orders,
  selectedProduct,
  accordionHeaders,
  downloadReceipt
}) => {
  const { t } = useTranslation();
  const matchesMaxWidth = useMediaQuery('(max-width:834px)');
  const matchesMedWidth = useMediaQuery('(max-width:1000px)');
  const refundDisabled = (item: TransactionResults) => {
    return (
      ![OrderStatuses.FULFILLED, OrderStatuses.PARTIALLY_REFUNDED].includes(
        item.status as OrderStatuses
      ) || item.totalPaid === 0
    );
  };

  const showDownloadReceipt = (item: TransactionResults) =>
    receiptAvailableForStatuses.includes(item.status);

  const getJitNotice = (item: TransactionResults) => {
    if (item.status === OrderStatuses.REFUNDED) return t('orderHistory.alreadyRefunded');
    if (item.totalPaid === 0) return t('orderHistory.testWasFree');
    return null;
  };

  const paidStatus = (status: OrderStatus) => {
    switch (status) {
      case OrderStatuses.PAID:
        return (
          <>
            <Img sx={{ verticalAlign: '-3px', paddingRight: '5px' }} src={paidGreen} />
            {t('profilePage.paid')}
          </>
        );
      case OrderStatuses.FULFILLED:
        return (
          <>
            <Img sx={{ verticalAlign: '-3px', paddingRight: '5px' }} src={fulfilledIcon} />
            {t('profilePage.fulfilled')}
          </>
        );
      case OrderStatuses.REFUNDED:
        return (
          <>
            <Img sx={{ verticalAlign: '-3px', paddingRight: '5px' }} src={refundIcon} />{' '}
            {t('profilePage.refunded')}
          </>
        );
      case OrderStatuses.PARTIALLY_REFUNDED:
        return (
          <>
            <Img sx={{ verticalAlign: '-3px', paddingRight: '5px' }} src={refundIcon} />{' '}
            {t('profilePage.partiallyRefunded')}
          </>
        );
      case OrderStatuses.ERROR:
        return (
          <>
            <Img sx={{ verticalAlign: '-3px', paddingRight: '5px' }} src={errorIcon} />{' '}
            {t('profilePage.error')}
          </>
        );
      case OrderStatuses.PENDING:
        return (
          <>
            <Img sx={{ verticalAlign: '-3px', paddingRight: '5px' }} src={pendingIcon} />{' '}
            {t('profilePage.pending')}
          </>
        );
      case OrderStatuses.DENIED:
        return (
          <>
            <Img sx={{ verticalAlign: '-3px', paddingRight: '5px' }} src={errorIcon} />{' '}
            {t('profilePage.denied')}
          </>
        );
      default:
        return (
          <>
            <Img sx={{ verticalAlign: '-3px', paddingRight: '5px' }} src={pendingIcon} />{' '}
            {t('profilePage.paid')}
          </>
        );
    }
  };
  return (
    <Box id='SharedOrderHistoryAccordionContainer' sx={SharedOrderHistoryTableAccordionContainer}>
      <>
        {orders.length > 0 &&
          orders.map((item, index) => (
            <Accordion key={`${item}${index}`}>
              <AccordionSummary
                sx={{ ...OrderHistoryAccordionSummaryStyles, pointerEvents: 'none' }}
                expandIcon={
                  <ExpandMoreIcon
                    id='SharedScoresTableExpandMoreIcon'
                    sx={{
                      color: theme.palette.common.darkGrey8,
                      pointerEvents: 'auto'
                    }}
                  />
                }
              >
                <Box sx={{ ...OrderHistoryAccordionRow }}>
                  <Box
                    sx={{
                      ...OrderHistoryAccordionRowProductNameContainer,
                      ...SharedOrderHistoryTableCellWidth(matchesMaxWidth ? 4 : 1),
                      flexDirection: 'column'
                    }}
                  >
                    <Typography sx={OrderHistoryAccordionRowProductName}>
                      {item.essId || item.code}
                    </Typography>
                    {matchesMaxWidth && (
                      <>
                        <Box sx={{ ...OrderHistoryAccordionColumn, paddingLeft: 0 }}>
                          <Typography sx={{ flex: '1', margin: '1rem 0' }}>
                            {moment(item.createdAt).format('DD MMMM yyyy')}
                          </Typography>
                        </Box>
                        <Box sx={{ width: '100%' }}>{paidStatus(item.status)}</Box>
                        <Box sx={{ marginTop: '20px' }}>
                          <Button
                            disabled={refundDisabled(item)}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              selectedProduct(item.id);
                            }}
                            sx={[
                              SharedOrderHistoryAccordionContentRequestRefund,
                              sharedOrderHistoryTableRemovePaddingAndMargins
                            ]}
                          >
                            {t('profilePage.requestRefund')}
                          </Button>
                          <Tooltip
                            componentsProps={{ tooltip: { sx: purpleTooltip } }}
                            arrow
                            placement='bottom-end'
                            title={getJitNotice(item)}
                          >
                            <Img
                              sx={sharedOrderHistoryTableJitStyle(!!getJitNotice(item))}
                              src={InfoIcon}
                              alt='jit notice'
                            />
                          </Tooltip>
                        </Box>
                        {showDownloadReceipt(item) && (
                          <Tooltip
                            componentsProps={{
                              tooltip: { sx: { ...whiteTooltip, width: 'unset' } }
                            }}
                            arrow
                            placement='bottom'
                            title={t('profilePage.downloadReceipt')}
                          >
                            <Button sx={sharedOrderHistoryTableRemovePaddingAndMargins}>
                              <Img
                                width={18}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  downloadReceipt(item.id);
                                }}
                                sx={[
                                  sharedOrderHistoryTableDownloadReport,
                                  sharedOrderHistoryTableRemovePaddingAndMargins
                                ]}
                                src={DownloadBlueSvgIcon}
                              />
                              <Typography sx={[sharedOrderHistoryTableDownloadReceiptStyle]}>
                                {t('profilePage.downloadReceipt')}
                              </Typography>
                            </Button>
                          </Tooltip>
                        )}
                      </>
                    )}
                  </Box>
                  <Box
                    sx={{
                      ...OrderHistoryAccordionColumn,
                      ...SharedOrderHistoryTableCellWidth(1),
                      ...(matchesMaxWidth && { display: 'none' })
                    }}
                  >
                    <Typography sx={{ flex: '1' }}>
                      {moment(item.createdAt).format('DD MMMM yyyy')}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      ...OrderHistoryAccordionColumn,
                      ...SharedOrderHistoryTableCellWidth(1),
                      ...(matchesMaxWidth && { display: 'none' })
                    }}
                  >
                    <Typography sx={OrderHistoryStatusInformation}>
                      <Box sx={OrderHistoryStatusIcon}>{paidStatus(item.status)}</Box>
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      ...OrderHistoryAccordionColumn,
                      ...SharedOrderHistoryTableCellWidth(
                        matchesMaxWidth ? 4 : matchesMedWidth ? 5 : 1
                      )
                    }}
                  >
                    <Typography sx={{ flex: '1' }}>
                      <Box sx={FlexCenter}>
                        <Typography sx={OrderHistorySummaryOfCosts}>
                          {formatCurrency(
                            item.totalPaid,
                            item.currency.code,
                            item.currency.fractionDigits
                          )}
                        </Typography>
                      </Box>
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      ...SharedViewedBox,
                      ...SharedOrderHistoryTableCellWidth(matchesMaxWidth ? 4 : 1),
                      ...(matchesMaxWidth && { display: 'none' })
                    }}
                  >
                    <Button
                      disabled={refundDisabled(item)}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        selectedProduct(item.id);
                      }}
                      sx={SharedOrderHistoryAccordionContentRequestRefund}
                    >
                      {t('profilePage.requestRefund')}
                    </Button>
                    <Tooltip
                      componentsProps={{ tooltip: { sx: purpleTooltip } }}
                      arrow
                      placement='bottom-end'
                      title={getJitNotice(item)}
                    >
                      <Img
                        sx={sharedOrderHistoryTableJitStyle(!!getJitNotice(item))}
                        src={InfoIcon}
                        alt='jit notice'
                      />
                    </Tooltip>
                    {showDownloadReceipt(item) && (
                      <Tooltip
                        componentsProps={{ tooltip: { sx: { ...whiteTooltip, width: 'unset' } } }}
                        arrow
                        placement='bottom'
                        title={t('profilePage.downloadReceipt')}
                      >
                        <Img
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            downloadReceipt(item.id);
                          }}
                          sx={sharedOrderHistoryTableDownloadReport}
                          src={DownloadBlueSvgIcon}
                        />
                      </Tooltip>
                    )}
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails sx={SharedOrderHistoryTablePaddings}>
                <OrderHistoryAccordionTable
                  tableHeaders={accordionHeaders}
                  data={item.transactionProducts}
                />
              </AccordionDetails>
            </Accordion>
          ))}
      </>
    </Box>
  );
};
