import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { BaseSyntheticEvent, FC, useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ButtonStyles,
  ChangePasswordPopupContainer,
  changePasswordPopupErrorMessage,
  FormInputContainer,
  HintContainer,
  ImgStyles,
  InputLabel,
  InputStyles,
  PasswordChangedStack,
  PasswordLengthStyles,
  PasswordVisibilityIcon,
  PopupDescription,
  SuccessText
} from './ChangePasswordPopup.styles';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useForm } from 'react-hook-form';
import { SignInButton } from '@barracuda/shared/src/components/SignIn/SignInStyles';
import PasswordChanged from '../../assets/images/dashboard--account__passwordChanged.svg';
import { Img } from '@barracuda/shared/src/components/Primitive';
import { debounce } from 'lodash';
import { AxiosResponse } from 'axios';
import { Auth, sopClient } from '@barracuda/shared/src';
import {
  PasswordHintColor,
  SetPasswordHints
} from '@barracuda/shared/src/components/SetPassword/SetPassword.styles';
import { AppContext } from '../../context/App.context';

export const ChangePasswordPopup: FC = () => {
  const { t } = useTranslation();
  const [success, setSuccess] = useState(false);
  const [passwordErrors, setPasswordErrors] = useState<string[] | undefined>(undefined);
  const [password, setPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState<Record<string, boolean>>({
    old: false,
    new: false,
    confirm: false
  });
  const { setError } = useContext(AppContext);

  const {
    register,
    getValues,
    formState: { errors, isValid },
    setValue,
    setError: setFormError,
    trigger
  } = useForm({
    criteriaMode: 'all',
    mode: 'all',
    reValidateMode: 'onChange'
  });

  const handlePasswordValidation = async (password: string): Promise<string[]> => {
    try {
      const response = await Auth.sopClient.validatePassword(password);
      const { data } = response as AxiosResponse;
      return data?.validationErrors;
    } catch (e) {
      console.log('There was an error during password validation', e);
      return [];
    }
  };

  const changePassword = async () => {
    const { oldPassword, newPassword, confirmPassword } = getValues();
    if (confirmPassword !== newPassword) {
      setFormError('confirmPassword', {
        message: t('changePassword.confirmPasswordError')
      });
      return;
    }
    if (oldPassword === newPassword) {
      setFormError('confirmPassword', {
        message: t('changePassword.oldAndNewPasswordsAreEqualError')
      });
      return;
    }

    try {
      await sopClient.changePassword(oldPassword, newPassword, confirmPassword);
      setSuccess(true);
    } catch (e: any) {
      const message: string = e.response.data.message;
      const mappedResponseMessages: Record<string, string> = {
        'The password was already used, please choose a different password.': t(
          'changePassword.alreadyUsedPasswordError'
        ),
        'Old password does not match!': t('changePassword.oldPasswordDoesntMatchError')
      };

      if (mappedResponseMessages[message]) {
        setFormError('confirmPassword', {
          message: mappedResponseMessages[message]
        });
      } else {
        setError({ open: e.response.status !== 401 });
      }
      setSuccess(false);
    }
  };

  const checkPassword = useCallback(
    debounce(async (event: BaseSyntheticEvent) => {
      const value = event?.target.value;
      if (value) {
        const errors = await handlePasswordValidation(value);
        setPasswordErrors(errors || []);
      }
    }, 300),
    []
  );

  const handlePasswordChange = (event: BaseSyntheticEvent) => {
    setPassword(event?.target?.value);
    setValue('password', event?.target?.value);
    trigger('password');
    if (!event?.target?.value) {
      setPasswordErrors(undefined);
    }
    checkPassword(event);
  };

  const visibilityIcon = (key: string) => {
    return passwordVisible[key] ? (
      <VisibilityOffIcon
        sx={PasswordVisibilityIcon}
        onClick={() => setPasswordVisible({ ...passwordVisible, [key]: !passwordVisible[key] })}
      />
    ) : (
      <VisibilityIcon
        sx={PasswordVisibilityIcon}
        onClick={() => setPasswordVisible({ ...passwordVisible, [key]: !passwordVisible[key] })}
      />
    );
  };

  return (
    <Stack sx={ChangePasswordPopupContainer}>
      {!success && (
        <>
          <Typography sx={PopupDescription}>{t('changePassword.youCanNowCreate')}</Typography>
          <Stack>
            <Box sx={FormInputContainer}>
              <Box>
                <Typography sx={InputLabel}>{t('changePassword.oldPassword')}</Typography>
              </Box>
              <TextField
                {...register('oldPassword', {
                  required: true
                })}
                type={passwordVisible.old ? 'text' : 'password'}
                id='oldPasswordInput'
                variant='outlined'
                sx={InputStyles}
              />
              {visibilityIcon('old')}
              {errors?.oldPassword?.type === 'required' && (
                <Typography sx={changePasswordPopupErrorMessage}>{t('fieldIsRequired')}</Typography>
              )}
            </Box>
            <Box sx={[FormInputContainer, { marginBottom: 0 }]}>
              <Box>
                <Typography sx={InputLabel}>{t('changePassword.newPassword')}</Typography>
              </Box>
              <TextField
                {...register('newPassword', {
                  required: true
                })}
                type={passwordVisible.new ? 'text' : 'password'}
                id='newPasswordInput'
                onChange={handlePasswordChange}
                variant='outlined'
                sx={InputStyles}
              />
              {visibilityIcon('new')}
              <Typography sx={{ ...PasswordLengthStyles(password) }}>
                {password.length}/8
              </Typography>
            </Box>
            <Box sx={HintContainer}>
              <Typography sx={[SetPasswordHints, { textAlign: 'left' }]}>
                {t('atLeast')}
                <Typography
                  sx={[
                    SetPasswordHints,
                    PasswordHintColor(password, passwordErrors, 'NOT_LONG_ENOUGH')
                  ]}
                  component='span'
                >
                  {' '}
                  {t('signUp.charactersLength')}
                </Typography>
                ,
                <Typography
                  sx={[
                    SetPasswordHints,
                    PasswordHintColor(password, passwordErrors, 'NO_LOWERCASE_CHARACTER')
                  ]}
                  component='span'
                >
                  {' '}
                  {t('oneLowercaseLetter')}
                </Typography>
                ,
                <Typography
                  sx={[
                    SetPasswordHints,
                    PasswordHintColor(password, passwordErrors, 'NO_UPPERCASE_CHARACTER')
                  ]}
                  component='span'
                >
                  {' '}
                  {t('oneUppercaseLetter')}
                </Typography>
                ,
                <Typography
                  sx={[
                    SetPasswordHints,
                    PasswordHintColor(password, passwordErrors, 'NO_DIGIT_CHARACTER')
                  ]}
                  component='span'
                >
                  {' '}
                  {t('oneNumber')}
                </Typography>{' '}
                {t('and')}
                <Typography
                  sx={[
                    SetPasswordHints,
                    PasswordHintColor(password, passwordErrors, 'NO_SPECIAL_CHARACTER')
                  ]}
                  component='span'
                >
                  {' '}
                  {t('specialCharacter')}
                </Typography>
              </Typography>
            </Box>
            <Box sx={FormInputContainer}>
              <Box>
                <Typography sx={InputLabel}>{t('changePassword.confirmPassword')}</Typography>
              </Box>
              <TextField
                {...register('confirmPassword', {
                  required: true
                })}
                type={passwordVisible.confirm ? 'text' : 'password'}
                id='oldPasswordInput'
                variant='outlined'
                sx={InputStyles}
              />
              {visibilityIcon('confirm')}
              <Typography sx={changePasswordPopupErrorMessage}>
                {typeof errors?.confirmPassword?.message === 'string' &&
                  errors?.confirmPassword?.message}
              </Typography>
            </Box>
            <Button
              onClick={changePassword}
              type='submit'
              disabled={Object.keys(errors).length > 0 || !!passwordErrors?.length || !isValid}
              sx={[SignInButton, ButtonStyles]}
            >
              {t('changePassword.save')}
            </Button>
          </Stack>
        </>
      )}
      {success && (
        <>
          <Stack sx={PasswordChangedStack}>
            <Img src={PasswordChanged} sx={ImgStyles} />
            <Typography sx={SuccessText}>{t('changePassword.success')}</Typography>
            <Typography>{t('changePassword.passwordChanged')}</Typography>
          </Stack>
        </>
      )}
    </Stack>
  );
};
