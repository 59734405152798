import { FC, useContext, useEffect, useState } from 'react';
import { ResultsTestTable } from '@barracuda/shared/src/components/ResultsTestTable/ResultsTestTable';
import {
  License,
  MobileTestTile,
  PageableResponse,
  Score,
  SkillType,
  sopClient
} from '@barracuda/shared/src';
import { AxiosResponse } from 'axios';
import { AppContext } from '../../context/App.context';
import { Button, Stack, Typography, useMediaQuery } from '@mui/material';
import { PracticeButton } from './ResultsAccordionWrapper.style';
import { Dictionary, groupBy } from 'lodash';
import moment from 'moment';
import { MobileTestTileDate } from '../ResultsTablesWrapper/ResultsTablesWrapper.style';
import { useTranslation } from 'react-i18next';
import { useLocalizedNavigate } from '../../hooks/useLocalizedNavigate';

export const ResultsAccordionWrapper: FC = () => {
  const [licenses, setLicenses] = useState<License[]>([]);
  const navigate = useLocalizedNavigate();
  const { setError, setTestTaken } = useContext(AppContext);
  const { t } = useTranslation();
  const [datesDictionary, setDatesDictionary] = useState<Dictionary<License[]> | null>(null);
  const tableHeaders = Object.values(
    t('dashboardPage.resultsAccordionTableHeaders', { returnObjects: true })
  ).map((header) => header as string);
  const lowerThan600 = useMediaQuery('(max-width: 600px)');
  useEffect(() => {
    getLatestTests();
  }, []);

  const distinctDatesByDay = (array: License[], setter: (value: Dictionary<License[]>) => void) => {
    const dateDictionary = groupBy(array, (item) => {
      return moment(item.endTime || item.startTime).startOf('day');
    });
    setter(dateDictionary);
  };

  const getLatestTests = async () => {
    try {
      const response = (await sopClient.getLatestLicenses(3)) as AxiosResponse;
      const data = response.data as PageableResponse<License>;
      const results = data.results;
      setTestTaken(!!data?.results?.length);
      setLicenses(results);
      distinctDatesByDay(results, setDatesDictionary);
    } catch (e: any) {
      const {
        response: {
          data: { message }
        }
      } = e;
      setError(true, message);
    }
  };
  const findScoreByName = (name: SkillType, scale: string, scores: Score[]): string | null => {
    if (typeof scores === undefined) return null;
    const score = scores.find((el) => el.component === name && el.scale === scale);
    if (!score) {
      return null;
    }

    return score.score || score.label || null;
  };

  const rowClickable = (status: string) =>
    !['NEW', 'STARTED', 'COMPLETED', 'VALIDATED'].includes(status);

  const handleRowClick = (license: License) => {
    if (rowClickable(license.status)) {
      navigate(`results/test/${license.id}`);
    }
  };
  return (
    <Stack sx={licenses?.length === 1 ? { paddingBottom: '150px' } : {}}>
      {(!lowerThan600 || !licenses?.length) && (
        <ResultsTestTable
          handleRowClick={handleRowClick}
          licenses={licenses}
          findScoreByName={findScoreByName}
          tableHeaders={tableHeaders}
        />
      )}
      {!!licenses.length &&
        lowerThan600 &&
        datesDictionary &&
        Object.keys(datesDictionary).map((item, index) => (
          <Stack key={`DictStack-${index}`}>
            <Typography sx={MobileTestTileDate}>
              {moment(item).format('dddd, DD MMMM yyyy')}
            </Typography>
            {Object.values(datesDictionary[item]).map((value) => (
              <MobileTestTile
                key={`MobileTile-${index}`}
                handleRowClick={handleRowClick}
                license={value}
                practice={false}
              />
            ))}
          </Stack>
        ))}
      {licenses.length > 1 && (
        <Button onClick={() => navigate('results')} sx={PracticeButton}>
          {t('dashboardPage.seeAllResults')}
        </Button>
      )}
    </Stack>
  );
};
