import { theme } from '../../styles/theme';

export const SharedScoresTableContainer = {
  background: theme.palette.common.white,
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.25)',
  marginTop: '30px',
  minHeight: '558px',
  borderRadius: '8px'
};

export const SharedScoresTableHeader = {
  fontSize: '20px',
  fontWeight: 700,
  paddingLeft: '24px',
  paddingTop: '36px',
  paddingBottom: '36px',
  '@media screen and (max-width: 475px)': {
    paddingBottom: '10px'
  }
};

export const SharedScoresTableTHead = {
  background: theme.palette.common.lightGrey5
};

export const SharedScoresTableTHeadCell = {
  fontWeight: 600,
  textAlign: 'left'
};

export const TableFooterStyle = {
  display: 'flex',
  flexFlow: 'row-reverse'
};

export const SharedScoresTableMainHeader = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingRight: '1rem',
  position: 'relative',
  '@media screen and (max-width: 475px)': {
    paddingBottom: '10px',
    flexFlow: 'column',
    alignItems: 'flex-start'
  }
};

export const SearcherBox = {
  display: 'flex',
  gap: '30px',
  '@media screen and (max-width: 860px)': {
    gap: '10px',
    justifyContent: 'right'
  },
  '@media screen and (max-width: 475px)': {
    paddingLeft: '24px'
  }
};

export const StopSharingButton = {
  height: '36px',
  margin: 0
};

export const CheckboxStyle = {
  padding: '2px',
  margin: '0 7px 0 7px',
  '&.Mui-checked > svg': {
    fill: `${theme.palette.common.violet} !important`
  },
  '&.Mui-disabled > svg': {
    fill: `${theme.palette.common.grey} !important`
  },
  pointerEvents: 'auto'
};

export const LowResolutionAppear = {
  '@media screen and (min-width: 475px)': {
    display: 'none'
  },
  top: '38px',
  right: '30px'
};

export const EmailSmall = {
  fontSize: '12px',
  fontWeight: 400,
  color: theme.palette.common.black,
  marginLeft: '42px',
  whiteSpace: 'nowrap',
  maxWidth: '180px',
  textOverflow: 'ellipsis',
  overflow: 'hidden'
};

export const sharedScoresTableAccordionRow = {
  width: '100%',
  '> *': {
    padding: '16px'
  }
};

export const sharedScoresTableExpandIcon = {
  padding: 0,
  '.MuiAccordionSummary-expandIconWrapper': {
    position: 'absolute',
    right: '20px',
    '@media screen and (max-width: 1024px)': {
      top: '30px'
    }
  },
  '.MuiAccordionSummary-content': {
    flexFlow: 'column'
  },
  pointerEvents: 'none'
};

export const sharedScoresTableMobileRow = {
  display: 'flex',
  '> *': {
    fontSize: '14px'
  },
  '> p:first-child': {
    fontWeight: 700,
    width: '120px'
  }
};
