import { FC } from 'react';
import { Button, Dialog, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ModalMainContainer } from './LimitReachedModal.style';
import { buttonDark } from '../../styles/button';

export interface LimitReachedModalProps {
  readonly open: boolean;
  readonly handleClose: () => void;
  readonly message: string;
}

export const LimitReachedModal: FC<LimitReachedModalProps> = ({ open, handleClose, message }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={handleClose} disableEscapeKeyDown>
      <Stack alignItems='center' justifyContent='center' sx={ModalMainContainer}>
        <Typography sx={{ fontSize: '32px', fontWeight: 700 }}>
          {t('limitReachedModal.title')}
        </Typography>
        <Typography sx={{ fontSize: '16px', fontWeight: 400 }}>{message}</Typography>
        <Button onClick={handleClose} sx={[buttonDark, { marginTop: '50px' }]}>
          {t('limitReachedModal.close')}
        </Button>
      </Stack>
    </Dialog>
  );
};
