import { Box } from '@mui/material';
import { FC } from 'react';
import { accountContainerStyle, accountImageStyle } from './AccountPhoto.style';
import PersonIcon from '@mui/icons-material/Person';
import { Img } from '../Primitive';

export interface AccountPhotoProps {
  imageUri?: string;
  width?: string;
  height?: string;
  sx?: any;
  handlePhotoClick?: () => void;
  rectangular?: boolean;
}

export const AccountPhoto: FC<AccountPhotoProps> = ({
  imageUri,
  width,
  height,
  sx,
  handlePhotoClick,
  rectangular
}) => {
  return (
    <Box
      onClick={handlePhotoClick}
      sx={accountImageStyle(imageUri, width, height, rectangular, handlePhotoClick)}
    >
      {imageUri ? (
        <Img sx={sx} src={imageUri} />
      ) : (
        <Box sx={[accountContainerStyle(width, height), sx]}>
          <PersonIcon />
        </Box>
      )}
    </Box>
  );
};
