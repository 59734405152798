import { theme } from '../../styles/theme';

export const HelpCenterContainerWrapper = {
  maxWidth: '1440px !important',
  margin: '0 auto'
};

export const HelpCenterHeaderContainer = {
  paddingTop: '180px',
  paddingLeft: '130px',
  paddingRight: '180px',
  paddingBottom: '60px',
  ...HelpCenterContainerWrapper,
  '@media only screen and (min-width: 320px) and (max-width: 745px)': {
    paddingTop: '120px',
    paddingLeft: '30px',
    paddingRight: '30px',
    paddingBottom: '30px'
  },
  '@media only screen and (min-width: 746px) and (max-width: 1023px)': {
    paddingLeft: '50px',
    paddingRight: '50px'
  }
};

export const HelpCenterHeaderTitle = {
  fontSize: '50px',
  fontWeight: 400,
  marginBottom: '20px'
};

export const HelpCenterHeaderParagraph = {
  ...HelpCenterHeaderTitle,
  fontSize: '16px'
};

export const HelpCenterCategoriesContainer = {
  padding: '40px 30px',
  background: theme.palette.common.lightGrey4,
  height: '100%',
  '@media only screen and (min-width: 320px) and (max-width: 745px)': {
    display: 'none'
  }
};

export const TabletCategoriesBox = {
  '@media only screen and (min-width: 746px) and (max-width: 1023px)': {
    flexBasis: '235px',
    maxWidth: '235px'
  }
};

export const HelpCenterCategoriesMobileContainer = {
  display: 'none',
  '@media only screen and (min-width: 320px) and (max-width: 745px)': {
    display: 'block',
    marginTop: '-80px',
    '> p': {
      marginBottom: '15px'
    },
    '> .MuiInputBase-root': {
      marginBottom: '15px',
      width: '100%',
      maxWidth: '300px'
    }
  }
};

export const HelpCenterArticlesContainer = {
  '@media only screen and (min-width: 320px) and (max-width: 745px)': {
    paddingLeft: 0,
    maxWidth: '100%',
    flexBasis: '100%'
  },
  '@media only screen and (min-width: 746px) and (max-width: 1023px)': {
    flex: '1',
    paddingLeft: '20px'
  }
};

export const HelpCenterCategoriesHeader = {
  fontSize: '16px',
  fontWeight: 700,
  color: theme.palette.common.darkBlue2,
  marginBottom: '20px'
};

export const HelpCenterCategoryItem = {
  color: theme.palette.common.darkGrey3,
  fontWeight: 400,
  paddingLeft: '30px',
  paddingRight: '30px',
  marginLeft: '-30px',
  marginRight: '-30px',
  paddingTop: '12px',
  paddingBottom: '12px',
  cursor: 'pointer'
};

export const HelpCenterCategoryItemActive = {
  background: '#F2F2F2',
  color: theme.palette.common.darkBlue7,
  fontWeight: 700,
  borderRight: `4px solid ${theme.palette.common.darkBlue7}`
};

export const HelpCenterCategoryTitle = {
  fontSize: '35px',
  color: theme.palette.common.darkGrey11,
  marginBottom: '40px',
  fontWeight: 700
};

export const HelpCenterArticleTitle = {
  color: theme.palette.common.darkGrey3,
  fontWeight: 700
};

export const HelpCenterArticleContent = {
  color: theme.palette.common.darkBlue2,
  fontSize: '14px',
  display: 'block'
};

export const HelpCenterArticleLink = {
  textDecoration: 'underline',
  cursor: 'pointer'
};

export const HelpCenterListHeader = {
  fontWeight: 700
};

export const HelpCenterContactUsContainer = {
  paddingTop: '20px',
  borderTop: `1px solid ${theme.palette.common.lightGrey1}`,
  marginTop: '20px'
};
export const HelpCenterContactUsLink = {
  color: theme.palette.common.black,
  fontWeight: 700,
  cursor: 'pointer'
};
export const HelpCenterArticleAccordion = {
  marginBottom: '10px',
  padding: '10px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
};
