import { Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { Img } from '../Primitive';
import {
  whatCanDoNextTileStepCircle,
  whatCanDoNextTileStepContainer
} from './WhatCanDoNextTileStep.styles';
import { theme } from '../../styles/theme';

interface WhatCanDoNextTileStep {
  icon: any;
  title: string;
  action: () => void;
}

export const WhatCanDoNextTileStep: FC<WhatCanDoNextTileStep> = ({ icon, action, title }) => {
  return (
    <>
      <Stack direction='row' sx={whatCanDoNextTileStepContainer}>
        <Stack sx={whatCanDoNextTileStepCircle}>
          <Img src={icon} />
        </Stack>
        <Typography onClick={action} sx={{ color: theme.palette.common.white, fontWeight: 600 }}>
          {title}
        </Typography>
      </Stack>
    </>
  );
};
