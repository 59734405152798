import { FC } from 'react';
import { Box, Grid, Stack, Typography, SxProps, Theme } from '@mui/material';
import {
  BoxTitle,
  firstItem,
  FlexCenter,
  GrayCircle,
  secondItem,
  SectionBoxes,
  SectionTitle,
  thirdItem
} from './CheckSystemRequirementsResource.styles';
import LaptopIcon from '@sharedImages/laptop-icon.svg';
import CameraIcon from '@sharedImages/camera--icon.svg';
import SpeakerIcon from '@sharedImages/speaker-icon.svg';
import WifiIcon from '@sharedImages/wifi--icon.svg';
import GovermentIcon from '@sharedImages/government-id--icon.svg';
import DesktopIcon from '@sharedImages/desktop-monitor--icon.svg';
import ClockIcon from '@sharedImages/clock--icon.svg';
import PersonIcon from '@sharedImages/person-icon.svg';
import BulbIcon from '@sharedImages/bulb-icon.svg';
import { Img } from '@barracuda/shared/src/components/Primitive';
import { RegularTextColor } from '../GettingReadyPage/GettingReadyPage.styles';
import { useTranslation } from 'react-i18next';

export const CheckSystemRequirementsResource: FC<{
  readonly sx?: SxProps<Theme>;
  readonly id?: string;
}> = ({ sx, id }) => {
  const { t } = useTranslation();

  return (
    <Stack sx={sx} id={id}>
      <Typography sx={SectionTitle} component='h3'>
        {t('gettingReadyPage.systemRequirementsHeader')}
      </Typography>
      <Typography sx={RegularTextColor}>{t('gettingReadyPage.systemRequirementsText')}</Typography>
      <Grid container sx={{ mt: '35px' }}>
        <Grid item xs={12} sm={6} md={4} sx={firstItem}>
          <Typography sx={BoxTitle}>
            {t('gettingReadyPage.systemRequirementsItem1Title')}
          </Typography>
          <Box sx={FlexCenter}>
            <Box sx={GrayCircle}>
              <Img src={LaptopIcon} />
            </Box>
            <Typography sx={{ marginLeft: '10px' }}>
              {t('gettingReadyPage.systemRequirementsItem1Line1')}
            </Typography>
          </Box>
          <Box sx={FlexCenter}>
            <Box sx={GrayCircle}>
              <Img src={SpeakerIcon} />
            </Box>
            <Typography sx={{ marginLeft: '10px' }}>
              {t('gettingReadyPage.systemRequirementsItem1Line2')}
            </Typography>
          </Box>
          <Box sx={FlexCenter}>
            <Box sx={GrayCircle}>
              <Img src={WifiIcon} />
            </Box>
            <Typography sx={{ marginLeft: '10px' }}>
              {t('gettingReadyPage.systemRequirementsItem1Line3')}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4} sx={secondItem}>
          <Typography sx={BoxTitle}>
            {t('gettingReadyPage.systemRequirementsItem2Title')}
          </Typography>
          <Box sx={FlexCenter}>
            <Box sx={GrayCircle}>
              <Img src={GovermentIcon} />
            </Box>
            <Typography sx={{ marginLeft: '10px' }}>
              {t('gettingReadyPage.systemRequirementsItem2Line1')}
            </Typography>
          </Box>
          <Box sx={FlexCenter}>
            <Box sx={GrayCircle}>
              <Img src={DesktopIcon} />
            </Box>
            <Typography sx={{ marginLeft: '10px' }}>
              {t('gettingReadyPage.systemRequirementsItem2Line2')}
            </Typography>
          </Box>
          <Box sx={FlexCenter}>
            <Box sx={GrayCircle}>
              <Img src={CameraIcon} />
            </Box>
            <Typography sx={{ marginLeft: '10px' }}>
              {t('gettingReadyPage.systemRequirementsItem2Line3')}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4} sx={thirdItem}>
          <Typography sx={BoxTitle}>
            {t('gettingReadyPage.systemRequirementsItem3Title')}
          </Typography>
          <Box sx={FlexCenter}>
            <Box sx={GrayCircle}>
              <Img src={ClockIcon} />
            </Box>
            <Typography sx={{ marginLeft: '10px' }}>
              {t('gettingReadyPage.systemRequirementsItem3Line1')}
            </Typography>
          </Box>
          <Box sx={FlexCenter}>
            <Box sx={GrayCircle}>
              <Img src={BulbIcon} />
            </Box>
            <Typography sx={{ marginLeft: '10px' }}>
              {t('gettingReadyPage.systemRequirementsItem3Line2')}
            </Typography>
          </Box>
          <Box sx={FlexCenter}>
            <Box sx={GrayCircle}>
              <Img src={PersonIcon} />
            </Box>
            <Typography sx={{ marginLeft: '10px' }}>
              {t('gettingReadyPage.systemRequirementsItem3Line3')}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Box sx={SectionBoxes}></Box>
    </Stack>
  );
};
