import { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import { indicatorStyle } from './RetakeIndicator.style';
import { useTranslation } from 'react-i18next';

interface RetakeIndicatorProps {
  readonly desktop?: boolean;
}

export const RetakeIndicator: FC<RetakeIndicatorProps> = ({ desktop }) => {
  const { t } = useTranslation();

  return (
    <Stack sx={indicatorStyle}>
      <CachedIcon sx={{ fontSize: '1rem' }} />
      <Typography sx={{ fontWeight: 700, fontSize: desktop ? '10px' : '12px' }}>
        {t('dashboardPage.retake')}
      </Typography>
    </Stack>
  );
};
