import { theme } from '../../styles/theme';

export const ScoresPopupContainer = {
  padding: '40px',
  height: '700px',
  overflowY: 'auto',
  '@media only screen and (min-width: 320px) and (max-width: 745px)': {
    padding: '30px 20px',
    '.scores-in-popup .scores-container': {
      padding: '30px 20px !important'
    }
  }
};

export const ScoresPopupHeaderContainer = {
  display: 'flex',
  alignItems: 'center',
  marginTop: '20px',
  paddingBottom: '30px',
  '@media only screen and (max-width: 745px)': {
    flexDirection: 'column',
    alignItems: 'flex-start'
  }
};

export const ScoresPopupHeaderShieldContainer = {
  display: 'flex',
  '@media only screen and (min-width: 320px) and (max-width: 745px)': {
    flexDirection: 'column',
    alignItems: 'center'
  }
};

export const ScoresPopupDialogWrapperTitle = {
  '@media only screen and (min-width: 320px) and (max-width: 745px)': {
    marginBottom: '15px'
  }
};

export const ScoresPopupHeaderShieldLeftContainer = {
  position: 'relative',
  maxWidth: '160px !important'
};

export const ScoresPopupHeaderShieldRightContainer = {
  marginLeft: '40px',
  flex: '1',
  '@media only screen and (min-width: 320px) and (max-width: 745px)': {
    marginLeft: 0,
    marginTop: '15px'
  }
};

export const ScoresPopupHeaderShieldScore = {
  position: 'absolute',
  top: '42%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  fontSize: '65px',
  color: theme.palette.common.lightGrey6,
  fontWeight: 700
};

export const scoresPopupBoldLabel = {
  fontWeight: 600,
  color: theme.palette.common.darkBlue1
};

export const gseLabel = {
  ...scoresPopupBoldLabel,
  fontSize: '16px',
  textAlign: 'center'
};

export const overallProficiencyLabel = {
  ...scoresPopupBoldLabel,
  marginBottom: '10px',
  fontSize: '18px'
};

export const testDataLabel = {
  ...scoresPopupBoldLabel,
  fontSize: '16px'
};

export const ScoresPopupDescription = {
  height: '120px',
  overflowY: 'auto',
  marginBottom: '10px',
  '@media only screen and (min-width: 320px) and (max-width: 745px)': {
    height: 'auto'
  }
};

export const FlexCenter = {
  display: 'flex',
  alignItems: 'center'
};

export const CloseIconStyles = {
  position: 'absolute',
  top: '20px',
  right: '20px',
  cursor: 'pointer'
};

export const SkillScoresContainer = {
  p: 0,
  mt: '60px',
  '.scores-container': {
    p: '0 !important'
  }
};

export const DashboardTestDetailsContainer = {
  padding: '70px',
  display: 'flex',
  gap: '20px',
  background: theme.palette.common.lightGrey4,
  justifyContent: 'center'
};
