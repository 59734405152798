import { theme } from '../../styles/theme';

export const SimpleNotificationContainer = {
  display: 'flex',
  alignItems: 'center',
  background: theme.palette.common.lightBlue0,
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: 1.6,
  color: theme.palette.common.darkGrey2,
  padding: '13px',
  position: 'relative'
};
export const CloseIconStyle = {
  cursor: 'pointer',
  position: 'absolute',
  top: '5px',
  right: '5px'
};
export const NotificationText = {
  width: 'calc(100% - 60px)'
};
