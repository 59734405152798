import { FC } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Grid, Typography } from '@mui/material';
import checkImage from '../../assets/images/check-blue--icon.svg';
import DesktopWindowsRoundedIcon from '@mui/icons-material/DesktopWindowsRounded';
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined';
import beforeStartTestImage from '../../assets/images/before-start-test--image.svg';
import { Img } from '../Primitive';
import { icon, iconRow, button, imageContainer, closeIcon } from './BeforeStartTestPopup.styles';
import { useTranslation } from 'react-i18next';

interface BeforeStartTestPopupProps {
  handleClose: () => void;
  handleStartTest: () => void;
  closeOnly?: boolean;
}

export const BeforeStartTestPopup: FC<BeforeStartTestPopupProps> = ({
  handleClose,
  handleStartTest,
  closeOnly
}) => {
  const { t } = useTranslation();

  return (
    <Box>
      <CloseIcon sx={closeIcon} onClick={handleClose} />
      <Typography sx={{ marginBottom: '40px' }}>
        {t('gettingReadyPage.startTestDialogParagraph1')}
      </Typography>
      <Grid container spacing='30px'>
        <Grid item xs={12} sm={6}>
          <Box sx={iconRow}>
            <Img sx={icon} src={checkImage} />
            <Typography>{t('gettingReadyPage.startTestDialogLi1')}</Typography>
          </Box>
          <Box sx={iconRow}>
            <Img sx={icon} src={checkImage} />
            <Typography>{t('gettingReadyPage.startTestDialogLi2')}</Typography>
          </Box>
          <Typography sx={{ mt: '30px' }}>
            {t('gettingReadyPage.startTestDialogParagraph2')}
          </Typography>
          <Box sx={[iconRow, { mt: '20px' }]}>
            <DesktopWindowsRoundedIcon sx={icon} />
            <Typography>{t('gettingReadyPage.startTestDialogLi3')}</Typography>
          </Box>
          <Box sx={iconRow}>
            <PhotoCameraOutlinedIcon sx={icon} />
            <Typography>{t('gettingReadyPage.startTestDialogLi4')}</Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} sx={imageContainer}>
          <Img sx={{ maxWidth: '100%' }} src={beforeStartTestImage} />
        </Grid>
      </Grid>

      <Button onClick={handleStartTest} sx={button}>
        {closeOnly
          ? t('gettingReadyPage.startTestDialogButtonClose')
          : t('gettingReadyPage.startTestDialogButtonContinue')}
      </Button>
    </Box>
  );
};
