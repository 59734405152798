import { theme } from '@barracuda/shared/src/styles/theme';

export const productsListWrapperContainer = {
  padding: '0 65px 65px 65px',
  '@media screen and (max-width: 1650px)': {
    padding: '0 10px 65px 30px'
  },
  '@media screen and (max-width: 770px)': {
    padding: '0 10px 65px 30px',
    gap: '15px'
  },
  gap: '30px',
  justifyContent: 'center'
};

export const productsListWrapperProduct = {
  maxWidth: '390px',
  width: 'calc(33.33% - 30px)',
  '@media screen and (max-width: 770px)': {
    width: 'calc(50% - 15px)'
  }
};

export const productsListWrapperRedeemBar = {
  display: 'flex',
  width: { xs: 'unset', md: '100%' },
  padding: '2px',
  alignItems: 'center',
  borderRadius: '4px',
  border: `1px solid ${theme.palette.common.lightGrey1}`,
  backgroundColor: theme.palette.common.white,
  marginInline: '15px',
  position: 'relative'
};

export const productsListWrapperRedeemBarBluePipe = {
  width: '3px',
  height: '100%',
  position: 'absolute',
  borderRadius: '6px 0 0 6px',
  backgroundColor: theme.palette.common.darkTeal
};

export const productsListWrapperRedeemContent = {
  padding: '8px',
  display: 'flex',
  alignItems: { xs: 'flex-start', sm: 'center' },
  flexFlow: { xs: 'column', sm: 'row' }
};

export const productsListWrapperRedeemTooltip = {
  marginLeft: '8px',
  textDecoration: 'underline',
  paddingRight: '10px',
  cursor: 'pointer'
};
