import { theme } from '@theme';

export const AccordionBox = {
  width: '80%',
  maxWidth: '1150px',
  '@media screen and (max-width: 1100px)': {
    width: '92%'
  },
  marginBottom: '15px',
  marginTop: '0 !important',
  padding: 0,
  boxShadow: 'none',
  borderRadius: '8px',
  background: theme.palette.common.white,
  border: `1px solid ${theme.palette.common.lightGrey3}`,
  '&::before': {
    position: 'unset'
  }
};

export const AccordionTitle = {
  fontWeight: 600,
  fontSize: '20px',
  lineHeight: 1.2,
  color: theme.palette.common.darkGrey2,
  paddingLeft: '15px'
};

export const AccordionSubtitle = {
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: 1.2,
  color: theme.palette.common.darkBlue2,
  paddingLeft: '15px'
};

export const GrayButton = {
  height: '32px',
  margin: 0,
  minWidth: '120px'
};

export const LatestTestBadge = {
  background: theme.palette.common.lightBlue0,
  padding: '5px',
  borderRadius: '4px',
  '> span ': {
    fontWeight: 700
  }
};
