import { theme } from '@theme';

export const scoreReportViewDocumentContainer = {
  padding: '50px 70px 50px 70px',
  width: '100%',
  maxWidth: '1280px',
  margin: '0 auto',
  minHeight: 'calc(100vh - 231px)',
  '@media screen and (max-width: 1199px)': {
    padding: '50px 30px 50px 30px'
  },
  '@media screen and (max-width: 460px)': {
    padding: '50px 10px 50px 10px'
  }
};

export const scoreReportViewTitleContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  paddingBottom: '40px',
  '@media screen and (max-width: 600px)': {
    flexFlow: 'column',
    gap: 0,
    alignItems: 'flex-start'
  }
};

export const scoreReportViewTitleActions = {
  display: 'flex',
  alignItems: 'center',
  '@media screen and (max-width: 800px)': {
    flexFlow: 'column',
    gap: '20px'
  },
  '@media screen and (max-width: 560px)': {
    flexFlow: 'row',
    alignItems: 'center'
  }
};

export const scoreReportViewTitle = {
  fontFamily: 'Epilogue',
  fontWeight: 800,
  fontSize: '40px',
  lineHeight: '48px',
  color: theme.palette.common.darkGrey11,
  '@media screen and (min-width: 561px) (max-width: 1199px)': {
    fontSize: '34px'
  },
  '@media screen and (max-width: 560px)': {
    fontSize: '28px',
    fontWeight: 400
  },
  '@media screen and (max-width: 380px)': {
    fontSize: '26px'
  }
};

export const scoreReportViewTitleLink = {
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: 1.5,
  textDecoration: 'none',
  color: theme.palette.common.darkBlue7,
  marginRight: '20px',
  cursor: 'pointer',
  '@media screen and (max-width: 380px)': {
    fontSize: '14px'
  }
};

export const scoreReportViewCertificateHeader = (isMTS: boolean) => ({
  background: isMTS
    ? `linear-gradient(180deg, ${theme.palette.common.darkBlue11} -25.76%, ${theme.palette.common.darkBlue12} 64.01%, ${theme.palette.common.darkBlue13} 156.52%)`
    : `linear-gradient(141deg, ${theme.palette.common.violet} 0, ${theme.palette.common.violetDark2} 100%) !important`,
  height: '210px',
  display: 'flex',
  alignItems: 'center',
  '@media screen and (max-width: 560px)': {
    borderTopRightRadius: '8px',
    borderTopLeftRadius: '8px',
    left: 0,
    top: 0,
    width: '100%',
    position: 'absolute'
  }
});

export const scoreReportViewCertificateTitle = {
  paddingTop: '44px',
  textAlign: 'center',
  '@media screen and (max-width: 560px)': {
    marginTop: '210px'
  },
  background: theme.palette.common.lightGrey4
};

export const scoreReportViewCertificateHeaderImage = {
  marginLeft: '39px',
  paddingRight: '22px',
  '@media screen and (max-width: 460px)': {
    marginLeft: '15px'
  }
};

export const scoreReportViewCertificateHeaderImagePhoto = {
  border: '3px solid rgba(255, 255, 255, 0.85)',
  padding: '5px',
  borderRadius: '100px'
};

export const scoreReportViewCertificateHeaderData = {
  color: theme.palette.common.lightGrey4
};

export const scoreReportViewCertificateOverall = {
  background: theme.palette.common.lightGrey4,
  paddingTop: '83px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '@media screen and (max-width: 700px)': {
    flexFlow: 'column',
    marginTop: 0,
    'div:nth-of-type(1)': {
      width: '100%',
      order: '2'
    },
    'div:nth-of-type(2)': {
      order: '1',
      paddingRight: 0
    }
  },
  paddingBottom: '70px'
};

export const scoreReportViewCertificateOverallTitle = {
  fontFamily: 'Epilogue',
  fontSize: '28px',
  fontWeight: 800,
  color: theme.palette.common.darkGrey3,
  paddingBottom: '10px'
};

export const scoreReportViewCertificateOverallDescription = {
  color: theme.palette.common.darkBlue1,
  fontWeight: 400,
  fontSize: '16px',
  paddingBottom: '36px'
};

export const scoreReportViewCertificateSkillScores = {
  marginTop: '100px',
  display: 'flex',
  justifyContent: 'space-between',
  '@media screen and (max-width: 600px)': {
    width: '100%'
  },
  '@media screen and (max-width: 1199px)': {
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start'
  }
};

export const scoreReportViewCertificateSkillScoresTitle = {
  fontFamily: 'Epilogue',
  color: theme.palette.common.darkGrey3,
  fontSize: '28px',
  fontWeight: 800,
  paddingBottom: '62px'
};

export const scoreReportViewScaleDescription = {
  marginTop: '60px',
  width: '50%',
  '@media screen and (max-width: 600px)': {
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto'
  }
};

export const scoreReportViewSampleResponses = {
  marginTop: '100px'
};

export const scoreReportViewSampleResponseTitle = {
  fontFamily: 'Epilogue',
  color: theme.palette.common.darkGrey3,
  fontSize: '28px',
  fontWeight: 800,
  paddingBottom: '33px'
};

export const scoreReportViewDownloadButtonAlign = {
  marginBottom: 0,
  fontSize: '14px',
  ':hover > svg > path': {
    fill: theme.palette.common.darkGrey3
  },
  '@media screen and (max-width: 800px)': {
    order: '1'
  },
  '@media screen and (max-width: 600px)': {
    display: 'none'
  }
};

export const scoreReportViewDownloadButtonAlignMobile = {
  marginBottom: 0,
  display: 'none',
  position: 'fixed',
  bottom: '75px',
  right: '17px',
  borderRadius: '100%',
  width: '48px',
  height: '48px',
  zIndex: '100',
  padding: '5px 10px',
  ':hover > svg > path': {
    fill: theme.palette.common.darkGrey3
  },
  '@media screen and (max-width: 601px)': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
};

export const scoreReportViewUsernameText = {
  fontSize: '40px',
  fontWeight: 300,
  '@media screen and (max-width: 700px)': {
    fontSize: '24px'
  }
};

export const scoreReportViewTestIdBox = {
  display: 'flex',
  alignItems: 'center'
};

export const scoreReportViewTestIdText = {
  fontSize: '14px',
  fontWeight: 600,
  '@media screen and (max-width: 700px)': {
    fontSize: '12px'
  }
};

export const scoreReportViewTestIdValue = {
  marginLeft: '5px',
  fontWeight: 300,
  '@media screen and (max-width: 700px)': {
    fontSize: '12px'
  }
};

export const scoreReportViewCertificateTitleText = {
  color: theme.palette.common.darkGrey2,
  fontSize: '40px',
  fontWeight: 400,
  '@media screen and (max-width: 700px)': {
    fontSize: '30px'
  }
};

export const scoreReportViewCertificateValueText = {
  color: theme.palette.common.violet,
  fontSize: '30px',
  fontWeight: 800,
  '@media screen and (max-width: 700px)': {
    fontSize: '20px'
  }
};

export const scoreReportViewCertificateGraphBox = {
  width: '492px',
  height: '373px',
  marginLeft: '30px',
  '@media screen and (max-width: 600px)': {
    width: '90%',
    marginLeft: '30px'
  },
  '@media screen and (max-width: 560px)': {
    height: '250px'
  },
  '@media screen and (max-width: 420px)': {
    height: '200px'
  }
};

export const scoreReportViewAccordionStackStyle = {
  width: '40%',
  marginTop: '60px',
  position: 'absolute',
  right: 0,
  '@media screen and (max-width: 1199px)': {
    position: 'initial',
    width: '530px',
    marginLeft: '30px'
  },
  '@media screen and (max-width: 600px)': {
    width: '95%',
    marginLeft: '10px'
  }
};

export const scoreReportViewRectanglesContainer = {
  display: 'flex',
  gap: '50px',
  '@media screen and (max-width: 700px)': {
    flexFlow: 'column'
  }
};

export const scoreReportViewLinksHide = {
  '@media screen and (max-width: 800px)': {
    order: '2',
    marginRight: 0
  }
};

export const scoreReportViewResponsiveBadge = {
  position: 'relative',
  '@media screen and (max-width: 700px)': {
    marginTop: '40px',
    marginBottom: '40px'
  }
};

export const scoreReportViewMobileBoxShadow = {
  position: 'relative',
  '@media screen and (max-width: 560px)': {
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '8px',
    padding: '25px 25px 50px 25px'
  }
};

export const scoreReportViewViewTextStyle = {
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: 1.5,
  textDecoration: 'none',
  color: theme.palette.common.darkBlue7,
  '@media screen and (max-width: 500px)': {
    display: 'none'
  }
};

export const scoreReportViewPillStyles = {
  borderRadius: '30px',
  background: 'rgba(255, 255, 255, 0.60)',
  padding: '3px 15px',
  color: theme.palette.common.darkGrey2,
  fontSize: '10px',
  fontStyle: 'normal',
  fontWeight: 700
};
