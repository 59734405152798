import { Cart, CartItem, ELLCommerce, EProduct, ShoppingCart } from '@barracuda/shared/src';
import { CartProduct, PolicieLink } from '@pearson-ell/commerce-ui';
import React, { FC, useContext, useState } from 'react';
import { AppContext } from '../../context/App.context';
import { CouponCode } from '@pearson-ell/commerce-sdk';
import { useTranslation } from 'react-i18next';
import { useLocalizedNavigate } from 'src/hooks/useLocalizedNavigate';
import { ellCommerceInstanceConfig } from '../../utils/ell-commerce';

export const ShopWrapper: FC = () => {
  const { setLoading, cart, setCart, user, eCommerceCountryISO2 } = useContext(AppContext);
  const [products] = useState<EProduct[]>();
  const navigate = useLocalizedNavigate();
  const { t } = useTranslation();
  const policiesLinks: PolicieLink[] = [
    { name: t('policiesLinks.termsOfUse') },
    { name: t('policiesLinks.privacyPolicy') }
  ];

  const eCommerce = ELLCommerce.getInstance({
    ...ellCommerceInstanceConfig,
    defaultCountryISO2: eCommerceCountryISO2 || 'US'
  });

  const updateCart = (ev: React.ChangeEvent<HTMLInputElement>, item: CartItem) => {
    setLoading?.(true);
    eCommerce
      .CartService()
      .updateCartItem(cart?.id as string, item.id, {
        productId: item.productId,
        variantId: item.variantId,
        quantity: +ev.target.value,
        sku: item.sku
      })
      .then((r: Cart) => {
        setCart(r);
        setLoading?.(false);
      })
      .catch(() => {
        setLoading?.(false);
      })
      .finally(() => {
        setLoading?.(false);
      });
  };
  const redirectToCheckout = () => {
    setLoading?.(true);
    eCommerce
      .CartService()
      .getCheckoutUrl(user?.externalId || '', cart?.id || '', eCommerceCountryISO2)
      .then((r: string) => {
        window.open(r, '_self')?.addEventListener('load', () => {
          setLoading?.(false);
        });
      });
  };

  const removeItemFromCart = (
    e: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>,
    itemId: string
  ) => {
    if (e && cart?.id) {
      setLoading?.(true);
      eCommerce
        .CartService()
        .deleteCartItem(cart.id, itemId)
        .then(
          (r) => {
            setCart(r);
            setLoading?.(false);
          },
          () => {
            setCart(null);
            setLoading?.(false);
          }
        );
    }
  };

  const generateProducts = () => {
    return cart?.items.map((item: CartItem, index: number) => (
      <CartProduct
        key={`cartProduct-${index}`}
        item={item}
        onChange={(ev, item) => {
          if (item) updateCart(ev, item);
        }}
        onDelete={removeItemFromCart}
        debounceChangeQty={1000}
        hasDescription={!!item.description}
        currency={cart?.currency}
      />
    ));
  };
  const policiesLinksCallback = (
    e: React.MouseEvent<HTMLLinkElement> | React.KeyboardEvent<HTMLLinkElement>,
    linkName: string
  ) => {
    console.log(e);

    switch (linkName) {
      case t('policiesLinks.privacyPolicy'):
        return window.open(process.env.REACT_APP_PRIVACY_POLICY_LINK, '_blank');
      case t('policiesLinks.termsOfUse'):
        return navigate('../terms-of-use');
    }
  };

  const handleContinueShopping = () => navigate('../explore-tests');

  const applyCouponCode = async (cartId: string, coupon: CouponCode) => {
    const cartResponse = await eCommerce.CartService().applyCoupon(cartId, coupon);
    setCart(cartResponse);
    return eCommerce.CartService().getCart(cartId);
  };

  const withdrawCouponCode = async (cartId: string, code: string) => {
    const cartResponse = await eCommerce.CartService().removeCoupon(cartId, code);
    setCart(cartResponse);
    return eCommerce.CartService().getCart(cartId);
  };

  return (
    <>
      <ShoppingCart
        cart={cart}
        policiesLinks={policiesLinks}
        policiesLinksCallback={policiesLinksCallback}
        products={products}
        cartProducts={generateProducts()}
        redirectToCheckout={redirectToCheckout}
        onContinueShoppingClick={handleContinueShopping}
        applyCouponCode={applyCouponCode}
        withdrawCouponCode={withdrawCouponCode}
      />
    </>
  );
};
