import { theme } from '../../styles/theme';

export const PreviewHeader = {
  display: 'flex',
  justifyContent: 'space-between',
  paddingBottom: '100px',
  padding: '32px 42px 32px 42px'
};

export const PreviewHeaderTitle = {
  fontWeight: 700,
  fontSize: '18px',
  lineHeight: 1.3,
  letterSpacing: '0.02em',
  color: theme.palette.common.black
};

export const PreviewHeaderContentText = {
  fontWeight: 600,
  fontSize: '18px',
  lineHeight: 1.5,
  color: theme.palette.common.darkBlue1,
  paddingBottom: '37px'
};

export const PreviewHeaderContent = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  flexFlow: 'column',
  paddingBottom: '30px',
  paddingLeft: '30px',
  paddingRight: '30px'
};

export const PreviewFooter = {
  display: 'flex',
  height: '90px',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'theme.palette.common.lightBlue00'
};

export const PreviewContainer = {
  borderRadius: '0 0 8px 8px'
};
