import { FC } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { Img } from '../Primitive';
import checkIconPurple from '../../assets/images/check-icon.svg';
import {
  AuthPageLeftPanelColumn,
  AuthPageLeftPanelColumnBox,
  AuthPageLeftPanelColumnContent,
  AuthPageLeftPanelLeftColumnTitle,
  AuthPageLeftPanelLeftMainImage,
  AuthPageLeftPanelMainContainer,
  AuthPageLeftPanelProductName,
  AuthPageLeftPanelProps,
  AuthPageLeftPanelPropsContainer
} from './AuthPageLeftPanel.styles';

type AuthPageLeftPanelProps = {
  advantages: string[];
  headerWeb: string;
  description?: string;
  image: string;
  type?: 'join' | 'login';
};

export const AuthPageLeftPanel: FC<AuthPageLeftPanelProps> = ({
  advantages,
  headerWeb,
  description,
  image,
  type
}) => {
  const { t } = useTranslation();
  const Content = () => (
    <Box sx={AuthPageLeftPanelMainContainer(type)}>
      <Box sx={AuthPageLeftPanelColumnBox(type)}>
        <Typography sx={AuthPageLeftPanelProductName} component='p'>
          {t('versantByPearson')}
        </Typography>
        <Typography sx={AuthPageLeftPanelLeftColumnTitle(type)} component='h1'>
          <Trans i18nKey={headerWeb} />
        </Typography>
        {description && (
          <Typography sx={AuthPageLeftPanelColumnContent} component='p'>
            <Trans i18nKey={description} />
          </Typography>
        )}
        <Box sx={AuthPageLeftPanelPropsContainer}>
          <Box sx={{ maxWidth: '63%' }}>
            {advantages.map((information: string, index: number) => (
              <Box sx={AuthPageLeftPanelProps} key={`${information}-${index}`}>
                <Img
                  src={checkIconPurple}
                  sx={{
                    marginRight: '1rem'
                  }}
                />
                <Typography>{information as string}</Typography>
              </Box>
            ))}
          </Box>
          <Img sx={AuthPageLeftPanelLeftMainImage} src={image} />
        </Box>
      </Box>
    </Box>
  );

  const Screen = () =>
    type === 'join' ? (
      <Grid sx={AuthPageLeftPanelColumn} item xs={12} md={7}>
        <Content />
      </Grid>
    ) : (
      <Content />
    );
  return <Screen />;
};
