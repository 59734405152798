import { FC } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { theme } from '@theme';
import { Question } from '../HelpCenter/HelpCenter';
import { questionItem, questionItemDetails } from './QuestionItem.styles';
import { MarkdownRenderer } from '../MarkdownRenderer/MarkdownRenderer';

interface QuestionItemProps {
  readonly question: Question;
  readonly expanded?: boolean;
  readonly onClick: () => void;
  readonly categoryId: number;
  readonly index: number;
}

export const QuestionItem: FC<QuestionItemProps> = ({
  question,
  expanded,
  onClick,
  categoryId,
  index
}) => {
  return (
    <Accordion
      id={question.faqUrl}
      defaultExpanded={expanded}
      sx={questionItem}
      key={`question-item-${categoryId}-${index}-${question.questionId}-accordion`}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel-${index}-content`}
        id={`panel-${index}-header`}
        onClick={onClick}
      >
        <Typography sx={{ color: theme.palette.common.darkGrey3, fontWeight: 700 }}>
          {question.questionLabel}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <MarkdownRenderer markdown={question.text} sx={questionItemDetails} />
      </AccordionDetails>
    </Accordion>
  );
};
