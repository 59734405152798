export enum GenderType {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER = 'OTHER'
}

export interface GenderOption {
  id: GenderType;
  name: string;
}

export const genderOptions: GenderOption[] = [
  { id: GenderType.MALE, name: 'Male' },
  { id: GenderType.FEMALE, name: 'Female' },
  { id: GenderType.OTHER, name: 'Other' }
];
