import { FC, useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  AlignedBox,
  AllResultsLink,
  ForwardIcon,
  ResultDetailsLink,
  ShareResultsTitle
} from '../ShareResults/ShareResults.style';
import { Box, Link, Stack } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Typography from '@mui/material/Typography';
import {
  emailPreviewActiveLink,
  emailPreviewContainer,
  emailPreviewDisplayBox,
  emailPreviewTemplateContainer,
  emailPreviewTitleAndBox
} from './EmailPreview.style';
import { sopClient } from '@barracuda/shared/src';
import { useTranslation } from 'react-i18next';
import { useLocalizedNavigate } from '../../hooks/useLocalizedNavigate';

export const EmailPreview: FC = () => {
  const [preview, setPreview] = useState('');
  const navigate = useLocalizedNavigate();
  const { id } = useParams();
  const { state, pathname } = useLocation();
  const { t } = useTranslation();
  const emailTemplate = useCallback(
    (node: HTMLElement) => {
      if (node) {
        addMediaBreakpointsToTemplate();
        disableButtonAction();
      }
    },
    [preview]
  );
  useEffect(() => {
    const endpoint = pathname.includes('my-top-score')
      ? sopClient.myTopScoreEmailPreview
      : sopClient.getLicensesEmailPreview;
    endpoint({ email: state.email, company: state.organizationName }, id || '').then((response) => {
      const { data } = response;
      setPreview(data);
      disableLinkInPreviewMode();
      if (window.screen.width < 600) {
        addMediaBreakpointsToTemplate();
      }
    });
  }, []);

  const disableLinkInPreviewMode = () => {
    const linkElement = document.getElementById('redirectToScoresLink') as HTMLAnchorElement;
    if (linkElement) {
      linkElement.removeAttribute('href');
    }
  };
  const disableButtonAction = () => {
    const anchorLink = document.getElementsByClassName('button__black')[0] as HTMLAnchorElement;
    if (anchorLink) {
      anchorLink.style.setProperty('cursor', 'default');
      anchorLink.style.setProperty('pointer-events', 'none');
    }
  };
  const goToPreviousRoute = () => {
    const routePart = pathname.split('email-preview')[0];
    navigate(routePart);
  };
  const goToResultDetails = () => {
    const routePart = pathname.includes('my-top-score')
      ? `${pathname.split('my-top-score')[0]}my-top-score`
      : pathname.split('/share/email-preview')[0];
    navigate(routePart);
  };
  const addMediaBreakpointsToTemplate = () => {
    const template = document.getElementById('preview-container');
    template?.removeAttribute('style');
  };
  return (
    <>
      <Box sx={emailPreviewContainer}>
        <Box sx={AlignedBox}>
          <Link onClick={() => navigate('/dashboard/results')} sx={AllResultsLink}>
            {t('results.allResults')}
          </Link>
          <ArrowForwardIosIcon sx={ForwardIcon} />
          <Link onClick={() => goToResultDetails()} sx={ResultDetailsLink}>
            {t('results.resultDetails')}
          </Link>
          <ArrowForwardIosIcon sx={ForwardIcon} />
          <Link onClick={() => goToPreviousRoute()} sx={ResultDetailsLink}>
            {t('results.shareResults')}
          </Link>
          <ArrowForwardIosIcon sx={ForwardIcon} />
          <Typography sx={emailPreviewActiveLink}>{t('results.emailPreview')}</Typography>
        </Box>
        <Stack sx={emailPreviewTitleAndBox}>
          <Typography sx={ShareResultsTitle} component='h1'>
            {t('results.emailPreview')}
          </Typography>
          <Box sx={emailPreviewDisplayBox}>
            <Box
              ref={emailTemplate}
              sx={emailPreviewTemplateContainer}
              dangerouslySetInnerHTML={{ __html: preview }}
            ></Box>
          </Box>
        </Stack>
      </Box>
    </>
  );
};
