import { FC, useEffect } from 'react';
import Hotjar from '@hotjar/browser';

export const HotjarTracker: FC<{
  readonly siteId?: string;
  readonly version?: string;
}> = ({ siteId, version }) => {
  useEffect(() => {
    if (siteId && version) {
      Hotjar.init(Number(siteId), Number(version));
    }
  }, []);

  return null;
};
