import { theme } from '@barracuda/shared/src/styles/theme';

export const downloadCertificatePopupLanguageRow = {
  borderRadius: '8px',
  background: theme.palette.common.white,
  border: `1px solid ${theme.palette.common.borderGray}`,
  padding: '10px 20px',
  marginBottom: '10px',
  cursor: 'pointer'
};
export const downloadCertificatePopupRadioViolet = {
  '&.Mui-checked': {
    color: theme.palette.common.violet
  }
};

export const downloadCertificatePopupResponsiveConditions = {
  maxWidth: '600px',
  minHeight: { md: '400px', xs: '250px' },
  '@media screen and (max-height: 700px)': {
    minHeight: { md: '280px' }
  },
  '@media screen and (max-height: 500px)': {
    minHeight: 'unset'
  }
};
