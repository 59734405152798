import { theme } from '@theme';

export const NoTestsBox = {
  background: theme.palette.common.white,
  border: '0.8px solid #727272',
  borderRadius: '8px',
  padding: { xs: '15px', md: '25px 20px' },
  display: 'flex',
  alignItems: 'center',
  height: '100%'
};
export const NoTestsDescription = {
  display: 'flex',
  justifyContent: 'center',
  marginLeft: '30px'
};
export const AvailableTestsTitle = {
  fontWeight: 600,
  fontSize: '20px',
  color: theme.palette.common.darkGrey2,
  display: 'flex',
  alignItems: 'center',
  paddingBottom: '20px',
  width: '100%',
  '@media screen and (max-width: 400px)': {
    marginLeft: '20px'
  }
};
export const TestsCount = {
  fontSize: '14px',
  color: theme.palette.common.darkBlue2,
  fontWeight: 400,
  marginLeft: '12px'
};

export const NoTestsText = {
  paddingBottom: '30px',
  '@media screen and (max-width: 700px)': {
    paddingBottom: '10px'
  }
};
