import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Link, Stack, Typography } from '@mui/material';
import {
  LinkStyle,
  ScoreData,
  ScoreTitle,
  ShieldContainer,
  ShieldScore,
  TileBody,
  TileContainer,
  TileFooter,
  TileHead
} from './MyTopScoreTile.style';
import shield from '../../assets/images/score-shield--icon.svg';
import { NavigateFunction } from 'react-router-dom';
import type { MyTopScore } from '../../interface/my-top-score.interface';
import { Badge } from '../../interface/badge.interface';
import { Img } from '../Primitive';
import moment from 'moment';
import ArrowRight from '../../assets/images/arrow-right.svg';
import { StatusBadge } from '../StatusBadge';
import { CredlyBadgeDialog } from '../CredlyBadgeDialog/CredlyBadgeDialog';

export interface MyTopScoreTileProps {
  myTopScore: MyTopScore | undefined;
  navigate: NavigateFunction;
  badge?: Badge;
}

export const MyTopScoreTile: FC<MyTopScoreTileProps> = ({ myTopScore, badge, navigate }) => {
  const { t } = useTranslation();
  const [credlyOpened, setCredlyOpened] = useState<boolean>(false);
  return (
    <>
      <Stack sx={TileContainer}>
        <Box sx={TileHead}>
          <Typography component='span'>{t('results.myTopScoreHeader')}</Typography>
        </Box>
        <Box sx={TileBody}>
          <Box sx={ShieldContainer}>
            <Img width={80} alt='Shield icon' src={shield} />
            <Typography sx={ShieldScore}>{myTopScore?.score}</Typography>
          </Box>
          <Stack sx={ScoreData}>
            <Typography sx={ScoreTitle}>{t('english4SkillsTestName')}</Typography>
            <StatusBadge type='CERTIFIED' />
            <Link sx={LinkStyle} onClick={() => setCredlyOpened(true)}>
              {t('shareOnCredly')}
            </Link>
          </Stack>
        </Box>
        <Box sx={TileFooter} onClick={() => navigate(`./my-top-score`)}>
          <Typography>{`${t('generatedOn')} ${moment(myTopScore?.issuedAt).format(
            'DD MMMM yyyy'
          )}`}</Typography>
          <Img width={9} src={ArrowRight} />
        </Box>
      </Stack>
      {badge && (
        <CredlyBadgeDialog
          isOpen={credlyOpened}
          onCloseDialogClick={() => setCredlyOpened(false)}
          badgeImageUrl={badge.imageUrl}
          redeemed={badge.redeemed}
          acceptBadgeUrl={badge.acceptBadgeUrl}
        />
      )}
    </>
  );
};
