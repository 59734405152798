import { Box, Typography } from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';
import {
  CountdownDesc,
  CountdownValue,
  CounterBox,
  CounterContainer
} from './UploadIdCounter.style';
import ClockIcon from '../../assets/images/clock.svg';
import { Img } from '@barracuda/shared/src/components/Primitive';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

interface UploadIdCounterProps {
  setExpired: () => void;
  expirationTime: number | null;
  viewMode: 'all-done' | string;
}

export const UploadIdCounter: FC<UploadIdCounterProps> = ({
  setExpired,
  expirationTime,
  viewMode
}) => {
  const { t } = useTranslation();
  const [countDownDate] = useState(
    expirationTime ? new Date(expirationTime).getTime() - 1000 * 60 : null
  );
  const [countDown, setCountDown] = useState('');
  const [countDownNumber, setCountDownNumber] = useState<number>(countDownDate || 0);
  const countDownInterval = useRef<NodeJS.Timer>();

  useEffect(() => {
    if (countDownDate) {
      let differenceMs = moment(countDownDate).diff(new Date(), 'millisecond');
      const showHours = differenceMs > 1000 * 60 * 60;
      countDownInterval.current = setInterval(() => {
        differenceMs = moment(countDownDate).diff(new Date(), 'millisecond');
        setCountDownNumber(differenceMs);
        setCountDown(
          formatDifference(moment(countDownDate).diff(new Date(), 'seconds'), showHours)
        );
        if (countDownDate - new Date().getTime() <= 0) {
          setExpired();
          setCountDown('00:00');
          clearInterval(countDownInterval.current);
        }
      }, 1000);
      return () => clearInterval(countDownInterval.current);
    }
    return () => null;
  }, []);

  useEffect(() => {
    if (viewMode === 'all-done' || viewMode === 'all-done-failed') {
      clearInterval(countDownInterval.current);
    }
  }, [viewMode]);

  const formatDifference = (seconds: number, showHours: boolean) => {
    const secondsValue = `0${seconds % 60}`.slice(-2);
    const minutesValue = `0${Math.floor(seconds / 60) % 60}`.slice(-2);
    const hoursValue = Math.floor(seconds / 3600);
    return showHours
      ? `${hoursValue}:${minutesValue}:${secondsValue}`
      : `${minutesValue}:${secondsValue}`;
  };

  return (
    <Box sx={CounterContainer}>
      <Typography sx={CountdownDesc}>{t('mobileUploadId.timeLeftToSubmit')}:</Typography>
      <Box sx={CounterBox(countDownNumber)}>
        <Img src={ClockIcon} />
        <Typography sx={CountdownValue}>{countDown}</Typography>
      </Box>
    </Box>
  );
};
