import { $fontOpenSans, theme } from '../../styles/theme';

export const JoinWebSection = {
  background: '#F9F9F9',
  height: '100%',
  minHeight: '100vh',
  position: 'relative',
  padding: '40px',
  '@media screen and (max-width: 899px)': {
    padding: '13px'
  }
};

export const JoinWebInformationProps = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: '1rem'
};

export const JoinWebContainer = {
  marginTop: '90px',
  height: 'calc(100% - 120px)',
  maxWidth: '1366px',
  marginLeft: 'auto !important',
  marginRight: 'auto !important',
  justifyContent: 'stretch',
  minHeight: '755px',
  '@media screen and (max-width: 899px)': {
    marginTop: '90px'
  }
};

export const JoinWebColumn = {
  height: 'auto',
  '@media screen and (max-width: 899px)': {
    paddingLeft: '0 !important'
  }
};

export const JoinWebColumnBox = {
  background: theme.palette.common.white,
  padding: '50px',
  overflowX: 'hidden',
  button: {
    maxWidth: 'unset'
  },
  '@media screen and (max-width: 899px)': {
    padding: '25px',
    maxWidth: '563px',
    margin: 'auto'
  }
};

export const JoinWebRightColumnBox = {
  display: 'flex',
  flexDirection: 'column'
};

export const JoinWebLeftColumn = {
  display: 'flex',
  paddingLeft: '60px',
  paddingRight: '60px',
  flex: '1',
  textAlign: 'left',
  justifyContent: 'flex-start',
  alignItems: 'start',
  flexDirection: 'column',
  height: '100%',
  '@media screen and (max-width: 899px)': {
    display: 'none'
  }
};

export const JoinWebLeftColumnSubtitle = {
  color: theme.palette.common.violet,
  textTransform: 'uppercase',
  fontWeight: 400,
  lineHeight: 2,
  letterSpacing: '3px',
  width: '100%',
  textAlign: 'center',
  '@media screen and (min-width: 899px)': {
    display: 'none'
  }
};
export const LeftColumnProductName = {
  color: theme.palette.common.violet,
  fontSize: '16px',
  fontWeight: 400,
  letterSpacing: '3px',
  marginTop: '150px'
};

export const JoinWebLeftColumnTitle = {
  fontSize: '72px',
  fontWeight: 800,
  color: theme.palette.common.darkGrey6,
  lineHeight: '80px',
  letterSpacing: '-2px',

  '@media screen and (max-width: 1400px)': {
    fontSize: '60px',
    lineHeight: '70px'
  }
};

export const MobileColumnTitle = {
  fontSize: '35px',
  fontWeight: 800,
  color: theme.palette.common.darkGrey6,
  marginBottom: '70px',
  paddingLeft: '30px',
  paddingRight: '30px',
  fontFamily: $fontOpenSans,
  lineHeight: 1,
  textAlign: 'center',
  '@media screen and (min-width: 899px)': {
    display: 'none'
  },
  '@media only screen and (max-width: 899px)': {
    marginTop: '40px',
    marginBottom: '40px'
  },
  '@media screen and (max-width: 428px)': {
    paddingLeft: '15px',
    paddingRight: '15px',
    fontSize: '30px'
  }
};

export const consentSignUpContainer = {
  display: 'flex',
  height: '100vh',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#F9F9F9'
};
export const consentSignUpTitle = {
  fontWeight: 700,
  fontSize: '20px',
  paddingBottom: '37px'
};

export const consentsSignUpButtons = {
  display: 'flex',
  gap: '25px',
  marginTop: '50px'
};
