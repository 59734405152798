import { FC } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { SignInButton } from '../SignIn/SignInStyles';
import DancingPeople from '../../assets/images/dancing-people.svg';
import {
  MarginAlign,
  WelcomePopupButtonContainer,
  WelcomePopupImageCenter
} from './WelcomePopup.styles';
import { useTranslation } from 'react-i18next';

interface WelcomePopupProps {
  handleOnDone: (value: string) => void;
}

export const WelcomePopup: FC<WelcomePopupProps> = ({ handleOnDone }) => {
  const { t } = useTranslation();
  return (
    <>
      <Grid container>
        <Typography>{t('welcomePopup.title')}</Typography>
        <Grid item xs={12} sx={WelcomePopupImageCenter}>
          <img src={DancingPeople} />
        </Grid>
        <Box sx={WelcomePopupButtonContainer}>
          <Button
            onClick={() => handleOnDone('welcome')}
            type='submit'
            sx={[SignInButton, MarginAlign]}
          >
            {t('welcomePopup.takeATest')}
          </Button>
        </Box>
      </Grid>
    </>
  );
};
