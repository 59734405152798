import { ActivationCodeStatus } from '../../interface/activation-code.interface';
import { theme } from '../../styles/theme';

export const activationCodePopupBoxStyle = {
  display: 'flex',
  marginTop: '24px',
  gap: '13px',
  width: '100%'
};

export const activationCodePopupInputStyle = (status: ActivationCodeStatus) => ({
  '#activationCodeInput': {
    padding: '12px 14px'
  },
  width: '100%',
  '> .MuiInputBase-root': {
    borderRadius: '4px',
    border: status === 'INVALID' ? `1px solid ${theme.palette.common.darkRed}` : 'unset'
  }
});

export const activationCodePopupValidationResultStyle = (status: ActivationCodeStatus) => ({
  position: 'absolute',
  color: status === 'VALID' ? theme.palette.common.darkGreen : theme.palette.common.darkRed,
  marginTop: '5px',
  display: 'flex',
  alignItems: 'center'
});

export const activationCodePopupSkipButtonStyle = {
  textAlign: { xs: 'center', sm: 'right' },
  width: '100%',
  paddingBottom: '20px',
  fontWeight: 600,
  '&:hover': {
    cursor: 'pointer'
  }
};

export const activationCodePopupSmallScreenStyles = {
  alignSelf: 'start',
  marginTop: '30px'
};

export const activationCodePopupInputBoxStyle = {
  position: 'relative',
  width: '100%',
  maxWidth: { sm: '370px' }
};

export const activationCodePopupImageStyle = {
  maxWidth: '165px',
  marginTop: '56px',
  paddingBottom: '68px'
};

export const activationCodePopupDiscountBanner = {
  marginTop: '36px',
  position: 'relative',
  display: 'flex',
  padding: '2px',
  alignItems: 'center',
  borderRadius: '4px',
  border: `1px solid ${theme.palette.common.lightGrey1}`,
  backgroundColor: theme.palette.common.lightGrey4
};

export const activationCodePopupPurpleBar = {
  width: '3px',
  height: '100%',
  position: 'absolute',
  borderRadius: '6px 0 0 6px',
  backgroundColor: theme.palette.common.violet
};

export const activationCodePopupTextIcon = {
  padding: '8px',
  display: 'flex',
  flexFlow: { xs: 'column', sm: 'row' }
};
