import { FC, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import image from '../../assets/images/signIn-person.svg';
import {
  JoinWebColumn,
  JoinWebColumnBox,
  JoinWebContainer,
  JoinWebLeftColumnSubtitle,
  JoinWebRightColumnBox,
  JoinWebSection,
  MobileColumnTitle
} from './JoinWeb.styles';
import { SignUp } from '../SignUp/SignUp';
import { useTranslation } from 'react-i18next';
import { ScalableWrapper } from '../ScalableWrapper/ScalableWrapper';
import { RecaptchaComponent } from '../../interface';
import { AuthPageLeftPanel } from '../AuthPageLeftPanel/AuthPageLeftPanel';
import { SignUpConsent } from './SignUpConsent';
import { Language } from '../../interface/language.interface';

interface JoinWebProps {
  signUpSuccess?: { state: boolean; email: string };
  handleSSO: (mode: 'join' | 'signin') => void;
  handleSignIn: () => void;
  handleSignUp?: (
    email: string,
    password: string,
    emailReceiveConsent: boolean,
    captchaResult?: string,
    korConsents?: { [p: string]: string }
  ) => void;
  handlePasswordValidation?: (password: string) => Promise<string[]>;
  handleNavigate?: (url: string) => void;
  signUpEmail?: string;
  recaptchaComponent: RecaptchaComponent;
  korConsentsMode?: boolean;
  ipCountry?: string;
  onGoogleClick: () => void;
  consentsCancelClick: () => void;
  language?: Language;
}

export const JoinWebPage: FC<JoinWebProps> = ({
  handleSSO,
  handleSignUp,
  handlePasswordValidation,
  handleNavigate,
  handleSignIn,
  signUpEmail,
  recaptchaComponent,
  korConsentsMode,
  ipCountry,
  onGoogleClick,
  consentsCancelClick,
  language
}) => {
  const [consentsAccepted, setConsentsAccepted] = useState<boolean>(false);
  const [consentsDto, setConsentsDto] = useState<{ [p: string]: boolean }>();

  const onConsentsStatusChange = (accepted: boolean, consentsDto: { [p: string]: boolean }) => {
    if (consentsAccepted !== accepted) {
      setConsentsAccepted(accepted);
    }
    setConsentsDto(consentsDto);
  };

  const onCheckboxChanged = (consentsDto: { [p: string]: boolean }) => {
    setConsentsDto(consentsDto);
  };

  const saveConsentsAndSignUp = () => {
    localStorage.setItem('koConsents', JSON.stringify(consentsDto));
    handleSSO('join');
  };

  const signUpSuccess = handleSignIn;
  const signUpProps = {
    signUpSuccess,
    handleSSO,
    handleSignUp,
    handlePasswordValidation,
    handleNavigate,
    signUpEmail,
    recaptchaComponent,
    ipCountry,
    onGoogleClick,
    onConsentsStatusChange,
    onCheckboxChanged,
    consentsDto,
    consentsAccepted,
    language
  };

  const { t } = useTranslation();
  const signUpAdvantages = Object.values(
    t('signUp.advantages', { returnObjects: true })
  ) as string[];

  if (korConsentsMode)
    return (
      <SignUpConsent
        language={language}
        onCancelClick={consentsCancelClick}
        onSignUpClick={saveConsentsAndSignUp}
        onCheckboxChanged={onCheckboxChanged}
        onConsentsStatusChange={onConsentsStatusChange}
        consentsAccepted={consentsAccepted}
      />
    );

  return (
    <Box component='section' sx={JoinWebSection}>
      <ScalableWrapper>
        <Grid sx={JoinWebContainer} columnSpacing={{ xs: 2 }} container>
          <AuthPageLeftPanel
            advantages={signUpAdvantages}
            headerWeb='signUp.header'
            image={image}
            type='join'
          />
          <Grid sx={JoinWebColumn} item xs={12} md={5}>
            <Box sx={[JoinWebColumnBox, JoinWebRightColumnBox]}>
              <Typography sx={JoinWebLeftColumnSubtitle} component='p'>
                {t('versantByPearson')}
              </Typography>
              <Typography sx={MobileColumnTitle} component='h1'>
                {t('signUp.mobileHeader')}
              </Typography>
              <SignUp {...signUpProps} />
            </Box>
          </Grid>
        </Grid>
      </ScalableWrapper>
    </Box>
  );
};
