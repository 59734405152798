import ReactGA from 'react-ga4';
import { UaEventOptions } from 'react-ga4/types/ga4';

export const gaIdString = process.env.REACT_APP_GA_TRACKING_ID;
export const gaTrackingIds = gaIdString?.split(',');

export const setUpGa = () => {
  if (!gaTrackingIds) {
    return;
  }

  ReactGA.gtag('consent', 'default', {
    ad_storage: 'denied',
    analytics_storage: 'denied'
  });

  const gaInitOptions = gaTrackingIds.map((trackingId) => ({ trackingId }));
  ReactGA.initialize(gaInitOptions);
};

export const sendSignedInGAEvent = (event: UaEventOptions, externalId: string | undefined) => {
  ReactGA.event(event.action, {
    event_category: event.category,
    external_id: externalId
  });
};
