import { Theme } from '@mui/material';
import { theme } from '../../styles/theme';

export const container = (theme: Theme) =>
  ({
    userSelect: 'none',
    // on lg controls are abso
    marginBottom: { lg: '30px', xl: 0 },
    '.swiper-slide': {
      height: 'auto',
      ' > *': {
        height: '100%'
      }
    },
    '.swiper-pagination': {
      display: 'flex',
      gap: '10px',
      marginInline: 'auto',
      [theme.breakpoints.up('lg')]: {
        display: 'none'
      }
    },
    '.swiper-pagination-bullet': {
      borderRadius: '100%',
      width: '15px',
      height: '15px',
      backgroundColor: theme.palette.common.lightGrey1,
      display: 'inline-block',
      cursor: 'pointer'
    },
    '.swiper-pagination-bullet-active': {
      backgroundColor: theme.palette.common.violet
    }
  } as const);

export const sliderShadow = (theme: Theme) => ({
  height: '100%',
  position: 'absolute',
  top: 0,
  transition: '200ms opacity',
  zIndex: 2,
  pointerEvents: 'none',
  width: '30px',
  [theme.breakpoints.up('md')]: {
    width: '40px'
  }
});

export const beginSliderShadow = (visible: boolean) => ({
  background: 'linear-gradient(90deg, var(--shadow-color) 10%, transparent 100%)',
  left: 0,
  opacity: visible ? 1 : 0
});

export const endSliderShadow = (visible: boolean) => ({
  background: 'linear-gradient(270deg, var(--shadow-color) 10%, transparent 100%)',
  right: 0,
  opacity: visible ? 1 : 0
});

export const controls = (allItemsVisiblevisible: boolean) => ({
  marginTop: { xs: '30px', lg: 0 },
  marginInline: 'auto',
  gridTemplateRows: '1fr',
  gridColumnGap: '10px',
  alignItems: 'center',
  width: 'fit-content',
  display: allItemsVisiblevisible ? 'none' : 'grid'
});

export const button = {
  height: '32px',
  width: '32px',
  border: 'none',
  cursor: 'pointer',
  transition: '200ms opacity',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.common.darkGrey3,
  backgroundColor: 'unset',
  '@media only screen and (min-width: 1200px)': {
    borderRadius: '50%',
    backgroundColor: theme.palette.common.darkBlue2,
    color: theme.palette.common.white,
    position: 'absolute',
    zIndex: 1,
    height: '54px',
    width: '54px',
    bottom: '-30px'
  },
  '@media only screen and (min-width: 1536px)': {
    top: '50%',
    transform: 'translateY(-50%)'
  },
  '&[disabled]': {
    pointerEvents: 'none',
    opacity: 0.4
  }
};

export const buttonIcon = (theme: Theme) => ({
  fontSize: '22px',
  [theme.breakpoints.up('xl')]: {
    fontSize: '28px'
  }
});

export const prevButton = {
  ...button,
  left: { lg: 0, xl: '-84px' },
  gridArea: '1 / 1 / 2 / 2'
};

export const nextButton = {
  ...button,
  right: { xl: '-84px' },
  left: { lg: 'calc(54px + 20px)', xl: 'initial' },
  gridArea: '1 / 3 / 2 / 4'
};

export const paginationStyle = {
  gridArea: '1 / 2 / 2 / 3'
};
