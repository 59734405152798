import { FC, useContext } from 'react';
import { sopClient, User, UserDataFormContent } from '@barracuda/shared/src';
import { AppContext } from '../../context/App.context';
import { useUserDataForm } from '@barracuda/shared/src/components/UserDataFormContent/useUserDataForm';
import { Grid, Button, Box } from '@mui/material';
import { buttonDark } from '@barracuda/shared/src/styles/button';
import { useTranslation } from 'react-i18next';
import { CompleteProfileTips } from '../CompleteProfileTips/CompleteProfileTips';

interface UserDataFormProps {
  closeDialog: () => void;
}

export const UserDataForm: FC<UserDataFormProps> = ({ closeDialog }) => {
  const { user, setUser, testTaken } = useContext(AppContext);
  const { setError } = useContext(AppContext);
  const { t } = useTranslation();

  const submit = async (userPayload: Partial<User>) => {
    try {
      const response = await sopClient.updateProfile(userPayload);
      const { data } = response;
      setUser({ ...user, ...data });
      closeDialog();
    } catch (e: any) {
      const {
        response: {
          data: { message }
        }
      } = e;
      setError(true, message);
    }
  };

  if (!user) {
    return null;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const formProps = useUserDataForm({ user, submit });

  return (
    user && (
      <Box>
        <CompleteProfileTips />
        <UserDataFormContent user={user} testTaken={testTaken} invokedFromAccount {...formProps}>
          <Grid item xs={12} sx={{ marginTop: '40px' }}>
            <Button
              type='submit'
              disabled={
                Object.keys(formProps.formState.errors).length > 0 || !formProps.formState.isValid
              }
              sx={buttonDark}
              id='user-data-form-nextPage'
            >
              {t('profilePage.done')}
            </Button>
          </Grid>
        </UserDataFormContent>
      </Box>
    )
  );
};
