import { theme } from '@theme';
const isWindows = /Win/i.test(navigator.userAgent);

export const DashboardTestDetailsScoreBadgeContainer = {
  position: 'relative'
};

export const DashboardTestDetailsScore = (reportMode: 'mts' | 'two-skills') => ({
  fontSize: '72px',
  fontWeight: 700,
  position: 'absolute',
  top: '47%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  color: reportMode === 'mts' ? theme.palette.common.black : theme.palette.common.white
});

export const ReasonSectionSubtitle = {
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: 1.2,
  color: theme.palette.common.darkBlue1,
  paddingBottom: '8px',
  marginTop: '15px'
};

export const ReasonRow = {
  paddingBottom: '24px',
  borderBottom: `1px solid ${theme.palette.common.lightGrey1}`
};

export const ResultsModifications = {
  marginTop: 0
};
export const ScaleStyles = {
  '@media screen and (min-resolution: 1.25dppx) and (max-resolution: 1.49dppx)': {
    '.MuiPaper-root': {
      scale: isWindows ? '0.8' : 'unset',
      maxHeight: 'unset'
    }
  },
  '@media screen and (min-resolution: 1.5dppx)': {
    '.MuiPaper-root': {
      scale: isWindows ? '0.67' : 'unset',
      maxHeight: 'unset'
    }
  }
};
