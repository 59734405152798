import { FC } from 'react';
import { dashboardUploadIdButton, FlexCenter } from './DashboardUploadId.styles';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface DashboardUploadIdCameraFooterButtonsProps {
  readonly useFunction: () => void;
  readonly retryFunction: () => void;
}
export const DashboardUploadIdCameraFooterButtons: FC<
  DashboardUploadIdCameraFooterButtonsProps
> = ({ useFunction, retryFunction }) => {
  const { t } = useTranslation();

  return (
    <Box sx={FlexCenter}>
      <Button
        onClick={useFunction}
        sx={[
          dashboardUploadIdButton,
          {
            marginInline: 0
          }
        ]}
      >
        {t('mobileUploadId.use')}
      </Button>
      <Button
        onClick={retryFunction}
        sx={[
          dashboardUploadIdButton,
          {
            marginInline: '20px 0'
          }
        ]}
      >
        {t('mobileUploadId.retry')}
      </Button>
    </Box>
  );
};
