import { theme } from '@theme';

export const LeftStack = {
  backgroundColor: theme.palette.common.white,
  border: `1px solid ${theme.palette.common.darkGrey4}`,
  borderRadius: { xs: '6px 6px 0 0', md: '6px 0 0 6px' },
  padding: {
    xs: '65px 30px 34px',
    sm: '45px 30px 50px',
    lg: '65px 30px 60px'
  }
};
export const RightStack = {
  backgroundColor: theme.palette.common.darkGrey4,
  border: '1px solid #F3D1BF',
  color: theme.palette.common.lightGrey4,
  borderRadius: { xs: '0 0 6px 6px', md: '0 6px 6px 0' },
  padding: {
    xs: '65px 30px 34px',
    sm: '45px 30px 50px',
    lg: '65px 30px 60px'
  }
};
export const StackTitle = {
  marginTop: '20px',
  fontSize: '20px',
  fontWeight: 700,
  paddingBottom: '20px'
};
