import { theme } from '@theme';

export const HeaderContainer = {
  '@media screen and (max-width: 720px)': {
    paddingBottom: '50px'
  },
  marginInline: 'auto',
  width: '80%',
  maxWidth: '1150px',
  '@media screen and (max-width: 1100px)': {
    width: '92%'
  }
};
export const HeaderTitle = {
  fontSize: '42px',
  fontWeight: 600,
  maxWidth: '500px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  marginBottom: '20px'
};
export const HeaderSubtitle = {
  fontSize: '16px',
  fontWeight: 400,
  paddingBottom: '12px',
  '@media screen and (max-width: 900px)': {
    width: '80%'
  },
  '@media screen and (max-width: 870px)': {
    width: '65%'
  },
  '@media screen and (max-width: 720px)': {
    width: '100%'
  }
};
export const ImgPosition = {
  position: 'absolute',
  right: '105%',
  top: '50%',
  width: '344px',
  '@media screen and (max-width: 1519.99px)': {
    top: '65%'
  },
  '@media screen and (max-width: 980px)': {
    width: '257px',
    top: '80%'
  },
  '@media screen and (max-width: 720px)': {
    width: 'unset',
    top: '-20%',
    left: '30%'
  },
  '@media screen and (max-width: 500px)': {
    width: 'unset',
    top: '-20%',
    left: '20%'
  }
};
export const GridBlueJourney = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '@media screen and (max-width: 720px)': {
    flexBasis: '100%',
    maxWidth: '100%'
  }
};

export const HeaderTitleUsername = {
  fontSize: '42px',
  fontWeight: 600,
  color: theme.palette.common.violet
};

export const WelcomeBackMobile = {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  '@media only screen and (max-width: 720px)': {
    marginTop: '110px',
    maxWidth: '100%',
    flexBasis: '100%',
    justifyContent: 'center'
  }
};

export const GridContainer = {
  height: '442px',
  '@media only screen and (min-width: 720px) and (max-width: 1199px)': {
    height: '545px'
  },
  '@media only screen and (max-width: 720px)': {
    height: 'unset !important'
  },
  justifyContent: 'space-between',
  maxWidth: '1192px',
  margin: 'auto'
};
