import { Dispatch, FC, SetStateAction } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CertificateGraph, V2Certificate, V2CertificateSkill } from '@barracuda/shared/src';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography
} from '@mui/material';
import {
  scoreReportViewAccordionStackStyle,
  scoreReportViewCertificateGraphBox,
  scoreReportViewCertificateSkillScores,
  scoreReportViewCertificateSkillScoresTitle,
  scoreReportViewScaleDescription
} from './ScoreReportView.style';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export type ScoreReportMode = 'mts' | 'two-skills';

export interface ScoreReportViewSkillScoresSectionProps {
  readonly reportMode: ScoreReportMode;
  readonly mtsData?: V2Certificate;
  readonly subSkills: (V2CertificateSkill & { color: string })[];
  readonly mtsSkills: (V2CertificateSkill & { color: string })[];
  readonly overallScore: number;
  readonly expanded: string | false;
  readonly setExpanded: Dispatch<SetStateAction<string | false>>;
}
export const ScoreReportViewSkillScoresSection: FC<ScoreReportViewSkillScoresSectionProps> = ({
  reportMode,
  mtsData,
  overallScore,
  expanded,
  setExpanded,
  mtsSkills,
  subSkills
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Box sx={{ ...scoreReportViewCertificateSkillScores, position: 'relative' }}>
        <Box sx={{ width: '100%', marginLeft: '70px' }}>
          <Typography sx={scoreReportViewCertificateSkillScoresTitle}>
            {t('results.skillScores')}
          </Typography>
          <Box sx={scoreReportViewCertificateGraphBox}>
            <CertificateGraph
              min={10}
              max={90}
              mode={reportMode}
              subSkills={reportMode === 'two-skills' ? subSkills : mtsSkills}
              score={reportMode === 'two-skills' ? overallScore : mtsData?.score || 0}
            />
          </Box>
        </Box>
        <Stack sx={scoreReportViewAccordionStackStyle}>
          {(subSkills || mtsSkills) &&
            (reportMode === 'two-skills' ? subSkills : mtsSkills)
              ?.filter((i) => i.score)
              .map((desc: V2CertificateSkill, index: number) => (
                <Accordion
                  expanded={expanded === `subskill-${index}`}
                  onChange={() => setExpanded(`subskill-${index}`)}
                  key={`subskill-${index}`}
                  sx={{ marginBottom: '15px' }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>{(desc as V2CertificateSkill).label}</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: '0 40px 20px 40px' }}>
                    <Typography>{desc.description}</Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
        </Stack>
      </Box>
      <Stack sx={[scoreReportViewScaleDescription, { marginLeft: '70px' }]}>
        {reportMode === 'mts' && (
          <>
            <Typography sx={{ fontWeight: 600 }}>{t('results.myTopScoreHeader')}</Typography>
            <Typography sx={{ paddingBottom: '15px' }}>
              <Trans
                i18nKey='results.mtsDescription'
                components={{
                  italic: <span style={{ fontWeight: 600, fontStyle: 'italic' }} />,
                  bold: <strong style={{ fontWeight: 600 }} />
                }}
              />
            </Typography>
          </>
        )}
        {reportMode === 'two-skills' && (
          <>
            <Typography sx={{ fontWeight: 600 }}>{t('results.overallScore')}</Typography>
            <Typography sx={{ paddingBottom: '15px' }}>
              <Trans i18nKey='results.overallDescription' />
            </Typography>
          </>
        )}
        <Typography sx={{ fontWeight: 600 }}>{t('results.gse')}</Typography>
        <Typography>
          <Trans
            i18nKey='results.gseDescription'
            components={{
              a: <a style={{ color: 'inherit' }} target='_blank' href='http://english.com/gse' />
            }}
          />
        </Typography>
      </Stack>
    </>
  );
};
