import { User } from '../../interface/user.interface';
import { Language } from '../../interface/language.interface';

export const koConsentsList = [
  // TODO: Only excluded in case they change their minds
  // {
  //   formControlName: 'ageConsent',
  //   label: 'signUp.ageConsent',
  //   mandatory: true,
  //   tooltipMessage: ''
  // },
  {
    formControlName: 'termsAndUseConsent',
    label: 'signUp.termsAndUseConsent',
    mandatory: true,
    tooltipMessage: ''
  },
  {
    formControlName: 'collectPIConsent',
    label: 'signUp.collectPIConsent',
    mandatory: true,
    tooltipMessage: ''
  },
  {
    formControlName: 'collectUIIConsent',
    label: 'signUp.collectUIIConsent',
    mandatory: true,
    tooltipMessage: 'collectUIIConsentTooltip'
  },
  {
    formControlName: 'collectSIConsent',
    label: 'signUp.collectSIConsent',
    mandatory: true,
    tooltipMessage: 'collectSIConsentTooltip'
  },
  {
    formControlName: 'provisionPIConsent',
    label: 'signUp.provisionPIConsent',
    mandatory: true,
    tooltipMessage: 'provisionPIConsentTooltip'
  },
  {
    formControlName: 'transferPIConsent',
    label: 'signUp.transferPIConsent',
    mandatory: true,
    tooltipMessage: 'transferPIConsentTooltip'
  },
  {
    formControlName: 'marketingPurposesConsent',
    label: 'signUp.marketingPurposesConsent',
    mandatory: false,
    tooltipMessage: 'marketingPurposesConsentTooltip'
  },
  {
    formControlName: 'marketingEmailConsent',
    label: 'signUp.marketingEmailConsent',
    mandatory: false,
    tooltipMessage: ''
  }
];

export const generateConsentRedirectionLink = (formControlName: string, language?: Language) => {
  switch (formControlName) {
    case 'termsAndUseConsent':
      return `https://versantcertificate.pearson.com/${language || 'ENG'}/terms-of-use`;
    case 'collectPIConsent':
      return 'https://www.pearson.com/en-us/privacy-center/privacy-notices.html';
    case 'provisionPIConsent':
      return 'https://www.pearson.com/content/dam/global-store/global/privacy-center/VEC-Subprocessor-List.pdf';
    case 'transferPIConsent':
      return 'https://www.pearson.com/content/dam/global-store/global/privacy-center/VEC-Subprocessor-List.pdf';
    default:
      return '';
  }
};
export const hasValidConsents = (user: User) => {
  if (user?.countryOfResidence?.isoCode === 'KR') {
    const mandatoryConsents = koConsentsList
      .filter((item) => item.mandatory)
      .map((item) => item.formControlName);
    const userProfileConsents = Object.keys(user.profile);
    return mandatoryConsents.every(
      (item) => userProfileConsents.includes(item) && user.profile[item] === 'true'
    );
  } else {
    return true;
  }
};
