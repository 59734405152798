import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import image from '../../assets/images/loginWeb--welcome-back.svg';
import {
  LoginWebColumn,
  LoginWebColumnBox,
  LoginWebContainer,
  LoginWebLeftColumnSubtitle,
  LoginWebRightColumnBox,
  LoginWebSection,
  MobileColumnTitle,
  TabPanelInnerContainer
} from './LoginPage.styles';
import { SignIn } from '../SignIn/SignIn';
import { ResetPassword } from '../ResetPassword/ResetPassword';
import { SetPassword } from '../SetPassword/SetPassword';
import { useTranslation } from 'react-i18next';
import { ScalableWrapper } from '../ScalableWrapper/ScalableWrapper';
import { CaptchaVisibility, RecaptchaComponent } from '../../interface';
import { AuthPageLeftPanel } from '../AuthPageLeftPanel/AuthPageLeftPanel';

interface LogInProps {
  signUpHidden: boolean;
  signUpSuccess?: { state: boolean; email: string };
  handleSSO: (mode: 'join' | 'signin') => void;
  handleSignIn: (email: string, password: string, captchaResult?: string | null) => void;
  handleSignUp?: () => void;
  handleResetPassword?: () => void;
  mode?: 'signin' | 'email_form' | 'password_form';
  handleSignInRedirect?: () => void;
  handleSignUpRedirect: () => void;
  handleSendLink?: (email: string) => void;
  handleSetPasswordSubmit?: (password: string) => void;
  sendLinkSuccess?: boolean;
  setPasswordSuccess?: boolean;
  handlePasswordValidation?: (password: string) => Promise<string[]>;
  error?: string;
  clearError: () => void;
  emailFromSignUp?: string;
  captchaVisible?: CaptchaVisibility;
  isDesktop?: boolean;
  recaptchaComponent: RecaptchaComponent;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={TabPanelInnerContainer}>
          <Typography component='div'>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

export const LoginPage: FC<LogInProps> = ({
  signUpHidden,
  handleSSO,
  handleSignIn,
  handleSignUp,
  signUpSuccess,
  handleResetPassword,
  mode,
  handleSignInRedirect,
  handleSignUpRedirect,
  handleSendLink,
  handleSetPasswordSubmit,
  sendLinkSuccess,
  setPasswordSuccess,
  handlePasswordValidation,
  error,
  clearError,
  emailFromSignUp,
  captchaVisible,
  isDesktop,
  recaptchaComponent
}) => {
  function onSigninSuccess() {
    if (signUpHidden) {
      const a = document.createElement('a');
      a.href = process.env.REACT_APP_PESC_URL as string;
      a.click();
    }
    handleSignUp?.();
  }

  const signInProps = {
    signInSuccess: onSigninSuccess,
    handleSSO,
    handleSignIn,
    handleResetPassword,
    mode,
    emailFromSignUp: signUpSuccess?.email || emailFromSignUp,
    error,
    clearError,
    captchaVisible,
    recaptchaComponent
  };

  const resetPasswordProps = {
    handleSignInRedirect,
    handleSignUpRedirect,
    handleSendLink,
    sendLinkSuccess,
    signUpHidden,
    captchaIndex: captchaVisible?.index ?? 0,
    recaptchaComponent
  };

  const setPasswordProps = {
    submit: handleSetPasswordSubmit,
    setPasswordSuccess,
    handleSignInRedirect,
    handlePasswordValidation,
    clearError,
    error
  };

  const { t } = useTranslation();

  const signInAdvantages = Object.values(
    t('signIn.advantages', { returnObjects: true })
  ) as string[];

  return (
    <>
      <Box component='section' sx={LoginWebSection}>
        <ScalableWrapper ignoreExperience={isDesktop}>
          <Box sx={LoginWebContainer}>
            <AuthPageLeftPanel
              advantages={signInAdvantages}
              headerWeb='signIn.headerWeb'
              description='signIn.description'
              image={image}
              type='login'
            />
            <Box
              sx={[LoginWebColumn, { width: { md: '100%' }, maxWidth: { lg: '563px !important' } }]}
            >
              <Box sx={[LoginWebColumnBox, LoginWebRightColumnBox]}>
                <Typography sx={LoginWebLeftColumnSubtitle} component='p'>
                  {t('versantByPearson')}
                </Typography>
                <Typography sx={MobileColumnTitle} component='h1'>
                  {t('startYourLanguageJourneyHere')}
                </Typography>
                <TabPanel value={1} index={1}>
                  {(!mode || mode === 'signin') && <SignIn {...signInProps} />}
                  {mode === 'email_form' && <ResetPassword {...resetPasswordProps} />}
                  {mode === 'password_form' && <SetPassword {...setPasswordProps} />}
                </TabPanel>
              </Box>
            </Box>
          </Box>
        </ScalableWrapper>
      </Box>
    </>
  );
};
