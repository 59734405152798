import { FC, useContext } from 'react';
import {
  HelpCenter,
  helpCenterContent,
  helpCenterContentJpn,
  helpCenterContentKor
} from '@barracuda/shared/src';
import { AppContext } from '../../context/App.context';
import { useLocalizedNavigate } from '../../hooks/useLocalizedNavigate';
import { useLocation } from 'react-router-dom';

const useQueryParams = (): { expandId: string } => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const expandId = searchParams.get('expandId') as string;
  return { expandId };
};

export const HelpCenterWrapper: FC = () => {
  const { isLoggedIn, language } = useContext(AppContext);
  const navigate = useLocalizedNavigate();
  const { expandId } = useQueryParams();

  const handleContactUs = () => {
    window.open('https://pearson.tfaforms.net/3048');
  };

  const handleNavigate = (url: string) => {
    const link = isLoggedIn ? `/dashboard/${url}` : `/${url}`;
    navigate(link);
  };

  let helpCenterContentTranslated;
  switch (language) {
    case 'JPN':
      helpCenterContentTranslated = helpCenterContentJpn;
      break;

    case 'KOR':
      helpCenterContentTranslated = helpCenterContentKor;
      break;

    default:
      helpCenterContentTranslated = helpCenterContent;
  }

  const onAccordionChange = () => {
    const search = location.search
      .replace(/expandId=[^&]*/, '')
      .replace(/&&/, '&')
      .replace(/&$/, '');
    navigate({ search });
  };

  return (
    <HelpCenter
      isLoggedIn={isLoggedIn}
      handleContactUs={handleContactUs}
      handleNavigate={handleNavigate}
      content={helpCenterContentTranslated}
      expandId={expandId}
      handleArticleToggle={onAccordionChange}
      handleCategoryChange={onAccordionChange}
    />
  );
};
