import { theme } from '../../styles/theme';

export const PrivacyPolicyContentHeader = {
  color: theme.palette.common.black,
  fontSize: '25px',
  marginBottom: '20px',
  fontWeight: 700
};

export const PrivacyPolicyContentParagraph = {
  fontWeight: 400,
  lineHeight: 1.5
};

export const MB40 = {
  marginBottom: '40px'
};

export const MB20 = {
  marginBottom: '20px'
};

export const MB10 = {
  marginBottom: '10px'
};

export const FW600 = {
  fontWeight: 600
};

export const PrivacyPolicyItalic = {
  fontStyle: 'italic'
};

export const Padding20 = {
  padding: '20px'
};

export const BorderLight = {
  border: `1px solid ${theme.palette.common.lightGrey2}`
};
export const FS13 = {
  fontSize: '13px'
};
