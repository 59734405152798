import { FC } from 'react';
import {
  scoreReportViewCertificateOverall,
  scoreReportViewCertificateOverallDescription,
  scoreReportViewCertificateOverallTitle,
  scoreReportViewResponsiveBadge
} from './ScoreReportView.style';
import { Box, Stack, Typography } from '@mui/material';
import { theme } from '@theme';
import { useTranslation } from 'react-i18next';
import {
  ScoreProgressBar,
  SkillType,
  SkillTypes,
  V2Certificate,
  V2CertificateSkill
} from '@barracuda/shared/src';
import shield from '../../assets/images/cert-blue-shield.svg';
import shieldPurple from '../../assets/images/cert-purple-shield.svg';
import { DashboardTestDetailsScore } from '../DashboardTestDetails/DashboardTestDetails.styles';
import { ScoreReportMode } from './ScoreReportViewSkillScoresSection';
import { Img } from '@barracuda/shared/src/components/Primitive';

export interface ScoreReportViewProficiencySectionProps {
  readonly reportMode: ScoreReportMode;
  readonly mtsData?: V2Certificate;
  readonly twoSkillsData?: V2Certificate;
  readonly findSkillByTagMTS: (name: SkillType) => V2CertificateSkill | undefined;
  readonly overallScore: number;
}
export const ScoreReportViewProficiencySection: FC<ScoreReportViewProficiencySectionProps> = ({
  reportMode,
  twoSkillsData,
  findSkillByTagMTS,
  mtsData,
  overallScore
}) => {
  const { t } = useTranslation();

  return (
    <Box sx={scoreReportViewCertificateOverall}>
      <Stack sx={{ width: '50%', marginLeft: '70px' }}>
        <Typography sx={scoreReportViewCertificateOverallTitle}>
          {t('results.certificateOverallHeader')}
        </Typography>
        <Typography sx={scoreReportViewCertificateOverallDescription}>
          {reportMode === 'two-skills'
            ? twoSkillsData?.skills[SkillTypes.OVERALL_SCORE]?.description || ''
            : findSkillByTagMTS(SkillTypes.OVERALL_SCORE)?.description || ''}
        </Typography>
      </Stack>
      <Stack alignItems='center' justifyContent='center' sx={{ paddingRight: '120px' }}>
        <Stack sx={scoreReportViewResponsiveBadge}>
          <Img sx={{ width: '182px' }} src={reportMode === 'mts' ? shield : shieldPurple} />
          <Typography sx={DashboardTestDetailsScore(reportMode)}>
            {reportMode === 'two-skills' ? overallScore : mtsData?.score || 0}
          </Typography>
        </Stack>
        <Typography sx={{ fontSize: '18px', fontWeight: 700, paddingBottom: '60px' }}>
          {' '}
          {t('results.overallScore')}
        </Typography>
        <ScoreProgressBar
          color={
            reportMode === 'mts' ? theme.palette.common.darkBlue10 : theme.palette.common.violet
          }
          score={reportMode === 'two-skills' ? overallScore : mtsData?.score || 0}
          minValue={10}
          maxValue={90}
          isMts={reportMode === 'mts'}
          cefrScore={mtsData?.scoreCefr || twoSkillsData?.scoreCefr}
        />
      </Stack>
    </Box>
  );
};
