import { buttonDark } from '../../styles/button';
import { theme } from '../../styles/theme';

export const beforeStartTestSignedInPopupHeader = {
  marginBottom: '20px',
  fontSize: '16px',
  color: theme.palette.common.darkGrey1
};

export const beforeStartTestSignedInPopupButton = {
  ...buttonDark,
  marginTop: {
    xs: '40px',
    md: '100px'
  }
};
