import { theme } from '../../styles/theme';

export const RequirementsVerticalRowContainer = {
  display: 'flex',
  justifyContent: 'center',
  padding: '10rem 0',
  backgroundColor: theme.palette.common.lightGrey4
};
export const RequirementsVerticalRowContainerInner = {
  color: theme.palette.common.darkGrey2,
  width: 'calc(33.33% - 30px)',
  '@media screen and (max-width: 1000px)': {
    width: 'calc(50% - 30px)'
  }
};

export const RequirementsVerticalRowSubtitle = {
  fontSize: '14px',
  lineHeight: 1.7,
  paddingBottom: '2rem',
  fontWeight: 400,
  letterSpacing: 0,
  textAlign: 'left'
};
export const RequirementsVerticalRowTitle = {
  weight: '700',
  fontSize: '35px',
  lineHeight: '36px',
  paddingBottom: '2rem'
};
export const RequirementsVerticalRowTag = {
  fontFamily: 'Epilogue',
  weight: '400',
  fontSize: '16px',
  lineHeight: '32px',
  letterSpacing: '3px',
  color: theme.palette.common.darkBlue7
};
export const RequirementsVerticalRowSingleReqContainer = {
  border: '1px solid #727272',
  borderRadius: '6px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  paddingTop: '70px',
  paddingLeft: '45px',
  paddingRight: '30px',
  '@media screen and (max-width: 1365px)': {
    paddingLeft: '30px',
    paddingRight: '15px'
  },
  '@media screen and (max-width: 1200px)': {
    paddingLeft: '20px',
    paddingTop: '50px'
  },
  position: 'relative',
  maxWidth: '391px',
  '@media screen and (max-width: 600px)': {
    maxWidth: 'unset'
  },
  height: '392px',
  backgroundColor: theme.palette.common.white
};
export const RequirementsVerticalRowSingleReqTitle = {
  fontSize: '24px',
  fontWeight: 700,
  lineHeight: '32px',
  letterSpacing: 0,
  paddingBottom: '30px',
  display: 'flex'
};
export const RequirementsVerticalRowSingleReqContainerInner = {
  lineHeight: '22px',
  paddingBottom: '15px',
  display: 'flex',
  flexDirection: 'column'
};
export const RequirementsVerticalRowSingleReqContainerInnerMainRow = {
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
  height: '48px'
};
export const RequirementsVerticalRowSingleReqContainerInnerMainRowImg = {
  backgroundColor: theme.palette.common.lightGrey5,
  borderRadius: '50%',
  width: '40px',
  height: '40px',
  minWidth: '40px',
  minHeight: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};
export const RequirementsVerticalRowSingleReqContainerInnerMainRowTitle = {
  fontWeight: 400,
  fontSize: '16px',
  '@media screen and (max-width: 1200px)': {
    fontSize: '14px'
  }
};
export const RequirementsVerticalRowSingleReqBottomText = {
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '19px',
  letterSpacing: 0,
  textAlign: 'left',
  display: 'flex',
  alignItems: 'center',
  width: '85%',
  margin: '0 auto 1rem ',
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  span: {
    fontSize: '14px',
    textDecoration: 'underline',
    cursor: 'pointer',
    marginLeft: '5px'
  }
};
export const TilesResponsive = {
  justifyContent: 'space-between',
  '@media screen and (max-width: 900px)': {
    justifyContent: 'start'
  },
  '@media screen and (max-width: 600px)': {
    justifyContent: 'center'
  }
};
