export const DetailsHeader = {
  padding: '70px',
  display: 'flex',
  justifyContent: 'center',
  gap: '30px',
  '@media screen and (max-width: 1240px)': {
    padding: '70px 30px'
  },
  '@media screen and (max-width: 745px)': {
    paddingBottom: '10px'
  },
  '@media screen and (max-width: 430px)': {
    paddingBottom: '10px !important',
    padding: '70px 10px'
  },
  '@media screen and (max-width: 744px)': {
    flexFlow: 'column',
    justifyContent: 'center'
  }
};
export const SkillsTileVisibility = {
  width: '45%',
  '@media screen and (max-width: 744px)': {
    width: '100%'
  }
};
export const TestTileResponsive = {
  width: '55%',
  '@media screen and (max-width: 744px)': {
    width: '100%'
  }
};
