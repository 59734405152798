import { FC, useRef } from 'react';
import {
  dashboardUploadIdProcessContainer,
  dashboardUploadIdMobileTakePhotoButton,
  dashboardUploadIdMobileTakePhotoContainer,
  dashboardUploadIdMobileTakePhotoParagraph,
  dashboardUploadIdRotatedBlock,
  dashboardUploadIdVideo
} from './DashboardUploadId.styles';
import { alpha, Box, Stack, Typography } from '@mui/material';
import Webcam from 'react-webcam';
import {
  idVerificationMinHeight,
  idVerificationMinWidth
} from '../../../../shared/src/utils/utils';
import { Img } from '../../../../shared/src/components/Primitive';
import TakePhotoButton from '../../assets/images/take-photo-button.svg';
import { useTranslation } from 'react-i18next';
import { theme } from '@barracuda/shared/src/styles/theme';

export interface DashboardUploadIdPhotoModeProps {
  captureMobile: (screenshotSrc: string | null) => void;
}

export const DashboardUploadIdPhotoMode: FC<DashboardUploadIdPhotoModeProps> = ({
  captureMobile
}) => {
  const { t } = useTranslation();
  const mobileWebcamRef = useRef<Webcam>(null);
  const takeScreenshot = () => mobileWebcamRef?.current?.getScreenshot() || null;

  return (
    <Stack
      sx={[
        dashboardUploadIdProcessContainer,
        { background: alpha(theme.palette.common.black, 0.4) }
      ]}
    >
      <Box sx={dashboardUploadIdMobileTakePhotoContainer}>
        <Typography sx={dashboardUploadIdMobileTakePhotoParagraph}>
          {t('mobileUploadId.lineUp')}
        </Typography>
        <Box sx={dashboardUploadIdRotatedBlock} />
        <Webcam
          screenshotFormat='image/jpeg'
          videoConstraints={{
            aspectRatio: 0.6,
            facingMode: { ideal: 'environment' }
          }}
          ref={mobileWebcamRef}
          minScreenshotWidth={idVerificationMinWidth}
          minScreenshotHeight={idVerificationMinHeight}
          width='90%'
          style={dashboardUploadIdVideo}
        />

        <Img
          src={TakePhotoButton}
          onClick={() => captureMobile(takeScreenshot())}
          sx={dashboardUploadIdMobileTakePhotoButton}
        ></Img>
      </Box>
    </Stack>
  );
};
