import { Box, Theme, SxProps } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { ScalableDefault } from './ScalableWrapper.style';

interface ScalableWrapperProps {
  readonly sx?: SxProps<Theme>;
  readonly isLoggedIn?: boolean;
  readonly ignoreExperience?: boolean;
}

export const ScalableWrapper: FC<PropsWithChildren<ScalableWrapperProps>> = ({
  children,
  sx = {},
  isLoggedIn,
  ignoreExperience
}) => {
  const userAgent = navigator.userAgent;
  const isWindows = /Win/i.test(userAgent);

  return (
    <>
      {!isLoggedIn && !ignoreExperience ? (
        <Box sx={isWindows ? { ...ScalableDefault, ...sx } : sx}>{children}</Box>
      ) : (
        <>{children}</>
      )}
    </>
  );
};
