import { FC, useContext, useEffect, useState } from 'react';
import { Box, Button, Link, Typography } from '@mui/material';
import { AccountPhoto } from '@barracuda/shared/src/components/AccountPhoto';
import {
  CartContainer,
  HamburgerMenuStyle,
  languageSwitcher,
  LinkActiveStyle,
  LinkStyle,
  LogoLink,
  LogOutStyle,
  Navbar,
  NavbarDesktop,
  NavbarList,
  NavbarListRight,
  NavbarLogo,
  NavbarLogoMobile,
  NavbarRouteBoxContainer,
  NavbarRouteBoxLinks,
  NavbarRouteBoxStyle,
  RouteLink,
  SignInButtonNavbar,
  SignInUpButtonsContainer,
  UsernameStyle
} from './HeaderNavbar.style';
import ArrowRight from '../../assets/images/arrow-right.svg';
import Logo from '../../assets/images/pearson-logo-tm.png';
import LogoMobile from '../../assets/images/pearson-logo-mobile.png';
import { NavigateFunction, useLocation } from 'react-router-dom';
import { Auth, IRoute, ScalableWrapper, sopUtils } from '@barracuda/shared/src';
import { AppContext } from '../../context/App.context';
import HamburgerMenu from '../../assets/images/hamburger-menu.svg';
import { isMobile } from 'src/app.utils';
import { ShoppingCartIcon } from '@pearson-ell/commerce-ui';
import { LanguageSwitcherGlobal } from '@barracuda/shared/src/components/LanguageSwitcherGlobal/LanguageSwitcherGlobal';
import { Language } from '@barracuda/shared/src/interface/language.interface';
import { buttonOutlineDark } from '@barracuda/shared/src/styles/button';
import { useLocalizedNavigate } from 'src/hooks/useLocalizedNavigate';
import { useSwitchLanguage } from '../../hooks/useSwitchLanguage';
import { useLocalizedMatch } from '../../hooks/useLocalizedMatch';
import { useTranslation } from 'react-i18next';
import { Img } from '@barracuda/shared/src/components/Primitive';
import { ELLCommerce } from '@pearson-ell/commerce-sdk';
import { ellCommerceInstanceConfig } from '../../utils/ell-commerce';

const generateLinks = (links: any[], nav?: NavigateFunction) => {
  return links.map((link, index) => {
    const isActive = useLocalizedMatch(link.to) !== null;
    const item = !link.replace ? (
      <Link onClick={() => nav?.(link.to)} sx={[LinkStyle, LinkActiveStyle(isActive)]} key={index}>
        {link.label}
      </Link>
    ) : (
      <Link sx={{ ...LinkStyle }} key={index} onClick={() => window.open(link.to)}>
        {link.label}
      </Link>
    );

    return <li key={link.label}>{item}</li>;
  });
};

interface ILinksLeft {
  label: string;
  to: string;
  replace?: boolean;
}
export const HeaderNavbar: FC<{ code?: string }> = ({ code }) => {
  const { isLoggedIn, user, setUser, setIsLoggedIn, language, setDrawerOpen, cart } =
    useContext(AppContext);
  const isLoginPage = useLocalizedMatch('/login');
  const isJoinPage = useLocalizedMatch('/join');
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const navigate = useLocalizedNavigate();
  const switchLanguage = useSwitchLanguage(navigate);
  const [customNavigate, setCustomNavigate] = useState('');
  const [routes, setRoutes] = useState<IRoute[] | undefined>(undefined);
  const { eCommerceCountryISO2 } = useContext(AppContext);

  const eCommerce = ELLCommerce.getInstance({
    ...ellCommerceInstanceConfig,
    defaultCountryISO2: eCommerceCountryISO2 || 'US',
    getTokenFunction: () => `Bearer ${sopUtils.getSopToken()}`
  });
  useEffect(() => {
    setCustomNavigate(pathname.includes('dashboard') ? '/' : '/dashboard');
    if (pathname.includes('shares') && pathname.includes('report') && code) {
      setRoutes([
        { label: t('score.scores'), main: false, redirectTo: `/shares/${code}` },
        { label: t('score.lookup'), main: false, redirectTo: `/shares/${code}` },
        { label: t('score.scoreReportHeader'), main: true, redirectTo: `/shares/${code}/report` }
      ]);
    } else if (pathname.includes('shares') && code) {
      setRoutes([
        { label: t('score.scores'), main: false, redirectTo: `/shares/${code}` },
        { label: t('score.lookup'), main: true, redirectTo: `/shares/${code}` }
      ]);
    } else {
      setRoutes(undefined);
    }
  }, [pathname, code]);

  const linksLeft: ILinksLeft[] = [
    {
      label: t('menuLabels.exploreTests'),
      to: user && isLoggedIn ? '/dashboard/explore-tests' : '/explore-tests'
    },
    {
      label: t('menuLabels.gettingReady'),
      to: user && isLoggedIn ? '/dashboard/getting-ready' : '/getting-ready'
    },
    {
      label: t('menuLabels.helpCenter'),
      to: user && isLoggedIn ? '/dashboard/help-center' : '/help-center'
    },
    { label: t('menuLabels.contactUs'), to: 'https://pearson.tfaforms.net/3048', replace: true }
  ];

  const logout = () => {
    Auth.logout(user?.profile?.loggedBefore);
    eCommerce
      .CustomerService()
      .postLogoutEvent(user?.externalId || '', user?.countryOfResidence?.isoCode);
    setUser(null);
    setIsLoggedIn(false);
    navigate('/login');
  };

  const linkLeftItems = generateLinks(linksLeft, navigate);

  const handleSignUp = () => {
    localStorage.setItem('selectedTab', '0');
    navigate('/join');
  };

  const handleSignIn = () => {
    navigate('/login');
  };

  const handleAccountRedirect = () => {
    navigate('../dashboard/account');
  };

  return (
    <>
      <ScalableWrapper sx={Navbar}>
        <Box sx={NavbarDesktop}>
          <Link onClick={() => navigate(customNavigate)} sx={[LinkStyle, LogoLink]}>
            <Img sx={NavbarLogo} src={Logo} alt='VPEC logo' />
            <Img sx={NavbarLogoMobile} src={LogoMobile} alt='VPEC logo' />
          </Link>
          <Box component='ul' sx={{ ...NavbarList }}>
            {linkLeftItems}
          </Box>
          <LanguageSwitcherGlobal
            language={language as Language}
            languageChange={switchLanguage}
            sx={languageSwitcher}
          />
          {isLoggedIn && (
            <Box sx={CartContainer}>
              <ShoppingCartIcon onOpenCart={() => navigate('/dashboard/shop')} cart={cart} />
            </Box>
          )}
          {isLoggedIn && (
            <>
              <Box component='ul' sx={{ ...NavbarList, ...NavbarListRight }}>
                <Typography onClick={handleAccountRedirect} component='div' sx={UsernameStyle}>
                  {user?.firstName || user?.preferredName}
                </Typography>
                <AccountPhoto
                  handlePhotoClick={handleAccountRedirect}
                  imageUri={user?.imageUri}
                  width='50px'
                  height='50px'
                />
                <Typography component='div' sx={LogOutStyle} onClick={logout}>
                  {t('menuLabels.logOut')}
                </Typography>
              </Box>
              <Button sx={HamburgerMenuStyle} onClick={() => setDrawerOpen?.(true)}>
                <img src={HamburgerMenu} />
              </Button>
            </>
          )}
        </Box>
        {!(isLoginPage || isJoinPage) && !isLoggedIn && !isMobile() && (
          <Box sx={SignInUpButtonsContainer}>
            <Button
              sx={[buttonOutlineDark, { minWidth: '110px' }]}
              onClick={handleSignUp}
              id='header-sign-up'
            >
              {t('signUpText')}
            </Button>
            <Button sx={SignInButtonNavbar} onClick={handleSignIn} id='header-sign-in'>
              {t('signInText')}
            </Button>
          </Box>
        )}
        {!isLoggedIn && !pathname.includes('verification') && (
          <Button sx={HamburgerMenuStyle} onClick={() => setDrawerOpen?.(true)}>
            <img src={HamburgerMenu} alt='' />
          </Button>
        )}
      </ScalableWrapper>
      {!!routes && (
        <Box sx={NavbarRouteBoxStyle}>
          <Box sx={NavbarRouteBoxContainer}>
            {routes.map((item, index) => {
              return (
                <Box sx={NavbarRouteBoxLinks} key={`routeLink-${index}`}>
                  <Typography
                    key={index}
                    onClick={() => navigate(item.redirectTo)}
                    sx={{
                      ...RouteLink,
                      fontWeight: item.main ? '600' : '400',
                      cursor: 'pointer',
                      textDecoration: index === 0 ? 'underline' : 'none'
                    }}
                  >
                    {item.label}
                  </Typography>
                  {index !== routes.length - 1 && <img width={7} src={ArrowRight} alt='' />}
                </Box>
              );
            })}
          </Box>
        </Box>
      )}
    </>
  );
};
