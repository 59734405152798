import { BaseSyntheticEvent, FC, useEffect } from 'react';
import { Box, Button, Link, Stack, TextField, Typography } from '@mui/material';
import { SignInButton, SignInTextFieldContainer } from '../SignIn/SignInStyles';
import {
  LinkStyle,
  ResetPasswordButton,
  ResetPasswordContainer,
  ResetPasswordHeader,
  ResetPasswordInput,
  ResetPasswordParagraph,
  ResetPasswordSignInLink
} from './ResetPassword.styles';
import { FieldValues, useForm } from 'react-hook-form';
import { SignUpErrorMessage } from '../SignUp/SignUp.styles';
import resetPasswordSuccess from '../../assets/images/resetPassword--success.svg';
import { useTranslation, Trans } from 'react-i18next';
import { RecaptchaComponent } from '../../interface';
import { EMAIL_REGEXP_PATTERN } from '../../interface/validations';
import { Img } from '../Primitive';

const captchaId = 'captchaResult';

interface ResetPasswordProps {
  handleSignInRedirect?: () => void;
  handleSignUpRedirect: () => void;
  handleSendLink?: (email: string, captchaResult: string | null) => void;
  sendLinkSuccess?: boolean;
  signUpHidden?: boolean;
  recaptchaComponent: RecaptchaComponent;
  captchaIndex: number;
}

export const ResetPassword: FC<ResetPasswordProps> = ({
  handleSignInRedirect,
  handleSignUpRedirect,
  handleSendLink,
  sendLinkSuccess,
  signUpHidden,
  recaptchaComponent,
  captchaIndex
}) => {
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    trigger,
    formState: { errors }
  } = useForm({
    criteriaMode: 'all',
    mode: 'all',
    reValidateMode: 'onChange'
  });

  useEffect(() => {
    localStorage.removeItem('selectedTab');
    register(captchaId, {
      required: true,
      value: ''
    });
  }, []);

  const { t } = useTranslation();

  const submitHandler = (data: FieldValues, e?: BaseSyntheticEvent) => {
    e?.preventDefault();
    handleSendLink?.(data.email, data.captchaResult);
  };

  const navigateToSignIn = () => {
    if (!handleSignInRedirect) return;
    handleSignInRedirect?.();
  };

  const recaptchaChange = (value: string | null) => {
    setValue(captchaId, value);
    trigger(captchaId);
  };

  return (
    <>
      <Stack sx={ResetPasswordContainer} component='form' onSubmit={handleSubmit(submitHandler)}>
        {!sendLinkSuccess && (
          <>
            <Typography sx={ResetPasswordHeader} component='h2'>
              {t('resetPassword.header')}
            </Typography>
            <Typography sx={ResetPasswordParagraph}>{t('resetPassword.notToWorryText')}</Typography>
            <Box sx={[SignInTextFieldContainer, { marginTop: '8px' }]}>
              <TextField
                {...register('email', {
                  required: t('thisIsRequired') as string,
                  pattern: {
                    value: EMAIL_REGEXP_PATTERN,
                    message: t('invalidEmail')
                  }
                })}
                sx={ResetPasswordInput}
                label={t('email')}
              />
              {errors?.email?.type === 'required' && (
                <Typography sx={SignUpErrorMessage}>{t('fieldIsRequired')}</Typography>
              )}
              {errors?.email?.type === 'pattern' && (
                <Typography sx={SignUpErrorMessage}>
                  {t('thisShouldBeAValidEmailAddress')}
                </Typography>
              )}
            </Box>
            {recaptchaComponent(recaptchaChange, captchaIndex)}
            <Button
              type='submit'
              disabled={Object.keys(errors).length > 0 || !getValues(captchaId)}
              sx={[SignInButton, ResetPasswordButton]}
            >
              {t('resetPassword.sendLink')}
            </Button>
            <Typography sx={ResetPasswordParagraph}>
              {t('resetPassword.rememberedPassword')}
              <Link onClick={navigateToSignIn} sx={ResetPasswordSignInLink}>
                {t('signInText')}
              </Link>
            </Typography>
          </>
        )}{' '}
        {sendLinkSuccess && (
          <>
            <Img sx={{ paddingBottom: '69px' }} src={resetPasswordSuccess} />
            <Typography sx={[ResetPasswordHeader, { marginTop: '20px' }]} component='h2'>
              {t('resetPassword.checkYourEmail')}
            </Typography>
            <Typography sx={[ResetPasswordParagraph, { paddingInline: '20px' }]}>
              {t('resetPassword.sentLinkMessage')}
            </Typography>
            <Typography
              sx={[ResetPasswordParagraph, { paddingInline: '20px', marginTop: '1.5em' }]}
            >
              <Trans
                i18nKey='resetPassword.signUpFirst'
                components={{
                  a: <Link sx={LinkStyle} onClick={handleSignUpRedirect} />
                }}
              />
            </Typography>
            {signUpHidden && (
              <Button onClick={navigateToSignIn} sx={[SignInButton, ResetPasswordButton]}>
                {t('resetPassword.backToSignIn')}
              </Button>
            )}
          </>
        )}
      </Stack>
    </>
  );
};
