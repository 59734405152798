import { theme } from '../../styles/theme';

type SkillScoreGraphStyles = {
  [key: string]: string | any;
};

export const SkillScoreGraphScaleContainer: SkillScoreGraphStyles = {
  position: 'relative',
  width: '100%',
  height: '100%'
};

export const SkillScoreGraphLabel: SkillScoreGraphStyles = {
  fontWeight: 700,
  fontSize: '12px',
  position: 'absolute',
  top: '7px',
  left: '-7px',
  color: theme.palette.common.darkBlue1
};

export const SkillScoreGraphScalePoints: SkillScoreGraphStyles = {
  height: '50%',
  position: 'relative',
  display: 'flex',
  alignItems: 'flex-end'
};

export const SkillScoreGraphScalePointer: SkillScoreGraphStyles = {
  position: 'absolute',
  fontWeight: 600,
  fontSize: '14px',
  color: theme.palette.common.darkGrey7
};

export const SkillScoreGraphRuler: SkillScoreGraphStyles = {
  position: 'relative',
  width: '100%',
  height: '50%',
  backgroundColor: 'rgba(158, 0, 126, 0.05)',
  borderBottom: `2px solid ${theme.palette.common.violetLight3}`
};

export const SkillScoreGraphRulerPointer: SkillScoreGraphStyles = {
  bottom: 0,
  position: 'absolute',
  height: '50%',
  width: '2px',
  backgroundColor: theme.palette.common.violetLight3,
  '&:firstOfType, &:lastOfType': {
    height: '80%'
  }
};

export const SkillScoreGraphAchievedScorePointer: SkillScoreGraphStyles = {
  position: 'absolute',
  bottom: '-7px',
  height: '100%',
  width: '50px'
};

export const SkillScoreGraphAchievedScorePointerContent: SkillScoreGraphStyles = {
  height: '100%',
  position: 'relative'
};

export const SkillScoreGraphAchievedScorePointerContentText: SkillScoreGraphStyles = {
  textAlign: 'center',
  display: 'flex',
  top: '-7px',
  flexFlow: 'column',
  position: 'absolute',
  left: '-23px'
};

export const SkillScoreGraphFirstSpan: SkillScoreGraphStyles = {
  fontWeight: 600,
  fontSize: '10px',
  marginBottom: '5px'
};

export const SkillScoreGraphSecondSpan: SkillScoreGraphStyles = {
  fontWeight: 700,
  fontSize: '24px'
};

export const SkillScoreGraphAchievedScorePointerContentPointer: SkillScoreGraphStyles = {
  position: 'absolute',
  bottom: 0,
  background: `linear-gradient(259.64deg, #FF8275 4.71%, ${theme.palette.common.violet} 66.44%, ${theme.palette.common.violetDark1} 90.65%)`,
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.25)',
  borderRadius: '2.5px',
  width: '5px',
  height: '66%'
};
