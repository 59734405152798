import { theme } from '@theme';

export const PracticeButton = {
  alignSelf: 'flex-start',
  backgroundColor: theme.palette.common.white,
  color: theme.palette.common.black,
  fontWeight: 600,
  height: '32px',
  fontSize: '14px',
  textTransform: 'none',
  border: `2px solid ${theme.palette.common.darkGrey2}`,
  borderRadius: '40px',
  margin: '30px 0 30px 60px',
  '&:hover': {
    backgroundColor: theme.palette.common.black,
    border: `2px solid ${theme.palette.common.black}`,
    color: theme.palette.common.white
  }
};
