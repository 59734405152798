import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

const playContainerStyle = {
  position: 'absolute',
  top: '50%',
  left: '53%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'white',
  padding: '.75rem',
  borderRadius: 24,
  width: 100,
  height: 48,
  cursor: 'pointer',
  zIndex: '2',
  fontWeight: 700
};

interface PlayButtonProps {
  readonly onPlayClick: () => void;
  readonly text: string;
  readonly id?: string;
}

export const PlayButton: FC<PlayButtonProps> = ({ onPlayClick, id, text }) => (
  <Box role='button' onClick={() => onPlayClick()} sx={playContainerStyle} id={id}>
    <PlayArrowIcon sx={{ marginRight: '0.5rem' }} />
    <Typography sx={{ fontWeight: 600 }}>{text}</Typography>
  </Box>
);
