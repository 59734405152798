import { theme } from '../../styles/theme';

export const progressDot = (active: boolean) => ({
  width: '15px',
  height: '15px',
  background: theme.palette.common.violet,
  opacity: active ? 1 : '20%',
  borderRadius: '100px'
});

export const DotsContainer = {
  marginTop: '20px',
  gap: '17px',
  '@media screen and (max-height: 600px)': {
    marginTop: 0
  }
};
