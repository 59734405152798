export const userDataFormContentInput = {
  width: '90%',
  'input:disabled': {
    color: 'rgba(0, 0, 0, 0.38) !important',
    WebkitTextFillColor: 'rgba(0, 0, 0, 0.38)'
  }
};

export const userDataFormContentTooltip = {
  marginLeft: '8px',
  marginBottom: '7px'
};

export const userDataFormContentLabelWithTooltip = {
  display: 'flex',
  alignItems: 'center'
};

export const userDataFormContentInputWrapper = {
  position: 'relative',
  marginTop: '10px',
  alignSelf: 'flex-start',
  '@media screen and (min-height: 980px)': {
    marginTop: '20px'
  }
};

export const userDataFormContentCountryInputWrapper = {
  ...userDataFormContentInputWrapper,
  '.MuiAutocomplete-endAdornment .MuiButtonBase-root': {
    display: 'none'
  }
};

export const userDataFormContentError = {
  fontSize: '14px',
  color: 'red',
  marginTop: '5px'
};
