import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack, Typography } from '@mui/material';
import {
  dashboardUploadIdBulbContainer,
  dashboardUploadIdHeader,
  dashboardUploadIdImportantTitle,
  dashboardUploadIdPutYourIdText
} from './DashboardUploadId.styles';
import { Img } from '../../../../shared/src/components/Primitive';
import BulbIcon from '../../assets/images/bulb-icon.svg';

export interface DashboardUploadIdTitleSubtitleProps {
  readonly title: string;
  readonly subtitle: string;
  readonly withBulb?: boolean;
}

export const DashboardUploadIdTitleSubtitle: FC<DashboardUploadIdTitleSubtitleProps> = ({
  title,
  subtitle,
  withBulb
}) => {
  const { t } = useTranslation();

  return (
    <Stack>
      <Typography component='h1' sx={dashboardUploadIdHeader}>
        {t(title)}
      </Typography>
      <Typography sx={dashboardUploadIdPutYourIdText}>{t(subtitle)}</Typography>
      {withBulb && (
        <Box sx={dashboardUploadIdBulbContainer}>
          <Img src={BulbIcon} />
          <Box>
            <Typography sx={dashboardUploadIdImportantTitle}>
              {t('mobileUploadId.important')}:{' '}
            </Typography>
            {t('mobileUploadId.importantInfo')}
          </Box>
        </Box>
      )}
    </Stack>
  );
};
