import i18n from 'i18next';
import { translationENG } from '@barracuda/shared/src/assets/translations/english';
import { translationJPN } from '@barracuda/shared/src/assets/translations/japanese';
import { translationKOR } from '@barracuda/shared/src/assets/translations/korean';
import i18nCommon from '@barracuda/shared/src/i18n';

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

const defaultLanguage = localStorage.getItem('language')?.toLocaleLowerCase() || 'eng';

// Detecting i18n.isInitialized and applying translations don't work properly with ecommerce package.
// It's better to create new instance with fully controlled configuration than straight adding own keys
// to unknown i18n instance from anywhere else.
const getResources = (isI18nInitialized: boolean) => {
  // restore previously added bundle within other packages
  if (isI18nInitialized) {
    return {
      eng: {
        translation: {
          ...i18n.getResourceBundle('en', 'translation'),
          ...i18n.getResourceBundle('eng', 'translation'),
          ...translationENG
        }
      },
      jpn: {
        translation: {
          ...i18n.getResourceBundle('jp', 'translation'),
          ...i18n.getResourceBundle('jpn', 'translation'),
          ...translationJPN
        }
      },
      kor: {
        translation: {
          ...i18n.getResourceBundle('ko', 'translation'),
          ...i18n.getResourceBundle('kor', 'translation'),
          ...translationKOR
        }
      }
    };
  } else {
    return {
      eng: { translation: translationENG },
      jpn: { translation: translationJPN },
      kor: { translation: translationKOR }
    };
  }
};

i18n.init({
  fallbackLng: 'eng',
  lng: defaultLanguage,
  returnNull: false,
  interpolation: {
    escapeValue: false // not needed for react
  },
  resources: getResources(i18n.isInitialized)
});

export default i18n;

export const changeLanguage = async (language: string) => {
  await i18n.changeLanguage(language);
  await i18nCommon.changeLanguage(language); // to change language in shared package
};
