import { FC, useState } from 'react';
import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery
} from '@mui/material';

import ArrowRight from '../../assets/images/arrow-right.svg';

import {
  myTopScoreTableMobileTableVersion,
  myTopScoreTableSkillHighlight,
  myTopScoreTableCell,
  myTopScoreTableContainer,
  myTopScoreTableTable,
  myTopScoreTableTableRow
} from './MyTopScoreTable.style';
import { TestsTableTHead, TestsTableTHeadCell } from '../TestsTable/TestsTable.styles';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import { MTSLicense, MyTopScore } from '../../interface/my-top-score.interface';
import { Img } from '../Primitive';
import { NavigateFunction } from 'react-router-dom';
import { SkillScoreTable } from '../SkillScoreTable/SkillScoreTable';

interface MyTopScoreTableProps {
  myTopScore: MyTopScore | undefined;
  navigate: NavigateFunction;
}

export const MyTopScoreTable: FC<MyTopScoreTableProps> = ({ myTopScore, navigate }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const matches744 = useMediaQuery('(max-width: 744px)');
  const matches834 = useMediaQuery('(max-width: 834px)');
  const matches1200 = useMediaQuery('(max-width: 1200px)');
  const findScoreBySkillName = (name: string, license: MTSLicense): number | undefined => {
    return license.scores.find(
      (item) =>
        item.scale === myTopScore?.scale && item.component.toLowerCase() === name.toLowerCase()
    )?.score;
  };
  const findCefrLevel = (name: string, license: MTSLicense): string | undefined => {
    return license.scores.find(
      (item) => item.scale === 'CEFR' && item.component.toLowerCase() === name.toLowerCase()
    )?.label;
  };
  const shouldBeHighlighted = (skillName: string, licenseId: number): boolean => {
    return !!myTopScore?.skills.find(
      (skill) =>
        skill.component.toLowerCase() === skillName.toLowerCase() && skill.licenseId === licenseId
    );
  };
  const filterTableHeaders = () => {
    if (matches744)
      return tableHeaders.filter((_, index) => index !== 1 && index !== 2 && index !== 7);
    if (matches834) return tableHeaders.filter((_, index) => index !== 1 && index !== 2);
    if (matches1200) return tableHeaders.filter((_, index) => index !== 1);
    return tableHeaders;
  };

  const tableHeaders = Object.values(t('results.myTopScoreTableHeaders', { returnObjects: true }));
  const renderedTableHeader = filterTableHeaders();

  return (
    <>
      {myTopScore && (
        <Box className='scores-container' sx={myTopScoreTableContainer}>
          <SkillScoreTable open={open} setOpen={setOpen} />

          <Stack sx={myTopScoreTableMobileTableVersion}>
            {myTopScore.skills.map((item) => {
              return (
                <Box
                  onClick={() => navigate(`../results/test/${item.licenseId}`)}
                  sx={[
                    myTopScoreTableTableRow,
                    { display: 'flex', justifyContent: 'space-between', alignItems: 'center' }
                  ]}
                >
                  <Typography sx={{ padding: '10px' }}>{item.component}</Typography>
                  <Box sx={[myTopScoreTableSkillHighlight, { display: 'flex' }]}>
                    <Box sx={[{ marginRight: '25px' }]}>{item.score}</Box>{' '}
                    <Img sx={{ marginRight: '20px' }} src={ArrowRight} />
                  </Box>
                </Box>
              );
            })}
          </Stack>
          <TableContainer sx={myTopScoreTableTable}>
            <Table>
              <TableHead sx={TestsTableTHead}>
                <TableRow>
                  {renderedTableHeader.map((el, index) => (
                    <TableCell key={`${el}${index}`} sx={TestsTableTHeadCell}>
                      {el as string}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {myTopScore?.licenses.map((item, index) => (
                  <TableRow
                    onClick={() => navigate(`../results/test/${item.id}`)}
                    sx={myTopScoreTableTableRow}
                    id={`tableRow-${index}`}
                  >
                    <TableCell sx={{ fontWeight: 600, ...myTopScoreTableCell }}>
                      {item.product.name}
                    </TableCell>
                    {!matches1200 && <TableCell>{item.testCode}</TableCell>}
                    {!matches834 && (
                      <TableCell sx={myTopScoreTableCell}>
                        {moment(item.endTime).format('DD MMMM yyyy')}
                      </TableCell>
                    )}

                    <TableCell
                      sx={[
                        myTopScoreTableCell,
                        shouldBeHighlighted('speaking', item.id)
                          ? myTopScoreTableSkillHighlight
                          : {}
                      ]}
                    >
                      <Box>{findScoreBySkillName('speaking', item)}</Box>
                    </TableCell>
                    <TableCell
                      sx={[
                        myTopScoreTableCell,
                        shouldBeHighlighted('listening', item.id)
                          ? myTopScoreTableSkillHighlight
                          : {}
                      ]}
                    >
                      <Box>{findScoreBySkillName('listening', item)}</Box>
                    </TableCell>
                    <TableCell
                      sx={[
                        myTopScoreTableCell,
                        shouldBeHighlighted('reading', item.id) ? myTopScoreTableSkillHighlight : {}
                      ]}
                    >
                      <Box>{findScoreBySkillName('reading', item)}</Box>
                    </TableCell>
                    <TableCell
                      sx={[
                        myTopScoreTableCell,
                        shouldBeHighlighted('writing', item.id) ? myTopScoreTableSkillHighlight : {}
                      ]}
                    >
                      <Box>{findScoreBySkillName('writing', item)}</Box>
                    </TableCell>
                    {!matches744 && (
                      <TableCell sx={myTopScoreTableCell}>
                        <Typography component='span' sx={{ fontWeight: 600 }}>
                          {findScoreBySkillName('overall score', item)}
                        </Typography>
                      </TableCell>
                    )}
                    <TableCell sx={myTopScoreTableCell}>
                      <Typography component='span' sx={{ fontWeight: 600 }}>
                        {findCefrLevel('overall score', item)}
                      </Typography>
                    </TableCell>
                    <TableCell sx={myTopScoreTableCell}>
                      <Img src={ArrowRight} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Typography sx={{ marginLeft: '20px', marginTop: '40px' }}>
            <Trans
              i18nKey='results.highlightedScores'
              components={{
                span: <Typography component='span' sx={{ fontWeight: 600, fontStyle: 'italic' }} />
              }}
            />
          </Typography>
        </Box>
      )}
    </>
  );
};
