import { Dispatch, FC, SetStateAction } from 'react';
import {
  scoreReportViewRectanglesContainer,
  scoreReportViewSampleResponses,
  scoreReportViewSampleResponseTitle
} from './ScoreReportView.style';
import { Box, Stack, Typography } from '@mui/material';
import { SamplePreviewBox, SamplePreviewRectangle, ScoreReportDTO } from '@barracuda/shared/src';
import { useTranslation } from 'react-i18next';
import thirdTestImage from '../../assets/images/speaking-sample-blue.svg';
import secondTestImage from '../../assets/images/writing-sample-yellow.svg';
import { ModeType } from './ScoreReportView';

export interface ScoreReportViewSampleResponsesProps {
  readonly speakingResource: any;
  readonly writingResource: any;
  readonly certificateData: ScoreReportDTO | null;
  readonly mode: ModeType;
  readonly open: boolean;
  readonly setOpen: Dispatch<SetStateAction<boolean>>;
  readonly openPopup: (mode: ModeType) => void;
}
export const ScoreReportViewSampleResponses: FC<ScoreReportViewSampleResponsesProps> = ({
  speakingResource,
  writingResource,
  open,
  setOpen,
  mode,
  certificateData,
  openPopup
}) => {
  const { t } = useTranslation();

  if (speakingResource || writingResource || writingResource === '') {
    return (
      <Stack
        id='SampleResponseSection'
        sx={[scoreReportViewSampleResponses, { marginLeft: '70px' }]}
      >
        <Typography sx={scoreReportViewSampleResponseTitle}>
          {t('results.sampleResponseTitle', {
            firstName: certificateData?.testTaker?.firstName
          })}
        </Typography>
        <Box sx={scoreReportViewRectanglesContainer}>
          {speakingResource && (
            <SamplePreviewRectangle
              title={t('results.speakingSampleTitle')}
              text={t('results.speakingSampleText')}
              mode='listen'
              image={thirdTestImage}
              buttonText={t('results.listentoSample')}
              resource={speakingResource}
              handleClick={openPopup}
            />
          )}
          {writingResource !== null && writingResource !== undefined && (
            <SamplePreviewRectangle
              title={t('results.writingSampleTitle')}
              image={secondTestImage}
              text={t('results.writingSampleText')}
              mode='read'
              resource={writingResource}
              buttonText={t('results.readSample')}
              handleClick={openPopup}
            />
          )}
          <SamplePreviewBox
            testTakerName={
              certificateData?.testTaker?.firstName + ' ' + certificateData?.testTaker?.lastName
            }
            speakingResource={speakingResource}
            writingResource={writingResource}
            open={open}
            mode={mode}
            handleClose={() => setOpen(!open)}
          />
        </Box>
      </Stack>
    );
  }
  return null;
};
