import { Box, Button, Link, TextField, Typography } from '@mui/material';
import { BaseSyntheticEvent, FC, useCallback, useEffect, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import googleLogo from '../../assets/images/google-logo.svg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import image from '../../assets/images/already-have-an-account.svg';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  DontHaveAccountBox,
  ErrorInputBorderStyle,
  ErrorLinkStyle,
  IncorrectErrorAlign,
  RecaptchaBox,
  SignInAlreadyHaveAnAccountEmail,
  SignInAlreadyHaveAnAccountHeader,
  SignInAlreadyHaveAnAccountImage,
  SignInAlreadyHaveAnAccountParagraph,
  SignInButton,
  SignInButtonContainer,
  SignInDivider,
  SignInLoginWithGoogleButton,
  SignInResetPasswordLink,
  SignInTextField,
  SignInTextFieldContainer,
  SignUpLink
} from './SignInStyles';
import { useForm } from 'react-hook-form';
import { PasswordVisibilityIcon, SignUpErrorMessage } from '../SignUp/SignUp.styles';
import { useTranslation } from 'react-i18next';
import { CaptchaVisibility, RecaptchaComponent } from '../../interface';
import { EMAIL_REGEXP_PATTERN } from '../../interface/validations';
import { Img } from '../Primitive';
const captchaId = 'captchaResult';

export interface SignInProps {
  signInSuccess: () => void;
  handleSSO: (mode: 'join' | 'signin') => void;
  handleSignIn: (email: string, password: string, captchaResult?: string | null) => void;
  handleResetPassword?: () => void;
  mode?: 'signin' | 'email_form' | 'password_form' | 'signup';
  emailFromSignUp?: string;
  error?: string;
  clearError: () => void;
  captchaVisible?: CaptchaVisibility;
  recaptchaComponent: RecaptchaComponent;
}

export const SignIn: FC<SignInProps> = ({
  signInSuccess,
  handleSSO,
  handleSignIn,
  handleResetPassword,
  emailFromSignUp,
  error,
  clearError,
  captchaVisible,
  recaptchaComponent
}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const {
    register,
    unregister,
    handleSubmit,
    setValue,
    trigger,
    getValues,
    formState: { errors }
  } = useForm({
    criteriaMode: 'all',
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  const recaptchaChange = useCallback((value: string | null) => {
    setValue(captchaId, value);
    trigger(captchaId);
  }, []);

  useEffect(() => {
    if (captchaVisible?.visible) {
      register(captchaId, { required: true, value: '' });
    } else {
      unregister(captchaId);
    }
  }, [captchaVisible]);

  useEffect(() => {
    if (emailFromSignUp) {
      setEmail(emailFromSignUp);
      setValue('email', emailFromSignUp);
    }

    return () => {
      setEmail('');
      setValue('email', '');
    };
  }, [emailFromSignUp]);

  const { t } = useTranslation();

  const handleEmailChange = (event: BaseSyntheticEvent) => {
    setValue('email', event.target.value);
    setEmail(event.target.value);
    trigger('email');
  };

  const handlePasswordChange = (event: BaseSyntheticEvent) => {
    setValue('password', event.target.value);
    setPassword(event.target.value);
    trigger('password');
    clearError();
  };

  const signInHandler = () => {
    handleSignIn(email, password, getValues(captchaId));
  };

  const handleResetPasswordNavigation = () => {
    handleResetPassword?.();
  };

  const incorrectPasswordError =
    error && error.includes('Incorrect') && errors?.password?.type !== 'required';

  return (
    <>
      <Box component='form' onSubmit={handleSubmit(signInHandler)}>
        {!emailFromSignUp && (
          <>
            <Box sx={SignInButtonContainer}>
              <Button onClick={() => handleSSO('signin')} sx={SignInLoginWithGoogleButton}>
                <Img src={googleLogo} />
                {t('signIn.signInWithGoogle')}
              </Button>
            </Box>
            <Box sx={SignInDivider}>
              <span>{t('signIn.orSignInWith')}</span>
            </Box>
            <Box sx={SignInTextFieldContainer}>
              <TextField
                {...register('email', {
                  required: t('thisIsRequired') as string,
                  pattern: {
                    value: EMAIL_REGEXP_PATTERN,
                    message: t('invalidEmail')
                  }
                })}
                type='email'
                onChange={handleEmailChange}
                label={t('email')}
                id='email'
                sx={{
                  ...SignInTextField,
                  ...(incorrectPasswordError && { ...ErrorInputBorderStyle })
                }}
                variant='outlined'
              />
              {errors?.email?.type === 'required' && (
                <Typography sx={SignUpErrorMessage}>{t('fieldIsRequired')}</Typography>
              )}
              {errors?.email?.type === 'pattern' && (
                <Typography sx={SignUpErrorMessage}>
                  {t('thisShouldBeAValidEmailAddress')}
                </Typography>
              )}
            </Box>
          </>
        )}
        {emailFromSignUp && (
          <>
            <Typography sx={SignInAlreadyHaveAnAccountHeader} component='h1'>
              {t('signInPage.haveAccount')}
            </Typography>
            <Typography sx={SignInAlreadyHaveAnAccountParagraph}>
              {t('signInPage.enterPassword')}
            </Typography>
            <Img sx={SignInAlreadyHaveAnAccountImage} src={image} />
            <Typography sx={SignInAlreadyHaveAnAccountEmail}>{email}</Typography>
          </>
        )}
        <Box sx={[SignInTextFieldContainer, { marginBottom: '60px' }]}>
          <TextField
            {...register('password', {
              required: t('fieldIsRequired') as string
            })}
            type={passwordVisible ? 'text' : 'password'}
            onChange={handlePasswordChange}
            label={t('password')}
            id='password'
            sx={{ ...SignInTextField, ...(incorrectPasswordError && { ...ErrorInputBorderStyle }) }}
            variant='outlined'
          />
          {passwordVisible && (
            <VisibilityOffIcon
              sx={PasswordVisibilityIcon}
              onClick={() => setPasswordVisible(false)}
            />
          )}
          {!passwordVisible && (
            <VisibilityIcon sx={PasswordVisibilityIcon} onClick={() => setPasswordVisible(true)} />
          )}
          {errors?.password?.type === 'required' && (
            <Typography sx={SignUpErrorMessage}>This field is required</Typography>
          )}
          {incorrectPasswordError && (
            <Typography sx={[SignUpErrorMessage, IncorrectErrorAlign]}>
              {t('signIn.emailOrPasswordErrorMessage')}{' '}
              <Link onClick={() => handleResetPassword?.()} sx={ErrorLinkStyle}>
                {t('signIn.resetPassword')}
              </Link>
            </Typography>
          )}
          {error && error.includes('attempts') && (
            <Typography sx={[SignUpErrorMessage, IncorrectErrorAlign]}>{error}</Typography>
          )}
          {!incorrectPasswordError && (
            <Typography
              onClick={handleResetPasswordNavigation}
              sx={[SignUpLink, SignInResetPasswordLink]}
              component='a'
            >
              {t('signIn.resetPassword')}
            </Typography>
          )}
        </Box>
        <Box sx={SignInButtonContainer}>
          {captchaVisible?.visible && (
            <Box key={captchaVisible.index} sx={RecaptchaBox}>
              {recaptchaComponent(recaptchaChange, captchaVisible.index)}
            </Box>
          )}
          <Button
            disabled={captchaVisible?.visible && !getValues(captchaId)}
            tabIndex={0}
            type='submit'
            sx={SignInButton}
            id='sign-in'
          >
            {t('signInText')}
          </Button>
        </Box>
        <Box sx={[SignInButtonContainer, DontHaveAccountBox]}>
          {t('signIn.dontHaveAccountYet')}
          <Typography
            tabIndex={0}
            onClick={signInSuccess}
            component='a'
            sx={[SignUpLink, { marginLeft: '10px' }]}
          >
            {t('signUpText')}
          </Typography>
        </Box>
      </Box>
    </>
  );
};
