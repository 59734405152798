import { theme } from '../../styles/theme';

export const ConsentLinkStyle = {
  cursor: 'pointer',
  textDecoration: 'underline',
  color: theme.palette.common.darkGrey2,
  fontWeight: '600 !important',
  pointerEvents: 'auto'
};

export const ConsentCheckboxStyle = {
  padding: '2px',
  margin: '0 7px 0 7px',
  '&.Mui-checked > svg': {
    fill: `${theme.palette.common.violet} !important`
  }
};

export const ConsentOffer = {
  marginTop: '15px',
  marginBottom: '10px'
};

export const ConsentDataContainer = {
  width: '90%',
  padding: '10px 20px',
  margin: '0 auto',
  background: theme.palette.common.lightGrey4,
  borderRadius: '8px',
  border: `1px solid ${theme.palette.common.lightGrey1}`,
  'input:checked + svg': {
    fill: theme.palette.common.darkBlue7
  }
};

export const ConsentData = {
  color: theme.palette.common.darkGrey2,
  lineHeight: 1.6,
  fontSize: '14px',
  fontWeight: 400,
  letterSpacing: 0,

  '> *': {
    fontSize: '14px',
    fontWeight: 400
  }
};

export const mandatoryLabelStyle = {
  fontWeight: 600,
  marginTop: '15px',
  fontSize: '14px',
  color: theme.palette.common.violet
};
