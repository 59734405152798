import { theme } from '../../styles/theme';

export const container = (language: string) => ({
  fontFamily:
    language === 'jpn'
      ? '"Noto Sans JP", sans-serif !important'
      : '"Noto Sans", sans-serif !important',
  '> *': {
    fontFamily:
      language === 'jpn'
        ? '"Noto Sans JP", sans-serif !important'
        : '"Noto Sans", sans-serif !important'
  },
  a: {
    color: theme.palette.common.darkBlue7
  }
});

export const lowerAlphaBrace = {
  li: {
    position: 'relative',
    counterIncrement: 'count 1'
  },
  '> li::marker': {
    content: "counter(count, lower-alpha) ') '",
    counterIncrement: 'list',
    position: 'absolute',
    left: '-17px'
  }
};

export const PrivacyPolicyContentHeader = {
  color: theme.palette.common.black,
  fontSize: '25px',
  marginBottom: '20px',
  fontWeight: 700
};

export const PrivacyPolicyContentParagraph = {
  fontWeight: 400,
  lineHeight: 1.5
};

export const MB40 = {
  marginBottom: '40px'
};

export const MB20 = {
  marginBottom: '20px'
};

export const MB10 = {
  marginBottom: '10px'
};

export const FW600 = {
  fontWeight: 600
};

export const PrivacyPolicyItalic = {
  fontStyle: 'italic'
};

export const Padding20 = {
  padding: '20px'
};

export const BorderLight = {
  border: `1px solid ${theme.palette.common.lightGrey2}`
};
export const FS13 = {
  fontSize: '13px'
};
