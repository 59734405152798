export const ProductFeaturesTitle = {
  margin: 'auto',
  paddingBlock: '2rem',
  fontWeight: 700,
  fontSize: '35px',
  lineHeight: '47px',
  textAlign: 'center'
};
export const ProductFeaturesSingle = {
  padding: '0.5rem',
  height: '100%',
  fontWeight: 400,
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between'
};
export const ProductFeaturesSingleTitle = {
  fontSize: '20px',
  fontWeight: 700,
  lineHeight: 1.2,
  letterSpacing: 0,
  padding: '1rem'
};

export const ProductFeaturesSingleDesc = {
  fontSize: '16px',
  lineHeight: '26px',
  letterSpacing: 0,
  textAlign: 'center'
};
