export const DashboardHeaderLeft = {
  width: '757px',
  '@media screen and (max-width: 1024px)': {
    width: '100%'
  }
};
export const DashboardHeaderRight = {
  width: '536px',
  '@media screen and (max-width: 1024px)': {
    width: '100%'
  }
};
export const DetailsHeader = {
  padding: '70px',
  display: 'flex',
  justifyContent: 'center',
  gap: '25px',
  '@media screen and (max-width: 1240px)': {
    padding: '70px 30px'
  },
  '@media screen and (max-width: 745px)': {
    paddingBottom: '10px'
  },
  '@media screen and (max-width: 430px)': {
    paddingBottom: '10px !important',
    padding: '70px 10px'
  },
  '@media screen and (max-width: 1024px)': {
    flexFlow: 'column',
    paddingBottom: '10px'
  }
};
