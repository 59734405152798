import { FC } from 'react';
import { Grid, Typography } from '@mui/material';
import { DashboardResultsContainer } from './DashboardResults.styles';
import { ResultsTablesWrapper } from '../ResultsTablesWrapper/ResultsTablesWrapper';
import { DashboardResultsTiles } from './DashboardResultsTiles';
import { useTranslation } from 'react-i18next';

export const DashboardResults: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Grid container sx={DashboardResultsContainer}>
        <Typography component='h1'>{t('results.header')}</Typography>
        <DashboardResultsTiles />
        <ResultsTablesWrapper />
      </Grid>
    </>
  );
};
