import { FC } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import {
  PracticeButton,
  PracticeIcon,
  PracticeSkills,
  PracticeTestLabelContainer,
  PracticeTime,
  PracticeTitle,
  SkillBullet,
  SkillsAndTime,
  SkillWrapper,
  TitleAndSkills
} from './PracticeTestLabel.style';
import { Img } from '../Primitive';
import ClockIcon from '../../assets/images/clock-updated.svg';
import { useTranslation } from 'react-i18next';

export interface PracticeTestLabelProps {
  icon: any;
  title: string;
  skills: string[];
  time: number;
  handleStartTest: () => void;
}

export const PracticeTestLabel: FC<PracticeTestLabelProps> = ({
  time,
  skills,
  icon,
  title,
  handleStartTest
}) => {
  const { t } = useTranslation();

  return (
    <Box sx={PracticeTestLabelContainer}>
      <Img sx={PracticeIcon} src={icon} />
      <Stack sx={TitleAndSkills}>
        <Typography sx={PracticeTitle}>{title}</Typography>
        <Box sx={SkillsAndTime}>
          <Box sx={PracticeSkills}>
            {skills.map((item, index) => (
              <Box key={`skill-${index}`} sx={SkillWrapper}>
                <Typography>{item}</Typography>{' '}
                {index !== skills.length - 1 && <Box sx={SkillBullet} />}
              </Box>
            ))}
          </Box>
          <Typography sx={PracticeTime}>
            <Img sx={{ paddingRight: '5px' }} src={ClockIcon} /> {time}
            {t('mins')}
          </Typography>
        </Box>
      </Stack>
      <Button onClick={() => handleStartTest()} sx={PracticeButton}>
        {t('dashboardPage.practiceTestLabelButtonText')}
      </Button>
    </Box>
  );
};
