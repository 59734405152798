import { theme } from '../../styles/theme';
import { buttonOutlineDarkSecondary } from '../../styles/button';

export const PracticeTestLabelContainer = {
  display: 'flex',
  alignItems: 'center',
  background: theme.palette.common.lightGrey4,
  borderLeft: '7px solid #EAEAEA',
  borderRadius: '8px',
  height: '85px',
  marginBottom: '20px',
  paddingLeft: '25px',
  '@media screen and (max-width: 743px)': {
    flexFlow: 'column',
    alignItems: 'flex-start',
    height: 'unset'
  }
};

export const PracticeButton = {
  ...buttonOutlineDarkSecondary,
  textTransform: 'none',
  margin: '60px 30px 60px auto',
  '@media screen and (max-width: 743px)': {
    margin: '30px 0 30px 0'
  }
};

export const PracticeTitle = {
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: 1.2,
  color: theme.palette.common.darkGrey2,
  marginLeft: '15px',
  '@media screen and (max-width: 743px)': {
    marginLeft: 0
  }
};

export const PracticeSkills = {
  display: 'flex',
  alignItems: 'center',
  '@media screen and (min-width: 956px)': {
    marginLeft: '20px'
  },
  marginLeft: '15px',
  marginRight: '26px',
  '@media screen and (max-width: 770px)': {
    marginRight: '7px'
  },
  '@media screen and (max-width: 743px)': {
    marginLeft: 0
  }
};

export const SkillBullet = {
  width: '4px',
  height: '4px',
  background: theme.palette.common.darkBlue2,
  borderRadius: '100px',
  margin: '0 5px 0 5px'
};

export const PracticeTime = {
  fontSize: '12px',
  display: 'flex',
  alignItems: 'center',
  fontWeight: 600
};

export const SkillWrapper = {
  display: 'flex',
  alignItems: 'center',
  '> p': {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: 1.2,
    color: theme.palette.common.darkBlue2
  }
};

export const TitleAndSkills = {
  gap: '5px',
  '@media screen and (min-width: 1400px)': {
    flexFlow: 'row',
    gap: 0
  }
};

export const PracticeIcon = {
  '@media screen and (max-width: 743px)': {
    marginTop: '25px',
    marginBottom: '15px'
  }
};

export const SkillsAndTime = {
  display: 'flex',
  '@media screen and (max-width: 743px)': {
    flexFlow: 'column'
  }
};
