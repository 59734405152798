export const EventTypes = {
  // WEB
  WEBSITE_VISITED: { action: 'Website_visited', category: 'general' },
  APP_DOWNLOADED: { action: 'Application_downloaded', category: 'general' },
  SIGN_UP_APP: { action: 'Sign-Up button_landing_page', category: 'sign_up' },
  SIGN_UP_OAUTH: { action: 'Sign-Up with Google button_landing_page', category: 'sign_up' },
  SIGN_IN_APP: { action: 'Sign-In button_landing_page', category: 'sign_in' },
  SIGN_IN_OAUTH: { action: 'Sign-In with Google button_landing_page', category: 'sign_in' },
  START_PRACTICE_TEST: { action: 'Start_practice button_dashboard', category: 'start_test' },
  START_TEST: { action: 'Start_test button_dashboard', category: 'start_test' },

  //DESKTOP
  SIGN_IN_APP_DESKTOP: { action: 'Desktop Sign-In button_landing_page', category: 'sign_in' },
  SIGN_IN_OAUTH_DESKTOP: {
    action: 'Desktop Sign-In with Google button_landing_page',
    category: 'sign_in'
  },
  TEST_STARTED_DESKTOP: { action: 'Desktop Test_launched', category: 'test_in_progress' },
  PRACTICE_TEST_STARTED_DESKTOP: {
    action: 'Desktop Practice_test_launched',
    category: 'test_in_progress'
  },
  TEST_SETUP_COMPLETED_DESKTOP: {
    action: 'Desktop test_setup_successful',
    category: 'test_in_progress'
  },
  TEST_LATE_HANGUP: { action: 'Desktop test_late_hangup', category: 'test_finished' },
  TEST_FINISHED: { action: 'Desktop test_finished', category: 'test_finished' },
  TEST_ABORTED: { action: 'Desktop test_aborted', category: 'test_finished' },

  //TEST_SETUP
  TEST_SETUP_BANDWIDTH_SUCCESS: { action: 'Test setup bandwidth_success', category: 'test_setup' },
  TEST_SETUP_BANDWIDTH_FAILED: { action: 'Test setup bandwidth_failed', category: 'test_setup' },
  TEST_SETUP_SYSTEM_CHECK_SUCCESS: {
    action: 'Test setup system_check_success',
    category: 'test_setup'
  },
  TEST_SETUP_SYSTEM_CHECK_FAILED: {
    action: 'Test setup system_check_failed',
    category: 'test_setup'
  },
  TEST_SETUP_MICROPHONE_CHECK_SUCCESS: {
    action: 'Test setup microphone_check_success',
    category: 'test_setup'
  },
  TEST_SETUP_MICROPHONE_CHECK_FAILED: {
    action: 'Test setup microphone_check_failed',
    category: 'test_setup'
  },
  TEST_SETUP_BACKGROUND_NOISE_CHECK_SUCCESS: {
    action: 'Test setup background_noise_check_success',
    category: 'test_setup'
  },
  TEST_SETUP_BACKGROUND_NOISE_CHECK_FAILED: {
    action: 'Test setup background_noise_check_failed',
    category: 'test_setup'
  },
  TEST_SETUP_SPEAKER_CHECK_SUCCESS: {
    action: 'Test setup speaker_check_success',
    category: 'test_setup'
  },
  TEST_SETUP_SPEAKER_CHECK_FAILED: {
    action: 'Test setup speaker_check_failed',
    category: 'test_setup'
  },
  TEST_SETUP_TAKE_SELFIE_SUCCESS: {
    action: 'Test setup take_selfie_success',
    category: 'test_setup'
  },
  TEST_SETUP_TAKE_SELFIE_FAILED: {
    action: 'Test setup take_selfie_failed',
    category: 'test_setup'
  },
  TEST_SETUP_CAPTURE_VIDEO_SUCCESS: {
    action: 'Test setup capture_video_success',
    category: 'test_setup'
  },
  TEST_SETUP_CAPTURE_VIDEO_FAILED: {
    action: 'Test setup capture_video_failed',
    category: 'test_setup'
  },
  TEST_SETUP_ID_VERIFICATION_SUCCESS: {
    action: 'Test setup id_verification_success',
    category: 'test_setup'
  },
  TEST_SETUP_ID_VERIFICATION_FAILED: {
    action: 'Test setup id_verification_failed',
    category: 'test_setup'
  },
  TEST_SETUP_DROPPED: { action: 'Test setup dropped', category: 'test_setup' }
};
