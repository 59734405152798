export const LoaderContainer = () => ({
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  position: 'fixed',
  zIndex: '99999',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'rgba(0,0,0,0.35)'
});
