import { theme } from '@theme';

export const dashboardUploadIdContainer = {
  '--mobile-header-height': '90px',
  '--counter-height': '64px',
  '--counter-top-space': '25px',
  '--content-container-top-space': '15px',
  '--content-container-bottom-space': '15px',
  '--counter-top-position':
    'calc(var(--mobile-header-height) + var(--counter-height) + var(--counter-top-space) + var(--content-container-top-space))'
};

export const dashboardUploadIdProcessContainer = {
  padding: '15px',
  position: 'relative',
  height: '100vh'
};

export const dashboardUploadIdContentContainer = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'fixed',
  top: 'var(--counter-top-position)',
  left: '15px',
  right: '15px',
  height: 'calc(100% - var(--counter-top-position) - var(--content-container-bottom-space))',
  overflowY: 'auto',
  overflowX: 'hidden'
};

export const dashboardUploadIdHeader = {
  fontSize: '32px',
  fontWeight: 700,
  color: theme.palette.common.darkBlue1,
  display: 'flex',
  alignItems: 'center',
  marginBottom: '15px',
  textAlign: 'center',
  '> svg': {
    height: '20px',
    marginLeft: '5px'
  },
  '@media only screen and (min-width: 320px) and (max-width: 600px)': {
    fontSize: '25px',
    display: 'block',
    textAlign: 'center',
    marginTop: '50px'
  }
};

export const dashboardUploadIdSubheader = {
  fontSize: '24px',
  fontWeight: 400,
  color: theme.palette.common.darkBlue1,
  marginBottom: '10px',
  textAlign: 'center',
  '@media only screen and (min-width: 320px) and (max-width: 600px)': {
    fontSize: '18px'
  }
};

export const dashboardUploadIdButton = {
  borderRadius: '79px',
  backgroundColor: theme.palette.common.lightGrey6,
  border: 'none',
  color: 'black',
  boxShadow: '0 0 5px rgba(0, 0, 0, 0.15)',
  textTransform: 'none',
  fontWeight: 600,
  padding: '15px',
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginTop: '20px',
  '&:hover': {
    backgroundColor: theme.palette.common.lightGrey6,
    boxShadow: '0 0 20px rgba(0, 0, 0, 0.15)'
  }
};

export const dashboardUploadIdTipsWrapper = {
  display: 'flex',
  width: '710px',
  justifyContent: 'space-between',
  '> div': {
    position: 'relative'
  },
  img: {
    position: 'relative',
    zIndex: 0
  },
  '@media only screen and (min-width: 320px) and (max-width: 600px)': {
    width: 'auto',
    flexWrap: 'wrap'
  }
};

export const dashboardUploadIdTipsItemIcon = {
  position: 'absolute',
  top: '-10px',
  right: '-10px',
  background: 'white',
  borderRadius: '50%',
  zIndex: '1'
};

export const dashboardUploadIdIconRed = {
  fill: theme.palette.common.darkRed
};

export const dashboardUploadIdIconGreen = {
  fill: theme.palette.common.darkGreen
};

export const dashboardUploadIdThirdImageStyles = {
  '@media only screen and (min-width: 320px) and (max-width: 600px)': {
    marginLeft: '-5px',
    marginTop: '15px'
  }
};

export const dashboardUploadIdFourthImageStyles = {
  '@media only screen and (min-width: 320px) and (max-width: 600px)': {
    marginTop: '15px'
  }
};

export const dashboardUploadIdContinueBtn = {
  display: 'block',
  marginBottom: '80px',
  marginTop: '70px',
  marginLeft: 'auto',
  marginRight: 'auto'
};

export const dashboardUploadIdMobileHeader = {
  height: 'var(--mobile-header-height)',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.08)',
  display: 'flex',
  alignItems: 'center',
  position: 'fixed',
  width: '100%',
  background: theme.palette.common.white,
  zIndex: '100',
  left: 0,
  top: 0
};

export const dashboardUploadIdMobileTakePhotoContainer = {
  width: '100%',
  position: 'fixed',
  top: '90px',
  left: 0,
  height: 'calc(100vh - 90px)',
  overflowY: 'auto'
};

export const dashboardUploadIdMobileTakePhotoParagraph = {
  color: theme.palette.common.white,
  width: '80%',
  textAlign: 'center',
  fontWeight: 400,
  marginInline: 'auto',
  position: 'absolute',
  left: 0,
  right: 0,
  top: '20%',
  margin: 'auto',
  zIndex: 500
};

export const dashboardUploadIdMobileTakePhotoButton = {
  position: 'fixed',
  bottom: '20px',
  left: 'calc(50% - 52px/2)',
  zIndex: 500
};

export const dashboardUploadIdPutYourIdText = {
  fontSize: '16px',
  fontWeight: 400,
  textAlign: 'center',
  margin: '0 30px 0 30px'
};

export const dashboardUploadIdPutYourIdTextTipsRecommendationText = {
  marginTop: '20px',
  marginBottom: '50px',
  fontSize: '18px',
  fontWeight: 600,
  textAlign: 'center'
};

export const dashboardUploadIdTipsContainer = {
  background: theme.palette.common.lightGrey4,
  border: '1px solid #F0F0F0',
  borderRadius: '8px',
  padding: '12px 12px 30px 12px'
};
export const dashboardUploadIdFrontBackIdText = {
  textAlign: 'center',
  color: theme.palette.common.darkGrey11,
  fontSize: '16px',
  fontWeight: 700,
  marginTop: '10px'
};

export const dashboardUploadIdAllDoneContainer = {
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginTop: '15vh'
};

export const dashboardUploadIdAllDoneTitle = {
  fontSize: '25px',
  fontWeight: 700,
  marginTop: '20px',
  textAlign: 'center'
};
export const dashboardUploadIdAllDoneDesc = {
  fontSize: '16px',
  fontWeight: 400,
  textAlign: 'center',
  marginTop: '15px'
};

export const FlexCenter = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

export const dashboardUploadIdBulbContainer = {
  background: theme.palette.common.lightBlue0,
  border: `1px solid ${theme.palette.common.lightBlue02}`,
  borderRadius: '4px',
  width: '90%',
  padding: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '20px auto 30px auto',
  gap: '10px',
  '*': {
    fontSize: '13px'
  }
};

export const dashboardUploadIdImportantTitle = { fontWeight: 700, display: 'inline', fontSize: 13 };

export const dashboardUploadIdClickFrameText = { marginTop: '50px', paddingBottom: '15px' };

export const dashboardUploadIdLottieStyle = {
  position: 'absolute',
  right: '-8%',
  height: 'calc(100% - 34px)',
  display: 'flex',
  alignItems: 'center',
  '> div': {
    width: '40px',
    height: '40px'
  }
};

export const dashboardUploadIdVideo = {
  margin: 'auto',
  marginTop: '30vh',
  display: 'block',
  objectFit: 'cover',
  zIndex: 101,
  height: '32%',
  borderRadius: '10px',
  border: '3px solid white'
} as const;

export const dashboardUploadIdNavbarLogo = {
  marginLeft: '30px',
  width: { sm: '115px' }
};

export const dashboardUploadIdRotatedBlock = {
  background: '#8a8a8a',
  position: 'fixed',
  width: '282px',
  height: '87px',
  bottom: '-50px',
  right: '-90px',
  transform: 'rotate(-15deg)'
};
