import { FC } from 'react';
import { Box, Typography, SxProps, Theme } from '@mui/material';
import { QuestionTypeVideo } from '@barracuda/shared/src/components/QuestionTypeVideo/QuestionTypeVideo';
import VideoPlaceholder from '@sharedImages/video-placeholder.svg';
import { title, slider, sliderItemWrapper } from './ExploreTestQuestionsResource.styles';
import { Slider } from '@barracuda/shared/src/components/Slider/Slider';
import { RegularTextColor } from '../GettingReadyPage/GettingReadyPage.styles';
import { useTranslation } from 'react-i18next';

export const ExploreTestQuestionsResource: FC<{
  readonly handleItemClick?: (groupName: string, index: number) => void;
  readonly sx?: SxProps<Theme>;
  readonly id?: string;
}> = ({ sx, id, handleItemClick }) => {
  const { t } = useTranslation();

  return (
    <Box sx={sx} id={id}>
      <Typography sx={title} component='h3'>
        {t('gettingReadyPage.exploreTestHeader')}
      </Typography>
      <Typography sx={RegularTextColor}>{t('gettingReadyPage.exploreTestText')}</Typography>

      <Slider
        sx={slider}
        breakpoints={{
          0: {
            slidesPerView: 1,
            spaceBetween: 15
          },
          600: {
            slidesPerView: 1.2,
            spaceBetween: 15
          },
          900: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          1200: {
            slidesPerView: 3,
            spaceBetween: 20
          }
        }}
        items={[
          <Box sx={sliderItemWrapper}>
            <QuestionTypeVideo
              title={t('gettingReadyPage.exploreTestVideo1title')}
              image={VideoPlaceholder}
              description={t('gettingReadyPage.exploreTestVideo1Description')}
              onClick={() => handleItemClick?.('testQuestionTypes', 0)}
            />
          </Box>,
          <Box sx={sliderItemWrapper}>
            <QuestionTypeVideo
              title={t('gettingReadyPage.exploreTestVideo2title')}
              image={VideoPlaceholder}
              description={t('gettingReadyPage.exploreTestVideo2Description')}
              onClick={() => handleItemClick?.('testQuestionTypes', 1)}
            />
          </Box>,
          <Box sx={sliderItemWrapper}>
            <QuestionTypeVideo
              title={t('gettingReadyPage.exploreTestVideo3title')}
              image={VideoPlaceholder}
              description={t('gettingReadyPage.exploreTestVideo3Description')}
              onClick={() => handleItemClick?.('testQuestionTypes', 2)}
            />
          </Box>
        ]}
      />
    </Box>
  );
};
