import { theme } from '../../styles/theme';
import { buttonOutlineDarkSecondary } from '../../styles/button';

export const BasicInformationBoxContainer = {
  display: 'flex',
  flexDirection: 'row',
  paddingTop: '45px',
  paddingBottom: '40px',
  justifyContent: 'center',
  '@media screen and (min-width: 1201px)': {
    width: '1090px',
    alignSelf: 'center',
    paddingLeft: 0,
    paddingRight: 0
  },
  '@media screen and (max-width: 1023px)': {
    flexDirection: 'column'
  },
  '@media screen and (min-width: 1025px) and (max-width: 1200px)': {
    paddingLeft: '65px',
    paddingRight: '65px'
  },
  '@media screen and (min-width: 835px) and (max-width: 1024px)': {
    paddingLeft: '37px',
    paddingRight: '37px'
  },
  '@media screen and (min-width: 430px) and (max-width: 834px)': {
    paddingLeft: '20px',
    paddingRight: '20px'
  },
  '@media screen and (max-width: 429px)': {
    paddingLeft: '20px',
    paddingRight: '20px'
  }
};

export const BasicInformationBoxSectionContainer = {
  background: theme.palette.common.white,
  border: '1px solid #EAEAEA',
  borderRadius: '6px',
  padding: '35px 25px',
  flex: 1
};

export const BasicInformationBoxHeader = {
  fontSize: '22px',
  fontFamily: '"Open Sans", sans-serif',
  fontWeight: 600,
  marginBottom: '35px'
};

export const BasicInformationBoxHeaderContainer = {
  display: 'flex',
  justifyContent: 'space-between'
};

export const BasicInformationItem = {
  padding: '10px 18px',
  borderBottom: '1px solid #E9E9E9'
};

export const BasicInformationEllipsis = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  width: '100%',
  whiteSpace: 'nowrap'
};

export const BasicInformationItemLabel = {
  fontWeight: 600,
  lineHeight: 1
};

export const BasicInformationEditButton = {
  ...buttonOutlineDarkSecondary,
  alignSelf: 'flex-start'
};

export const PileLabel = {
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: 1.6
};

export const Pile = {
  background: theme.palette.common.darkBlue1,
  borderRadius: '32px',
  color: theme.palette.common.white,
  padding: '4px 16px',
  width: 'min-content'
};
