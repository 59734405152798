import { Autocomplete, Button, Grid, TextField, Typography } from '@mui/material';
import { BaseSyntheticEvent, FC } from 'react';
import { useForm } from 'react-hook-form';
import {
  DoneButton,
  FormInput,
  FormInputContainer,
  InputLabel
} from './DemographicInfoPopup.style';
import { Country } from '../../interface/dictionary.interfaces';
import { LanguageInterface, User } from '../../index';
import { SignInButton } from '../SignIn/SignInStyles';
import { debounce } from 'lodash';
import { SignUpErrorMessage } from '../SignUp/SignUp.styles';
import { useTranslation } from 'react-i18next';

interface DemographicInfoPopupProps {
  user?: User;
  token?: string;
  formSubmit: (values: { [p: string]: any }) => void;
  closeDialog: (user?: User) => void;
  countries: Country[] | [];
  cities: Country[] | [];
  languages: LanguageInterface[] | [];
  setCountry: (value: Country | null) => void;
  setCity: (value: Country | null) => void;
  setCitizenship: (value: Country | null) => void;
  setLanguage: (value: LanguageInterface | null) => void;
  country: Country | null;
  city: Country | null;
  language: LanguageInterface | null;
  citizenship: Country | null;
  handleInputChange: (
    event: BaseSyntheticEvent,
    resources: 'countries' | 'languages' | 'cities'
  ) => void;
}

export const DemographicInfoPopup: FC<DemographicInfoPopupProps> = ({
  formSubmit,
  cities,
  languages,
  countries,
  country,
  citizenship,
  city,
  language,
  handleInputChange,
  setCitizenship,
  setCity,
  setCountry,
  setLanguage
}) => {
  const formData = useForm({
    criteriaMode: 'all',
    mode: 'all',
    reValidateMode: 'onChange'
  });
  const { t } = useTranslation();

  const clearCity = () => {
    setCity(null);
    formData.setValue('cityOfResidence', null);
    formData.trigger('cityOfResidence');
  };

  const handleAutocompleteChange = (
    _: BaseSyntheticEvent,
    v: Country | LanguageInterface | null,
    formControlName: string
  ) => {
    switch (formControlName) {
      case 'countryOfResidence':
        setCountry(v as Country);
        clearCity();
        break;
      case 'cityOfResidence':
        setCity(v as Country);
        break;
      case 'countryOfCitizenship':
        setCitizenship(v as Country);
        break;
      case 'nativeLanguage':
        setLanguage(v as LanguageInterface);
        break;
    }
    formData.setValue(formControlName, v || null);
    formData.trigger(formControlName);
  };

  return (
    <>
      <Grid container sx={{ marginTop: '40px' }}>
        <Grid xs={12} sm={6} item sx={FormInputContainer}>
          <Typography sx={InputLabel}>{t('profilePage.countryOfResidence')}</Typography>
          <Autocomplete
            {...formData.register('countryOfResidence', {
              required: true,
              value: country
            })}
            disablePortal={false}
            forcePopupIcon={false}
            id='countryInput'
            options={countries.length ? countries : []}
            getOptionLabel={(option) => option.name}
            onInputChange={debounce((e) => handleInputChange(e, 'countries'), 300)}
            onChange={(event, value) =>
              handleAutocompleteChange(event, value, 'countryOfResidence')
            }
            value={country}
            renderInput={(params) => (
              <TextField
                {...params}
                type='text'
                id='countryOfResidence'
                variant='outlined'
                sx={FormInput}
              />
            )}
            noOptionsText={t('noOptions')}
          />
          {formData.formState.errors?.countryOfResidence?.type === 'required' && (
            <Typography sx={[SignUpErrorMessage, { bottom: '5px' }]}>
              {t('fieldIsRequired')}
            </Typography>
          )}
        </Grid>
        <Grid xs={12} sm={6} item sx={FormInputContainer}>
          <Typography sx={InputLabel}>{t('profilePage.cityOfResidence')}</Typography>
          <Autocomplete
            {...formData.register('cityOfResidence', {
              required: true,
              value: city
            })}
            disablePortal={false}
            forcePopupIcon={false}
            id='cityOfResidence'
            options={cities.length ? cities : city ? [city] : []}
            getOptionLabel={(option) => option.name}
            onInputChange={debounce((e) => handleInputChange(e, 'cities'), 300)}
            onChange={(event, value) => handleAutocompleteChange(event, value, 'cityOfResidence')}
            value={city}
            renderInput={(params) => (
              <TextField
                {...params}
                type='text'
                id='cityOfResidenceInput'
                variant='outlined'
                sx={FormInput}
              />
            )}
          />
          {formData.formState.errors?.cityOfResidence?.type === 'required' && (
            <Typography sx={[SignUpErrorMessage, { bottom: '5px' }]}>
              {t('fieldIsRequired')}
            </Typography>
          )}
        </Grid>
        <Grid xs={12} sm={6} item sx={FormInputContainer}>
          <Typography sx={InputLabel}>{t('profilePage.citizenship')}</Typography>
          <Autocomplete
            {...formData.register('countryOfCitizenship', {
              value: citizenship
            })}
            disablePortal={false}
            forcePopupIcon={false}
            id='citizenship'
            options={countries.length ? countries : []}
            getOptionLabel={(option) => option.name}
            onInputChange={debounce((e) => handleInputChange(e, 'countries'), 300)}
            onChange={(event, value) =>
              handleAutocompleteChange(event, value, 'countryOfCitizenship')
            }
            value={citizenship}
            renderInput={(params) => (
              <TextField
                {...params}
                type='text'
                id='citizenshipInput'
                variant='outlined'
                sx={FormInput}
              />
            )}
            noOptionsText={t('noOptions')}
          />
        </Grid>
        <Grid xs={12} sm={6} item sx={FormInputContainer}>
          <Typography sx={InputLabel}>{t('profilePage.firstLanguage')}</Typography>
          <Autocomplete
            {...formData.register('nativeLanguage', {
              value: language
            })}
            disablePortal={false}
            forcePopupIcon={false}
            id='firstLanguage'
            options={languages.length ? languages : language ? [language] : []}
            getOptionLabel={(option) => option.name}
            onInputChange={debounce((e) => handleInputChange(e, 'languages'), 300)}
            onChange={(event, value) => handleAutocompleteChange(event, value, 'nativeLanguage')}
            value={language || null}
            renderInput={(params) => (
              <TextField
                {...params}
                type='text'
                id='firstLanguageInput'
                variant='outlined'
                sx={FormInput}
              />
            )}
            noOptionsText={t('noOptions')}
          />
        </Grid>
      </Grid>
      <Button
        onClick={() => formSubmit(formData.getValues())}
        disabled={!formData.formState.isValid}
        type='submit'
        sx={[SignInButton, DoneButton]}
      >
        {t('profilePage.done')}
      </Button>
    </>
  );
};
