import { theme } from '@theme';

import { Theme } from '@mui/material';

export const SectionMargin = (theme: Theme) => ({
  marginTop: '50px',
  [theme.breakpoints.up('md')]: {
    marginTop: '100px'
  }
});

export const SectionMarginBottom = (theme: Theme) => ({
  marginBottom: '50px',
  [theme.breakpoints.up('md')]: {
    marginBottom: '100px'
  }
});

export const SectionContainer = {
  maxWidth: '1240px',
  marginInline: 'auto'
};

export const FooterSectionContainer = {
  '@media only screen and (min-width: 320px) and (max-width: 745px)': {
    paddingTop: 0
  }
};

export const heroSectionContainer = {
  paddingTop: {
    xs: '120px',
    lg: '217px'
  }
};

export const HeroSectionLeftColumn = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
};

export const H1 = {
  fontSize: {
    xs: '40px',
    sm: '45px',
    lg: '50px'
  },
  color: theme.palette.common.darkGrey2,
  fontWeight: 700,
  lineHeight: 1.2
};

export const heroText = {
  fontSize: '20px',
  marginTop: '20px',
  lineHeight: 1.6
};

export const H3 = {
  color: theme.palette.common.darkGrey2,
  fontWeight: 700,
  fontSize: {
    xs: '28px',
    sm: '30px',
    md: '35px'
  },
  marginBottom: '15px',
  lineHeight: 1.5
};

export const HeroSectionButtonsContainer = (theme: Theme) => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: '45px',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  'button:first-of-type': {
    marginRight: 0,
    marginBottom: '10px',
    [theme.breakpoints.up('sm')]: {
      marginRight: '15px',
      marginBottom: 0
    },
    [theme.breakpoints.up('md')]: {
      marginRight: '25px',
      marginBottom: 0
    }
  }
});

export const HeroSectionRightImage = {
  maxWidth: '100%'
};

export const AccordionContainer = {
  width: '60%',
  margin: '0 auto',
  '@media only screen and (min-width: 320px) and (max-width: 745px)': {
    width: '100%'
  }
};

export const BlueLink = {
  cursor: 'pointer',
  fontWeight: 600,
  color: theme.palette.common.darkBlue7,
  textDecoration: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  svg: {
    height: '15px'
  }
};

export const FooterContainer = {
  paddingTop: '40px',
  paddingBottom: '40px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '@media only screen and (min-width: 320px) and (max-width: 745px)': {
    paddingTop: '20px',
    paddingBottom: '20px'
  }
};

export const FooterLinksContainer = {
  flex: '1',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  a: {
    color: theme.palette.common.white,
    fontSize: '14px',
    textDecoration: 'none'
  },
  '@media only screen and (min-width: 320px) and (max-width: 745px)': {
    flexDirection: 'column',
    alignItems: 'flex-start',
    a: {
      display: 'inline-block',
      marginBottom: '10px'
    }
  }
};

export const PagePadding = {
  paddingInline: '16px'
};

export const SectionPaddingBottom = (theme: Theme) => ({
  paddingBottom: '50px',
  [theme.breakpoints.up('md')]: {
    paddingBottom: '100px'
  }
});

export const GraySection = {
  overflowX: 'hidden',
  background: '#f9f9f9'
};

export const FooterCopyright = {
  color: theme.palette.common.white,
  fontSize: '14px',
  width: '320px',
  textAlign: 'right',
  '@media only screen and (min-width: 320px) and (max-width: 745px)': {
    width: '100%',
    textAlign: 'left',
    marginTop: '20px'
  }
};

export const FooterLink = {
  cursor: 'pointer',
  paddingLeft: '30px',
  paddingRight: '30px',
  '@media only screen and (min-width: 320px) and (max-width: 745px)': {
    paddingLeft: 0
  }
};

export const checkAndShareResultsSectionContainer = (theme: Theme) => ({
  paddingTop: '60px',
  paddingBottom: '75px',
  [theme.breakpoints.up('md')]: {
    paddingTop: '120px',
    paddingBottom: '150px'
  }
});

export const RegularTextColor = {
  color: theme.palette.common.darkGrey3
};
