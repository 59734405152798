import { FC, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import heroImage from '@sharedImages/resources-landing--hero-image.svg';
import {
  checkAndShareResultsSectionContainer,
  FooterSectionContainer,
  GraySection,
  H1,
  HeroSectionButtonsContainer,
  heroSectionContainer,
  HeroSectionLeftColumn,
  HeroSectionRightImage,
  heroText,
  PagePadding,
  SectionContainer,
  SectionMargin,
  SectionPaddingBottom
} from './GettingReadyPage.styles';
import { DialogWrapper } from '@barracuda/shared/src/components/DialogWrapper/DialogWrapper';
import { DownloadAppPopup } from '@barracuda/shared/src/components/DownloadAppPopup/DownloadAppPopup';
import { VideoPopup } from '@barracuda/shared/src/components/VideoPopup/VideoPopup';
import { GettingReadyPageFooter } from '../GettingReadyPageFooter/GettingReadyPageFooter';
import { BeforeStartTestPopup } from '@barracuda/shared/src/components/BeforeStartTestPopup/BeforeStartTestPopup';
import { BeforeStartTestSignedInPopup } from '@barracuda/shared/src/components/BeforeStartTestSignedInPopup/BeforeStartTestSignedInPopup';
import { useTranslation } from 'react-i18next';
import { HowToGuidesResource } from '../HowToGuidesResource/HowToGuidesResource';
import { ExploreTestQuestionsResource } from '../ExploreTestQuestionsResource/ExploreTestQuestionsResource';
import { CheckSystemRequirementsResource } from '../CheckSystemRequirementsResource/CheckSystemRequirementsResource';
import { CheckAndShareResultsResource } from '../CheckAndShareResultsResource/CheckAndShareResultsResource';
import { buttonDark, buttonOutlineDark } from '@barracuda/shared/src/styles/button';
import { GettingReadyPageFaq } from '../GettingReadyPageFaq/GettingReadyPageFaq';
import { FooterGetInTouch } from '@barracuda/shared/src/components/FooterGetInTouch/FooterGetInTouch';
import { NavigateFunction } from 'react-router-dom';
import { ScalableWrapper } from '@barracuda/shared/src/components/ScalableWrapper/ScalableWrapper';
import { GetStartedResource } from '../GetStartedResource/GetStartedResource';
import { PrepareForTestResource } from '../PrepareForTestResource/PrepareForTestResource';
import { Img } from '@barracuda/shared/src/components/Primitive';

interface GettingReadyPageProps {
  readonly handleContactUs: () => void;
  readonly handleStartTest: () => void;
  readonly handleDownloadGuide: () => void;
  readonly handleHelpCenter: () => void;
  readonly isDesktop?: boolean;
  readonly handleNavigate: (to: string) => void;
  readonly videoUrls: Record<string, string[]>;
  readonly isMobile?: boolean;
  readonly isLoggedIn?: boolean;
  readonly navigate: NavigateFunction;
}

export const GettingReadyPage: FC<GettingReadyPageProps> = ({
  handleDownloadGuide,
  handleHelpCenter,
  handleNavigate,
  videoUrls,
  isMobile,
  isLoggedIn,
  navigate
}) => {
  const [open, setOpen] = useState({
    guide: false,
    download: false,
    startTest: false,
    video: false
  });
  const [video, setVideo] = useState('');
  const [activeVideoIndex, setActiveVideoIndex] = useState(0);
  // there is multiple video sections, this hooks provide ability to keep playing next videos inside one section
  const [activeVideoGroup, setActiveVideoGroup] = useState('');
  const [beforeStartOpen, setBeforeStartOpen] = useState(false);
  const { t } = useTranslation();

  const footerLinks = [
    { label: t('footerLinks.termsOfUse'), to: 'terms-of-use' },
    {
      label: t('footerLinks.privacy'),
      to: process.env.REACT_APP_PRIVACY_POLICY_LINK as string,
      external: true
    },
    {
      label: t('footerLinks.cookies'),
      to: process.env.REACT_APP_COOKIE_POLICY_LINK as string,
      external: true
    }
  ];

  const openVideoModal = (groupName: string, index: number) => {
    // prevent executing function if there is no movies under groupName array
    if (!videoUrls[groupName]) return;

    setActiveVideoGroup(groupName);
    setActiveVideoIndex(index);
    setVideo(videoUrls[groupName][index]);
    setOpen({ ...open, video: true });
  };

  const onVideoEnded = () => {
    if (videoUrls[activeVideoGroup][activeVideoIndex + 1]) {
      openVideoModal(activeVideoGroup, activeVideoIndex + 1);
    }
  };

  const downloadGuide = () => {
    handleDownloadGuide();
    setOpen({ ...open, guide: false });
  };

  const startTest = () => {
    isMobile
      ? setBeforeStartOpen(true)
      : isLoggedIn
      ? navigate('..#startPractice')
      : setOpen({ startTest: true, download: false, guide: false, video: false });
  };

  const MAC_URL = `${process.env.REACT_APP_PESC_URL}${process.env.REACT_APP_PESC_DOWNLOAD_DMG_MAC_PATH}`;
  const MSI_URL = `${process.env.REACT_APP_PESC_URL}${process.env.REACT_APP_PESC_DOWNLOAD_MSI_PATH}`;

  return (
    <ScalableWrapper isLoggedIn={isLoggedIn}>
      <Box>
        <Box sx={[GraySection, heroSectionContainer, SectionPaddingBottom]}>
          {/* Hero section */}
          <Box sx={[SectionContainer, PagePadding]} id='hero'>
            <Grid container spacing={{ xs: '16px', md: '40px', lg: '120px' }}>
              <Grid item md={6} lg={5} xs={12} sx={HeroSectionLeftColumn}>
                <Typography sx={H1} component='h1'>
                  {t('gettingReadyPage.heroHeader')}
                </Typography>
                <Box sx={heroText}>{t('gettingReadyPage.heroText')}</Box>
                <Box sx={HeroSectionButtonsContainer}>
                  <Button
                    sx={buttonDark}
                    onClick={() =>
                      isMobile
                        ? setBeforeStartOpen(true)
                        : isLoggedIn
                        ? navigate('..#startPractice')
                        : setOpen({
                            startTest: true,
                            download: false,
                            guide: false,
                            video: false
                          })
                    }
                  >
                    {t('gettingReadyPage.heroPracticeButtonText')}
                  </Button>
                  <Button sx={buttonOutlineDark} onClick={downloadGuide}>
                    {t('gettingReadyPage.heroDownloadButtonText')}
                  </Button>
                </Box>
              </Grid>
              <Grid item md={6} lg={7} xs={12}>
                <Img sx={HeroSectionRightImage} src={heroImage} />
              </Grid>
            </Grid>
          </Box>

          <Grid container sx={[SectionContainer, SectionMargin, PagePadding]}>
            {/* How to get started section */}
            <GetStartedResource />

            {/* watch our how-to guides section */}
            <HowToGuidesResource sx={SectionMargin} openVideoModal={openVideoModal} />
          </Grid>
        </Box>

        {/* Prepare for your test section */}
        <PrepareForTestResource
          id='prepare-for-your-test'
          handleStartPractice={startTest}
          handleDownload={downloadGuide}
          sx={[SectionContainer, PagePadding, SectionMargin]}
        />

        {/* Explore test question types section */}
        <ExploreTestQuestionsResource
          sx={[SectionContainer, SectionMargin, PagePadding]}
          id='explore-test-question-types'
          handleItemClick={openVideoModal}
        />

        {/* Check system requirements section */}
        <CheckSystemRequirementsResource
          id='check-system-requirements'
          sx={[SectionContainer, SectionMargin, PagePadding, { mb: '90px' }]}
        />

        {/* Check and share your results section */}
        <Box sx={[GraySection, PagePadding, checkAndShareResultsSectionContainer]}>
          <CheckAndShareResultsResource
            sx={[SectionContainer, FooterSectionContainer]}
            id='check-and-share-your-results'
          />
        </Box>

        {/* Common questions section */}
        <GettingReadyPageFaq readMoreAction={handleHelpCenter} id='common-questions' />

        {/* Get in touch section */}
        <Box sx={SectionMargin}>
          <FooterGetInTouch id='get-in-touch' />
        </Box>
        <GettingReadyPageFooter navigate={handleNavigate} footerLinks={footerLinks} />
        {open.startTest && (
          <DialogWrapper
            key='startTestDownload'
            open={open.startTest}
            handleClose={() => null}
            subtitle={t('gettingReadyPage.startTestDialogSubtitle') as string}
            scrollable
            title={t('gettingReadyPage.startTestDialogTitle')}
          >
            <BeforeStartTestPopup
              handleClose={() => setOpen({ ...open, startTest: false })}
              handleStartTest={() =>
                isLoggedIn ? navigate('../#startPractice') : navigate('../join')
              }
            />
          </DialogWrapper>
        )}
        {open.download && (
          <DialogWrapper
            key='downloadPopup'
            open={open.download}
            handleClose={() => setOpen({ ...open, download: false })}
            closeable
            subtitle={t('gettingReadyPage.downloadPopupSubtitle') as string}
            scrollable
            title={t('gettingReadyPage.downloadPopupTitle')}
          >
            <DownloadAppPopup
              MSI_URL={MSI_URL}
              MAC_URL={MAC_URL}
              closeDialog={() =>
                setOpen({ download: false, startTest: false, guide: false, video: false })
              }
            />
          </DialogWrapper>
        )}
        {open.video && (
          <DialogWrapper
            key='downloadPopup'
            open={open.video}
            handleClose={() => setOpen({ ...open, video: false })}
            subtitle='Get ready'
            title={t('gettingReadyPage.downloadPopupTitle')}
            clean
            videoWrapper
            isMobile={isMobile}
          >
            <VideoPopup onEnded={onVideoEnded} url={video} />
          </DialogWrapper>
        )}
        <DialogWrapper
          key='formDownload'
          open={beforeStartOpen}
          handleClose={() => setBeforeStartOpen(false)}
          scrollable
          title={t('gettingReadyPage.formDownloadTitle')}
        >
          <BeforeStartTestSignedInPopup
            handleClose={() => setBeforeStartOpen(false)}
            handleStartTest={() => setBeforeStartOpen(false)}
            closeOnly
          />
        </DialogWrapper>
      </Box>
    </ScalableWrapper>
  );
};
