import { isMobile } from '../app.utils';
import customProtocolCheck from 'custom-protocol-check';
import { sopClient } from '@barracuda/shared/src';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import sopUtils from '../../../shared/src/utils/sopUtils';

export const useLaunchTest = (
  openMobileDialogFn: (value: boolean) => void,
  openDownloadDialogFn: (value: boolean) => void,
  isPractice?: boolean
): [(retake?: boolean) => void, boolean, Dispatch<SetStateAction<boolean>>, string] => {
  const [testLimitReached, setTestLimitReached] = useState(false);
  const [message, setMessage] = useState('');

  const checkTestLimitReached = async (retake?: boolean): Promise<boolean> => {
    const { data } = await sopClient.canStartAnotherTest();
    setMessage(data.message);
    return isPractice || retake ? false : data.testLimitReached;
  };

  return [
    useCallback((retake?: boolean) => {
      if (isMobile()) {
        openMobileDialogFn(true);
      } else {
        checkTestLimitReached(retake).then((r) => {
          setTestLimitReached(r);
          if (r) {
            return;
          } else {
            const anchor = document.createElement('a');
            anchor.href = `${process.env.REACT_APP_DEEPLINK_ID}://token=${sopUtils.getSopToken()}`;
            anchor.addEventListener('click', (event) => {
              event.preventDefault ? event.preventDefault() : (event.returnValue = false);
              customProtocolCheck(
                anchor.href,
                () => {
                  openDownloadDialogFn(true);
                },
                undefined,
                1000
              );
            });
            anchor.click();
            return;
          }
        });
      }
    }, []),
    testLimitReached,
    setTestLimitReached,
    message
  ];
};
