import { theme } from '@theme';

export const UserDataFormTipsContainer = {
  display: 'flex',
  alignItems: 'start',
  border: `1px solid ${theme.palette.common.lightBlue02}`,
  background: theme.palette.common.lightBlue0,
  padding: '15px',
  marginBottom: '30px',
  marginTop: '10px'
};

export const UserDataFormTipsParagraph = {
  fontSize: '14px',
  '> ul': {
    marginBlock: 0,
    '> li': {
      color: theme.palette.common.darkBlue2
    }
  }
};
