import React, { FC } from 'react';
import {
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import {
  DateHideCondition,
  dynamicColumnsStyle,
  HideLastRowBorder,
  InitialPadding,
  NoTestsContainer,
  NoTestsDescription,
  ProductNameOnly,
  TestsTableArrowCellImage,
  TestsTableContainer,
  TestsTableOverallCell,
  TestsTableTBody,
  TestsTableTestCell,
  TestsTableTHead,
  TitleAndDate
} from './ResultsTestTable.style';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { StatusBadge } from '../StatusBadge/StatusBadge';
import moment from 'moment';
import { License, Score, statusesWithoutDetailsPage } from '../../interface/licenses.interfaces';
import testIcon from '../../assets/images/test--icon.svg';
import NoTestsToDisplay from '../../assets/images/no-tests-table.svg';
import { Img } from '../Primitive';
import { useTranslation } from 'react-i18next';
import { SkillTypes, SkillType } from '../../index';

export interface ITestsTableProps {
  query?: string;
  tableHeaders: string[];
  licenses: License[];
  handleRowClick: (t: License) => void;
  findScoreByName: (name: SkillType, scale: string, scores: Score[]) => string | null;
  handleStartTest?: (_: React.MouseEvent<HTMLElement> | null, licenseId: number) => void;
  practice?: boolean;
}

export const ResultsTestTable: FC<ITestsTableProps> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <Box sx={TestsTableContainer}>
        <TableContainer>
          <Table>
            <TableHead sx={TestsTableTHead}>
              <TableRow>
                {props.tableHeaders.map((el, index) => (
                  <TableCell key={`${el}${index}`} sx={[dynamicColumnsStyle(el), InitialPadding]}>
                    {el}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody sx={TestsTableTBody}>
              {props.licenses.length > 0 &&
                props.licenses.map((t, index) => (
                  <TableRow
                    key={`${t.id}${index}`}
                    sx={[
                      props.licenses.length > 1 ? HideLastRowBorder : {},
                      {
                        cursor:
                          ['NEW', 'STARTED', 'COMPLETED', 'VALIDATED'].includes(t.status) ||
                          t.product.practice
                            ? 'default'
                            : 'pointer'
                      }
                    ]}
                    onClick={!t.product.practice ? () => props.handleRowClick(t) : () => null}
                  >
                    <TableCell sx={InitialPadding}>
                      <Box sx={TestsTableTestCell}>
                        <Img src={testIcon} />
                        <Box sx={ProductNameOnly}>{t.product.name}</Box>
                        <Box sx={TitleAndDate}>
                          {t.product.name}
                          <Typography>
                            {t.endTime ? moment(t.endTime).format('DD MMM, yyyy') : ''}
                          </Typography>
                        </Box>
                        {process.env.REACT_APP_PESC_CONTENT_QA === '1' && props.handleStartTest && (
                          <Button
                            disabled={!['NEW', 'VALIDATED'].includes(t.status)}
                            onClick={(e) => props.handleStartTest && props.handleStartTest(e, t.id)}
                          >
                            QA: {t.testCode}
                          </Button>
                        )}
                      </Box>
                    </TableCell>
                    <TableCell sx={DateHideCondition}>
                      {t.endTime ? moment(t.endTime).format('DD MMM, yyyy') : ''}
                    </TableCell>
                    {!props.practice && (
                      <TableCell>
                        <StatusBadge type={t.status} />
                      </TableCell>
                    )}
                    <TableCell
                      sx={[TestsTableOverallCell, { width: props.practice ? '170px' : '140px' }]}
                    >
                      {props.findScoreByName(
                        SkillTypes.OVERALL_SCORE,
                        !props.practice ? 'GSE' : 'LEVEL',
                        t.scores
                      )}
                    </TableCell>
                    <TableCell sx={TestsTableOverallCell}>
                      {props.findScoreByName(SkillTypes.OVERALL_SCORE, SkillTypes.CEFR, t.scores)}
                    </TableCell>
                    <TableCell sx={{ width: '50px' }}>
                      {!statusesWithoutDetailsPage.includes(t.status) && !t.product.practice && (
                        <ArrowForwardIosIcon sx={[TestsTableArrowCellImage]} />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              {!props.licenses.length && (
                <TableRow>
                  <TableCell sx={{ textAlign: 'center' }} colSpan={9}>
                    <Stack sx={NoTestsContainer}>
                      <Img width={200} src={NoTestsToDisplay} />
                      <Typography sx={NoTestsDescription}>{t('results.noResultsText')}</Typography>
                    </Stack>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};
