import { FC, useEffect, useState } from 'react';
import {
  Badge,
  LatestTestDetailsTile,
  License,
  SignInStyles,
  SkillScoresTable,
  SkillsTile,
  SkillType,
  V2CertificateSkill
} from '../../index';
import { Box } from '@mui/material';
import {
  DashboardTestDetailsContainer,
  DashboardTestDetailsSkillScoresTableMobileContainer,
  DownloadButtonAlignMobile
} from '../DashboardTestDetails/DashboardTestDetails.styles';
import { NavigateFunction } from 'react-router-dom';
import { CredlyBadgeDialog } from '../CredlyBadgeDialog/CredlyBadgeDialog';
import {
  DetailsHeader,
  SkillsTileVisibility,
  SkillsTileVisibilityMobile,
  TestTileResponsive
} from './DashboardTestDetailsCertified.style';
import { theme } from '../../styles/theme';
import { sopClient } from '../../api/Auth';

interface DashboardTestDetailsCertifiedProps {
  navigate: NavigateFunction;
  license: License;
  skills: Record<string, V2CertificateSkill>;
  findSkillByName: (name: SkillType) => V2CertificateSkill | undefined;
  findScoreByName: (name: string) => number;
  handleDownloadCertificate: () => void;
  languageChange?: (lang: string) => void;
  t: (item: string) => string;
  isDesktop?: boolean;
  findCefrScoreBySkill: (skillName: string) => string | undefined;
}

export const DashboardTestDetailsCertified: FC<DashboardTestDetailsCertifiedProps> = ({
  navigate,
  license,
  skills,
  findSkillByName,
  handleDownloadCertificate,
  findScoreByName,
  findCefrScoreBySkill
}) => {
  const [isCredlyDialogOpen, setIsCredlyDialogOpen] = useState(false);

  const [badge, setBadge] = useState<Badge>();
  useEffect(() => {
    sopClient
      .getUserBadges()
      .then((result) => (result.data ? setBadge(result.data) : setBadge(undefined)));
  }, []);

  return (
    <Box sx={{ backgroundColor: theme.palette.common.lightGrey4 }}>
      <Box sx={DetailsHeader}>
        <Box
          onClick={handleDownloadCertificate}
          sx={[SignInStyles.SignInButton, DownloadButtonAlignMobile]}
        >
          <svg
            width='18'
            height='18'
            viewBox='0 0 18 18'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M16.5 11.75C16.9142 11.75 17.25 12.0858 17.25 12.5V15.5C17.25 16.7426 16.2426 17.75 15 17.75H3C1.75736 17.75 0.75 16.7426 0.75 15.5V12.5C0.75 12.0858 1.08579 11.75 1.5 11.75C1.91421 11.75 2.25 12.0858 2.25 12.5V15.5C2.25 15.9142 2.58579 16.25 3 16.25H15C15.4142 16.25 15.75 15.9142 15.75 15.5V12.5C15.75 12.0858 16.0858 11.75 16.5 11.75ZM9 0.5C9.41421 0.5 9.75 0.835786 9.75 1.25V9.93934L12.2197 7.46967C12.5126 7.17678 12.9874 7.17678 13.2803 7.46967C13.5732 7.76256 13.5732 8.23744 13.2803 8.53033L9.53033 12.2803C9.38388 12.4268 9.19194 12.5 9 12.5C8.80806 12.5 8.61612 12.4268 8.46967 12.2803L4.71967 8.53033C4.42678 8.23744 4.42678 7.76256 4.71967 7.46967C5.01256 7.17678 5.48744 7.17678 5.78033 7.46967L8.25 9.93934V1.25C8.25 0.835786 8.58579 0.5 9 0.5Z'
              fill={theme.palette.common.lightBlue00}
            />
          </svg>
        </Box>
        <Box sx={TestTileResponsive}>
          <LatestTestDetailsTile
            handleDownloadCertificate={handleDownloadCertificate}
            license={license}
            navigate={navigate}
            skills={skills}
            findSkillByName={findSkillByName}
          />
        </Box>
        <Box sx={SkillsTileVisibility}>
          <SkillsTile findScoreByName={findScoreByName} />
        </Box>
      </Box>
      <Box
        sx={[
          DashboardTestDetailsContainer,
          DashboardTestDetailsSkillScoresTableMobileContainer,
          { paddingTop: 0 }
        ]}
      >
        <SkillScoresTable
          skills={skills}
          license={license}
          findCefrScoreBySkill={findCefrScoreBySkill}
        />
        <Box sx={SkillsTileVisibilityMobile}>
          <SkillsTile findScoreByName={findScoreByName} />
        </Box>
      </Box>
      {badge && (
        <CredlyBadgeDialog
          isOpen={isCredlyDialogOpen}
          onCloseDialogClick={() => setIsCredlyDialogOpen(false)}
          badgeImageUrl={badge.imageUrl}
          redeemed={badge.redeemed}
          acceptBadgeUrl={badge.acceptBadgeUrl}
        />
      )}
    </Box>
  );
};
