import { theme } from '@theme';

export const ScoreLookupContainer = {
  padding: '100px',
  width: '900px',
  '@media screen and (min-width: 701px) and (max-width: 1000px)': {
    width: '700px'
  },
  '@media screen and (min-width: 501px) and (max-width: 700px)': {
    width: '100%',
    padding: '70px 50px'
  },
  '@media screen and (max-width: 500px)': {
    background: theme.palette.common.lightGrey4,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '8px',
    width: '90%',
    padding: '20px',
    margin: '20px auto 30px auto'
  }
};

export const ScoreLookupTitle = {
  fontFamily: 'Epilogue',
  fontStyle: 'normal',
  fontWeight: 800,
  fontSize: '40px',
  lineHeight: '48px',
  color: theme.palette.common.darkGrey11,
  '@media screen and (max-width: 500px)': {
    fontSize: '26px',
    fontWeight: 400
  }
};

export const ScoreLookupDescription = {
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: 1.5,
  color: theme.palette.common.darkGrey11,
  marginTop: '40px',
  marginBottom: '25px',
  '@media screen and (max-width: 500px)': {
    marginTop: '20px'
  }
};

export const ScoreLookupTerms = {
  marginTop: '15px',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: 1.4,
  color: theme.palette.common.black
};

export const TermsLink = {
  fontWeight: 600,
  color: theme.palette.common.violet,
  cursor: 'pointer',
  textDecoration: 'none'
};

export const SupportPageLink = {
  textDecoration: 'none',
  color: theme.palette.common.darkBlue7,
  cursor: 'pointer',
  fontFamily: 'inherit',
  fontWeight: 600
};

export const FormBox = {
  paddingBottom: '20px',
  display: 'flex',
  flexFlow: 'column',
  position: 'relative'
};

export const ViewScoresButtonBox = {
  alignItems: 'center',
  marginTop: '45px',
  display: 'flex'
};

export const BoxWithJit = {
  display: 'flex',
  alignItems: 'center',
  '> div': {
    paddingRight: '10px'
  },
  img: {
    paddingBottom: '5px'
  }
};

export const CheckboxResponsive = {
  alignItems: 'flex-start',
  marginTop: '35px',
  width: '70%',
  '@media screen and (max-width: 500px)': {
    width: '100%'
  }
};
