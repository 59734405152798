import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
  useMediaQuery
} from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { DashboardHeaderWrapper } from '../DashboardHeaderWrapper/DashboardHeaderWrapper';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  AccordionBox,
  AccordionSubtitle,
  AccordionTitle,
  LatestTestBadge
} from './DashboardAccordionWrapper.styles';
import ShopAccordionIcon from '@sharedImages/shop-accordion-icon.svg';
import PracticeAccordionIcon from '@sharedImages/practice-accordion-icon.svg';
import TakeTestAccordionIcon from '@sharedImages/take-test-accordion-icon.svg';
import ResultsAccordionIcon from '@sharedImages/results-accordion-icon.svg';
import ShareResultsAccordionIcon from '@sharedImages/share-results-accordion-icon.svg';
import { Img } from '@barracuda/shared/src/components/Primitive';
import { ProductsListWrapper } from '../ProductsListWrapper/ProductsListWrapper';
import { PracticeTestsSetWrapper } from '../PracticeTestsSetWrapper/PracticeTestsSetWrapper';
import { TakeTestAccordionWrapper } from '../TakeTestAccordionWrapper/TakeTestAccordionWrapper';
import { ResultsAccordionWrapper } from '../ResultsAccordionWrapper/ResultsAccordionWrapper';
import { ShareResultsAccordionWrapper } from '../ShareResultsAccordionWrapper/ShareResultsAccordionWrapper';
import {
  ActivationCodeStatus,
  AvailableProduct,
  DialogWrapper,
  License,
  PageableResponse,
  Score,
  SkillType,
  SkillTypes,
  sopClient
} from '@barracuda/shared/src';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { scrollToElement } from '@barracuda/shared/src/utils/utils';
import { ActivationCodePopupWrapper } from '../ActivationCodePopupWrapper/ActivationCodePopupWrapper';
import { AppContext } from '../../context/App.context';

export const DashboardAccordionWrappers: FC<{
  hash?: string;
}> = () => {
  const { hash } = useLocation();
  const [buyTestExpanded, setBuyTestExpanded] = useState(!hash);
  const [shareExpanded, setShareExpanded] = useState(hash === '#shareResults');
  const [startPracticeExpanded, setStartPracticeExpanded] = useState(hash === '#startPractice');
  const [takeTestExpanded, setTakeTestExpanded] = useState(hash === '#takeTest');
  const [latestScore, setLatestScore] = useState<string | null>(null);
  const [activationClosable, setActivationCloseable] = useState(false);
  const [status, setStatus] = useState<ActivationCodeStatus>('DEFAULT');
  const [availableProducts, setAvailableProducts] = useState<AvailableProduct[]>([]);
  const { t } = useTranslation();
  const { setAvailableTests } = useContext(AppContext);
  const [activationCodeOpened, setActivationCodeOpened] = useState(false);

  useEffect(() => {
    if (hash) {
      scrollToElement(hash);
    }
  }, []);

  const forceHideSubtitles = useMediaQuery('(max-width: 500px)');

  const findScoreByName = (name: SkillType, scale: string, scores: Score[]): string | null => {
    if (typeof scores === 'undefined') return null;
    const score = scores.find((el) => el.component === name && el.scale === scale);

    return score?.score || null;
  };

  const getLatestTestOverallScore = async () => {
    const response = await sopClient.getLatestLicense();
    const data = response.data as PageableResponse<License>;
    const latestLicense = data.results.filter((license) => license.status !== 'VALIDATED')[0];
    setLatestScore(findScoreByName(SkillTypes.OVERALL_SCORE, 'GSE', latestLicense?.scores));
  };

  const subtitleVisible = (itemName: string, expanded: boolean | undefined): boolean => {
    if (forceHideSubtitles) return false;
    if (
      itemName === t('dashboardPage.accordionShareResultsTitle') ||
      itemName === t('dashboardPage.accordionTakeTestTitle')
    ) {
      return !expanded;
    } else {
      return true;
    }
  };

  const fetchAvailableProducts = async () => {
    const { data } = await sopClient.getAvailableProducts();
    const availableProductsArray: AvailableProduct[] = [];
    const availableRetakesArray: AvailableProduct[] = [];
    let totalNumber = 0;
    for (const item of data.results) {
      const [totalLicenses, totalRetakes] = await Promise.all([
        await sopClient.getLicensesByProductId(item.id),
        await sopClient.getRetakesByProductId(item.id, {
          status: ['NEW', 'VALIDATED'],
          practice: false,
          retake: true,
          sort: 'createdAt',
          order: 'asc'
        })
      ]);
      const { data } = totalLicenses;
      if (data.total) {
        item.numberOfItems = data.total;
        totalNumber += data.total;
        availableProductsArray.push(item);
      }

      if (totalRetakes.data.total) {
        totalNumber += totalRetakes.data.total;
        Array.from({ length: totalRetakes.data.total }, () =>
          availableRetakesArray.push({ ...item, retake: true })
        );
      }
    }

    setAvailableTests?.(totalNumber);
    setAvailableProducts([...availableRetakesArray, ...availableProductsArray]);
  };

  const handleActivationCodePopupClose = () => {
    setActivationCodeOpened(false);
    if (status === 'VALID') {
      fetchAvailableProducts();
    }
  };

  useEffect(() => {
    getLatestTestOverallScore();
  }, []);

  const ActivationCodeDialogWrapper = (
    <DialogWrapper
      key='activationCode'
      title={t('activationCodePopup.title')}
      subtitle={t('activationCodePopup.subtitle')}
      open={activationCodeOpened}
      closeable={activationClosable}
      handleClose={handleActivationCodePopupClose}
    >
      <ActivationCodePopupWrapper
        status={status}
        cancelButton
        handleCodeRedeemBlockedAction={() => setActivationCloseable(true)}
        setStatus={setStatus}
        checkRedeemBlocked
        closeDialog={() => setActivationCodeOpened(false)}
      />
    </DialogWrapper>
  );

  const accordionData = [
    {
      id: '#buyTest',
      icon: ShopAccordionIcon,
      title: t('dashboardPage.accordionTitle'),
      subtitle: t('dashboardPage.accordionSubtitle'),
      details: (
        <ProductsListWrapper onEnterActivationCodeClick={() => setActivationCodeOpened(true)} />
      ),
      expanded: buyTestExpanded,
      setExpanded: setBuyTestExpanded
    },
    {
      id: '#startPractice',
      icon: PracticeAccordionIcon,
      title: t('dashboardPage.accordionPracticeTitle'),
      subtitle: t('dashboardPage.accordionPracticeSubtitle'),
      expanded: startPracticeExpanded,
      setExpanded: setStartPracticeExpanded,
      details: <PracticeTestsSetWrapper />
    },
    {
      id: '#takeTest',
      icon: TakeTestAccordionIcon,
      title: t('dashboardPage.accordionTakeTestTitle'),
      details: (
        <TakeTestAccordionWrapper
          buyTestExpanded={buyTestExpanded}
          availableProducts={availableProducts}
          fetchAvailableProducts={fetchAvailableProducts}
          setBuyTestExpanded={setBuyTestExpanded}
          setActivationCodeOpened={() => setActivationCodeOpened(true)}
        />
      ),
      expanded: takeTestExpanded,
      setExpanded: setTakeTestExpanded
    },
    {
      id: '#results',
      icon: ResultsAccordionIcon,
      title: t('dashboardPage.accordionLatestTestTitle'),
      subtitle: (
        <>
          {latestScore && (
            <Box sx={LatestTestBadge}>
              <Trans
                i18nKey='dashboardPage.accordionLatestTestResult'
                // there might be string or number - only some strings would be translated
                values={{ score: t(latestScore) }}
                components={{ span: <span /> }}
              />
            </Box>
          )}
        </>
      ),
      details: <ResultsAccordionWrapper />
    },
    {
      id: '#shareResults',
      icon: ShareResultsAccordionIcon,
      title: t('dashboardPage.accordionShareResultsTitle'),
      subtitle: t('dashboardPage.accordionShareResultsSubtitle'),
      details: <ShareResultsAccordionWrapper />,
      expanded: shareExpanded,
      setExpanded: setShareExpanded
    }
  ];
  const accordions = accordionData.map((item, index) => (
    <Accordion
      key={`dashboard-accordion-${index}`}
      expanded={item.expanded}
      onChange={() => item?.setExpanded?.(!item.expanded)}
      id={item.id}
      sx={AccordionBox}
    >
      <AccordionSummary
        sx={{
          '.MuiAccordionSummary-content, MuiAccordionSummary-content.Mui-expanded': {
            marginBlock: '30px'
          }
        }}
        expandIcon={<ExpandMoreIcon />}
      >
        <Stack justifyContent='center' alignItems='center' direction='row'>
          <Img src={item.icon} />
          <Typography sx={AccordionTitle}>{item.title}</Typography>
          {subtitleVisible(item.title, item.expanded) && (
            <Box sx={AccordionSubtitle}>{item.subtitle}</Box>
          )}
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: '0 20px 50px' }}>{item.details}</AccordionDetails>
    </Accordion>
  ));

  return (
    <>
      <DashboardHeaderWrapper
        startPracticeExpanded={startPracticeExpanded}
        setStartPracticeExpanded={setStartPracticeExpanded}
        buyTestExpanded={buyTestExpanded}
        setBuyTestExpanded={setBuyTestExpanded}
        takeTestExpanded={takeTestExpanded}
        setTakeTestExpanded={setTakeTestExpanded}
        setActivationCodeOpened={() => setActivationCodeOpened(true)}
      />
      <Stack sx={{ alignItems: 'center', paddingBottom: '30px' }}>{accordions}</Stack>
      {activationCodeOpened && ActivationCodeDialogWrapper}
    </>
  );
};
