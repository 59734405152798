import { FC, useContext, useEffect, useState } from 'react';
import { PracticeTestLabel } from '@barracuda/shared/src/components/PracticeTestLabel/PracticeTestLabel';
import FourSkillsIcon from '../../assets/images/4skills-icon.svg';
import ReadingWritingIcon from '../../assets/images/reading-writing-icon.svg';
import SpeakingListeningIcon from '../../assets/images/speaking-listening-icon.svg';
import { Stack } from '@mui/material';
import {
  AvailableProduct,
  DialogWrapper,
  EventTypes,
  LimitReachedModal,
  PracticeDownloadAppPopup,
  sopClient
} from '@barracuda/shared/src';
import { isMobile } from '../../app.utils';
import { PracticeLabelContainer } from './PracticeTestsSetWrapper.style';
import { useTranslation } from 'react-i18next';
import { useLaunchTest } from 'src/hooks/useLaunchTest';
import { theme } from '@theme';
import { sendSignedInGAEvent } from '../../utils/ga';
import { AppContext } from '../../context/App.context';

export const PracticeTestsSetWrapper: FC = () => {
  const [beforeStartOpen, setBeforeStartOpen] = useState(false);
  const [beforeStartOpenMobile, setBeforeStartOpenMobile] = useState(false);
  const [practiceTests, setPracticeTests] = useState<AvailableProduct[] | []>([]);
  const practiceIcons = [FourSkillsIcon, ReadingWritingIcon, SpeakingListeningIcon];
  const [launchTest, limitReached, setLimitReached, message] = useLaunchTest(
    () => null,
    setBeforeStartOpen,
    true
  );
  const { t } = useTranslation();
  const { user } = useContext(AppContext);

  useEffect(() => {
    fetchPracticeTests();
  }, []);

  const fetchPracticeTests = async () => {
    const response = await sopClient.getPracticeTests();
    const { data } = response;
    setPracticeTests(data.results);
  };

  const handleStartTest = () => {
    isMobile() ? setBeforeStartOpenMobile(true) : launchTest();
  };
  const handleCloseDialog = (value?: string) => {
    if (value && value === 'download') {
      sendSignedInGAEvent(EventTypes.APP_DOWNLOADED, user?.externalId);
    }
    setBeforeStartOpen(false);
  };

  return (
    <>
      <DialogWrapper
        key='beforeStartDownload'
        open={beforeStartOpen}
        gridModifications={{ width: '980px' }}
        handleClose={() => setBeforeStartOpen(false)}
        scrollable
        subtitle={t('dashboardPage.practiceSubtitle')}
        title={t('dashboardPage.practiceTitle')}
        subtitleColor={theme.palette.common.violet}
        closeable
      >
        <PracticeDownloadAppPopup
          MSI_URL={process.env.REACT_APP_PESC_DOWNLOAD_MSI_PATH}
          MAC_URL={process.env.REACT_APP_PESC_DOWNLOAD_DMG_MAC_PATH}
          closeDialog={(value) => handleCloseDialog(value)}
          practice
        />
      </DialogWrapper>
      <DialogWrapper
        gridModifications={{ maxWidth: '980px', width: 'unset' }}
        key='beforeStartDownloadMobile'
        open={beforeStartOpenMobile}
        handleClose={() => setBeforeStartOpenMobile(false)}
        scrollable
        subtitle={t('dashboardPage.practiceSubtitle')}
        title={t('dashboardPage.practiceTitle')}
      >
        <PracticeDownloadAppPopup
          mobile
          MSI_URL={process.env.REACT_APP_PESC_DOWNLOAD_MSI_PATH}
          MAC_URL={process.env.REACT_APP_PESC_DOWNLOAD_DMG_MAC_PATH}
          closeDialog={() => setBeforeStartOpenMobile(false)}
          practice
        />
      </DialogWrapper>
      <Stack sx={PracticeLabelContainer}>
        {practiceTests.map((item, index) => (
          <PracticeTestLabel
            key={`PracticeTestLabel-${item.id}`}
            icon={practiceIcons[index]}
            handleStartTest={handleStartTest}
            title={item.name}
            skills={JSON.parse(item.metadata?.TEST_SKILLS)}
            time={JSON.parse(item.metadata?.TEST_DURATION)}
          />
        ))}
      </Stack>
      <LimitReachedModal
        open={limitReached}
        handleClose={() => setLimitReached(false)}
        message={message}
      />
    </>
  );
};
