import { useForm, FormState, UseFormRegister } from 'react-hook-form';
import { useState, Dispatch, SetStateAction, BaseSyntheticEvent, useCallback } from 'react';
import moment, { Moment } from 'moment';
import { Country } from '../../interface/dictionary.interfaces';
import { GenderTypes, User } from '../../index';
import { GenderType } from '../../interface/gender.interfaces';
import debounce, { Cancelable } from '@mui/utils/debounce';
import { sopClient } from '../../api/Auth';

type UseUserDataFormProps = {
  readonly user: User;
  readonly submit: (user: Partial<User>) => void;
};

type UserFields = Pick<
  User,
  'dateOfBirth' | 'email' | 'firstName' | 'lastName' | 'gender' | 'citizenship'
>;

// for some reason citizenship?: string doesn't match with citizenship: string | undefined
type UserFieldsSecond = Pick<
  User,
  'dateOfBirth' | 'email' | 'firstName' | 'lastName' | 'gender'
> & {
  citizenship: string | undefined;
};

export interface UseUserDataFormReturn {
  readonly date: Moment | null;
  readonly name: string;
  readonly setName: Dispatch<SetStateAction<string>>;
  readonly surname: string;
  readonly setSurname: Dispatch<SetStateAction<string>>;
  readonly country: Country | null;
  readonly genderValue: GenderTypes.GenderOption | null;
  readonly setGenderValue: Dispatch<SetStateAction<GenderTypes.GenderOption | null>>;
  readonly countryOfCitizenship: Country;
  readonly countries: Country[];

  readonly register: UseFormRegister<UserFieldsSecond>;
  readonly formState: FormState<UserFields>;

  readonly onSubmit: (e?: BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  readonly handleDatepickerChange: (newDate: Moment | null) => void;
  readonly handleGenderAutocompleteChange: (
    _: BaseSyntheticEvent,
    g: GenderTypes.GenderOption | null
  ) => void;
  readonly handleGenderInputChange: (event: BaseSyntheticEvent) => void;
  readonly handleCitizenshipAutocompleteChange: (_: BaseSyntheticEvent, v: Country | null) => void;
  readonly handleCitizenshipInputChange: ((event: BaseSyntheticEvent) => void) & Cancelable;
  readonly handleDatepickerError: (error: string | null) => void;
}

interface UseUserDataForm {
  (props: UseUserDataFormProps): UseUserDataFormReturn;
}

export const useUserDataForm: UseUserDataForm = ({
  user: { dateOfBirth, email, firstName, lastName, gender, countryOfCitizenship },
  submit
}) => {
  const [date, setDate] = useState(moment(dateOfBirth).isValid() ? moment(dateOfBirth) : null);
  const [name, setName] = useState(firstName);
  const [surname, setSurname] = useState(lastName);
  const [country, setCountry] = useState<Country | null>(countryOfCitizenship);
  const [countries, setCountries] = useState([]);
  const [genderValue, setGenderValue] = useState<GenderTypes.GenderOption | null>(
    gender
      ? (GenderTypes.genderOptions.find((el) => el.id === gender) as GenderTypes.GenderOption)
      : null
  );

  const defaultValues = {
    dateOfBirth,
    email,
    firstName,
    lastName,
    gender,
    citizenship: country?.name
  };

  const { register, handleSubmit, setValue, trigger, setError, formState } = useForm({
    criteriaMode: 'all',
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues
  });

  const userDataFormHandler = (data: Partial<User>) => {
    delete data.citizenship;
    delete data.email;
    const { firstName, lastName, gender } = data;
    const dateOfBirth = moment(data.dateOfBirth).isValid()
      ? moment(data.dateOfBirth).toISOString(false)
      : data.dateOfBirth;
    const payload: Partial<User> = {
      firstName,
      lastName,
      dateOfBirth,
      gender,
      countryOfCitizenship: country ? country : undefined
    };
    submit(payload);
  };
  const onSubmit = handleSubmit(userDataFormHandler);

  const handleDatepickerChange = (newDate: Moment | null) => {
    if (!newDate) {
      return;
    }
    setValue('dateOfBirth', newDate.toISOString(true));
    setDate(newDate);
    trigger('dateOfBirth');
  };

  const handleGenderAutocompleteChange = (
    _: BaseSyntheticEvent,
    g: GenderTypes.GenderOption | null
  ) => {
    setValue('gender', g?.id as GenderType);
    setGenderValue(g);
    trigger('gender');
  };
  const handleGenderInputChange = useCallback((event: BaseSyntheticEvent) => {
    const value = event?.target.value;
    if (
      value &&
      GenderTypes.genderOptions.find(({ name }) => value.toLowerCase() === name.toLowerCase())
    ) {
      setValue('gender', event?.target.value);
    } else {
      setValue('gender', genderValue?.id as GenderType);
    }
  }, []);

  const handleCitizenshipAutocompleteChange = (_: BaseSyntheticEvent, v: Country | null) => {
    setValue('citizenship', v ? v.name : '');
    setCountry(v);
    trigger('citizenship');
  };

  const getCountries = async (query: string) => {
    const response = await sopClient.getCountries(query);
    const { data } = response;
    setCountries(data.results);
  };

  const handleCitizenshipInputChange = useCallback(
    debounce((event: BaseSyntheticEvent) => {
      const value = event?.target.value;
      if (value) {
        getCountries(value);
      }
    }, 300),
    []
  );

  const handleDatepickerError = (error: string | null) => {
    if (!error) return;

    switch (error) {
      case 'minDate': {
        setError('dateOfBirth', { types: { minDate: 'Min Date Error' } });
        break;
      }
      case 'invalidDate': {
        setError('dateOfBirth', { types: { invalidDate: 'Invalid Date Error' } });
        break;
      }
      case 'maxDate': {
        setError('dateOfBirth', { types: { maxDate: 'Max Date Error' } });
        break;
      }
    }
    trigger('dateOfBirth');
  };

  return {
    date,
    name,
    setName,
    surname,
    setSurname,
    country,
    genderValue,
    setGenderValue,
    countryOfCitizenship,
    countries,

    register,
    formState,

    onSubmit,
    handleDatepickerChange,
    handleGenderAutocompleteChange,
    handleGenderInputChange,
    handleCitizenshipAutocompleteChange,
    handleCitizenshipInputChange,
    handleDatepickerError
  };
};
