import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  container,
  lowerAlphaBrace,
  FW600,
  MB10,
  MB20,
  PrivacyPolicyContentHeader,
  PrivacyPolicyContentParagraph,
  PrivacyPolicyItalic
} from './TermsOfUseContent.styles';

interface TermsOfUseContent {
  language: string;
}

export const TermsOfUseContent: FC<TermsOfUseContent> = ({ language }) => {
  const { t } = useTranslation();

  return (
    <>
      <Box sx={container(language)}>
        <Typography sx={PrivacyPolicyContentHeader} component='h2'>
          {t('termsOfUse.preSection.header')}
        </Typography>
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.preSection.paragraph1') }}
          sx={[PrivacyPolicyContentParagraph, MB20, FW600, PrivacyPolicyItalic]}
        />
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.preSection.paragraph2') }}
          sx={[PrivacyPolicyContentParagraph, MB20]}
        />

        <Typography sx={[PrivacyPolicyContentParagraph, MB20]}>
          {t('termsOfUse.preSection.paragraph3')}
        </Typography>
        <Typography sx={[PrivacyPolicyContentParagraph, MB20, FW600]}>
          {t('termsOfUse.content.1.question')}
        </Typography>
        <Typography sx={[PrivacyPolicyContentParagraph, MB20]}>
          {t('termsOfUse.content.1.paragraph1')}
        </Typography>
        <Typography sx={[PrivacyPolicyContentParagraph, MB20, FW600]}>
          {t('termsOfUse.content.2.question')}
        </Typography>
        <Typography sx={[PrivacyPolicyContentParagraph, MB20, FW600, PrivacyPolicyItalic]}>
          {t('termsOfUse.content.2.paragraph1')}
        </Typography>
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.2.paragraph2') }}
          sx={[PrivacyPolicyContentParagraph, MB20]}
        />
        <Typography sx={[PrivacyPolicyContentParagraph, MB20, FW600]}>
          {t('termsOfUse.content.3.question')}
        </Typography>
        <Typography sx={[PrivacyPolicyContentParagraph, MB20, FW600, PrivacyPolicyItalic]}>
          {t('termsOfUse.content.3.paragraph1')}
        </Typography>
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.3.paragraph2') }}
          sx={[PrivacyPolicyContentParagraph, MB20]}
        />
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.3.paragraph3') }}
          sx={[PrivacyPolicyContentParagraph, MB20]}
        />
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.3.paragraph4') }}
          sx={[PrivacyPolicyContentParagraph, MB20]}
        />
        <Typography sx={[PrivacyPolicyContentParagraph, MB20, FW600]}>
          {t('termsOfUse.content.4.question')}
        </Typography>
        <Typography sx={[PrivacyPolicyContentParagraph, MB20, FW600, PrivacyPolicyItalic]}>
          {t('termsOfUse.content.4.paragraph1')}
        </Typography>
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.4.paragraph2') }}
          sx={[PrivacyPolicyContentParagraph, MB20]}
        />
        <Typography sx={[PrivacyPolicyContentParagraph, MB20, FW600]}>
          {t('termsOfUse.content.5.question')}
        </Typography>
        <Typography sx={[PrivacyPolicyContentParagraph, MB20, FW600, PrivacyPolicyItalic]}>
          {t('termsOfUse.content.5.paragraph1')}
        </Typography>
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.5.paragraph2') }}
          sx={[PrivacyPolicyContentParagraph, MB20]}
        />
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.5.paragraph3') }}
          sx={[PrivacyPolicyContentParagraph, MB20]}
        />
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.5.paragraph4') }}
          sx={[PrivacyPolicyContentParagraph, MB20]}
        />
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.5.paragraph5') }}
          sx={[PrivacyPolicyContentParagraph, MB20]}
        />
        <Typography sx={[PrivacyPolicyContentParagraph, MB20, FW600]}>
          {t('termsOfUse.content.6.question')}
        </Typography>
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.6.paragraph1') }}
          sx={[PrivacyPolicyContentParagraph, MB20]}
        />
        <Typography sx={[PrivacyPolicyContentParagraph, MB20, FW600]}>
          {t('termsOfUse.content.7.question')}
        </Typography>
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.7.paragraph1') }}
          sx={[PrivacyPolicyContentParagraph, MB20, FW600, PrivacyPolicyItalic]}
        />
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.7.paragraph2') }}
          sx={[PrivacyPolicyContentParagraph, MB20]}
        />
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.7.paragraph3') }}
          sx={[PrivacyPolicyContentParagraph, MB20]}
        />
        <Typography sx={[PrivacyPolicyContentParagraph, MB20, FW600]}>
          {t('termsOfUse.content.8.question')}
        </Typography>
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.8.paragraph1') }}
          sx={[PrivacyPolicyContentParagraph, MB20, FW600, PrivacyPolicyItalic]}
        />
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.8.paragraph2') }}
          sx={[PrivacyPolicyContentParagraph, MB20]}
        />
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.8.paragraph3') }}
          sx={[PrivacyPolicyContentParagraph, MB20]}
        />
        <Typography sx={[PrivacyPolicyContentParagraph, MB20, FW600]}>
          {t('termsOfUse.content.9.question')}
        </Typography>
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.9.paragraph1') }}
          sx={[PrivacyPolicyContentParagraph, MB20, FW600, PrivacyPolicyItalic]}
        />
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.9.paragraph2') }}
          sx={[PrivacyPolicyContentParagraph, MB20]}
        />
        <Typography sx={[PrivacyPolicyContentParagraph, MB20, FW600]}>
          {t('termsOfUse.content.10.question')}
        </Typography>
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.10.paragraph1') }}
          sx={[PrivacyPolicyContentParagraph, MB20, FW600, PrivacyPolicyItalic]}
        />
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.10.paragraph2') }}
          sx={[PrivacyPolicyContentParagraph, MB20]}
        />
        <Typography sx={[PrivacyPolicyContentParagraph, MB20, FW600]}>
          {t('termsOfUse.content.11.question')}
        </Typography>
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.11.paragraph1') }}
          sx={[PrivacyPolicyContentParagraph, MB20, FW600, PrivacyPolicyItalic]}
        />
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.11.paragraph2') }}
          sx={[PrivacyPolicyContentParagraph, MB20]}
        />

        <Typography sx={[PrivacyPolicyContentParagraph, MB20, FW600]}>
          {t('termsOfUse.content.12.question')}
        </Typography>
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.12.paragraph1') }}
          sx={[PrivacyPolicyContentParagraph, MB20, FW600, PrivacyPolicyItalic]}
        />
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.12.paragraph2') }}
          sx={[PrivacyPolicyContentParagraph, MB20]}
        />
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.12.paragraph3') }}
          sx={[PrivacyPolicyContentParagraph]}
        />
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.12.paragraph4') }}
          sx={[PrivacyPolicyContentParagraph]}
        />
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.12.paragraph5') }}
          sx={[PrivacyPolicyContentParagraph]}
        />
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.12.paragraph6') }}
          sx={[PrivacyPolicyContentParagraph]}
        />
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.12.paragraph7') }}
          sx={[PrivacyPolicyContentParagraph, MB20]}
        />
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.12.paragraph8') }}
          sx={[PrivacyPolicyContentParagraph, MB20]}
        />
        <Box component='ul'>
          <Box
            sx={[MB10]}
            component='li'
            dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.12.bullet1') }}
          />
          <Box
            sx={[MB10]}
            component='li'
            dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.12.bullet2') }}
          />
          <Box
            sx={[MB10]}
            component='li'
            dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.12.bullet3') }}
          />
          <Box
            sx={[MB10]}
            component='li'
            dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.12.bullet4') }}
          />
        </Box>
        <Typography sx={[PrivacyPolicyContentParagraph, MB20, FW600]}>
          {t('termsOfUse.content.13.question')}
        </Typography>
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.13.paragraph1') }}
          sx={[PrivacyPolicyContentParagraph, MB20, FW600, PrivacyPolicyItalic]}
        />
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.13.paragraph2') }}
          sx={[PrivacyPolicyContentParagraph, MB20]}
        />
        <Typography sx={[PrivacyPolicyContentParagraph, MB20, FW600]}>
          {t('termsOfUse.content.14.question')}
        </Typography>
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.14.paragraph1') }}
          sx={[PrivacyPolicyContentParagraph, MB20, FW600, PrivacyPolicyItalic]}
        />
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.14.paragraph2') }}
          sx={[PrivacyPolicyContentParagraph, MB20]}
        />
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.14.paragraph3') }}
          sx={[PrivacyPolicyContentParagraph, MB20]}
        />
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.14.paragraph4') }}
          sx={[PrivacyPolicyContentParagraph, MB20]}
        />
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.14.paragraph5') }}
          sx={[PrivacyPolicyContentParagraph, MB20]}
        />
        <Typography sx={[PrivacyPolicyContentParagraph, MB20, FW600]}>
          {t('termsOfUse.content.15.question')}
        </Typography>
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.15.paragraph1') }}
          sx={[PrivacyPolicyContentParagraph, MB20, FW600, PrivacyPolicyItalic]}
        />
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.15.paragraph2') }}
          sx={[PrivacyPolicyContentParagraph, MB20]}
        />
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.15.paragraph3') }}
          sx={[PrivacyPolicyContentParagraph, MB20]}
        />
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.15.paragraph4') }}
          sx={[PrivacyPolicyContentParagraph, MB20]}
        />
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.15.paragraph5') }}
          sx={[PrivacyPolicyContentParagraph, MB20]}
        />
        <Typography sx={[PrivacyPolicyContentParagraph, MB20, FW600]}>
          {t('termsOfUse.content.16.question')}
        </Typography>
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.16.paragraph1') }}
          sx={[PrivacyPolicyContentParagraph, MB20, FW600, PrivacyPolicyItalic]}
        />
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.16.paragraph2') }}
          sx={[PrivacyPolicyContentParagraph, MB20]}
        />
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.16.paragraph3') }}
          sx={[PrivacyPolicyContentParagraph, MB20]}
        />
        {/* there is no equivalent in english version (japanese version has one paragraph more) */}
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.16.paragraph4') }}
          sx={[PrivacyPolicyContentParagraph, MB20]}
        />
        <Typography sx={[PrivacyPolicyContentParagraph, MB20, FW600]}>
          {t('termsOfUse.content.17.question')}
        </Typography>
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.17.paragraph1') }}
          sx={[PrivacyPolicyContentParagraph, MB20, FW600, PrivacyPolicyItalic]}
        />
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.17.paragraph2') }}
          sx={[PrivacyPolicyContentParagraph, MB20]}
        />
        <Typography sx={[PrivacyPolicyContentParagraph, MB20, FW600]}>
          {t('termsOfUse.content.18.question')}
        </Typography>
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.18.paragraph1') }}
          sx={[PrivacyPolicyContentParagraph, MB20, FW600, PrivacyPolicyItalic]}
        />
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.18.paragraph2') }}
          sx={[PrivacyPolicyContentParagraph, MB20]}
        />
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.18.paragraph3') }}
          sx={[PrivacyPolicyContentParagraph, MB20]}
        />
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.18.paragraph4') }}
          sx={[PrivacyPolicyContentParagraph, MB20]}
        />
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.18.paragraph5') }}
          sx={[PrivacyPolicyContentParagraph, MB20]}
        />
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.18.paragraph6') }}
          sx={[PrivacyPolicyContentParagraph, MB20]}
        />
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.18.paragraph7') }}
          sx={[PrivacyPolicyContentParagraph, MB20]}
        />
        <Typography sx={[PrivacyPolicyContentParagraph, MB20, FW600]}>
          {t('termsOfUse.content.19.question')}
        </Typography>
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.19.paragraph1') }}
          sx={[PrivacyPolicyContentParagraph, MB20, FW600, PrivacyPolicyItalic]}
        />
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.19.paragraph2') }}
          sx={[PrivacyPolicyContentParagraph, MB20]}
        />
        <Typography sx={[PrivacyPolicyContentParagraph, MB20, FW600]}>
          {t('termsOfUse.content.20.question')}
        </Typography>
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.20.paragraph1') }}
          sx={[PrivacyPolicyContentParagraph, MB20, FW600, PrivacyPolicyItalic]}
        />
        <Box component='ol'>
          <Box
            component='li'
            sx={[MB10]}
            dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.20.paragraph2') }}
          ></Box>
          <Box
            component='li'
            sx={[MB10]}
            dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.20.paragraph3') }}
          ></Box>
          <Box
            component='li'
            sx={[MB10]}
            dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.20.paragraph4') }}
          ></Box>
          <Box
            component='li'
            sx={[MB10]}
            dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.20.paragraph5') }}
          ></Box>
        </Box>
        <Typography sx={[PrivacyPolicyContentParagraph, MB20, FW600]}>
          {t('termsOfUse.content.21.question')}
        </Typography>
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.21.paragraph1') }}
          sx={[PrivacyPolicyContentParagraph, MB20, FW600, PrivacyPolicyItalic]}
        />
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.21.paragraph2') }}
          sx={[PrivacyPolicyContentParagraph, MB20]}
        />
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.21.paragraph3') }}
          sx={[PrivacyPolicyContentParagraph, MB20]}
        />
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.21.paragraph4') }}
          sx={[PrivacyPolicyContentParagraph, MB20]}
        />
        <Typography sx={[PrivacyPolicyContentParagraph, MB20, FW600]}>
          {t('termsOfUse.content.22.question')}
        </Typography>
        <Typography
          dangerouslySetInnerHTML={{ __html: t('termsOfUse.content.22.paragraph1') }}
          sx={[PrivacyPolicyContentParagraph, MB20]}
        />
        <Typography sx={PrivacyPolicyContentHeader} component='h2'>
          {t('termsOfUse.preSection2.header', {
            productName: process.env.REACT_APP_GENERAL_PRODUCT_NAME
          })}
        </Typography>
        <Typography sx={[PrivacyPolicyContentParagraph, MB20, FW600]}>
          {t('termsOfUse.content2.1.question')}
        </Typography>
        <Box component='ol' sx={lowerAlphaBrace}>
          <Box
            sx={[MB10]}
            component='li'
            dangerouslySetInnerHTML={{
              __html: t('termsOfUse.content2.1.bullet1', {
                productName: process.env.REACT_APP_GENERAL_PRODUCT_NAME
              })
            }}
          />
          <Box
            sx={[MB10]}
            component='li'
            dangerouslySetInnerHTML={{ __html: t('termsOfUse.content2.1.bullet2') }}
          />
          <Box
            sx={[MB10]}
            component='li'
            dangerouslySetInnerHTML={{ __html: t('termsOfUse.content2.1.bullet3') }}
          />
        </Box>
        {/*there error*/}

        <Typography sx={[PrivacyPolicyContentParagraph, MB20, FW600]}>
          {t('termsOfUse.content2.2.question')}
        </Typography>

        <Box component='ol' sx={lowerAlphaBrace}>
          <Box
            sx={[MB10]}
            component='li'
            dangerouslySetInnerHTML={{
              __html: t('termsOfUse.content2.2.bullet1', { url: process.env.REACT_APP_PESC_URL })
            }}
          />
          <Box
            sx={[MB10]}
            component='li'
            dangerouslySetInnerHTML={{ __html: t('termsOfUse.content2.2.bullet2') }}
          />
        </Box>

        <Typography sx={[PrivacyPolicyContentParagraph, MB20, FW600]}>
          {t('termsOfUse.content2.3.question')}
        </Typography>

        <Box component='ol' sx={lowerAlphaBrace}>
          <Box
            sx={[MB10]}
            component='li'
            dangerouslySetInnerHTML={{
              __html: t('termsOfUse.content2.3.bullet1', {
                url: process.env.REACT_APP_PRIVACY_POLICY_LINK
              })
            }}
          />
        </Box>

        <Typography sx={[PrivacyPolicyContentParagraph, MB20, FW600]}>
          {t('termsOfUse.content2.4.question')}
        </Typography>

        <Box component='ol' sx={lowerAlphaBrace}>
          <Box
            sx={[MB10]}
            component='li'
            dangerouslySetInnerHTML={{ __html: t('termsOfUse.content2.4.bullet1') }}
          />
        </Box>

        <Typography sx={[PrivacyPolicyContentParagraph, MB20, FW600]}>
          {t('termsOfUse.content2.5.question')}
        </Typography>

        <Box component='ol' sx={lowerAlphaBrace}>
          <Box
            sx={[MB10]}
            component='li'
            dangerouslySetInnerHTML={{ __html: t('termsOfUse.content2.5.bullet1') }}
          />
          <Box
            sx={[MB10]}
            component='li'
            dangerouslySetInnerHTML={{ __html: t('termsOfUse.content2.5.bullet2') }}
          />
          <Box
            sx={[MB10]}
            component='li'
            dangerouslySetInnerHTML={{ __html: t('termsOfUse.content2.5.bullet3') }}
          />
          <Box
            sx={[MB10]}
            component='li'
            dangerouslySetInnerHTML={{ __html: t('termsOfUse.content2.5.bullet4') }}
          />
          <Box
            sx={[MB10]}
            component='li'
            dangerouslySetInnerHTML={{ __html: t('termsOfUse.content2.5.bullet5') }}
          />
        </Box>

        <Typography sx={[PrivacyPolicyContentParagraph, MB20, FW600]}>
          {t('termsOfUse.content2.6.question')}
        </Typography>

        <Box component='ol' sx={lowerAlphaBrace}>
          <Box
            sx={[MB10]}
            component='li'
            dangerouslySetInnerHTML={{ __html: t('termsOfUse.content2.6.bullet1') }}
          />
          <Box
            sx={[MB10]}
            component='li'
            dangerouslySetInnerHTML={{ __html: t('termsOfUse.content2.6.bullet2') }}
          />
          <Box
            sx={[MB10]}
            component='li'
            dangerouslySetInnerHTML={{ __html: t('termsOfUse.content2.6.bullet3') }}
          />
          <Box
            sx={[MB10]}
            component='li'
            dangerouslySetInnerHTML={{ __html: t('termsOfUse.content2.6.bullet4') }}
          />
        </Box>

        <Typography sx={[PrivacyPolicyContentParagraph, MB20, FW600]}>
          {t('termsOfUse.content2.7.question')}
        </Typography>

        <Box component='ol' sx={lowerAlphaBrace}>
          <Box
            sx={[MB10]}
            component='li'
            dangerouslySetInnerHTML={{ __html: t('termsOfUse.content2.7.bullet1') }}
          />
          <Box
            sx={[MB10]}
            component='li'
            dangerouslySetInnerHTML={{ __html: t('termsOfUse.content2.7.bullet2') }}
          />
          <Box
            sx={[MB10]}
            component='li'
            dangerouslySetInnerHTML={{ __html: t('termsOfUse.content2.7.bullet3') }}
          />
          <Box
            sx={[MB10]}
            component='li'
            dangerouslySetInnerHTML={{ __html: t('termsOfUse.content2.7.bullet4') }}
          />
        </Box>

        <Typography sx={[PrivacyPolicyContentParagraph, MB20, FW600]}>
          {t('termsOfUse.content2.8.question')}
        </Typography>

        <Box component='ol' sx={lowerAlphaBrace}>
          <Box
            sx={[MB10]}
            component='li'
            dangerouslySetInnerHTML={{ __html: t('termsOfUse.content2.8.bullet1') }}
          />
        </Box>
      </Box>
    </>
  );
};
