import { Box, Button, Typography } from '@mui/material';
import { FC } from 'react';
import { SignInButton } from '../SignIn/SignInStyles';
import {
  ButtonContainer,
  RectangleContainer,
  RectangleContainerImage,
  RectangleText,
  RectangleTitle
} from './SamplePreviewRectangle.styles';

export interface DashboardTestRectangleProps {
  title: string;
  testKey?: string;
  buttonText?: string;
  image: string;
  handleClick: (mode: 'listen' | 'read') => void;
  mode: 'listen' | 'read';
  text: string;
  resource: string | null;
}

export const SamplePreviewRectangle: FC<DashboardTestRectangleProps> = ({
  title,
  buttonText,
  image,
  handleClick,
  mode,
  text
}) => {
  return (
    <>
      <Box sx={RectangleContainer}>
        <img src={image} style={RectangleContainerImage} />
        <Typography sx={RectangleTitle}>{title}</Typography>
        <Typography sx={RectangleText}>{text}</Typography>
        <Box sx={ButtonContainer}>
          <Button
            onClick={() => handleClick(mode)}
            type='submit'
            sx={[SignInButton, { margin: 0 }]}
          >
            {buttonText ? buttonText : 'Take practice test'}
          </Button>
        </Box>
      </Box>
    </>
  );
};
