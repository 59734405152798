import { theme } from '@theme';

export const CounterContainer = {
  background: theme.palette.common.darkBlue7,
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.25)',
  borderRadius: '8px',
  height: '64px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  position: 'fixed',
  top: 'calc(var(--mobile-header-height) + 25px)',
  left: '15px',
  right: '15px',
  zIndex: 500
};
export const CounterBox = (value: number) => ({
  padding: '10px',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  height: '30px',
  background:
    value <= 1000 * 60
      ? '#FFE0DF'
      : value <= 1000 * 60 * 5
      ? theme.palette.common.lightYellow
      : theme.palette.common.white,
  boxShadow: '0 0 5px rgba(0, 0, 0, 0.15)',
  color: theme.palette.common.darkGrey2,
  fontSize: '16px',
  borderRadius: '8px'
});
export const CountdownValue = {
  fontSize: '14px',
  fontWeight: 600,
  marginLeft: '12px'
};
export const CountdownDesc = {
  fontSize: '14px',
  fontWeight: 600,
  color: theme.palette.common.lightGrey4
};
