import { theme } from './theme';

export const purpleTooltip = {
  background: theme.palette.common.violetLight2,
  color: theme.palette.common.lightGrey4,
  fontSize: '12px',
  fontWeight: 400,
  '.MuiTooltip-arrow': {
    color: theme.palette.common.violetLight2
  }
};

export const whiteTooltip = {
  background: theme.palette.common.white,
  borderRadius: '4px',
  border: `1px solid ${theme.palette.common.lightGrey1}`,
  color: theme.palette.common.darkGrey11,
  width: '250px',
  padding: '11px',
  fontSize: '12px',
  boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
  fontWeight: 400,
  '.MuiTooltip-arrow': {
    color: theme.palette.common.white
  }
};
