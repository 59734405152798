import { theme } from '../../styles/theme';

export const FlexCenter = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

export const TileContainer = {
  position: 'relative',
  height: '100%',
  background: theme.palette.common.white,
  border: `3px solid ${theme.palette.common.violetLight}`,
  borderRadius: '8px'
};
export const TileHead = {
  background: '#FDF5FB',
  borderWidth: '3px 0 0 3px',
  borderColor: theme.palette.common.violetLight,
  borderRadius: '8px 0',
  ...FlexCenter,
  width: '45%',
  '> span': {
    fontSize: '20px',
    fontWeight: 400,
    color: theme.palette.common.darkBlue2,
    padding: '8px',
    letterSpacing: '0.08em'
  }
};
export const TileBody = {
  marginTop: '30px',
  marginBottom: '30px',
  paddingLeft: '30px',
  paddingRight: '30px',
  display: 'flex',
  '@media screen and (max-width: 520px)': {
    paddingBottom: '50px'
  }
};
export const ShieldContainer = {
  position: 'relative',
  ...FlexCenter
};
export const ShieldScore = {
  fontSize: '42px',
  fontWeight: 700,
  color: theme.palette.common.lightGrey6,
  position: 'absolute',
  marginLeft: '12%',
  marginRight: '12%',
  left: 0,
  right: 0,
  textAlign: 'center',
  top: '16%'
};
export const ScoreData = {
  marginTop: '30px',
  display: 'flex',
  '> div': {
    marginLeft: '15px'
  },
  paddingLeft: '30px',
  paddingRight: '30px',
  '@media screen and (max-width: 520px)': {
    flexFlow: 'column',
    paddingTop: '30px',
    '> div': {
      marginLeft: 0,
      marginTop: '10px'
    }
  }
};
export const LinkVisibility = {
  '@media screen and (min-width: 520px)': {
    display: 'none'
  },
  position: 'absolute',
  right: '20px',
  top: '20px'
};
export const ScoreTitle = {
  color: theme.palette.common.darkBlue1,
  fontWeight: 700,
  fontSize: '25px',
  '@media screen and (max-width: 520px)': {
    fontSize: '20px'
  }
};
export const TileFooter = (ignoreHiding = false) => ({
  background: '#FDF5FB',
  borderWidth: '0 3px 3px 3px',
  borderColor: theme.palette.common.violetLight,
  borderRadius: '0 0 12.5413px 12.5413px',
  width: '100%',
  marginTop: 'auto',
  padding: '19px',
  ...FlexCenter,
  '@media screen and (max-width: 520px)': {
    display: ignoreHiding ? 'flex' : 'none'
  }
});

export const OverallProficiency = {
  marginLeft: '24px'
};
export const OverallProficiencyTitle = {
  fontSize: '18px',
  fontWeight: 600,
  paddingBottom: '5px'
};
export const OverallProficiencyDesc = {
  fontSize: '16px',
  fontWeight: 400,
  maxHeight: '160px',
  overflow: 'auto',
  a: {
    fontWeight: 600,
    textDecoration: 'none',
    color: theme.palette.common.darkBlue7,
    cursor: 'pointer'
  }
};
export const GSEScoreStyle = {
  fontSize: '14px',
  textAlign: 'center'
};
export const TakenOnStyle = {
  marginTop: '20px',
  fontSize: '16px',
  fontWeight: 600,
  '> span': {
    fontWeight: 400,
    fontSize: '16px'
  }
};
