import { theme } from '../../styles/theme';

export const SimpleColorBarContainer = {
  marginBottom: '15px',
  maxWidth: '436px',
  '@media screen and (max-width: 745px)': {
    width: '100%',
    maxWidth: 'unset'
  }
};

export const SimpleColorBarHeader = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
};

export const SimpleColorBarScore = {
  display: 'flex',
  alignItems: 'center',
  fontSize: '16px',
  color: theme.palette.common.darkGrey8,
  fontWeight: 400
};

export const SimpleColorBarItem = {
  borderRadius: '10px',
  height: '20px',
  background: theme.palette.common.white,
  width: '100%',
  position: 'relative'
};

export const SimpleColorBarItemFill = {
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  borderRadius: '10px'
};

export const exceptionIcon = {
  marginLeft: '5px',
  width: '16px'
};
