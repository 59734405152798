import { theme } from '@theme';

export const SideBarNavigationMobile = {
  display: 'block',
  '.MuiPaper-root': {
    zIndex: '1500',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingTop: '45px',
    width: '60%'
  },
  '@media only screen and (min-width: 1080px)': {
    display: 'none'
  }
};

export const DashboardListItem = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: '12px',
  color: theme.palette.common.darkGrey7,
  cursor: 'pointer',
  'img, svg': {
    marginBottom: '10px'
  },
  svg: {
    fill: theme.palette.common.black
  }
};
export const DashboardMobileListItem = {
  display: 'flex',
  flexDirection: 'column',
  padding: '12px',
  color: theme.palette.common.darkGrey2,
  cursor: 'pointer',
  fontWeight: 600,
  fontSize: '18px',
  lineHeight: 1.1,
  'img, svg': {
    marginBottom: '10px'
  },
  svg: {
    fill: theme.palette.common.black
  }
};

export const DashboardOutlet = {
  paddingLeft: '100px',
  marginTop: 0,
  height: '100%',
  width: '100%',
  minHeight: '100vh',
  '@media only screen and (max-width: 1080px)': {
    padding: 0
  }
};
export const LogoutMobileBox = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};
