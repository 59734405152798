import { theme } from '../../styles/theme';

export const SkillScoreDetailsContainer = {
  padding: '25px',
  '@media only screen and (min-width: 320px) and (max-width: 745px)': {
    padding: '10px'
  }
};

export const SkillScoreDetailsHeader = {
  color: theme.palette.common.darkBlue1,
  fontSize: '14px',
  fontWeight: 700,
  marginBottom: '10px',
  marginTop: '10px'
};

export const SkillScoreDetailsUl = {
  margin: 0
};

export const SkillScoreDetailsLi = {
  fontWeight: 400
};

export const SkillScoreDetailsTipsContainer = {
  '@media only screen and (min-width: 320px) and (max-width: 1023px)': {
    marginTop: '20px'
  }
};
