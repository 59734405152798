import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Link,
  Stack,
  TextField,
  Tooltip
} from '@mui/material';
import { BaseSyntheticEvent, FC, useCallback, useContext, useState } from 'react';
import { FormInputLabel, FormInputTextField } from '../ShareResults/ShareResults.style';
import { useForm } from 'react-hook-form';
import {
  BoxWithJit,
  CheckboxResponsive,
  FormBox,
  ScoreLookupContainer,
  ScoreLookupDescription,
  ScoreLookupTerms,
  ScoreLookupTitle,
  SupportPageLink,
  TermsLink,
  ViewScoresButtonBox
} from './ScoreLookupView.style';
import {
  ConsentCheckboxStyle,
  ConsentData,
  SignUpErrorMessage
} from '@barracuda/shared/src/components/SignUp/SignUp.styles';
import { ScoreLookupRequest, SopStatusResponse } from '@barracuda/shared/src';
import { AxiosResponse } from 'axios';
import { debounce } from 'lodash';
import { Country } from '@barracuda/shared/src/interface/dictionary.interfaces';
import Typography from '@mui/material/Typography/Typography';
import { AppContext } from '../../context/App.context';
import InfoIcon from '../../assets/images/info-icon.svg';
import { SignInButton } from '@barracuda/shared/src/components/SignIn/SignInStyles';
import { useTranslation } from 'react-i18next';
import { LocalizedNavLink } from '../LocalizedNavLink/LocalizedNavLink';
import { useLocalizedNavigate } from '../../hooks/useLocalizedNavigate';
import { purpleTooltip } from '@barracuda/shared/src/styles/tooltip';
import { ScoreReportMode } from '../ScoreReportView/ScoreReportViewSkillScoresSection';
import { ScoreLookupExpiredView } from './ScoreLookupExpiredView/ScoreLookupExpiredView';
import { sopClient } from '@barracuda/shared/src';

interface ScoreLookupViewProps {
  code: string | undefined;
  mode: ScoreReportMode;
}

export const ScoreLookupView: FC<ScoreLookupViewProps> = ({ code, mode }) => {
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState<Country | null>(null);
  const [expired, setExpired] = useState(false);
  const { setError } = useContext(AppContext);
  const [countryJitOpen, setCountryJitOpen] = useState(false);
  const [organizationJitOpen, setOrganizationJitOpen] = useState(false);
  const { t } = useTranslation();
  const navigate = useLocalizedNavigate();

  const form = useForm({
    criteriaMode: 'all',
    mode: 'all',
    reValidateMode: 'onChange'
  });
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    trigger
  } = form;

  const handleGetCountries = async (query: string) => {
    const response = await sopClient.getCountries(query);
    const { data } = response as AxiosResponse;
    setCountries(data.results);
  };

  const viewScores = (data: Partial<ScoreLookupRequest>) => {
    const lookupDto = { ...data, country: country?.id };
    sopClient.certificateLookup(code, lookupDto).then(
      (result) => {
        const { data } = result;
        if (data.pin) {
          navigate(`report`, { state: data.pin });
        }
      },
      (error) => {
        console.log(error);
        const {
          response: {
            data: { message, type }
          }
        } = error;
        type === SopStatusResponse.SHARE_EXPIRED_EXCEPTION
          ? setExpired(true)
          : setError({ open: true, message });
      }
    );
  };
  const handleCountryInputChange = useCallback(
    debounce((event: BaseSyntheticEvent) => {
      const value = event?.target.value;
      handleGetCountries(value);
    }, 300),
    []
  );
  const handleCountryAutocompleteChange = (_: BaseSyntheticEvent, v: Country | null) => {
    setValue('country', v ? v.name : '');
    setCountry(v);
    trigger('country');
  };

  const backToHomepage = () => {
    navigate('/');
  };

  if (expired) return <ScoreLookupExpiredView backToHomepage={backToHomepage} />;

  return (
    <Stack sx={ScoreLookupContainer} component='form' onSubmit={handleSubmit(viewScores)}>
      <Typography sx={ScoreLookupTitle}>{t('scoreLookup.lookup')}</Typography>
      <Typography sx={ScoreLookupDescription}>
        {t('scoreLookup.description1')}{' '}
        <Link href='https://pearson.tfaforms.net/3048' sx={SupportPageLink}>
          {t('scoreLookup.description2')}
        </Link>{' '}
        {t('scoreLookup.description3')}
      </Typography>
      <Stack>
        <Box sx={FormBox}>
          <Typography sx={FormInputLabel}>{t('scoreLookup.name')}</Typography>
          <TextField sx={FormInputTextField} {...register('testTakerName', { required: true })} />
          {errors?.testTakerName?.type === 'required' && (
            <Typography sx={{ ...SignUpErrorMessage, bottom: '5px' }}>
              {t('scoreLookup.required')}
            </Typography>
          )}
        </Box>

        <Typography sx={FormInputLabel}>
          {t(mode === 'mts' ? 'testTakerId' : 'testCode')}
        </Typography>
        <Box sx={{ position: 'relative' }}>
          <TextField
            sx={FormInputTextField}
            {...register('accessCode', {
              required: true
            })}
          />
          {errors?.testCode?.type === 'required' && (
            <Typography sx={SignUpErrorMessage}>{t('scoreLookup.required')}</Typography>
          )}
        </Box>
      </Stack>
      <Stack sx={{ marginTop: '20px' }}>
        <Box sx={BoxWithJit}>
          <Typography component='div' sx={FormInputLabel}>
            {t('scoreLookup.org')}
          </Typography>
          <Tooltip
            placement={window.screen.width >= 1200 ? 'right' : 'top'}
            open={organizationJitOpen}
            componentsProps={{ tooltip: { sx: purpleTooltip } }}
            arrow
            title='We’ll use this data for internal reporting purposes only.'
          >
            <img
              onMouseLeave={() => setOrganizationJitOpen(false)}
              onMouseOver={() => setOrganizationJitOpen(true)}
              onClick={() => setOrganizationJitOpen(!organizationJitOpen)}
              src={InfoIcon}
            />
          </Tooltip>
        </Box>
        <Box sx={{ position: 'relative', paddingBottom: '20px' }}>
          <TextField sx={[FormInputTextField]} {...register('organization', { required: true })} />
          {errors?.organization?.type === 'required' && (
            <Typography sx={[SignUpErrorMessage, { bottom: 0 }]}>
              {t('scoreLookup.required')}
            </Typography>
          )}
        </Box>
        <Box sx={BoxWithJit}>
          <Typography component='div' sx={FormInputLabel}>
            {t('scoreLookup.country')}
          </Typography>
          <Tooltip
            placement={window.screen.width >= 1200 ? 'right' : 'top'}
            open={countryJitOpen}
            componentsProps={{ tooltip: { sx: purpleTooltip } }}
            arrow
            title={t('scoreLookup.tooltip')}
          >
            <img
              onMouseLeave={() => setCountryJitOpen(false)}
              onMouseOver={() => setCountryJitOpen(true)}
              onClick={() => setCountryJitOpen(!countryJitOpen)}
              src={InfoIcon}
            />
          </Tooltip>
        </Box>

        <Box
          sx={{
            position: 'relative',
            '.MuiAutocomplete-endAdornment .MuiButtonBase-root': {
              display: 'none'
            }
          }}
        >
          <Autocomplete
            disablePortal={false}
            forcePopupIcon={false}
            id='countryInput'
            options={countries.length ? countries : []}
            getOptionLabel={(option) => option.name}
            onInputChange={handleCountryInputChange}
            onChange={handleCountryAutocompleteChange}
            sx={FormInputTextField}
            value={country}
            renderInput={(params) => (
              <TextField
                {...register('country', {
                  value: country?.name || null
                })}
                {...params}
                placeholder='Start typing ...'
              />
            )}
          />
        </Box>
        <FormControlLabel
          sx={[ConsentData, CheckboxResponsive]}
          label={t('scoreLookup.consent')}
          control={
            <Checkbox
              defaultChecked={false}
              sx={ConsentCheckboxStyle}
              size='small'
              color='default'
              id='ConsentCheckbox'
              {...register('consent', {
                required: true
              })}
            />
          }
        />
      </Stack>
      <Box sx={ViewScoresButtonBox}>
        <Button
          disabled={Object.keys(errors).length > 0 || !isValid}
          type='submit'
          sx={{ ...SignInButton, marginBottom: 0 }}
        >
          {t('scoreLookup.viewCertificate')}
        </Button>
      </Box>
      <Typography sx={ScoreLookupTerms}>
        {t('scoreLookup.byClickingOn')}{' '}
        <Typography component='span' sx={[ScoreLookupTerms, { fontWeight: 600 }]}>
          {' '}
          {t('scoreLookup.viewCertificate')}{' '}
        </Typography>{' '}
        {t('scoreLookup.youAreAgreeing')}{' '}
        <Link href={process.env.REACT_APP_PRIVACY_POLICY_LINK} style={TermsLink}>
          {t('scoreLookup.privacyPolicy')}
        </Link>
        ,{' '}
        <LocalizedNavLink to='/terms-of-use' style={TermsLink}>
          {t('scoreLookup.termisOfUse')}
        </LocalizedNavLink>{' '}
        &{' '}
        <Link href={process.env.REACT_APP_COOKIE_POLICY_LINK} style={TermsLink}>
          {t('scoreLookup.cookiePolicy')}
        </Link>
      </Typography>
    </Stack>
  );
};
