import { FC, useEffect, useRef, useState } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { Auth, sopClient } from '@barracuda/shared/src';
import {
  dashboardUploadIdContainer,
  dashboardUploadIdMobileHeader,
  dashboardUploadIdNavbarLogo
} from './DashboardUploadId.styles';
import { LottieRefCurrentProps } from 'lottie-react';
import { useSearchParams } from 'react-router-dom';
import Logo from '../../assets/images/pearson-logo-tm.png';
import LogoMobile from '../../assets/images/pearson-logo-mobile.png';
import { Img } from '@barracuda/shared/src/components/Primitive';
import { idVerificationMinHeight, idVerificationMinWidth } from '@barracuda/shared/src/utils/utils';
import { isMobile } from '../../app.utils';
import { UploadIdErrorScreen } from '../UploadIdErrorScreen/UploadIdErrorScreen';
import { UploadIdCounter } from '../UploadIdCounter/UploadIdCounter';
import { useLocalizedNavigate } from 'src/hooks/useLocalizedNavigate';
import { DashboardUploadIdPhotoMode } from './DashboardUploadIdPhotoMode';
import { DashboardUploadIdProcessMode } from './DashboardUploadIdProcessMode';

export type ViewModeType =
  | 'take-photo'
  | 'all-done'
  | 'attempt-failed'
  | 'expired'
  | 'normal'
  | 'back-accepted'
  | 'already-submitted'
  | 'all-done-failed';

export const DashboardUploadId: FC = () => {
  type PhotoSide = 'back' | 'front';
  const backIdSuccess = useRef<LottieRefCurrentProps>(null);
  const [searchParams] = useSearchParams();
  const manual = searchParams.get('manual') === 'true';
  const code = searchParams.get('code');
  const [token, setToken] = useState<string | null>(searchParams.get('token'));
  const [photo, setPhoto] = useState<string>('');
  const [backPhoto, setBackPhoto] = useState<string>('');
  const navigate = useLocalizedNavigate();
  const theme = useTheme();
  const xsWindow = useMediaQuery(theme.breakpoints.down('sm'));
  const [photoDimension, setPhotoDimension] = useState<{ width: number; height: number }>({
    width: 0,
    height: 0
  });
  const [backPhotoDimension, setBackPhotoDimension] = useState<{ width: number; height: number }>({
    width: 0,
    height: 0
  });
  const [status, setStatus] = useState<'accepted' | 'rejected' | null>(null);
  const [backStatus, setBackStatus] = useState<'accepted' | 'rejected' | null>(null);
  const [viewMode, setViewMode] = useState<ViewModeType>('normal');

  useEffect(() => {
    (async () => {
      if (!code && !token) {
        setViewMode('expired');
        return;
      }
      if (code) {
        const response = await sopClient.getTokenFromCode(code);
        setToken(response.data.token);
      }
    })();
  }, []);

  useEffect(() => {
    const i = new Image();
    i.onload = () => {
      setPhotoDimension({ width: i.width, height: i.height });
    };
    i.src = photo;
  }, [photo]);

  useEffect(() => {
    const i = new Image();

    i.onload = () => {
      setBackPhotoDimension({ width: i.width, height: i.height });
    };
    i.src = backPhoto;
  }, [backPhoto]);

  useEffect(() => {
    token && checkIfUploadIsPossible();
  }, [token]);

  const urlToFile = (url: string, filename: string) => {
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename);
      });
  };

  const checkIfUploadIsPossible = () => {
    sopClient.checkIfCanUploadId(token).then(
      (r) => {
        if (r.data?.canVerify) {
          setViewMode('normal');
        } else {
          setViewMode('already-submitted');
        }
      },
      () => setViewMode('expired')
    );
  };

  const setExpired = () => setViewMode('expired');

  const captureMobile = (screenshotSrc: string | null) => {
    if (screenshotSrc) {
      if (!photo) {
        setPhoto(screenshotSrc);
      } else {
        setBackPhoto(screenshotSrc);
      }
      setViewMode('normal');
    }
  };
  const photoDimensionInvalid = (type: PhotoSide) => {
    const { width, height } = type === 'front' ? photoDimension : backPhotoDimension;
    return width < idVerificationMinWidth || height < idVerificationMinHeight
      ? `Image should have at least ${idVerificationMinWidth}x${idVerificationMinHeight} px size, current size ${width}x${height}\``
      : '';
  };

  const retry = () => {
    setPhoto('');
    setViewMode('take-photo');
  };
  const backRetry = () => {
    setBackPhoto('');
    setViewMode('take-photo');
  };
  const flowRetry = () => {
    if (status === 'rejected') {
      setPhoto('');
    } else {
      setBackPhoto('');
    }
    setViewMode('take-photo');
  };

  const use = async () => {
    if (!photoDimensionInvalid('front') || status === 'rejected') {
      setStatus('accepted');
    } else {
      setStatus('rejected');
      setViewMode('attempt-failed');
    }
  };
  const backUse = async () => {
    if (!photoDimensionInvalid('back') || backStatus === 'rejected') {
      setBackStatus('accepted');
      setViewMode('back-accepted');
      backIdSuccess?.current?.play();
    } else {
      setBackStatus('rejected');
      setViewMode('attempt-failed');
    }
  };

  const sendSelectedIds = async () => {
    const form = new FormData();
    const imgFront = await urlToFile(photo, 'id-verification.jpg');
    form.append('page1', imgFront, 'id-verification.jpg');
    if (backPhoto) {
      const imgBack = await urlToFile(backPhoto, 'id-verification-back.jpg');
      form.append('page2', imgBack, 'id-verification-back.jpg');
    }
    form.append('device', 'mobile');
    form.append('source_flow', 'desktop');

    const idvFailedFeatureFlag = process.env.REACT_APP_IDV_FAILED_FEATURE_FLAG === 'true';

    sopClient.uploadUserId(form, token).then(
      (r) => {
        console.log(r);
        setViewMode('all-done');
      },
      (err) => {
        console.log(err);
        setViewMode(idvFailedFeatureFlag ? 'all-done-failed' : 'all-done'); // New screen will be implemented to handle idv verification fail. This is only for dev testing purposes.
      }
    );
  };

  if (!isMobile()) return <UploadIdErrorScreen />;

  return (
    <Box sx={dashboardUploadIdContainer}>
      <Box sx={dashboardUploadIdMobileHeader}>
        <Img
          onClick={() => navigate('/')}
          src={xsWindow ? LogoMobile : Logo}
          sx={dashboardUploadIdNavbarLogo}
          alt='VPEC logo'
        />
      </Box>
      {viewMode !== 'already-submitted' && token && (
        <UploadIdCounter
          expirationTime={Auth.getQrCodeExpirationTime(token)}
          viewMode={viewMode}
          setExpired={setExpired}
        />
      )}
      {viewMode !== 'take-photo' && (
        <DashboardUploadIdProcessMode
          viewMode={viewMode}
          backPhoto={backPhoto}
          photo={photo}
          status={status}
          setViewMode={setViewMode}
          use={use}
          backUse={backUse}
          sendSelectedIds={sendSelectedIds}
          retry={retry}
          backRetry={backRetry}
          manual={manual}
          flowRetry={flowRetry}
          backIdSuccess={backIdSuccess}
        />
      )}
      {viewMode === 'take-photo' && <DashboardUploadIdPhotoMode captureMobile={captureMobile} />}
    </Box>
  );
};
