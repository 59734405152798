import { Box, Button, Stack, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import {
  BadgeTitle,
  BadgeContainer,
  CheckRow,
  PracticeButton,
  FlexCenter,
  CredlyDescriptionCenter,
  InsideStack,
  StackNextToBadge,
  RedeemedStack
} from './AcceptCredlyBadge.style';
import CheckImg from '../../assets/images/check-blue--icon.svg';
import { Img } from '../Primitive';
import { Badge } from '../../interface/badge.interface';
import { useTranslation } from 'react-i18next';
import { sopClient } from '../../api/Auth';

export const AcceptCredlyBadge: FC<{ anyTestScored: boolean }> = ({ anyTestScored }) => {
  const [badge, setBadge] = useState<Badge>();
  const { t } = useTranslation();

  useEffect(() => {
    sopClient
      .getUserBadges()
      .then((result) => (result.data ? setBadge(result.data) : setBadge(undefined)));
  }, []);

  return (
    <>
      <Stack sx={BadgeContainer}>
        <Typography sx={BadgeTitle}>
          {anyTestScored && badge?.redeemed
            ? t('dashboardPage.credlyBadgeTitleRedeemed')
            : t('dashboardPage.credlyBadgeTitle')}
        </Typography>
        {anyTestScored && (
          <Stack sx={InsideStack}>
            <Img
              width={130}
              height={130}
              src={
                badge?.redeemed
                  ? badge.imageUrl
                  : `${process.env.REACT_APP_PESC_URL}${process.env.REACT_APP_PESC_GENERIC_BADGE_IMAGE}`
              }
              alt='Generic badge'
            />
            {!badge?.redeemed && (
              <Stack sx={StackNextToBadge}>
                <Box sx={CheckRow}>
                  <Img width={22} src={CheckImg} />{' '}
                  <Typography>{t('dashboardPage.credlyBadgeLi1')}</Typography>
                </Box>
                <Box sx={CheckRow}>
                  <Img width={22} src={CheckImg} />{' '}
                  <Typography>{t('dashboardPage.credlyBadgeLi2')}</Typography>
                </Box>
                <Box sx={CheckRow}>
                  <Img width={22} src={CheckImg} />{' '}
                  <Typography>{t('dashboardPage.credlyBadgeLi3')}</Typography>
                </Box>
                <Button
                  onClick={() =>
                    window.open(
                      badge?.redeemed
                        ? badge.acceptBadgeUrl
                        : process.env.REACT_APP_PESC_CREDLY_LEARN_MORE_LINK,
                      '_'
                    )
                  }
                  sx={[PracticeButton, badge?.redeemed ? { width: '150px' } : {}]}
                >
                  {badge?.redeemed
                    ? t('dashboardPage.credlyBadgeButtonRedeemed')
                    : t('dashboardPage.credlyBadgeButton')}
                </Button>
              </Stack>
            )}
            {badge?.redeemed && (
              <Stack sx={RedeemedStack}>
                <Typography>{t('dashboardPage.credlyBadgeRedeemedCongratulationsText')}</Typography>
                <Typography sx={{ marginTop: '15px' }}>
                  {t('dashboardPage.credlyBadgeRedeemedCongratulationsTextLine2')}
                </Typography>
                <Button
                  onClick={() =>
                    window.open(
                      badge?.redeemed
                        ? badge.acceptBadgeUrl
                        : process.env.REACT_APP_PESC_CREDLY_LEARN_MORE_LINK,
                      '_'
                    )
                  }
                  sx={[PracticeButton, badge?.redeemed ? { width: '150px' } : {}]}
                >
                  {badge?.redeemed
                    ? t('dashboardPage.credlyBadgeButtonRedeemed')
                    : t('dashboardPage.credlyBadgeButton')}
                </Button>
              </Stack>
            )}
          </Stack>
        )}
        {!anyTestScored && (
          <Stack sx={FlexCenter}>
            <Img
              width={150}
              src={`${process.env.REACT_APP_PESC_URL}${process.env.REACT_APP_PESC_GENERIC_BADGE_IMAGE}`}
            />
            <Stack sx={CredlyDescriptionCenter}>
              <Typography>{t('dashboardPage.credlyBadgeTaketestToGetCredly')}</Typography>
            </Stack>
            <Button
              onClick={() =>
                window.open(
                  badge?.redeemed
                    ? badge.acceptBadgeUrl
                    : process.env.REACT_APP_PESC_CREDLY_LEARN_MORE_LINK,
                  '_'
                )
              }
              sx={PracticeButton}
            >
              {badge?.redeemed
                ? t('dashboardPage.credlyBadgeButtonRedeemed')
                : t('dashboardPage.credlyBadgeButton')}
            </Button>
          </Stack>
        )}
      </Stack>
    </>
  );
};
