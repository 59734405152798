import { theme } from '@theme';

export const item = {
  background: theme.palette.common.white,
  borderRadius: '8px',
  padding: '30px',
  height: '100%'
};

export const description = {
  marginBottom: '30px'
};

export const iconWrapper = {
  width: '60px',
  height: '60px',
  background: theme.palette.common.lightGrey5,
  borderRadius: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

export const titleStyle = {
  marginTop: '10px',
  fontWeight: 700,
  fontSize: '20px',
  color: theme.palette.common.darkGrey3
};

export const descriptionStyle = {
  fontSize: '14px',
  fontWeight: 300,
  color: theme.palette.common.darkGrey3
};
