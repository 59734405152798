import { FC, useEffect, useState } from 'react';
import { remark } from 'remark';
import remarkHtml from 'remark-html';
import { Box, SxProps, Theme } from '@mui/material';

export const MarkdownRenderer: FC<{ readonly markdown: string; sx?: SxProps<Theme> }> = ({
  markdown,
  sx
}) => {
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    const processMarkdown = async () => {
      const result = await remark().use(remarkHtml).process(markdown);
      setHtmlContent(result.toString());
    };

    processMarkdown();
  }, [markdown]);

  return <Box sx={sx} dangerouslySetInnerHTML={{ __html: htmlContent }} />;
};
