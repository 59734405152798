import { theme } from '@theme';

export const managePreferencesNavigation = {
  display: 'flex',
  alignItems: 'center',
  height: '40px',
  fontSize: '14px',
  '> p': {
    fontSize: '14px'
  },
  marginTop: '90px',
  backgroundColor: theme.palette.common.lightGrey10,
  width: '100%',
  paddingLeft: '55px',
  '@media screen and (min-width: 1199px)': {
    marginTop: 0
  },
  '@media screen and (max-width: 540px)': {
    paddingLeft: '25px'
  },
  '@media screen and (max-width: 400px)': {
    paddingLeft: '5px'
  }
};
export const managePreferencesNavigationMainLink = {
  textDecoration: 'underline',
  color: theme.palette.common.darkBlue1,
  cursor: 'pointer'
};

export const managePreferencesForwardIcon = {
  width: '14px',
  '@media screen and (max-width: 400px)': {
    width: '6px'
  },
  margin: '0 7px 0 7px'
};

export const managePreferencesContainer = {
  padding: '40px 90px 90px 90px',
  background: theme.palette.common.lightGrey5,
  minHeight: '100vh',
  '@media only screen and (max-width: 1200px)': {
    padding: '26px 60px'
  },
  '@media only screen and (max-width: 833px)': {
    padding: '26px 40px'
  }
};

export const managePreferencesWhiteBox = {
  '@media only screen and (min-width: 743px)': {
    width: '722px'
  },
  backgroundColor: theme.palette.common.white,
  borderRadius: '4px',
  '@media only screen and (min-width: 1513px)': {
    width: '943px'
  },
  '@media only screen and (max-width: 743px)': {
    marginTop: '30px'
  },
  padding: '70px'
};

export const managePreferencesWhiteBoxHeader = {
  fontWeight: 700,
  fontSize: '25px',
  lineHeight: '1.2',
  paddingBottom: '17px'
};

export const managePreferencesCheckboxHeader = {
  fontWeight: 600,
  paddingBottom: '10px'
};

export const managePreferencesConsentCheckboxStyle = {
  padding: '2px',
  margin: '0 7px 0 7px',
  '&.Mui-checked > svg': {
    fill: `${theme.palette.common.violet} !important`
  }
};

export const managePreferencesConsentLabel = {
  alignItems: 'flex-start',
  paddingBottom: '20px',
  borderBottom: `1px solid ${theme.palette.common.lightGrey12}`
};
