import { theme } from '@theme';

export const categoryItem = {
  color: theme.palette.common.darkGrey3,
  fontWeight: 400,
  paddingLeft: '30px',
  paddingRight: '30px',
  marginLeft: '-30px',
  marginRight: '-30px',
  paddingTop: '12px',
  paddingBottom: '12px',
  cursor: 'pointer'
};

export const categoryItemActive = {
  background: '#F2F2F2',
  color: theme.palette.common.darkBlue7,
  fontWeight: 700,
  borderRight: `4px solid ${theme.palette.common.darkBlue7}`
};
