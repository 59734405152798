import { theme } from '@theme';

export const helpCenterHeader = {
  paddingTop: '180px',
  paddingLeft: '130px',
  paddingRight: '180px',
  paddingBottom: '60px',
  maxWidth: '1440px !important',
  margin: '0 auto',
  '@media only screen and (min-width: 320px) and (max-width: 745px)': {
    paddingTop: '120px',
    paddingLeft: '30px',
    paddingRight: '30px',
    paddingBottom: '30px'
  },
  '@media only screen and (min-width: 746px) and (max-width: 1023px)': {
    paddingLeft: '50px',
    paddingRight: '50px'
  }
};

export const helpCenterTitle = {
  fontSize: '50px',
  fontWeight: 400,
  marginBottom: '20px'
};

export const HelpCenterHeaderParagraph = {
  ...helpCenterTitle,
  fontSize: '16px'
};

export const helpCenterCategoriesContainer = {
  padding: '40px 30px',
  background: theme.palette.common.lightGrey4,
  height: '100%',
  '@media only screen and (min-width: 320px) and (max-width: 745px)': {
    display: 'none'
  }
};

export const helpCenterTabletCategoriesBox = {
  '@media only screen and (min-width: 746px) and (max-width: 1023px)': {
    flexBasis: '235px',
    maxWidth: '235px'
  }
};

export const helpCenterCategoriesMobileContainer = {
  display: 'none',
  '@media only screen and (min-width: 320px) and (max-width: 745px)': {
    display: 'block',
    marginTop: '-80px',
    '> p': {
      marginBottom: '15px'
    },
    '> .MuiInputBase-root': {
      marginBottom: '15px',
      width: '100%',
      maxWidth: '300px'
    }
  }
};

export const helpCenterArticlesContainer = {
  '@media only screen and (min-width: 320px) and (max-width: 745px)': {
    paddingLeft: 0,
    maxWidth: '100%',
    flexBasis: '100%'
  },
  '@media only screen and (min-width: 746px) and (max-width: 1023px)': {
    flex: '1',
    paddingLeft: '20px'
  }
};

export const helpCenterCategoriesHeader = {
  fontSize: '16px',
  fontWeight: 700,
  color: theme.palette.common.darkBlue2,
  marginBottom: '20px'
};

export const helpCenterCategoryTitle = {
  fontSize: '35px',
  color: theme.palette.common.darkGrey11,
  marginBottom: '40px',
  fontWeight: 700
};

export const helpCenterContactUsContainer = {
  paddingTop: '20px',
  borderTop: `1px solid ${theme.palette.common.lightGrey1}`,
  marginTop: '20px'
};

export const helpCenterContactUsLink = {
  color: theme.palette.common.black,
  fontWeight: 700,
  cursor: 'pointer'
};
