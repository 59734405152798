import { theme } from '../../styles/theme';

export const imageStyles = {
  maxWidth: { xs: '100%', sm: '80%', md: '100%' }
};

export const leftColumn = {
  display: 'flex',
  alignContent: 'center',
  flexDirection: 'column',
  flexShrink: 0
};

export const rightColumn = {
  display: 'flex',
  marginTop: { xs: '2rem', md: '0 !important' }
};

export const SubtitleStyles = {
  fontSize: '20px',
  fontWeight: 700,
  lineHeight: '24px',
  paddingBottom: '.5rem',
  color: theme.palette.common.darkBlue2
};
