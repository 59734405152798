import { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
  Auth,
  EventTypes,
  JoinWebPage,
  LoginWrapperMode,
  sopClient,
  sopUtils,
  User
} from '@barracuda/shared/src';
import { AppContext } from '../../context/App.context';
import { useLocation, useSearchParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { SoftProfile } from '../SoftProfile/SoftProfile';
import ReactGA from 'react-ga4';
import { useAddCartItem } from '../ExploreTests/useAddCartItem';
import { RecaptchaWeb } from '@barracuda/shared/src/components/Recaptcha/RecaptchaWeb';
import { RecaptchaChangeHandler } from '@barracuda/shared/src/interface/recaptcha.interface';
import ReCAPTCHA from 'react-google-recaptcha';
import { useLocalizedNavigate } from '../../hooks/useLocalizedNavigate';
import { useLocalizedMatch } from '../../hooks/useLocalizedMatch';

interface JoinProps {
  forceAccountExistMode?: boolean;
  setForceAccountExistMode?: (value: boolean) => void;
}

export const JoinWrapper: FC<JoinProps> = ({ forceAccountExistMode, setForceAccountExistMode }) => {
  const navigate = useLocalizedNavigate();
  const [signUpSuccess, setSignUpSuccess] = useState({
    state: false,
    email: ''
  });

  const [sendLinkSuccess, setSendLinkSuccess] = useState(false);
  const [setPasswordSuccess, setSetPasswordSuccess] = useState(false);
  const { setIsLoggedIn, setUser, setError, user, error, ipCountry, language } =
    useContext(AppContext);
  const [mode, setMode] = useState<LoginWrapperMode>('signin');
  const [korConsentsMode, setKorConsentsMode] = useState(false);
  const [cachedEmail, setCachedEmail] = useState('');
  const [searchParams] = useSearchParams();
  const isResetPassword = useLocalizedMatch('/reset-password');
  const isSignIn = useLocalizedMatch('/login');
  const isSetPassword = isResetPassword && !!searchParams.get('token');
  const captchaRef = useRef<ReCAPTCHA>();
  const sopToken = sopUtils.getSopToken();

  const location = useLocation();

  const addCartItem = useAddCartItem();
  useEffect(() => {
    if (location) {
      const { state } = location;
      if (state) console.log('cartItemId', state.cartItemId);
    }
  }, [location]);

  const forceExistMode = () => {
    if (forceAccountExistMode) {
      localStorage.removeItem('selectedTab');
      setSignUpSuccess({
        state: true,
        email: cachedEmail
      });
    }
  };

  useEffect(() => {
    if (isResetPassword && !isSetPassword) {
      localStorage.setItem('selectedTab', '1');
      setMode('email_form');
      tabChangedAction();
    }

    if (isSetPassword) {
      setMode('password_form');
      tabChangedAction();
    }

    if (isSignIn) {
      setError({ open: false, message: '' });
      setMode('signin');
    }
    if (forceAccountExistMode) {
      forceExistMode();
    }
  }, [isResetPassword, isSignIn, isSetPassword, forceAccountExistMode]);

  const clearError = () => {
    setError({ open: false, message: '' });
  };

  const handleSSO = async (mode: 'join' | 'signin') => {
    try {
      await Auth.oAuthLogin(searchParams.get('verificationLicenseId'), true);
      ReactGA.event(mode === 'join' ? EventTypes.SIGN_UP_OAUTH : EventTypes.SIGN_IN_OAUTH);
    } catch (err) {
      console.error(`Error: token request; ${err}`);
    }
  };

  const handleNavigate = (url: string) => {
    navigate(`/${url}`);
  };

  const handleSignUp = async (
    email: string,
    password: string,
    emailReceiveConsent: boolean,
    captchaResult?: string,
    korConsents?: { [p: string]: string }
  ) => {
    try {
      setCachedEmail(email);
      await Auth.signUp(email, password, captchaResult);
      ReactGA.event(EventTypes.SIGN_UP_APP);
      const loginResponse = await sopClient.signIn(email, password);
      const { data } = loginResponse;
      if (data) {
        setUser(data.user);
        sopUtils.setSopToken(data.token);
        ReactGA.set({ userId: data.user?.externalId });
        const metadata =
          ipCountry === 'KR' && korConsents
            ? korConsents
            : {
                EMAIL_RECEIVE_CONSENT: emailReceiveConsent.toString()
              };
        sopClient.addMetadataToProfile(metadata).then((response) => {
          const { data } = response;
          setUser(data);
        });
      }
    } catch (error: any) {
      const { response } = error;
      if (
        response.status === 409 &&
        response.data.type === 'TestTakerAlreadyExistsValidationException'
      ) {
        handleResetPassword(email);
        setSignUpSuccess({
          state: true,
          email
        });
      }
    }
  };

  const handleResetPassword = (email: string) => {
    navigate('/login', {
      state: {
        email
      }
    });
  };

  const handleSignInRedirect = () => {
    navigate('/login');
    setMode('signin');
    setSendLinkSuccess(false);
    setSignUpSuccess({
      state: false,
      email: ''
    });
  };

  const tabChangedAction = () => {
    setForceAccountExistMode?.(false);
    sopUtils.removeSopToken();
    setUser(null);
  };

  const handleSetPasswordSubmit = async (password: string) => {
    const token = searchParams.get('token');
    if (!token) return;
    try {
      await sopClient.setNewPassword(password, token);
      setSetPasswordSuccess(true);
    } catch (e) {
      console.log('There was an error during settingnew password', e);
    }
  };

  const handlePasswordValidation = async (password: string): Promise<string[]> => {
    try {
      const response = await Auth.sopClient.validatePassword(password);
      const { data } = response as AxiosResponse;
      return data?.validationErrors;
    } catch (e) {
      console.log(e);
      return [];
    }
  };

  const handleSoftProfileComplete = (_user?: User) => {
    if (sopToken) {
      setIsLoggedIn(true);
      if (location?.state?.cartItemId && _user) {
        const pId = location?.state?.cartItemId;
        addCartItem(pId, _user, true);
      } else {
        navigate('../dashboard');
      }
    }
  };

  const handleSignIn = () => {
    if (location?.state?.cartItemId) {
      navigate('/login', { state: { cartItemId: location.state.cartItemId } });
    } else {
      navigate('/login');
    }
  };

  const recaptchaComponent = useCallback(
    (onChange: RecaptchaChangeHandler) => <RecaptchaWeb ref={captchaRef} onChange={onChange} />,
    []
  );

  const onGoogleClick = () => {
    ipCountry === 'KR' ? setKorConsentsMode(true) : handleSSO('join');
  };

  const consentsCancelClick = () => {
    setKorConsentsMode(false);
  };

  const joinWebProps = {
    handleSSO,
    handleSignIn,
    handleSignUp,
    signUpSuccess,
    handleResetPassword,
    mode,
    handleSignInRedirect,
    handleSetPasswordSubmit,
    sendLinkSuccess,
    setPasswordSuccess,
    handlePasswordValidation,
    error: error?.message,
    navigate: navigate,
    clearError,
    tabChangedAction,
    handleNavigate,
    signUpEmail: location?.state?.emailSignUp,
    recaptchaComponent,
    korConsentsMode,
    ipCountry,
    onGoogleClick,
    consentsCancelClick,
    language
  };

  return (
    <>
      <JoinWebPage {...joinWebProps} />
      {user && sopToken && !forceAccountExistMode && (
        <SoftProfile
          setForceAccountExistMode={setForceAccountExistMode}
          user={user}
          softProfileComplete={handleSoftProfileComplete}
        />
      )}
    </>
  );
};
