import { FC } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { container, header, text } from './style';
import { buttonOutlineDark } from '../../styles/button';

export const FooterGetInTouch: FC<{ id?: string }> = ({ id }) => {
  const { t } = useTranslation();

  const handleContactUs = () => {
    window.open('https://pearson.tfaforms.net/3048');
  };

  return (
    <Box sx={container} id={id}>
      <Typography component='h3' sx={header}>
        {t('footerGetInTouchHeader')}
      </Typography>
      <Typography sx={text}>{t('footerGetInTouchText')}</Typography>
      <Button sx={buttonOutlineDark} onClick={handleContactUs}>
        {t('footerGetInTouchButton')}
      </Button>
    </Box>
  );
};
