import { theme } from '@barracuda/shared/src/styles/theme';

export const scoreLookupExpiredViewTitle = {
  fontSize: '40px',
  fontWeight: 800,
  color: theme.palette.common.darkGrey11,
  paddingBottom: '70px'
};

export const scoreLookupExpiredViewContainer = {
  padding: { xs: '50px', sm: '100px' },
  maxWidth: '920px'
};
