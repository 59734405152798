import { FC, useContext, useEffect, useState } from 'react';
import { ProductTile } from '../ProductTile/ProductTile';
import { Box, Grid, Stack, Tooltip, useMediaQuery } from '@mui/material';
import { AvailableProduct, ProductsSlider, sopClient } from '@barracuda/shared/src';
import {
  productsListWrapperContainer,
  productsListWrapperProduct,
  productsListWrapperRedeemBar,
  productsListWrapperRedeemBarBluePipe,
  productsListWrapperRedeemContent,
  productsListWrapperRedeemTooltip
} from './ProductsListWrapper.style';
import { useAddCartItem } from '../ExploreTests/useAddCartItem';
import { AppContext } from 'src/context/App.context';
import { ProductMetadata } from '@barracuda/shared/src/interface/products.interface';
import { Img } from '../../../../shared/src/components/Primitive';
import { Trans } from 'react-i18next';
import DiscountSquaresImage from '../../assets/images/products-list-wrapper-discount-squares.svg';
import { purpleTooltip } from '../../../../shared/src/styles/tooltip';
import InfoIcon from '../../../../shared/src/assets/images/info-icon-outlinted.svg';

export const ProductsListWrapper: FC<{ onEnterActivationCodeClick: () => void }> = ({
  onEnterActivationCodeClick
}) => {
  const { eCommerceCountryISO2 } = useContext(AppContext);

  const [products, setProducts] = useState<AvailableProduct[] | []>([]);
  const [tooltipOpened, setTooltipOpened] = useState(false);

  const below675px = useMediaQuery('(max-width: 675px)');
  useEffect(() => {
    fetchAvailableProducts();
  }, []);
  const fetchAvailableProducts = async () => {
    const response = await sopClient.getAvailableProducts();
    const { data } = response;
    setProducts(data.results.filter((p: AvailableProduct) => !!p.essId));
  };
  const addCartItem = useAddCartItem();

  const parseProductMetadata = (metadata: ProductMetadata) => {
    const time = Number(metadata.TEST_DURATION);
    const skills = JSON.parse(metadata.TEST_SKILLS);
    return {
      skills,
      time
    };
  };

  const RedeemPrompt = (
    <Box sx={productsListWrapperRedeemBar}>
      <Box sx={productsListWrapperRedeemBarBluePipe} />
      <Box sx={productsListWrapperRedeemContent}>
        <Img sx={{ paddingRight: '8px' }} src={DiscountSquaresImage} width={30} height={30} />
        <Box sx={{ alignItems: 'center', display: { xs: 'block', sm: 'flex' } }}>
          <Trans
            components={{
              bold: (
                <strong
                  onClick={onEnterActivationCodeClick}
                  style={productsListWrapperRedeemTooltip}
                />
              )
            }}
            i18nKey='activationCodePopup.gotTestToRedeem'
          />
          <Tooltip
            componentsProps={{ tooltip: { sx: purpleTooltip } }}
            placement='bottom-start'
            open={tooltipOpened}
            arrow
            title={
              <Trans
                components={{
                  bold: <strong style={{ fontWeight: 600 }} />
                }}
                i18nKey='activationCodePopup.tooltipExtended'
              />
            }
          >
            <Img
              width={18}
              src={InfoIcon}
              alt='Tooltip hint icon'
              onMouseLeave={() => setTooltipOpened(false)}
              onMouseOver={() => setTooltipOpened(true)}
              onClick={() => setTooltipOpened(!tooltipOpened)} //For mobile purposes, since there is tap instead of hover functionality.
            />
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      {!below675px && (
        <Stack>
          <Grid container sx={productsListWrapperContainer}>
            {products?.map((item, index) => (
              <Grid item sx={productsListWrapperProduct} key={`product-${index}`}>
                <ProductTile
                  type='buy'
                  data={parseProductMetadata(item.metadata)}
                  essId={item.essId}
                  handleBuy={addCartItem}
                  eCommerceCountryISO2={eCommerceCountryISO2 || 'US'}
                />
              </Grid>
            ))}
            {RedeemPrompt}
          </Grid>
        </Stack>
      )}
      {below675px && (
        <Box
          sx={{
            marginLeft: '-10px',
            ' & > div': { width: 'calc(100% + 10px)' }
          }}
        >
          <ProductsSlider maxSteps={products.length} additionalComponent={RedeemPrompt}>
            {products?.map((item, index) => (
              <Box sx={{ marginInline: '10px' }} key={`product-${index}`}>
                <ProductTile
                  type='buy'
                  data={parseProductMetadata(item.metadata)}
                  essId={item.essId}
                  handleBuy={addCartItem}
                  eCommerceCountryISO2={eCommerceCountryISO2 || 'US'}
                />
              </Box>
            ))}
          </ProductsSlider>
        </Box>
      )}
    </>
  );
};
