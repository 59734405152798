import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ViewModeType } from './DashboardUploadId';
import { Button, Stack, Typography } from '@mui/material';
import {
  dashboardUploadIdAllDoneContainer,
  dashboardUploadIdAllDoneDesc,
  dashboardUploadIdAllDoneTitle,
  dashboardUploadIdButton
} from './DashboardUploadId.styles';
import { Img } from '../../../../shared/src/components/Primitive';
import AllDoneImg from '../../assets/images/verification--uploadId__allDone.svg';
import AlreadySubmittedImg from '../../assets/images/already-submitted.svg';
import SessionExpiredImg from '../../assets/images/verification--uploadId__sessionExpired.svg';

export interface DashboardUploadIdResultModeProps {
  viewMode: ViewModeType;
  manual: boolean;
  flowRetry: () => void;
}

export const DashboardUploadIdResultMode: FC<DashboardUploadIdResultModeProps> = ({
  viewMode,
  manual,
  flowRetry
}) => {
  const { t } = useTranslation();
  const generateResult = () => {
    switch (viewMode) {
      case 'all-done-failed': {
        return (
          <Stack sx={dashboardUploadIdAllDoneContainer}>
            <Img width={85} src={SessionExpiredImg} />
            <Typography sx={dashboardUploadIdAllDoneTitle}>Verification failed</Typography>
            <Typography sx={dashboardUploadIdAllDoneDesc}>Id verification was rejected</Typography>
          </Stack>
        );
      }
      case 'all-done': {
        return (
          <Stack sx={dashboardUploadIdAllDoneContainer}>
            <Img width={85} src={AllDoneImg} />
            <Typography sx={dashboardUploadIdAllDoneTitle}>
              {t('mobileUploadId.allDone')}
            </Typography>
            {!manual && (
              <Typography sx={dashboardUploadIdAllDoneDesc}>
                {t('mobileUploadId.toContinue', {
                  productName: process.env.REACT_APP_GENERAL_PRODUCT_NAME
                })}
              </Typography>
            )}
            {manual && (
              <Typography sx={dashboardUploadIdAllDoneDesc}>
                {t('mobileUploadId.thanksForCompleting')}
              </Typography>
            )}
          </Stack>
        );
      }
      case 'already-submitted': {
        return (
          <Stack sx={[dashboardUploadIdAllDoneContainer, { marginTop: 0 }]}>
            <Img sx={{ marginBottom: '10px' }} src={AlreadySubmittedImg} />
            <Typography sx={[dashboardUploadIdAllDoneTitle, { marginTop: 0 }]}>
              {t('mobileUploadId.alreadySubmittedTitle')}
            </Typography>
            <Typography sx={dashboardUploadIdAllDoneDesc}>
              {t('mobileUploadId.alreadySubmittedDescription')}
            </Typography>
          </Stack>
        );
      }
      case 'expired': {
        return (
          <Stack sx={dashboardUploadIdAllDoneContainer}>
            <Img width={85} src={SessionExpiredImg} />
            {!manual && (
              <>
                <Typography sx={dashboardUploadIdAllDoneTitle}>
                  {t('mobileUploadId.sessionExpired')}
                </Typography>
                <Typography sx={dashboardUploadIdAllDoneDesc}>
                  {t('mobileUploadId.outOfTime', {
                    productName: process.env.REACT_APP_GENERAL_PRODUCT_NAME
                  })}
                </Typography>
              </>
            )}
            {manual && (
              <>
                <Typography sx={dashboardUploadIdAllDoneTitle}>
                  {t('mobileUploadId.linkNotValidAnymore')}
                </Typography>
                <Typography sx={dashboardUploadIdAllDoneDesc}>
                  <Trans
                    i18nKey='mobileUploadId.linkOnlyValidFor'
                    components={{
                      a: <a target='_blank' href='https://pearson.tfaforms.net/3048' />,
                      bold: <strong />
                    }}
                  />
                </Typography>
              </>
            )}
          </Stack>
        );
      }
      case 'attempt-failed': {
        return (
          <Stack sx={[dashboardUploadIdAllDoneContainer, { marginTop: '70px' }]}>
            <Typography sx={dashboardUploadIdAllDoneTitle}>
              {t('mobileUploadId.photoDidntMeetRequirements')}
            </Typography>
            <Typography sx={dashboardUploadIdAllDoneDesc}>
              {t('mobileUploadId.pleaseTryAgain')}
            </Typography>
            <Button
              onClick={flowRetry}
              sx={[
                dashboardUploadIdButton,
                {
                  marginLeft: 0,
                  marginRight: '10px',
                  marginBottom: '30px'
                }
              ]}
            >
              {t('mobileUploadId.tryAgain')}
            </Button>
          </Stack>
        );
      }
      default:
        return null;
    }
  };

  return generateResult();
};
