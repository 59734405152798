import { FC } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import Img from '../Primitive/Img';
import {
  RequirementsVerticalRowContainer,
  RequirementsVerticalRowContainerInner,
  RequirementsVerticalRowSingleReqContainer,
  RequirementsVerticalRowSingleReqContainerInner,
  RequirementsVerticalRowSingleReqContainerInnerMainRow,
  RequirementsVerticalRowSingleReqContainerInnerMainRowImg,
  RequirementsVerticalRowSingleReqContainerInnerMainRowTitle,
  RequirementsVerticalRowSingleReqTitle,
  RequirementsVerticalRowSubtitle,
  RequirementsVerticalRowTag,
  RequirementsVerticalRowTitle,
  TilesResponsive
} from './RequirementsVerticalRow.style';

type RequirementRowType = {
  title: string;
  image: string;
};

type RequirementRow = {
  title: string;
  bottomText?: string;
  req: RequirementRowType[];
};

type RequirementsVerticalRowProps = {
  title: string;
  subtitle: string;
  tag?: string;
  requirementsRows: RequirementRow[];
};

export const RequirementsVerticalRow: FC<RequirementsVerticalRowProps> = ({
  requirementsRows,
  title,
  subtitle,
  tag
}) => (
  <Grid container sx={RequirementsVerticalRowContainer}>
    <Grid item xs={12} sx={RequirementsVerticalRowContainerInner}>
      <Typography sx={RequirementsVerticalRowTag}>{tag}</Typography>
      <Typography sx={RequirementsVerticalRowTitle}>{title}</Typography>
      <Typography sx={RequirementsVerticalRowSubtitle}>{subtitle}</Typography>
    </Grid>
    <Grid container sx={TilesResponsive}>
      {requirementsRows?.map((requirements, index: number) => (
        <Grid
          key={`row-${index}`}
          item
          xs={12}
          sm={6}
          md={4}
          sx={RequirementsVerticalRowSingleReqContainer}
        >
          <Typography sx={RequirementsVerticalRowSingleReqTitle}>{requirements.title}</Typography>
          <Box sx={{ height: '-webkit-fill-available' }}>
            {requirements?.req.map(({ title, image }, index: number) => (
              <Box
                key={`${title.replace(' ', '')}-${index}`}
                sx={RequirementsVerticalRowSingleReqContainerInner}
              >
                <Box sx={RequirementsVerticalRowSingleReqContainerInnerMainRow}>
                  <Box sx={RequirementsVerticalRowSingleReqContainerInnerMainRowImg}>
                    <Img src={image} />
                  </Box>
                  <Typography
                    component='h6'
                    sx={RequirementsVerticalRowSingleReqContainerInnerMainRowTitle}
                  >
                    {title}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Grid>
      ))}
    </Grid>
  </Grid>
);
