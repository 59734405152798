import { theme } from '@theme';

export const ProfileWrapperStyle = {
  minHeight: 'calc(100vh - 350px)',
  background: theme.palette.common.lightGrey5
};
export const ChangePasswordModifications = {
  '@media screen and (min-width: 1100px)': {
    width: '980px !important',
    display: 'flex',
    flexFlow: 'column',
    '> div': {
      flex: 0
    }
  }
};
