import { FC } from 'react';
import { Box } from '@mui/material';
import badgeIcon from '../../assets/images/badge--icon.svg';
import clockGrayIcon from '../../assets/images/clock--gray.svg';
import hangupDocumentIcon from '../../assets/images/hangup-document.svg';
import inProgressIcon from '../../assets/images/in-progress-icon.svg';
import completedIcon from '../../assets/images/completed-icon.svg';
import errorIcon from '../../assets/images/error-icon.-badge.svg';
import editNoteIcon from '../../assets/images/edit-note.svg';
import unableToCertifyIcon from '../../assets/images/unable-to-certify--icon.svg';
import speakingUnscorableIcon from '../../assets/images/speaking-unscorable-icon.svg';
import unscorableIcon from '../../assets/images/unscorable--icon.svg';
import ViolationIcon from '../../assets/images/violation-icon.svg';
import {
  StatusBadgeAppealed,
  StatusBadgeCertified,
  StatusBadgeCompleted,
  StatusBadgeError,
  StatusBadgeInProgress,
  StatusBadgeLateHangup,
  StatusBadgePendingCertification,
  StatusBadgeSpeakingUnscorable,
  StatusBadgeStarted,
  StatusBadgeUnableToCertify,
  StatusBadgeUnscorable
} from './StatusBadge.styles';
import { Status } from '../../interface/licenses.interfaces';
import { useTranslation } from 'react-i18next';
import { Img } from '../Primitive';

export interface IStatusBadgeProps {
  type: Status | undefined;
}

const useGetBadge = (type: string) => {
  const { t } = useTranslation();

  if (!type) return;
  switch (type) {
    case 'CERTIFIED':
      return (
        <Box sx={StatusBadgeCertified}>
          <Img src={badgeIcon} />
          {t('results.resultBadge.certified')}
        </Box>
      );
    case 'NOT_SCORABLE':
      return (
        <Box sx={StatusBadgeUnscorable}>
          <Img src={unscorableIcon} />
          {t('results.resultBadge.unscorable')}
        </Box>
      );
    case 'SCORED':
      return (
        <Box sx={StatusBadgePendingCertification}>
          <Img src={clockGrayIcon} />
          {t('results.resultBadge.pendingCertification')}
        </Box>
      );
    case 'CERTIFICATION_FAILED':
      return (
        <Box sx={StatusBadgeUnableToCertify}>
          <Img src={unableToCertifyIcon} />
          {t('results.resultBadge.unabletoCertify')}
        </Box>
      );
    case 'SPEAKING_UNSCORBALE':
    case 'PARTIALLY_SCORED':
      return (
        <Box sx={StatusBadgeSpeakingUnscorable}>
          <Img src={speakingUnscorableIcon} />
          {t('results.resultBadge.speakingUnscorable')}
        </Box>
      );
    case 'APPEALED':
      return (
        <Box sx={StatusBadgeAppealed}>
          <Img src={clockGrayIcon} />
          {t('results.resultBadge.appealed')}
        </Box>
      );
    case 'STARTED':
      return (
        <Box sx={StatusBadgeStarted}>
          <Img src={editNoteIcon} />
          {t('results.resultBadge.started')}
        </Box>
      );
    case 'LATE_HANGUP':
    case 'USER_HUNG_UP':
      return (
        <Box sx={StatusBadgeLateHangup}>
          <Img src={hangupDocumentIcon} />
          {t('results.resultBadge.incomplete')}
        </Box>
      );
    case 'UPLOADING':
      return (
        <Box sx={StatusBadgeInProgress}>
          <Img src={inProgressIcon} />
          {t('results.resultBadge.uploading')}
        </Box>
      );
    case 'ABORTED':
      return (
        <Box sx={StatusBadgeLateHangup}>
          <Img src={ViolationIcon} />
          {t('results.resultBadge.ruleViolation')}
        </Box>
      );
    case 'IN_PROGRESS':
      return (
        <Box sx={StatusBadgeInProgress}>
          <Img src={inProgressIcon} />
          {t('results.resultBadge.inProgress')}
        </Box>
      );
    case 'COMPLETED':
      return (
        <Box sx={StatusBadgeCompleted}>
          <Img src={completedIcon} />
          {t('results.resultBadge.completed')}
        </Box>
      );
    case 'ERROR':
      return (
        <Box sx={StatusBadgeError}>
          <Img src={errorIcon} />
          {t('results.resultBadge.error')}
        </Box>
      );
    case 'NEW':
    default:
      return <Box />;
  }
};

export const StatusBadge: FC<IStatusBadgeProps> = ({ type }) => useGetBadge(type || '') || <></>;
