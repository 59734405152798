import { Box, Grid, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import {
  Description,
  HideFor600px,
  JourneyStepImage,
  JourneySteps,
  JourneyStepTime,
  StartJourneyContainer,
  StartJourneyTitle,
  StepContainer
} from './StartJourneyStepper.style';
import DownloadTestStep from '../../assets/images/download-app-step.svg';
import PrepareEnvStep from '../../assets/images/prepare-env-step.svg';
import VerifyIdStep from '../../assets/images/verify-identity-step.svg';
import StartTestStep from '../../assets/images/start-test-step.svg';
import { Img } from '../Primitive';
import { useTranslation } from 'react-i18next';

export const StartJourneyStepper: FC = () => {
  const { t } = useTranslation();

  const steps = [
    {
      icon: DownloadTestStep,
      description: t('dashboardPage.startJourneyStepperStep1Description'),
      timeRequired: t('dashboardPage.startJourneyStepperTimeRequired', { time: 2 })
    },
    {
      icon: PrepareEnvStep,
      description: t('dashboardPage.startJourneyStepperStep2Description'),
      timeRequired: t('dashboardPage.startJourneyStepperTimeRequired', { time: 2 })
    },
    {
      icon: VerifyIdStep,
      description: t('dashboardPage.startJourneyStepperStep3Description'),
      timeRequired: t('dashboardPage.startJourneyStepperTimeRequired', { time: 5 })
    },
    {
      icon: StartTestStep,
      description: t('dashboardPage.startJourneyStepperStep4Description'),
      timeRequired: t('dashboardPage.startJourneyStepperTimeRequired', { time: '30 - 60' })
    }
  ];
  return (
    <Stack sx={StartJourneyContainer}>
      <Typography sx={StartJourneyTitle}>{t('dashboardPage.startJourneyStepperTitle')}</Typography>
      <Grid container sx={JourneySteps}>
        {steps.map((item, index) => (
          <Grid item key={`journey-step-${index}`} sx={StepContainer}>
            <Img sx={JourneyStepImage} src={item.icon} />
            <Box sx={Description}>
              <Typography sx={HideFor600px} component='span'>{`${index + 1}.`}</Typography>
              <Typography component='span'>{item.description} </Typography>
              <Typography component='div' sx={JourneyStepTime}>
                {item.timeRequired}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};
