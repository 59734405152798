import { FC } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { container, descriptionStyle, titleStyle } from './QuestionTypeVideo.styles';
import { Img } from '../Primitive';

export interface QuestionTypeVideoProps {
  source?: string;
  title: string;
  description: string;
  image: any;
  onClick: () => void;
}

export const QuestionTypeVideo: FC<QuestionTypeVideoProps> = ({
  title,
  description,
  image,
  onClick
}) => (
  <Stack sx={container} onClick={onClick}>
    <Box sx={{ width: '100%' }}>
      <Img sx={{ width: '100%' }} src={image} />
    </Box>
    <Stack sx={{ padding: '10px 30px 30px' }}>
      <Typography sx={titleStyle}>{title}</Typography>
      <Typography sx={descriptionStyle}>{description}</Typography>
    </Stack>
  </Stack>
);
