import { theme } from '../../styles/theme';

export const TestsTableContainer = {
  background: theme.palette.common.white
};

export const TestsTableTHead = {
  background: theme.palette.common.lightGrey5,
  border: 0
};

export const TestsTableTHeadCell = {
  fontWeight: 600
};

export const dynamicColumnsStyle = (el: string) => {
  const skills = ['Speaking', 'Listening', 'Reading', 'Writing'];
  return {
    border: 0,
    fontWeight: 600,
    '@media screen and (max-width: 1199px)': {
      display: skills.includes(el) ? 'none !important' : 'table-cell'
    },
    '@media screen and (max-width: 850px)': {
      display: el === 'Date' ? 'none !important' : 'table-cell'
    }
  };
};

export const DateHideCondition = {
  '@media screen and (max-width: 850px)': {
    display: 'none'
  }
};

export const TitleAndDate = {
  '@media screen and (min-width: 850px)': {
    display: 'none'
  },
  display: 'flex',
  flexFlow: 'column'
};

export const ProductNameOnly = {
  '@media screen and (max-width: 850px)': {
    display: 'none'
  }
};

export const TestsTableTBody = {
  td: {
    height: '69px'
  }
};

export const HideLastRowBorder = {
  '&:last-of-type': {
    td: {
      border: 0
    }
  }
};

export const TestsTableTestCell = {
  display: 'flex',
  alignItems: 'center',
  fontSize: '16px',
  fontWeight: 600,
  img: {
    marginRight: '5px'
  }
};

export const TestsTableOverallCell = {
  fontSize: '16px',
  fontWeight: 600,
  width: '140px'
};

export const TestsTableArrowCellImage = {
  height: '15px'
};

export const NoTestsDescription = { marginTop: '20px', paddingBottom: '30px' };

export const NoTestsContainer = {
  alignItems: 'center'
};

export const InitialPadding = {
  '&:first-of-type': {
    paddingLeft: '50px',
    '@media screen and (max-width: 750px)': {
      paddingLeft: '10px'
    }
  }
};
