import { FC } from 'react';
import { Box, Button, Typography, SxProps, Theme } from '@mui/material';
import { H3 } from '../GettingReadyPage/GettingReadyPage.styles';
import { Stack } from '@mui/material';
import PracticeIcon from '@sharedImages/practice-the-test-icon.svg';
import GuideIcon from '@sharedImages/read-test-guide-icon.svg';
import { LeftStack, RightStack, StackTitle } from './PrepareForTestResource.styles';
import { Img } from '@barracuda/shared/src/components/Primitive';
import { useTranslation } from 'react-i18next';
import { buttonOutlineDark, buttonOutlineLight } from '@barracuda/shared/src/styles/button';

export const PrepareForTestResource: FC<{
  readonly handleDownload: () => void;
  readonly handleStartPractice: () => void;
  readonly sx?: SxProps<Theme>;
  readonly id?: string;
}> = ({ sx, id, handleDownload, handleStartPractice }) => {
  const { t } = useTranslation();

  return (
    <Box sx={sx} id={id}>
      <Typography component='h2' sx={[H3, { marginBottom: '20px' }]}>
        {t('gettingReadyPage.prepareForTestHeader')}
      </Typography>

      <Box sx={{ display: { md: 'flex' } }}>
        <Stack sx={LeftStack}>
          <Img width={60} src={PracticeIcon} />
          <Typography sx={StackTitle}>{t('gettingReadyPage.prepareForTestLeftHeader')}</Typography>
          <Typography sx={{ paddingBottom: '40px' }}>
            {t('gettingReadyPage.prepareForTestLeftText')}
          </Typography>
          <Button onClick={() => handleStartPractice()} sx={[buttonOutlineDark, { mt: 'auto' }]}>
            {t('gettingReadyPage.prepareForTestLeftButton')}
          </Button>
        </Stack>
        <Stack sx={RightStack}>
          <Img width={60} src={GuideIcon} />
          <Typography sx={StackTitle}>{t('gettingReadyPage.prepareForTestRightHeader')}</Typography>
          <Typography sx={{ paddingBottom: '40px' }}>
            {t('gettingReadyPage.prepareForTestRightText')}
          </Typography>
          <Button onClick={() => handleDownload()} sx={[buttonOutlineLight, { mt: 'auto' }]}>
            {t('gettingReadyPage.prepareForTestRightButton')}
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};
