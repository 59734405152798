import { ProcessingOrderBanner, SharedOrderHistory, sopClient } from '@barracuda/shared/src';
import { Box, Stack, useMediaQuery } from '@mui/material';
import { BaseSyntheticEvent, FC, useCallback, useContext, useEffect, useState } from 'react';
import { SharedScoreContainer, SharedScoreWrapper } from './AccountOrderHistoryWrapper.styles';
import { debounce } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import {
  OrderTransactionProduct,
  SingleOrderHistory,
  TransactionResults
} from '@barracuda/shared/src/interface/transaction-product.interface';
import moment from 'moment';
import { OrderRefundPopup } from '../OrderRefundPopup/OrderRefundPopup';
import { AppContext } from '../../context/App.context';
import { saveAxiosResponseFile } from '../../app.utils';

export const AccountOrderHistoryWrapper: FC = () => {
  const BANNER_EXPIRATION_MINUTES = 5;
  const [page, setPage] = useState(0);
  const [totalOrderHistory, setTotalOrderHistory] = useState(0);
  const [showRecentOrder, setShowRecentOrder] = useState<boolean>(false);
  const [recentOrder, setRecentOrder] = useState<{ orderId: string; date: Date } | null>(null);
  const matches700 = useMediaQuery('(max-width: 700px)');

  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<number>(0);
  const { t } = useTranslation();
  const matchesMaxWidth = useMediaQuery('(max-width:834px)');
  const refundHeaders = t('profilePage.refundPopupHeaders', { returnObjects: true }) as string[];
  const { setError } = useContext(AppContext);
  const tableHeaders = Object.values(
    t(
      `profilePage.${
        matchesMaxWidth ? 'allOrderHistoryTableHeadersShortcut' : 'allOrderHistoryTableHeaders'
      }`,
      { returnObjects: true }
    )
  ).map((header) => header as string);

  const [orderHistory, setOrderHistory] = useState<TransactionResults[]>([]);

  useEffect(() => {
    loadOrderHistory();
  }, []);

  const handleSearchChange = (query: string) => {
    loadOrderHistory(page, query);
  };

  const handleInputChange = useCallback(
    debounce((event: BaseSyntheticEvent) => {
      const value = event?.target.value;
      handleSearchChange(value);
    }, 300),
    []
  );

  const handleChangePage = async (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    loadOrderHistory(newPage);
  };

  const applyCreateAtInformation = (results: TransactionResults[]) =>
    results.map((result: TransactionResults) => {
      const createdAt = result.createdAt;
      const currency = result.currency;
      return {
        ...result,
        transactionProducts: result.transactionProducts.map((product: OrderTransactionProduct) => ({
          ...product,
          createdAt,
          currency
        }))
      };
    });
  const loadOrderHistory = (pageNumber?: number, query?: string) => {
    sopClient.getOrderHistory(pageNumber, query).then((response: any) => {
      const { data } = response;
      setTotalOrderHistory(data.total);
      setOrderHistory(applyCreateAtInformation(data.results));
      showProcessingOrderBanner(data.results);
    });
  };

  const showProcessingOrderBanner = (results: SingleOrderHistory[]) => {
    const lsOrder = localStorage.getItem('recentOrder');
    if (lsOrder) {
      const lsOrderParsed = (JSON.parse(lsOrder) as { orderId: string; date: Date }) || null;
      const searchedOrder = results.find((item) => item.essId === lsOrderParsed.orderId);
      if (!!searchedOrder && processingExpired(lsOrderParsed)) {
        localStorage.removeItem('recentOrder');
      }
      setRecentOrder(lsOrderParsed);
      setShowRecentOrder(!searchedOrder || !processingExpired(lsOrderParsed));
    }
  };

  const processingExpired = (order: { orderId: string; date: Date } | null) => {
    if (order) {
      const expirationDate = moment(order.date).add(BANNER_EXPIRATION_MINUTES, 'minutes');
      return moment(new Date()).isAfter(expirationDate);
    }
    return false;
  };

  const setSelectedProductItem = (transactionID: number) => {
    setSelectedItem(transactionID);
    setOpen(true);
  };

  const downloadReceipt = async (transactionId?: number) => {
    try {
      if (transactionId) {
        const response = await sopClient.downloadReceipt(transactionId);
        saveAxiosResponseFile(response);
      }
    } catch (e: any) {
      if (e.response.status === 403) {
        const message = <Trans i18nKey='toast.refreshMTSError' components={{ a: <a href='' /> }} />;
        setError({ open: true, message });
        return;
      }
      const responseAsText = await e.response.data.text();
      const { message } = JSON.parse(responseAsText);
      setError({ open: e.response?.status !== 401, message });
    }
  };

  return (
    <Box sx={SharedScoreWrapper}>
      <Stack sx={SharedScoreContainer}>
        {showRecentOrder && <ProcessingOrderBanner recentOrder={recentOrder} />}
        <SharedOrderHistory
          title={t('profilePage.titleAllOrders')}
          tableHeaders={tableHeaders}
          accordionHeaders={t(
            `profilePage.${matchesMaxWidth ? 'accordionHeadersShortcut' : 'accordionHeaders'}`,
            { returnObjects: true }
          )}
          page={page}
          orders={orderHistory}
          total={totalOrderHistory}
          handleChangePage={handleChangePage}
          setPage={setPage}
          handleInputChange={handleInputChange}
          selectedProduct={setSelectedProductItem}
          downloadReceipt={downloadReceipt}
        />
      </Stack>
      {open && (
        <OrderRefundPopup
          open={open}
          tableHeaders={
            matches700 ? refundHeaders.filter((_, index) => index !== 1) : refundHeaders
          }
          productID={selectedItem}
          handleClose={() => {
            setOpen(!open);
            loadOrderHistory(page);
          }}
        />
      )}
    </Box>
  );
};
