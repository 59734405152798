import { FC, useEffect, useState } from 'react';
import { TopScoreBadge } from '@barracuda/shared/src/components/TopScoreBadge/TopScoreBadge';
import { Grid } from '@mui/material';
import { BadgeContent, BadgesContainer } from './ShareResultsAccordionWrapper.style';
import { AcceptCredlyBadge } from '@barracuda/shared/src/components/AcceptCredlyBadge/AcceptCredlyBadge';
import { MyTopScore, sopClient } from '@barracuda/shared/src';
import { useLocalizedNavigate } from '../../hooks/useLocalizedNavigate';

export const ShareResultsAccordionWrapper: FC = () => {
  const navigate = useLocalizedNavigate();
  const [anyTestScored, setAnyTestScored] = useState(false);
  const [myTopScore, setMyTopScore] = useState<MyTopScore>();
  useEffect(() => {
    checkIfHasAnyTestScored();
    fetchMyTopScore();
  }, []);

  const checkIfHasAnyTestScored = async () => {
    const result = await sopClient.hasAnyTestScored();
    setAnyTestScored(result);
  };
  const fetchMyTopScore = async () => {
    sopClient
      .getMyTopScore(process.env.REACT_APP_MTS_CODE || '')
      .then((response) => {
        if (response?.data) {
          setMyTopScore(response.data);
        }
      })
      .catch((error) => console.error('There was an error during getting MyTopScore.', error));
  };

  return (
    <>
      <Grid container sx={BadgesContainer}>
        <Grid item sx={BadgeContent}>
          <TopScoreBadge myTopScore={myTopScore} navigate={navigate} />
        </Grid>
        <Grid sx={BadgeContent} item>
          <AcceptCredlyBadge anyTestScored={anyTestScored} />
        </Grid>
      </Grid>
    </>
  );
};
