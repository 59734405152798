export const BadgesContainer = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '50px',
  paddingBottom: '40px',
  '@media screen and (max-width: 1370px)': {
    gap: '20px'
  }
};
export const BadgeContent = {
  width: 'calc(50% - 50px)',
  height: '500px',
  '@media screen and (max-width: 1370px)': {
    width: 'calc(50% - 20px)'
  },
  '@media screen and (max-width: 880px)': {
    width: 'calc(100% - 20px)',
    maxWidth: 'unset'
  },
  maxWidth: '450px'
};
