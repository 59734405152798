import { theme } from '../../styles/theme';

export const FlexCenter = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

export const TileContainer = {
  background: theme.palette.common.white,
  border: `3px solid ${theme.palette.common.violetLight}`,
  borderRadius: '12.5413px',
  width: '367px',
  '@media screen and (max-width: 680px)': {
    width: 'unset',
    maxWidth: '370px'
  },
  '&:hover': {
    boxShadow: '0 0 30px rgba(0, 0, 0, 0.2)'
  }
};
export const TileHead = {
  background: '#FDF5FB',
  borderWidth: '3px 0 0 3px',
  borderColor: theme.palette.common.violetLight,
  borderRadius: '12.5413px 0',
  ...FlexCenter,
  width: '45%',
  '> span': {
    color: theme.palette.common.darkBlue2,
    padding: '8px'
  }
};
export const TileBody = {
  marginTop: '30px',
  paddingLeft: '20px',
  paddingRight: '20px',
  paddingBottom: '20px',
  ...FlexCenter,
  flex: 1
};
export const ShieldContainer = {
  position: 'relative'
};
export const ShieldScore = {
  fontSize: '31px',
  fontWeight: 700,
  color: theme.palette.common.lightGrey6,
  position: 'absolute',
  marginLeft: '12%',
  marginRight: '12%',
  left: 0,
  right: 0,
  textAlign: 'center',
  top: '21%'
};
export const ScoreData = {
  marginLeft: '15px'
};
export const ScoreTitle = {
  color: theme.palette.common.black,
  fontWeight: 600,
  fontSize: '18px',
  width: '85%'
};
export const TileFooter = {
  background: '#FDF5FB',
  height: '50px',
  marginTop: 'auto',
  borderWidth: '0 3px 3px 3px',
  borderColor: theme.palette.common.violetLight,
  borderRadius: '0 0 10px 10px',
  width: '100%',
  ...FlexCenter,
  justifyContent: 'space-between',
  paddingLeft: '25px',
  paddingRight: '25px',
  '> p': {
    fontWeight: 600,
    color: theme.palette.common.darkGrey3,
    fontSize: '15px',
    padding: '15px 0 15px 0'
  },
  cursor: 'pointer',
  transition: 'padding-right 0.5s',
  '&:hover': {
    background: '#F9EDF6',
    paddingRight: '19px'
  }
};
export const StatusDescription = {
  color: theme.palette.common.darkBlue2,
  fontSize: '12px',
  fontWeight: 400,
  textAlign: 'center',
  paddingBottom: '15px',
  paddingLeft: '20px',
  paddingRight: '20px'
};
