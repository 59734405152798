import { FC, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { GettingReadyPage } from '../GettingReadyPage/GettingReadyPage';
import { AppContext } from '../../context/App.context';
import { isMobile } from 'src/app.utils';
import { scrollToElement } from '@barracuda/shared/src/utils/utils';
import { Language } from '@barracuda/shared/src/interface/language.interface';
import { useLocalizedNavigate } from '../../hooks/useLocalizedNavigate';

export const GettingReadyPageWrapper: FC = () => {
  const navigate = useLocalizedNavigate();

  const { user, isLoggedIn } = useContext(AppContext);
  const { hash, state } = useLocation();
  const { language } = useContext(AppContext);

  useEffect(() => {
    if (hash) {
      // using split to remove # from string
      scrollToElement(hash.split('#')[1], 50);
    }
    if (state) {
      setTimeout(() => {
        scrollToElement(state.split('#')[1], 100);
      }, 400);
    }
  }, []);

  const handleContactUs = () => {
    window.open('https://pearson.tfaforms.net/3048');
  };

  const handleStartTest = () => {
    if (!user && !isLoggedIn) {
      navigate('/login');
    } else {
      navigate('/dashboard', { state: { tryRunTest: true } });
    }
  };

  let guideUrl = '';
  switch (language) {
    case 'JPN':
      guideUrl = `${process.env.REACT_APP_PESC_URL}${process.env.REACT_APP_PESC_JPN_TEST_TAKER_GUIDE_PDF}`;
      break;

    case 'KOR':
      guideUrl = `${process.env.REACT_APP_PESC_URL}${process.env.REACT_APP_PESC_KOR_TEST_TAKER_GUIDE_PDF}`;
      break;

    default:
      guideUrl = `${process.env.REACT_APP_PESC_URL}${process.env.REACT_APP_PESC_ENG_TEST_TAKER_GUIDE_PDF}`;
  }

  const handleDownloadGuide = () => {
    const anchor = document.createElement('a');
    anchor.href = guideUrl;
    anchor.target = '_blank';
    anchor.download = `PESC-TestTakerGuide-${language}.pdf`;
    anchor.click();
  };

  const handleHelpCenter = () => {
    if (!user && !isLoggedIn) {
      navigate('/help-center');
    } else {
      navigate('/dashboard/help-center');
    }
  };

  const handleNavigate = (to: string) => {
    const link = isLoggedIn ? `/dashboard/${to}` : `/${to}`;
    navigate(link);
  };

  const videoUrls = (language: Language | undefined): Record<string, string[]> => {
    let urls: Record<string, string[]> = {
      testQuestionTypes: [
        `${process.env.REACT_APP_PESC_URL}${process.env.REACT_APP_PESC_4_SKILLS_TEST_WALKTHROUGH_VIDEO_URL}`,
        `${process.env.REACT_APP_PESC_URL}${process.env.REACT_APP_PESC_SPEAKING_LISTENING_TEST_WALKTHROUGH_VIDEO_URL}`,
        `${process.env.REACT_APP_PESC_URL}${process.env.REACT_APP_PESC_READING_WRITING_TEST_WALKTHROUGH_VIDEO_URL}`
      ]
    };

    switch (language) {
      case 'JPN':
        urls.howToGuides = [
          `${process.env.REACT_APP_PESC_URL}${process.env.REACT_APP_PESC_GETTING_READY_FOR_TEST_VIDEO_URL_JPN}`,
          `${process.env.REACT_APP_PESC_URL}${process.env.REACT_APP_PESC_TAKING_THE_TEST_VIDEO_URL_JPN}`,
          `${process.env.REACT_APP_PESC_URL}${process.env.REACT_APP_PESC_UNDERSTANDING_RESULTS_VIDEO_URL_JPN}`,
          `${process.env.REACT_APP_PESC_URL}${process.env.REACT_APP_PESC_SHARING_RESULTS_VIDEO_URL_JPN}`
        ];
        break;

      case 'KOR':
        urls.howToGuides = [
          `${process.env.REACT_APP_PESC_URL}${process.env.REACT_APP_PESC_GETTING_READY_FOR_TEST_VIDEO_URL_KOR}`,
          `${process.env.REACT_APP_PESC_URL}${process.env.REACT_APP_PESC_TAKING_THE_TEST_VIDEO_URL_KOR}`,
          `${process.env.REACT_APP_PESC_URL}${process.env.REACT_APP_PESC_UNDERSTANDING_RESULTS_VIDEO_URL_KOR}`,
          `${process.env.REACT_APP_PESC_URL}${process.env.REACT_APP_PESC_SHARING_RESULTS_VIDEO_URL_KOR}`
        ];
        break;

      default:
        // fallback
        urls.howToGuides = [
          `${process.env.REACT_APP_PESC_URL}${process.env.REACT_APP_PESC_GETTING_READY_FOR_TEST_VIDEO_URL_ENG}`,
          `${process.env.REACT_APP_PESC_URL}${process.env.REACT_APP_PESC_TAKING_THE_TEST_VIDEO_URL_ENG}`,
          `${process.env.REACT_APP_PESC_URL}${process.env.REACT_APP_PESC_UNDERSTANDING_RESULTS_VIDEO_URL_ENG}`,
          `${process.env.REACT_APP_PESC_URL}${process.env.REACT_APP_PESC_SHARING_RESULTS_VIDEO_URL_ENG}`
        ];
    }

    return urls;
  };

  return (
    <GettingReadyPage
      navigate={navigate}
      isLoggedIn={isLoggedIn}
      handleHelpCenter={handleHelpCenter}
      handleStartTest={handleStartTest}
      handleContactUs={handleContactUs}
      handleDownloadGuide={handleDownloadGuide}
      handleNavigate={handleNavigate}
      isMobile={isMobile()}
      videoUrls={videoUrls(language)}
    />
  );
};
