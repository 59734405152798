import { FC } from 'react';
import { Box, Button, Typography } from '@mui/material';
import notFound from '../../assets/images/notFoundPage--404.svg';
import {
  pageNotFoundBtn,
  pageNotFoundContainer,
  pageNotFoundH2,
  pageNotFoundH3,
  pageNotFoundWrapper,
  pageNotFountImage
} from './PageNotFound.styles';
import { Language, ScalableWrapper } from '@barracuda/shared/src';
import { useLocalizedNavigate } from '../../hooks/useLocalizedNavigate';
import { Navigate } from 'react-router-dom';
import { IGNORE_PATHS, SUPPORTED_LANGUAGES, VALID_LANGUAGE_PREFIXES } from '../../app.utils';
import { Img } from '@barracuda/shared/src/components/Primitive';
import { useTranslation } from 'react-i18next';

const defaultLanguage = localStorage.getItem('language') || (SUPPORTED_LANGUAGES[0] as Language);

export const PageNotFound: FC = () => {
  const navigate = useLocalizedNavigate();
  const { t } = useTranslation();
  const isValidPath = () =>
    VALID_LANGUAGE_PREFIXES.includes(location.pathname.slice(0, 5)) ||
    IGNORE_PATHS.includes(location.pathname); //5 because of three language code letters and 2x slash outside /ENG/, /JPN/

  return isValidPath() ? (
    <ScalableWrapper>
      <Box sx={pageNotFoundWrapper}>
        <Box sx={pageNotFoundContainer}>
          <Img sx={pageNotFountImage} alt='VPEC logo' src={notFound} />
          <Typography sx={pageNotFoundH2} component='h2'>
            {t('pageNotFound.title')}
          </Typography>
          <Typography sx={pageNotFoundH3} component='h3'>
            {t('pageNotFound.description')}
          </Typography>
          <Button onClick={() => navigate('/')} sx={pageNotFoundBtn}>
            {t('pageNotFound.button')}
          </Button>
        </Box>
      </Box>
    </ScalableWrapper>
  ) : (
    <Navigate to={`/${defaultLanguage}${location.pathname}${location.search}${location.hash}`} />
  );
};
