import background from '../../assets/images/redeemActivationCodeTile--background.svg';
import { theme } from '../../styles/theme';

export const redeemActivationCodeContainer = {
  background: `${theme.palette.common.white} url(${background}) no-repeat`,
  color: theme.palette.common.darkGrey2,
  justifyContent: 'center',
  boxShadow: '-1px -1px 8px rgba(0, 0, 0, 0.05)',
  borderRadius: '10px',
  padding: { xs: '27px 35px', xl: '44px 35px 30px' },
  position: 'relative',
  height: '100%',
  ':after': {
    content: '""',
    display: 'block',
    height: '8px',
    backgroundColor: theme.palette.common.darkBlue9,
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px'
  }
};
