export const DownloadImage = {
  display: 'block',
  margin: '0 auto',
  width: '85%'
};
export const DownloadButtonContainer = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};
export const DownloadButton = {
  marginBottom: 0,
  marginTop: '20px'
};
