import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Auth, Language, ScalableWrapper } from '@barracuda/shared/src';
import { AppContext } from '../../context/App.context';
import { useLocalizedNavigate } from '../../hooks/useLocalizedNavigate';
import { useLocation } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { Box, Grid, Link, MenuItem, Select, Skeleton, Typography } from '@mui/material';
import { theme } from '@theme';
import {
  helpCenterArticlesContainer,
  helpCenterCategoriesContainer,
  helpCenterCategoriesHeader,
  helpCenterCategoriesMobileContainer,
  helpCenterCategoryTitle,
  helpCenterContactUsContainer,
  helpCenterContactUsLink,
  helpCenterHeader,
  HelpCenterHeaderParagraph,
  helpCenterTitle,
  helpCenterTabletCategoriesBox
} from './HelpCenter.styles';
import { useTranslation, Trans } from 'react-i18next';
import { CategoryItem } from '../CategoryItem/CategoryItem';
import { QuestionItem } from '../QuestionItem/QuestionItem';

export interface Category {
  id: number;
  label: string;
}

export interface Question {
  questionId: number;
  questionLabel: string;
  text: string;
  faqUrl: string;
}

const useQueryParams = (): { expandId: string } => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const expandId = searchParams.get('expandId') as string;
  return { expandId };
};

const handleContactUs = () => {
  window.open('https://pearson.tfaforms.net/3048');
};

const languageMap: Record<Language, 'JAPANESE' | 'KOREAN' | 'ENGLISH'> = {
  JPN: 'JAPANESE',
  KOR: 'KOREAN',
  ENG: 'ENGLISH'
};

export const HelpCenter: FC = () => {
  const { isLoggedIn, language } = useContext(AppContext);
  const navigate = useLocalizedNavigate();
  const { expandId } = useQueryParams();
  const [activeCategoryId, setActiveCategoryId] = useState<number | undefined>(0);
  const [categories, setCategories] = useState<Category[]>([]);
  const [questions, setQuestions] = useState<Question[]>([]);
  const { t } = useTranslation();
  const faqLanguage = useMemo(() => languageMap[language || 'ENG'] || 'ENGLISH', [language]);

  useEffect(() => {
    Auth.sopClient.getFaqCategories(faqLanguage).then((result: AxiosResponse<Category[]>) => {
      setCategories(result.data);
      setActiveCategoryId(result.data?.[0]?.id);
    });
  }, []);

  useEffect(() => {
    if (!activeCategoryId) {
      return;
    }
    setQuestions([]);
    Auth.sopClient
      .getFaqQuestions(faqLanguage, activeCategoryId)
      .then((result: AxiosResponse<Question[]>) => setQuestions(result.data));
  }, [activeCategoryId, language]);

  useEffect(() => {
    if (expandId) {
      scrollToElement();
    }
  });

  const currentCategoryLabel = useCallback(() => {
    return categories.find((category) => {
      return category.id === activeCategoryId;
    })?.label;
  }, [activeCategoryId]);

  const removeExpandId = () => {
    const search = location.search
      .replace(/expandId=[^&]*/, '')
      .replace(/&&/, '&')
      .replace(/&$/, '');
    navigate({ search });
  };

  const scrollToElement = () => {
    const element = document.getElementById(expandId);
    if (element) {
      const topPosition = element.getBoundingClientRect().top + window.scrollY - 90;
      window.scrollTo({
        top: topPosition,
        behavior: 'smooth'
      });
    }
  };

  const onChangeCategory = useCallback((categoryId: number) => {
    setActiveCategoryId(categoryId);
    removeExpandId();
  }, []);

  const categoryItems = useMemo(() => {
    if (!categories.length) {
      return <Skeleton animation='wave' variant='rectangular' height={400} />;
    }
    return categories.map((category, index) => (
      <CategoryItem
        onClick={() => onChangeCategory(category.id)}
        active={activeCategoryId === category.id}
        label={category.label}
        key={`category-item-${index}`}
      />
    ));
  }, [categories, activeCategoryId]);

  const QuestionsSkeleton = useCallback(
    () => (
      <Box>
        {[1, 2, 3, 4].map((index) => (
          <Skeleton
            key={`question-skeleton-${index}`}
            sx={{ mb: '10px' }}
            animation='wave'
            variant='rectangular'
            height={68}
          />
        ))}
      </Box>
    ),
    []
  );

  return (
    <ScalableWrapper isLoggedIn={isLoggedIn}>
      <Box sx={{ background: theme.palette.common.lightGrey10 }}>
        <Grid container>
          <Grid item xs={12} sx={helpCenterHeader}>
            <Typography sx={helpCenterTitle} component='h1'>
              {t('helpCenterPage.header')}
            </Typography>
            <Typography sx={HelpCenterHeaderParagraph}>
              {t('helpCenterPage.headerText', {
                productName: process.env.REACT_APP_GENERAL_PRODUCT_NAME
              })}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Grid container sx={[helpCenterHeader, { paddingTop: '40px' }]}>
        <Grid item xs={12} sx={helpCenterCategoriesMobileContainer}>
          <Typography>{t('helpCenterPage.categories')}</Typography>
          <Select
            value={activeCategoryId}
            onChange={(event) => onChangeCategory(Number(event.target.value))}
          >
            {categories.map((category, index) => (
              <MenuItem key={'selectCategory' + index} value={category.id}>
                {category.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={3} sx={[helpCenterCategoriesContainer, helpCenterTabletCategoriesBox]}>
          <Typography sx={helpCenterCategoriesHeader}>{t('helpCenterPage.categories')}</Typography>
          {categoryItems}
          <Box sx={helpCenterContactUsContainer}>
            <Trans
              i18nKey='helpCenterPage.sidebarText'
              components={{
                a: (
                  <Link
                    onClick={() => handleContactUs()}
                    sx={helpCenterContactUsLink}
                    component='span'
                  />
                )
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={8} sx={[{ paddingLeft: '80px' }, helpCenterArticlesContainer]}>
          <Typography sx={helpCenterCategoryTitle} component='h2'>
            {currentCategoryLabel()}
          </Typography>
          {!questions.length && <QuestionsSkeleton />}
          {questions.map((question, index) => (
            <QuestionItem
              question={question}
              onClick={removeExpandId}
              key={`question-item-${index}`}
              expanded={question.faqUrl === expandId}
              categoryId={activeCategoryId || 0}
              index={index}
            />
          ))}
        </Grid>
      </Grid>
    </ScalableWrapper>
  );
};
