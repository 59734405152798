import { theme } from '../../styles/theme';
import {
  LoginWebColumn,
  LoginWebColumnBox,
  LoginWebLeftColumn,
  LoginWebLeftColumnTitle
} from '../LoginWeb/LoginPage.styles';
import {
  JoinWebColumnBox,
  JoinWebLeftColumn,
  JoinWebLeftColumnTitle
} from '../JoinWeb/JoinWeb.styles';

export const AuthPageLeftPanelColumn = {
  height: 'auto',
  '@media screen and (max-width: 899px)': {
    paddingLeft: '0 !important'
  }
};

export const AuthPageLeftPanelPropsContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '2rem',
  width: '100%'
};

export const AuthPageLeftPanelColumnContent = {
  color: theme.palette.common.darkGrey2,
  letterSpacing: 0,
  fontWeight: 400,
  lineHeight: 1.5,
  marginTop: '1rem'
};

export const AuthPageLeftPanelProps = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: '1rem'
};

export const AuthPageLeftPanelProductName = {
  color: theme.palette.common.violet,
  fontWeight: 400,
  letterSpacing: '3px',
  marginTop: '150px'
};

export const AuthPageLeftPanelMainContainer = (type?: string) =>
  type === 'login'
    ? { ...LoginWebColumn, width: '60%', maxWidth: { lg: '665px !important' } }
    : { ...JoinWebColumnBox, ...JoinWebLeftColumn };

export const AuthPageLeftPanelColumnBox = (type?: string) =>
  type === 'login' ? [LoginWebColumnBox, LoginWebLeftColumn] : { width: '100%' };

export const AuthPageLeftPanelLeftColumnTitle = (type?: string) =>
  type === 'login' ? LoginWebLeftColumnTitle : JoinWebLeftColumnTitle;

export const AuthPageLeftPanelLeftMainImage = { ml: '2%', maxWidth: { md: '35%', lg: 'unset' } };
