import { theme } from '../../styles/theme';

export const container = {
  background: theme.palette.common.white,
  boxShadow: '0 0 10px rgba(0, 0, 0, 0.15)',
  borderRadius: '4px',
  width: '100%'
};

export const titleStyle = {
  fontWeight: 600,
  marginTop: '15px'
};

export const descriptionStyle = {
  fontSize: '14px',
  fontWeight: 300,
  color: theme.palette.common.darkBlue2
};
