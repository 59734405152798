import { Box, Button, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import {
  Description,
  DownloadButton,
  FlexCenter,
  ListPoint,
  PopupContent
} from './PracticeDownloadAppPopup.style';
import { isMac } from '../../utils/utils';
import { SignInButton } from '../SignIn/SignInStyles';
import PracticeDownloadImg from '../../assets/images/practice-download.png';
import { Img } from '../Primitive';
import { Trans, useTranslation } from 'react-i18next';
import checkIconPurple from '../../assets/images/check-icon.svg';
import BarracudaLaptop from '../../assets/images/download-mobile-image.png';

interface PracticeDownloadAppPopupProps {
  closeDialog: (key: string) => void;
  MSI_URL: string | undefined;
  MAC_URL: string | undefined;
  practice?: boolean;
  mobile?: boolean;
}

export const PracticeDownloadAppPopup: FC<PracticeDownloadAppPopupProps> = ({
  closeDialog,
  MSI_URL,
  MAC_URL,
  practice,
  mobile
}) => {
  const { t } = useTranslation();

  const downloadApp = (url: string | undefined) => {
    if (!url) return;
    window.open(url);
    closeDialog('download');
  };

  return (
    <>
      <Box sx={PopupContent}>
        <Stack>
          <Typography sx={Description}>
            {t(
              practice
                ? 'dashboardPage.beforeStartModalTextPractice'
                : 'dashboardPage.beforeStartModalText'
            )}
          </Typography>
          <Stack gap='16px'>
            <Box sx={FlexCenter}>
              <Img
                src={checkIconPurple}
                sx={{
                  marginRight: '1rem'
                }}
              />
              <Typography sx={ListPoint}>
                <Trans
                  i18nKey='dashboardPage.beforeStartModalListItem1'
                  components={{ bold: <strong /> }}
                />
              </Typography>
            </Box>
            <Box sx={FlexCenter}>
              <Img
                src={checkIconPurple}
                sx={{
                  marginRight: '1rem'
                }}
              />
              <Typography sx={ListPoint}>
                <Trans
                  i18nKey='dashboardPage.beforeStartModalListItem2'
                  components={{ bold: <strong /> }}
                />
              </Typography>
            </Box>
            <Box sx={FlexCenter}>
              <Img
                src={checkIconPurple}
                sx={{
                  marginRight: '1rem'
                }}
              />
              <Typography sx={ListPoint}>
                <Trans
                  i18nKey={
                    practice
                      ? 'dashboardPage.beforeStartModalListItemPractice3'
                      : 'dashboardPage.beforeStartModalListItem3'
                  }
                  components={{ bold: <strong /> }}
                />
              </Typography>
            </Box>
            <Typography sx={{ marginTop: '30px' }}>
              <Trans
                i18nKey={
                  mobile
                    ? 'dashboardPage.finalParagraphProductName'
                    : 'dashboardPage.finalParagraph'
                }
                components={{ bold: <strong /> }}
                values={{ productName: process.env.REACT_APP_GENERAL_PRODUCT_NAME }}
              ></Trans>
            </Typography>
          </Stack>
        </Stack>
        <Img
          sx={[{ width: '50%' }, mobile ? { maxHeight: '250px' } : {}]}
          src={mobile ? BarracudaLaptop : PracticeDownloadImg}
        />
      </Box>
      <Button
        onClick={
          mobile ? () => closeDialog('all-done') : () => downloadApp(isMac() ? MAC_URL : MSI_URL)
        }
        sx={[SignInButton, DownloadButton]}
      >
        {t(mobile ? 'dashboardPage.backToDashboard' : 'dashboardPage.beforeStartModalButton')}
      </Button>
    </>
  );
};
