import { theme, $fontOpenSans } from '../../styles/theme';
import { alpha } from '@mui/material';

const flexCenter = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start'
};

export const StatusBadgeCertified = {
  padding: '5px 13px 5px 8px',
  borderColor: alpha(theme.palette.common.darkGreen, 0.1),
  background: alpha(theme.palette.common.darkGreen, 0.08),
  color: theme.palette.common.darkGrey2,
  fontSize: '14px',
  fontWeight: 700,
  borderRadius: '15px',
  width: 'fit-content',
  fontFamily: $fontOpenSans,
  whiteSpace: 'nowrap',
  ...flexCenter,
  '> img': {
    marginRight: '5px'
  }
};

export const StatusBadgePendingCertification = {
  ...StatusBadgeCertified,
  background: 'rgba(145, 145, 145, 0.05)',
  border: `1px solid ${alpha(theme.palette.common.darkRed, 0.05)}`,
  fontFamily: $fontOpenSans
};

export const StatusBadgeUnableToCertify = {
  ...StatusBadgeCertified,
  background: 'rgba(211, 0, 24, 0.05)',
  border: '1px solid rgba(211, 0, 24, 0.1)',
  fontFamily: $fontOpenSans
};

export const StatusBadgeUnscorable = {
  ...StatusBadgeCertified,
  background: 'rgba(211, 0, 24, 0.7)',
  color: theme.palette.common.white
};
export const StatusBadgeSpeakingUnscorable = {
  ...StatusBadgeCertified,
  background: 'rgba(211, 0, 24, 0.70)',
  border: '1px solid #FFD5BD',
  color: theme.palette.common.lightGrey6,
  fontFamily: $fontOpenSans
};
export const StatusBadgeInProgress = {
  ...StatusBadgeCertified,
  background: 'rgba(255, 187, 28, 0.1)',
  border: '1px solid #FFECBF',
  color: theme.palette.common.darkGrey3,
  fontFamily: $fontOpenSans
};
export const StatusBadgeCompleted = {
  ...StatusBadgeCertified,
  background: '#EBF5EF',
  border: '1px solid #D4EADD',
  color: theme.palette.common.darkGreen,
  fontFamily: $fontOpenSans
};
export const StatusBadgeError = {
  ...StatusBadgeCertified,
  background: alpha(theme.palette.common.darkRed, 0.7),
  border: '1px solid #D4EADD',
  color: theme.palette.common.lightGrey6,
  fontFamily: $fontOpenSans
};

export const StatusBadgeAppealed = {
  ...StatusBadgeCertified,
  background: theme.palette.common.darkBlue2,
  color: theme.palette.common.white,
  fontFamily: $fontOpenSans
};
export const StatusBadgeLateHangup = {
  ...StatusBadgeCertified,
  background: theme.palette.common.darkGrey8,
  color: theme.palette.common.lightGrey6,
  fontFamily: $fontOpenSans
};
export const StatusBadgeStarted = {
  ...StatusBadgeCertified,
  background: theme.palette.common.white,
  color: theme.palette.common.darkBlue7,
  border: '1px solid #C9E2E9',
  fontFamily: $fontOpenSans,
  '> img': {
    width: '15px',
    height: 'auto',
    marginRight: '3px'
  }
};
