import { Language } from '@barracuda/shared/src';
import { AxiosResponse } from 'axios';
import { saveAs } from 'file-saver';

export function isMobile(): boolean {
  return (
    !!(
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) ||
    isAndroid() ||
    isIOS()
  );
}

export function isAndroid(): boolean {
  return !!navigator.userAgent.match(/Android/i);
}

export function isIOS(): boolean {
  return !!(
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i)
  );
}

export const SUPPORTED_LANGUAGES: Language[] = ['ENG', 'JPN', 'KOR'];
export const VALID_LANGUAGE_PREFIXES: string[] = SUPPORTED_LANGUAGES.map((lang) => `/${lang}/`);
export const IGNORE_PATHS = ['/version.txt', '/favicon.ico'];

export const saveAxiosResponseFile = (response: AxiosResponse<Blob, any>) => {
  const filename = response.headers['content-disposition'].split(';')[1] as string;
  const name = filename.split('"')[1] as string;
  saveAs(response.data, name);
};
