import { theme } from '@theme';

export const PanelBox = {
  width: '100%',
  height: '1px',
  background: theme.palette.common.grey,
  margin: 0
};

export const HideMobileColumnStyle = (el: string, skills: string[]) => ({
  '@media screen and (max-width: 1024px)': {
    display: skills.includes(el) ? 'none !important' : 'table-cell'
  },
  '@media screen and (max-width: 1200px)': {
    display: el === 'Status' ? 'none !important' : 'table-cell'
  }
});

export const TableMobileCondition = {
  '@media screen and (max-width: 700px)': {
    display: 'none'
  }
};

export const TestsStack = {
  '@media screen and (min-width: 701px)': {
    display: 'none'
  },
  background: theme.palette.common.white,
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.25)',
  borderRadius: '8px'
};

export const StackTitle = {
  fontWeight: 400,
  fontSize: '24px',
  lineHeight: 1.2,
  color: theme.palette.common.darkBlue1,
  paddingBottom: '20px'
};

export const StackMobileCondition = {
  '@media screen and (min-width: 701px)': {
    display: 'none'
  },
  marginTop: '47px'
};

export const StackNextButton = {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  width: '100%',
  flex: '1'
};

export const PaginationPosition = {
  marginBottom: '15px'
};

export const NoTestsMobile = {
  padding: '30px',
  textAlign: 'center',
  fontSize: '14px'
};

export const MobileTestTileDate = {
  fontWeight: 700,
  fontSize: '12px',
  lineHeight: '16px',
  color: theme.palette.common.black,
  padding: '20px 20px 10px 20px'
};

export const LoginRightColumnTabs = {
  width: '100%',
  marginBottom: '35px',
  flexShrink: 0,
  '.MuiTabs-indicator': {
    backgroundColor: theme.palette.common.violet,
    height: '3px'
  }
};

export const ScalableFix = {
  '@media screen and (min-resolution: 1.25dppx) and (max-resolution: 1.49dppx)': {
    scale: '1.25',
    width: 'calc(100% * (1/1.25))',
    transformOrigin: 'left',
    '.MuiTabs-indicator': {
      bottom: '3px'
    }
  },
  '@media screen and (min-resolution: 1.5dppx)': {
    scale: '1.5',
    width: 'calc(100% * (1/1.5))',
    transformOrigin: 'left',
    '.MuiTabs-indicator': {
      bottom: '7px'
    }
  },
  '.MuiButtonBase-root': {
    '@media screen and (min-resolution: 1.25dppx) and (max-resolution: 1.49dppx)': {
      scale: '0.8',
      width: 'calc(100% * (1/0.8))',
      transformOrigin: 'left'
    },
    '@media screen and (min-resolution: 1.5dppx)': {
      scale: '0.67',
      width: 'calc(100% * (1/0.67))',
      transformOrigin: 'left'
    }
  }
};

export const LoginRightColumnTab = {
  flex: '1',
  borderBottom: `1px solid ${theme.palette.common.grey}`,
  paddingTop: '20px',
  paddingBottom: '20px',
  fontSize: '18px',
  fontWeight: 700,
  color: `${theme.palette.common.darkGrey5} !important`
};
