const isWindows = /Win/i.test(navigator.userAgent);

export const TellUsMoreModifications = {
  '@media screen and (max-height: 600px)': {
    paddingBottom: '20px !important'
  }
};
export const ScaleStyles = {
  '@media screen and (min-resolution: 1.25dppx) and (max-resolution: 1.49dppx)': {
    '.MuiPaper-root': {
      scale: isWindows ? '0.8' : 'unset',
      maxHeight: 'unset'
    }
  },
  '@media screen and (min-resolution: 1.5dppx)': {
    '.MuiPaper-root': {
      scale: isWindows ? '0.67' : 'unset',
      maxHeight: 'unset'
    }
  }
};
