import { FC, useContext, useState } from 'react';
import { Stack } from '@mui/material';
import {
  BasicInformationBox,
  DialogWrapper,
  SecurityAndPrivacyBox,
  User
} from '@barracuda/shared/src';
import { AppContext } from '../../context/App.context';
import { ChangePasswordModifications, ProfileWrapperStyle } from './ProfileWrapper.style';
import { UserDataForm } from '../UserDataForm/UserDataForm';
import { DemographicInfoPopupWrapper } from '../DemographicInfoPopupWrapper/DemographicInfoPopupWrapper';
import { ChangePasswordPopup } from '../ChangePasswordPopup/ChangePasswordPopup';
import { useTranslation } from 'react-i18next';
import { useLocalizedNavigate } from '../../hooks/useLocalizedNavigate';
import { theme } from '@theme';

export const ProfileWrapper: FC = () => {
  const { user, setUser } = useContext(AppContext);
  const navigate = useLocalizedNavigate();
  const [editOpenedType, setEditOpenedType] = useState<'personal' | 'demographic' | null>(null);
  const [changePasswordOpened, setChangePasswordOpened] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleNavigate = (to: string) => {
    navigate(`/dashboard/${to}`);
  };
  const editAction = (type: 'personal' | 'demographic' | null) => {
    setEditOpenedType(type);
  };
  const handleCloseDialog = (user?: User) => {
    if (user) {
      setUser(user);
    }
    setEditOpenedType(null);
  };
  return (
    <>
      <Stack sx={ProfileWrapperStyle}>
        <BasicInformationBox editAction={editAction} user={user} />
        <SecurityAndPrivacyBox
          setChangePasswordOpened={setChangePasswordOpened}
          navigate={handleNavigate}
        />
      </Stack>
      <DialogWrapper
        open={editOpenedType === 'personal'}
        handleClose={() => setEditOpenedType(null)}
        subtitle=''
        scrollable
        title={t('profilePage.editProfileTitle')}
      >
        <UserDataForm closeDialog={handleCloseDialog} />
      </DialogWrapper>
      <DialogWrapper
        open={editOpenedType === 'demographic'}
        handleClose={() => setEditOpenedType(null)}
        subtitle=''
        title={t('profilePage.editProfileTitle')}
      >
        <DemographicInfoPopupWrapper handleCloseDialog={handleCloseDialog} />
      </DialogWrapper>
      <DialogWrapper
        closeable
        open={changePasswordOpened}
        scrollable
        gridModifications={ChangePasswordModifications}
        handleClose={() => setChangePasswordOpened(false)}
        subtitle={t('profilePage.managePasswordHeader')}
        title={t('profilePage.changeYourPassword')}
        subtitleColor={theme.palette.common.violet}
      >
        <ChangePasswordPopup />
      </DialogWrapper>
    </>
  );
};
