import { theme } from '../../styles/theme';

export const indicatorStyle = {
  width: 'fit-content',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '1px',
  padding: '1px 5px',
  color: theme.palette.common.teal,
  background: theme.palette.common.lightTeal,
  borderRadius: '4px'
};
