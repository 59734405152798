export const whatCanDoNextTileStepContainer = {
  alignItems: 'center',
  gap: '5px',
  marginTop: '10px',
  cursor: 'pointer'
};

export const whatCanDoNextTileStepCircle = {
  background: 'rgba(255, 255, 255, 0.1)',
  width: '32px',
  height: '32px',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center'
};
