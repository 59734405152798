import { FC, useContext, useEffect, useState } from 'react';
import {
  AgeRestrictionPopup,
  DialogWrapper,
  SoftProfileAllDone,
  User,
  Auth,
  ProgressDots,
  ActivationCodeStatus,
  sopClient,
  SopStatusResponse
} from '@barracuda/shared/src';
import { SoftProfileSurveyFormWrapper } from '../SoftProfileSurveyFormWrapper/SoftProfileSurveyFormWrapper';
import { TellUsMoreForm } from '../TellUsMoreForm/TellUsMoreForm';
import { ScaleStyles, TellUsMoreModifications } from './SoftProfile.style';
import { AppContext } from '../../context/App.context';
import { useTranslation } from 'react-i18next';
import { isMobile } from '../../app.utils';
import { useLocalizedNavigate } from '../../hooks/useLocalizedNavigate';
import { ActivationCodePopupWrapper } from '../ActivationCodePopupWrapper/ActivationCodePopupWrapper';

interface SoftProfileProps {
  softProfileComplete?: (_user?: User) => void;
  user: User | undefined;
  setSoftProfileOpened?: (value: boolean) => void;
  setForceAccountExistMode?: (value: boolean) => void;
}

export const SoftProfile: FC<SoftProfileProps> = ({
  softProfileComplete,
  user,
  setSoftProfileOpened,
  setForceAccountExistMode
}) => {
  const { t } = useTranslation();
  const navigate = useLocalizedNavigate();
  const [activationClosable, setActivationCloseable] = useState(false);
  const [status, setStatus] = useState<ActivationCodeStatus>('DEFAULT');
  const [state, setState] = useState({
    tellUsMore: false,
    ageRestriction: false,
    survey: false,
    activationCode: false,
    allDone: false
  });
  const { setUser, setIsLoggedIn } = useContext(AppContext);

  useEffect(() => {
    setState({
      tellUsMore: true,
      ageRestriction: false,
      survey: false,
      activationCode: false,
      allDone: false
    });
  }, []);

  const handleClose = (key: string, ageFailed?: boolean) => {
    if (key === 'tellUsMore') {
      setState({
        tellUsMore: false,
        ageRestriction: !!ageFailed,
        survey: !ageFailed,
        activationCode: false,
        allDone: false
      });
    } else if (key === 'survey') {
      setState({
        tellUsMore: false,
        ageRestriction: false,
        survey: false,
        activationCode: true,
        allDone: false
      });
    } else if (key === 'activationCode') {
      setState({
        tellUsMore: false,
        ageRestriction: false,
        survey: false,
        activationCode: false,
        allDone: true
      });
      sendMetadataToSop('ACTIVATION_CODE_POPUP_DISPLAYED', 'true');
    } else if (key === 'allDone') {
      setState({
        tellUsMore: false,
        ageRestriction: false,
        survey: false,
        activationCode: false,
        allDone: false
      });
      sendMetadataToSop('ALL_DONE_DISPLAYED', 'true');
      softProfileComplete?.(user);
    } else if (key === 'ageRestriction') {
      setState({
        tellUsMore: false,
        ageRestriction: false,
        survey: false,
        activationCode: false,
        allDone: false
      });
      setSoftProfileOpened?.(false);
      logout();
    }
  };

  const sendMetadataToSop = async (key: string, value: string) => {
    try {
      const payload = {
        [key]: value
      };
      const response = await sopClient.addMetadataToProfile(payload);
      const { data } = response;
      setUser({ ...data });
    } catch (error: any) {
      const {
        response: {
          data: { type }
        }
      } = error;
      if (type === SopStatusResponse.AUTHENTICATION_EXPIRED) {
        handleTokenExpiredAction();
      }
    }
  };

  const handleTokenExpiredAction = () => {
    setState({
      tellUsMore: false,
      ageRestriction: false,
      survey: false,
      activationCode: false,
      allDone: false
    });
    setForceAccountExistMode?.(true);
    navigate('/login', { state: { error: true } });
  };

  const logout = () => {
    Auth.logout('true');
    setUser(null);
    setIsLoggedIn(false);
    navigate?.('/');
  };

  return (
    <>
      <DialogWrapper
        key='tellUsMoreForm'
        open={state.tellUsMore}
        scrollable
        subtitle={t('tellUsMoreForm.getToKnowYou').toUpperCase()}
        title={t('tellUsMoreForm.tellUsMore')}
        gridModifications={TellUsMoreModifications}
      >
        <TellUsMoreForm
          handleTokenExpiredAction={handleTokenExpiredAction}
          closeDialog={handleClose}
        />
        <ProgressDots numberOfDots={3} activeIndex={0} />
      </DialogWrapper>
      <DialogWrapper
        key='ageRestrictionForm'
        open={state.ageRestriction}
        scrollable
        subtitle=''
        title=''
        gridModifications={TellUsMoreModifications}
      >
        <AgeRestrictionPopup handleClose={handleClose} />
      </DialogWrapper>
      <DialogWrapper
        scrollable
        key='surveyForm'
        open={state.survey}
        subtitle={t('tellUsMoreForm.getToKnowYou').toUpperCase()}
        gridModifications={TellUsMoreModifications}
        title={t('tellUsMoreForm.tellUsMore')}
      >
        <SoftProfileSurveyFormWrapper
          handleTokenExpiredAction={handleTokenExpiredAction}
          closeDialog={handleClose}
        />
        <ProgressDots numberOfDots={3} activeIndex={1} />
      </DialogWrapper>
      <DialogWrapper
        key='activationCode'
        title={t('activationCodePopup.title')}
        subtitle={t('activationCodePopup.subtitle')}
        scrollable
        open={state.activationCode}
        closeable={activationClosable}
        handleClose={() => handleClose('activationCode')}
      >
        <ActivationCodePopupWrapper
          status={status}
          handleTokenExpiredAction={handleTokenExpiredAction}
          setStatus={setStatus}
          handleCodeRedeemBlockedAction={() => setActivationCloseable(true)}
          closeDialog={() => handleClose('activationCode')}
        />
        <ProgressDots numberOfDots={3} activeIndex={2} />
      </DialogWrapper>
      <DialogWrapper
        scrollable
        key='allDone'
        open={state.allDone}
        subtitle={t('tellUsMoreForm.getReady')}
        title={t('tellUsMoreForm.done')}
        paperRootModifications={ScaleStyles}
      >
        <SoftProfileAllDone isMobile={isMobile()} closeDialog={handleClose} />
      </DialogWrapper>
    </>
  );
};
