import { FC } from 'react';
import { Box, Button, Grid, Typography, SxProps, Theme } from '@mui/material';
import {
  description,
  descriptionStyle,
  iconWrapper,
  item,
  titleStyle
} from './CheckAndShareResultsResource.styles';
import { Img } from '@barracuda/shared/src/components/Primitive';
import gettingReadyPageResultsClock from '@sharedImages/gettingReadyPage--results__clock.svg';
import gettingReadyPageResultsCalendar from '@sharedImages/gettingReadyPage--results__calendar.svg';
import gettingReadyPageResultsShare from '@sharedImages/gettingReadyPage--results__share.svg';
import {
  RegularTextColor,
  H3,
  SectionMarginBottom
} from '../GettingReadyPage/GettingReadyPage.styles';
import MyTopScoreImg from '@sharedImages/show-your-credly--image.png';
import CredlyImg from '@sharedImages/gettingReadyPage--checkAndShareResults__myTopScore.svg';
import { useTranslation } from 'react-i18next';
import { buttonOutlineDark } from '@barracuda/shared/src/styles/button';

const Item: FC<{
  readonly title: string;
  readonly subTitle?: string;
  readonly description?: string;
  readonly icon?: string | undefined;
}> = ({ title, subTitle, description, icon }) => (
  <Grid item xs={12} sm={6} md={4}>
    <Box sx={item}>
      {icon && (
        <Box sx={iconWrapper}>
          <Img src={icon} />
        </Box>
      )}
      <Typography sx={titleStyle}>{title}</Typography>
      {subTitle && <Typography>{subTitle}</Typography>}
      {description && <Typography sx={descriptionStyle}>{description}</Typography>}
    </Box>
  </Grid>
);

export const CheckAndShareResultsResource: FC<{
  readonly sx?: SxProps<Theme>;
  readonly id?: string;
}> = ({ sx, id }) => {
  const { t } = useTranslation();
  return (
    <Grid container id={id} sx={sx}>
      <Typography component='h2' sx={[H3, { marginBottom: '20px' }]}>
        {t('gettingReadyPage.resultsHeader')}
      </Typography>
      <Typography sx={[RegularTextColor, description]}>
        {t('gettingReadyPage.resultsText')}
      </Typography>

      <Grid container spacing='20px' sx={SectionMarginBottom}>
        <Item
          title={t('gettingReadyPage.resultsItem1Title')}
          subTitle={t('gettingReadyPage.resultsItem1Subtitle') as string}
          description={t('gettingReadyPage.resultsItem1Description') as string}
          icon={gettingReadyPageResultsClock}
        />
        <Item
          title={t('gettingReadyPage.resultsItem2Title')}
          subTitle={t('gettingReadyPage.resultsItem2Subtitle') as string}
          description={t('gettingReadyPage.resultsItem2Description') as string}
          icon={gettingReadyPageResultsCalendar}
        />
        <Item
          title={t('gettingReadyPage.resultsItem3Title')}
          subTitle={t('gettingReadyPage.resultsItem3Subtitle') as string}
          description={t('gettingReadyPage.resultsItem3Description') as string}
          icon={gettingReadyPageResultsShare}
        />
      </Grid>

      <Grid
        id='my-top-score'
        container
        rowSpacing={{ xs: '30px', lg: '75px' }}
        columnSpacing={{ md: '30px', lg: '100px' }}
        sx={{
          px: { md: '50px', lg: '100px' }
        }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
        >
          <Typography sx={{ fontSize: '24px', fontWeight: 600 }}>
            {t('gettingReadyPage.resultsTitle2')}
          </Typography>
          <Typography sx={{ mt: '15px' }}>{t('gettingReadyPage.resultsText2')}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Img sx={{ maxWidth: '100%' }} src={CredlyImg} />
        </Grid>
        <Grid item xs={12} sm={6} order={{ xs: 1, sm: 0 }}>
          <Img sx={{ maxWidth: '100%' }} src={MyTopScoreImg} />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
        >
          <Typography sx={{ fontSize: '24px', fontWeight: 600 }}>
            {t('gettingReadyPage.resultsTitle3')}
          </Typography>
          <Typography sx={{ mt: '15px' }}>{t('gettingReadyPage.resultsText3')}</Typography>
          <Button
            onClick={() => window.open('https://www.pearson.com/english/about-us/credly.html')}
            sx={[buttonOutlineDark, { mt: '25px' }]}
          >
            {t('gettingReadyPage.resultsButton')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
