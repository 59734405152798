import { FC } from 'react';
import { Box, Button, Typography } from '@mui/material';
import {
  beforeStartTestSignedInPopupButton,
  beforeStartTestSignedInPopupHeader
} from './BeforeStartTestSignedInPopup.styles';
import { useTranslation, Trans } from 'react-i18next';

interface BeforeStartTestSignedInPopupProps {
  handleClose: () => void;
  handleStartTest: () => void;
  closeOnly?: boolean;
}
export const BeforeStartTestSignedInPopup: FC<BeforeStartTestSignedInPopupProps> = ({
  handleClose
}) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography sx={beforeStartTestSignedInPopupHeader}>
        <Trans i18nKey='gettingReadyPage.formDownloadText' components={{ bold: <strong /> }} />
      </Typography>
      <Box sx={{ display: 'flex' }}>
        <Button onClick={handleClose} sx={beforeStartTestSignedInPopupButton}>
          {t('gettingReadyPage.formDownloadClose')}
        </Button>
      </Box>
    </Box>
  );
};
