import { theme } from '../../styles/theme';

export const MainBox = {
  background: theme.palette.common.lightGrey6,
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.25)',
  borderRadius: '8px',
  borderLeft: `${theme.palette.common.violet} solid 8px`,
  padding: '22px',
  '@media screen and (max-width: 1060px)': {
    paddingLeft: 0
  },
  '@media screen and (max-width: 875px)': {
    width: '380px'
  },
  '@media screen and (max-width: 420px)': {
    width: 'unset',
    paddingRight: 0
  }
};

export const MyTopScoreHeaderData = {
  fontSize: '25px',
  marginLeft: '40px',
  fontWeight: 700,
  color: theme.palette.common.darkBlue1,
  '> span': {
    fontWeight: 600,
    color: theme.palette.common.darkBlue7,
    fontSize: '25px'
  },
  '@media screen and (max-width: 1060px)': {
    marginLeft: '20px'
  }
};

export const MyTopScoreData = {
  fontSize: '16px',
  fontWeight: 400,
  color: theme.palette.common.darkGrey3,
  '> span': {
    fontWeight: 600,
    color: theme.palette.common.darkGrey2
  },
  '@media screen and (max-width: 1366px)': {
    marginLeft: '40px'
  },
  '@media screen and (max-width: 1060px)': {
    marginLeft: '20px'
  }
};

export const MyTopScoreUpper = {
  paddingBottom: '15px',
  '@media screen and (min-width: 1366px)': {
    display: 'none'
  }
};

export const MyTopScoreInside = {
  '@media screen and (max-width: 1366px)': {
    display: 'none'
  }
};

export const MainBoxFlex = {
  display: 'flex',
  alignItems: 'center',
  gap: '50px',
  '@media screen and (max-width: 1060px)': {
    gap: '30px'
  },
  '@media screen and (max-width: 930px)': {
    gap: '5px'
  },
  '@media screen and (max-width: 875px)': {
    flexFlow: 'column',
    alignItems: 'flex-start'
  }
};
