import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import {
  ActivationCodePopup,
  ActivationCodeStatus,
  sopClient,
  SopStatusResponse,
  sopUtils
} from '@barracuda/shared/src';

export interface ActivationCodePopupWrapperProps {
  closeDialog?: () => void;
  cancelButton?: boolean;
  handleCodeRedeemBlockedAction: () => void;
  setStatus: Dispatch<SetStateAction<ActivationCodeStatus>>;
  status: ActivationCodeStatus;
  checkRedeemBlocked?: boolean;
  handleTokenExpiredAction?: () => void;
}

const TEMPORARILY_BLOCKED = 'code.redemption.temporarily.blocked.for.tt';

export const ActivationCodePopupWrapper: FC<ActivationCodePopupWrapperProps> = ({
  closeDialog,
  cancelButton,
  handleCodeRedeemBlockedAction,
  status,
  setStatus,
  checkRedeemBlocked,
  handleTokenExpiredAction
}) => {
  const sopToken = sopUtils.getSopToken();
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setProperStatus();
  }, []);

  useEffect(() => {
    if (errorMessage === TEMPORARILY_BLOCKED) {
      setStatus('TOO_MANY_ATTEMPTS');
    }
  }, [errorMessage]);

  const setProperStatus = () => {
    if (checkRedeemBlocked) {
      sopClient.checkActivationCodeRedeemBlocked().then(
        (r) => {
          if (r.data) {
            handleCodeRedeemBlockedAction();
            setStatus('TOO_MANY_ATTEMPTS');
          } else {
            setStatus('DEFAULT');
          }
        },
        () => setStatus('DEFAULT')
      );
    } else {
      setStatus('DEFAULT');
    }
  };

  const redeemActivationCode = (activationCode: string) => {
    if (sopToken) {
      sopClient
        .redeemActivationCode(activationCode)
        .then(handleSuccessResponse, handleErrorResponse);
    }
  };
  const handleErrorResponse = (e: any) => {
    const {
      response: {
        data: { message, type }
      }
    } = e;

    setErrorMessage(message);

    switch (type) {
      case SopStatusResponse.VALIDATION_EXCEPTION: {
        setStatus('ALREADY_USED');
        break;
      }
      case SopStatusResponse.CODE_REDEEM_EXPIRED_EXCEPTION: {
        setStatus('EXPIRED');
        break;
      }
      case SopStatusResponse.CODE_REDEEM_BLOCKED: {
        setStatus('TOO_MANY_ATTEMPTS');
        handleCodeRedeemBlockedAction();
        break;
      }
      case SopStatusResponse.AUTHENTICATION_EXPIRED: {
        handleTokenExpiredAction?.();
        break;
      }
      default:
        setStatus('INVALID');
    }
  };
  const handleSuccessResponse = () => {
    setStatus('VALID');
    setErrorMessage('validMessage');
  };

  return (
    <ActivationCodePopup
      errorMessage={errorMessage}
      closeDialog={closeDialog}
      onSubmit={redeemActivationCode}
      status={status}
      setStatus={setStatus}
      cancelButton={cancelButton}
    />
  );
};
