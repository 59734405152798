import { createContext, Dispatch, SetStateAction } from 'react';
import { User } from '@barracuda/shared/src';
import { Cart } from '@pearson-ell/commerce-sdk';
import { Language } from '@barracuda/shared/src/interface/language.interface';
import { ResultsSurveyVariant } from '../components/ResultsSurvey/ResultsSurvey';

export const AppContext = createContext<{
  isLoggedIn?: boolean;
  setIsLoggedIn?: any;
  user?: User;
  setUser?: any;
  error?: { open: boolean; message?: string };
  setError?: any;
  loading?: boolean;
  setLoading?: Dispatch<SetStateAction<boolean>>;
  cart?: Cart;
  setCart?: any;
  emailPreviewData?: {
    company?: string;
    email?: string;
  };
  setEmailPreviewData?: any;
  testTaken?: boolean;
  setTestTaken?: any;
  eCommerceCountryISO2?: string;
  setECommerceCountryISO2?: any;
  language?: Language;
  setLanguage?: Dispatch<SetStateAction<Language>>;
  isDrawerOpen?: boolean;
  setDrawerOpen?: Dispatch<SetStateAction<boolean>>;
  availableTests?: number;
  setAvailableTests?: Dispatch<SetStateAction<number>>;
  resultsSurveyOpen?: boolean;
  setResultsSurveyOpen?: Dispatch<SetStateAction<boolean>>;
  resultsSurveyVariant?: string;
  setResultsSurveyVariant?: Dispatch<SetStateAction<ResultsSurveyVariant | undefined>>;
  resultsSurveyLicenseId?: string;
  setResultsSurveyLicenseId?: Dispatch<SetStateAction<string | undefined>>;
  ipCountry?: string;
}>({});
