import { ScalableWrapper } from '@barracuda/shared/src';
import { Stack } from '@mui/material';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { ScoreLookupView } from '../ScoreLookupView/ScoreLookupView';
import { Outlet, Route, Routes, useLocation, useParams } from 'react-router-dom';
import { ScoreReportView } from '../ScoreReportView/ScoreReportView';
import { GettingReadyPageFooter } from '../GettingReadyPageFooter/GettingReadyPageFooter';
import { useTranslation } from 'react-i18next';
import { useLocalizedNavigate } from 'src/hooks/useLocalizedNavigate';
import { ScoreReportMode } from '../ScoreReportView/ScoreReportViewSkillScoresSection';

interface AdminPageWrapperProps {
  setCode: Dispatch<SetStateAction<string | undefined>>;
}
export const AdminPageWrapper: FC<AdminPageWrapperProps> = ({ setCode }) => {
  const { code } = useParams();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [mode, _setMode] = useState<ScoreReportMode>(
    pathname.includes('shares-mts') ? 'mts' : 'two-skills'
  );
  const navigate = useLocalizedNavigate();

  const footerLinks = [
    { label: t('footerLinks.termsOfUse'), to: 'terms-of-use' },
    {
      label: t('footerLinks.privacy'),
      to: process.env.REACT_APP_PRIVACY_POLICY_LINK as string,
      external: true
    },
    {
      label: t('footerLinks.cookies'),
      to: process.env.REACT_APP_COOKIE_POLICY_LINK as string,
      external: true
    }
  ];
  const handleNavigate = (to: string) => {
    const link = `/${to}`;
    navigate(link);
  };

  useEffect(() => {
    setCode(code);
  }, [code]);

  return (
    <>
      <Stack>
        <ScalableWrapper>
          <Routes>
            <Route path='/' element={<ScoreLookupView mode={mode} code={code} />} />
            <Route path='/report' element={<ScoreReportView mode={mode} code={code} />} />
          </Routes>
          <Outlet />
          <GettingReadyPageFooter navigate={handleNavigate} footerLinks={footerLinks} />
        </ScalableWrapper>
      </Stack>
    </>
  );
};
