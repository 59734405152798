import { theme } from '../../styles/theme';

export const container = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: theme.palette.common.darkBlue7,
  p: { xs: '30px', md: '60px' }
};

export const header = {
  fontSize: '30px',
  fontWeight: 700,
  color: theme.palette.common.white
};

export const text = {
  fontSize: '20px',
  marginTop: '20px',
  color: theme.palette.common.white
};

export const button = {
  mt: '45px'
};
