import { FC, useContext, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { PrivacyPolicyContent, ScalableWrapper } from '@barracuda/shared/src';
import { useLocation } from 'react-router-dom';
import { AppContext } from '../../context/App.context';
import { useTranslation } from 'react-i18next';
import { theme } from '@theme';
import {
  PrivacyPolicyHeaderWeb,
  PrivacyPolicyHeaderWebTitle
} from '../TermsOfUse/TermsOfUse.styles';

export const PrivacyPolicy: FC = () => {
  const { t, i18n } = useTranslation();
  const { isLoggedIn } = useContext(AppContext);
  const location = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0
    });
  }, [location]);

  return (
    <ScalableWrapper isLoggedIn={isLoggedIn}>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={[PrivacyPolicyHeaderWeb, { paddingTop: isLoggedIn ? '100px' : '190px' }]}
        >
          <Typography sx={PrivacyPolicyHeaderWebTitle} component='h1'>
            {t('privacyPolicy.header')}
          </Typography>
          <Typography>{t('privacyPolicy.subheader')}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={[
            PrivacyPolicyHeaderWeb,
            { background: theme.palette.common.white, paddingTop: '100px' }
          ]}
        >
          <PrivacyPolicyContent language={i18n.language} t={t} />
        </Grid>
      </Grid>
    </ScalableWrapper>
  );
};
