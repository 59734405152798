import { Box, Button, Grid, Typography, useMediaQuery } from '@mui/material';
import Img from '../Primitive/Img';
import { FC } from 'react';
import {
  ImgSizeStyle,
  typeOfTestContainer,
  typeOfTestContainerInner,
  typeOfTestPrice,
  typeOfTestTags,
  typeOfTestTextContainer,
  typeOfTestTime,
  typeOfTestTitle
} from './TypeOfTest.styles';
import timerSmall from '../../assets/images/timer-small--icon.svg';
import dot from '../../assets/images/dot--icon.svg';
import infoTag from '../../assets/images/info-tag--icon.svg';
import { useTranslation } from 'react-i18next';
import { buttonDark, buttonOutlineDark } from '../../styles/button';
import { SkeletonWrapper } from '../SkeletonWrapper/SkeletonWrapper';

type TypeOfTestProps = {
  image: string;
  title: string;
  description: string;
  time: number | string;
  price: string;
  vertical?: boolean;
  tags: string[];
  handleBuyNow: (pId: string) => void;
  handleStartPractice: () => void;
  productId: string;
  popular?: boolean;
  condition?: boolean;
};

export const TypeOfTest: FC<TypeOfTestProps> = ({
  image,
  title,
  description,
  time,
  price,
  popular,
  vertical,
  tags,
  productId,
  handleBuyNow,
  handleStartPractice,
  condition
}) => {
  const { t } = useTranslation();
  const tabletBreakpoint: boolean = useMediaQuery('(min-width: 750px)');

  return (
    <Grid
      container
      sx={[typeOfTestContainer(!!vertical), { maxWidth: vertical ? '420px' : 'unset' }]}
    >
      <Grid
        item
        sm={vertical || !tabletBreakpoint ? 12 : 5}
        md={vertical ? 12 : 5}
        sx={{
          position: 'relative'
        }}
      >
        <SkeletonWrapper
          condition={!!condition}
          style={ImgSizeStyle(!!vertical)}
          variant='rectangular'
        >
          {popular && (
            <Img
              src={infoTag}
              sx={{
                position: 'absolute',
                right: '20px',
                top: '20px'
              }}
            />
          )}
          <Img src={image} sx={ImgSizeStyle(!!vertical)} />
        </SkeletonWrapper>
      </Grid>
      <Grid item sm={vertical || !tabletBreakpoint ? 12 : 7} md={vertical ? 12 : 7}>
        <Box
          sx={{
            ...typeOfTestContainerInner(vertical || false),
            ...(!tabletBreakpoint && { padding: '1rem 0rem' })
          }}
        >
          <SkeletonWrapper condition={!!condition} rows={7}>
            <Box sx={typeOfTestTextContainer}>
              <Box>
                <Typography
                  component='h5'
                  sx={[typeOfTestTitle, { fontSize: vertical ? '17px' : '20px' }]}
                >
                  {title}
                </Typography>
                <Typography sx={typeOfTestTags}>
                  {tags.map((tag: string, index: number) => (
                    <Box key={`${tag}${index}`} sx={typeOfTestTags}>
                      {tag}
                      {index + 1 !== tags.length && <Img sx={{ margin: '0 .5rem' }} src={dot} />}
                    </Box>
                  ))}
                </Typography>
              </Box>
              {!vertical && (
                <Typography component='h5' sx={typeOfTestTime}>
                  <Img src={timerSmall} />
                  {time} {t('exploreTestsPage.min')}
                </Typography>
              )}
            </Box>
            <Box>
              {vertical && (
                <Typography
                  component='h5'
                  sx={[typeOfTestTime, { fontSize: vertical ? '12px' : '20px' }]}
                >
                  <Img src={timerSmall} sx={{ width: vertical ? '14px' : '18px' }} />
                  {time} {t('exploreTestsPage.min')}
                </Typography>
              )}
              <Typography
                dangerouslySetInnerHTML={{
                  __html: description
                }}
              />
              <Typography sx={typeOfTestPrice}>{price}</Typography>
            </Box>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                gap: '20px'
              }}
            >
              <Button sx={buttonDark} onClick={() => handleBuyNow(productId)}>
                {t('exploreTestsPage.buyNow')}
              </Button>
              <Button onClick={() => handleStartPractice()} sx={buttonOutlineDark}>
                {t('exploreTestsPage.practiceForFree')}
              </Button>
            </Box>
          </SkeletonWrapper>
        </Box>
      </Grid>
    </Grid>
  );
};
