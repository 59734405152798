import { theme } from '../../styles/theme';

export const OrderHistoryAccordionTableContainer = {
  background: theme.palette.common.white,
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.25)',
  marginTop: '30px',
  cursor: 'pointer'
};

export const OrderHistoryAccordionTableTheadCell = {
  fontWeight: 600,
  textAlign: 'left',
  color: `${theme.palette.common.lightGrey6} !important`,
  backgroundColor: theme.palette.common.violet,
  ':first-child': {
    borderTopLeftRadius: '8px'
  },
  ':last-child': {
    borderTopRightRadius: '8px'
  }
};

export const FlexCenter = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

export const OrderHistoryAccordionContent = {
  borderBottomLeftRadius: '8px',
  borderBottomRightRadius: '8px',
  fontSize: '16px',
  letterSpacing: '0em',
  fontWeight: 400
};
export const OrderHistoryAccordionContentName = {
  fontWeight: 600,
  lineHeight: '22px'
};
