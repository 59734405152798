import { FC, useContext, useEffect, useMemo, useState } from 'react';
import {
  AvailableProduct,
  CallToActionTop,
  countryISO3ToISO2,
  DialogWrapper,
  ELLCommerce,
  FooterGetInTouch,
  LimitReachedModal,
  PracticeDownloadAppPopup,
  RequirementsVerticalRow,
  ScalableWrapper,
  sopClient,
  TypeOfTest
} from '@barracuda/shared/src';
import { AppContext } from '../../context/App.context';
import { GettingReadyPageFooter } from '../GettingReadyPageFooter/GettingReadyPageFooter';
import { Box, Button, Grid, Typography, useMediaQuery } from '@mui/material';
import gettingReady from '../../assets/images/exploreTests-getting-ready.svg';
import governmentId from '../../assets/images/government-id--icon.svg';
import wellItSpace from '../../assets/images/well-it-space--icon.svg';
import spareTime from '../../assets/images/spare-time--icon.svg';
import personIcon from '../../assets/images/person--icon.svg';
import reliableInternet from '../../assets/images/reliable-internet--icon.svg';
import singleMonitor from '../../assets/images/single-monitor--icon.svg';
import computerRequirements from '../../assets/images/computer-requirements--icon.svg';
import speakerMicrophone from '../../assets/images/speaker-microphone--icon.svg';
import frontFacingCamera from '../../assets/images/front-facint-camera--icon.svg';
import test4Skills from '../../assets/images/exploreTests--4-skills.png';
import testReadingAndWriting from '../../assets/images/exploreTestes--reading-and-writing.png';
import testSpeakingAndListening from '../../assets/images/exploreTests-speaking-and-listening.png';
import {
  exploreTestsExploreResources,
  exploreTestsWrapperCallToActionTop,
  exploreTestsWrapperCheckYourTestAndRequirements,
  exploreTestsWrapperChooseTestContainer,
  exploreTestsWrapperChooseTestDesc,
  exploreTestsWrapperChooseTestTitle,
  exploreTestsWrapperHorizontalLine,
  exploreTestsWrapperHorizontalLineContainer,
  exploreTestsWrapperHorizontalLineText,
  exploreTestsWrapperJustifyCenter,
  exploreTestsWrapperSidePadding,
  exploreTestsWrapperTypeOfTests,
  exploreTestsWrapperTypeOfTestsHeader,
  sectionWrapper,
  SmallTestsAlignEnd,
  SmallTestsAlignStart
} from './ExploreTestsWrapper.styles';
import { useAddCartItem } from './useAddCartItem';
import { Trans, useTranslation } from 'react-i18next';
import { CurrencySymbolPosition, Product } from '@pearson-ell/commerce-sdk';
import { buttonDark } from '@barracuda/shared/src/styles/button';
import { useLaunchTest } from '../../hooks/useLaunchTest';
import { useLocalizedNavigate } from 'src/hooks/useLocalizedNavigate';
import { theme } from '@theme';
import { ellCommerceInstanceConfig } from '../../utils/ell-commerce';

export const ExploreTestsWrapper: FC = () => {
  const { isLoggedIn, eCommerceCountryISO2 } = useContext(AppContext);
  const [userCountry, setUserCountry] = useState<string>();
  const [eCommerceProducts, setECommerceProducts] = useState<Product[]>();
  const [sopProducts, setSopProducts] = useState<AvailableProduct[]>();
  const xsWindow = useMediaQuery('(max-width: 900px)');
  const navigate = useLocalizedNavigate();
  const { t, i18n } = useTranslation();
  const [beforeStartOpen, setBeforeStartOpen] = useState(false);
  const [beforeStartOpenMobile, setBeforeStartOpenMobile] = useState(false);

  const imgReplacement: boolean = useMediaQuery('(min-width: 750px) and (max-width: 1024px)');
  const [launchTest, limitReached, setLimitReached, message] = useLaunchTest(
    setBeforeStartOpenMobile,
    setBeforeStartOpen,
    true
  );

  const footerLinks = [
    { label: t('footerLinks.termsOfUse'), to: 'terms-of-use' },
    {
      label: t('footerLinks.privacy'),
      to: process.env.REACT_APP_PRIVACY_POLICY_LINK as string,
      external: true
    },
    {
      label: t('footerLinks.cookies'),
      to: process.env.REACT_APP_COOKIE_POLICY_LINK as string,
      external: true
    }
  ];
  const productsSKU = {
    FOUR_SKILLS: sopProducts?.find((i) => i.code === 'BCUDA_4S')?.essId || '',
    READING_WRITING: sopProducts?.find((i) => i.code === 'BCUDA_2S_RW')?.essId || '',
    SPEAKING_LISTENING: sopProducts?.find((i) => i.code === 'BCUDA_2S_SL')?.essId || ''
  };

  const handleNavigate = (url: string) => {
    const link = isLoggedIn ? `/dashboard/${url}` : `/${url}`;
    navigate(link);
  };

  const addCartItem = useAddCartItem();
  const handleStartPractice = () => {
    if (isLoggedIn) {
      launchTest();
    } else {
      navigate('../login');
    }
  };

  useEffect(() => {
    sopClient.getUserIPCountry().then((response) => setUserCountry(countryISO3ToISO2(response)));
  }, []);

  useEffect(() => {
    fetchSopProducts();
  }, []);

  const fetchSopProducts = async () => {
    const response = await sopClient.getAvailableProductsUnauthorized(
      process.env.REACT_APP_PESC_ECOMMERCE_APPLICATION_ID?.toUpperCase()
    );
    const { data } = response;
    if (data) {
      setSopProducts(data.results);
    }
  };

  useEffect(() => {
    const eCommerce = ELLCommerce.getInstance({
      ...ellCommerceInstanceConfig,
      getTokenFunction: () => '',
      defaultCountryISO2: isLoggedIn ? eCommerceCountryISO2 || 'US' : userCountry || 'US'
    });
    eCommerce
      .ProductService()
      .getProductsUnauthenticated(
        '',
        isLoggedIn ? eCommerceCountryISO2 || 'US' : userCountry || 'US'
      )
      .then((r) => {
        if (r) setECommerceProducts(r);
      });
  }, [userCountry, eCommerceCountryISO2, isLoggedIn, i18n.language]);

  const getProductPrice = (id: string, products: Product[]) => {
    const product = products.find((i) => i.sku === id);
    if (product?.currency.symbolPosition === ('left' as unknown as CurrencySymbolPosition)) {
      return `${product?.currency.symbol}${product?.price}`;
    } else {
      return `${product?.price}${product?.currency.symbol}`;
    }
  };

  const getProductName = (id: string, products: Product[]) => {
    const product = products.find((i) => i.sku === id);
    return product?.name || '';
  };

  const getProductDescription = (id: string, products: Product[]) => {
    const product = products.find((i) => i.sku === id);
    return product?.description || '';
  };
  const getProductImage = (id: string, products: Product[]) => {
    const product = products.find((i) => i.sku === id);
    return (
      product?.images.find((img) =>
        imgReplacement && productsSKU.FOUR_SKILLS === id ? img.isThumbNail : !img.isThumbNail
      )?.imageUrl || ''
    );
  };

  const testTypes = useMemo(() => {
    const _image = eCommerceProducts && getProductImage(productsSKU.FOUR_SKILLS, eCommerceProducts);
    return {
      image: _image?.length ? _image : test4Skills,
      title: eCommerceProducts ? getProductName(productsSKU.FOUR_SKILLS, eCommerceProducts) : '',
      time: 60,
      popular: true,
      price: eCommerceProducts ? getProductPrice(productsSKU.FOUR_SKILLS, eCommerceProducts) : '',
      tags: t('exploreTestsPage.tagsAll', { returnObjects: true }) as string[],
      description: eCommerceProducts
        ? getProductDescription(productsSKU.FOUR_SKILLS, eCommerceProducts)
        : '',
      productId: productsSKU.FOUR_SKILLS
    };
  }, [eCommerceProducts, i18n.language]);

  const testTypesSmall = useMemo(() => {
    return [
      {
        image: eCommerceProducts
          ? getProductImage(productsSKU.SPEAKING_LISTENING, eCommerceProducts)
          : testSpeakingAndListening,
        title: eCommerceProducts
          ? getProductName(productsSKU.SPEAKING_LISTENING, eCommerceProducts)
          : '',
        time: 30,
        price: eCommerceProducts
          ? getProductPrice(productsSKU.SPEAKING_LISTENING, eCommerceProducts)
          : '',
        tags: t('exploreTestsPage.tagsListeningSpeaking', { returnObjects: true }) as string[],
        description: eCommerceProducts
          ? getProductDescription(productsSKU.SPEAKING_LISTENING, eCommerceProducts)
          : '',
        productId: productsSKU.SPEAKING_LISTENING
      },
      {
        image: eCommerceProducts
          ? getProductImage(productsSKU.READING_WRITING, eCommerceProducts)
          : testReadingAndWriting,
        title: eCommerceProducts
          ? getProductName(productsSKU.READING_WRITING, eCommerceProducts)
          : '',
        time: 30,
        price: eCommerceProducts
          ? getProductPrice(productsSKU.READING_WRITING, eCommerceProducts)
          : '',
        tags: t('exploreTestsPage.tagsReadingSpeaking', { returnObjects: true }) as string[],
        description: eCommerceProducts
          ? getProductDescription(productsSKU.READING_WRITING, eCommerceProducts)
          : '',
        productId: productsSKU.READING_WRITING
      }
    ];
  }, [eCommerceProducts]);

  const propsCallToActionExploreResources = {
    textI18nKey: 'exploreTestsPage.exploreResources',
    describeI18nKey: 'exploreTestsPage.watchOurWalkthroughs',
    image: gettingReady,
    smallTitle: true
  };

  const requirements = [
    {
      title: t('exploreTestsPage.requirements.system'),
      bottomText: t('exploreTestsPage.requirements.checkYourSetUp'),
      req: [
        {
          title: t('exploreTestsPage.requirements.windowOrMacComp'),
          image: computerRequirements
        },
        {
          title: t('exploreTestsPage.requirements.speakerOrMicrophone'),
          image: speakerMicrophone
        },
        {
          title: t('exploreTestsPage.requirements.reliableInternet'),
          image: reliableInternet
        }
      ]
    },
    {
      title: t('exploreTestsPage.requirements.security'),
      req: [
        {
          title: t('exploreTestsPage.requirements.governmentId'),
          image: governmentId
        },

        {
          title: t('exploreTestsPage.requirements.singleMonitor'),
          image: singleMonitor
        },
        {
          title: t('exploreTestsPage.requirements.frontFacingCamera'),
          image: frontFacingCamera
        }
      ]
    },
    {
      title: t('exploreTestsPage.requirements.environment'),
      req: [
        {
          title: t('exploreTestsPage.requirements.sixtyMinutes'),
          image: spareTime
        },
        {
          title: t('exploreTestsPage.requirements.quietWellLit'),
          image: wellItSpace
        },

        {
          title: t('exploreTestsPage.requirements.privateSpaceWith'),
          image: personIcon
        }
      ]
    }
  ];

  const tabletBreakpoint: boolean = useMediaQuery('(max-width: 750px)');

  return (
    <ScalableWrapper isLoggedIn={isLoggedIn}>
      <Grid
        container
        sx={{
          ...exploreTestsWrapperCallToActionTop,
          ...(xsWindow && { ...exploreTestsWrapperSidePadding })
        }}
      >
        <Grid xs={12} md={10} item>
          <Grid container sx={exploreTestsWrapperJustifyCenter}>
            <Grid xs={12} md={6} item>
              <Box sx={exploreTestsWrapperChooseTestContainer}>
                <Typography component='h1' sx={exploreTestsWrapperChooseTestTitle}>
                  {t('exploreTestsPage.chooseYourTest')}
                </Typography>
                <Typography component='h5' sx={exploreTestsWrapperChooseTestDesc}>
                  <Trans
                    i18nKey='exploreTestsPage.ourEnglishSkillsCertificate'
                    components={{
                      bold: <Typography component='span' sx={{ fontWeight: 600 }} />
                    }}
                    values={{ productName: process.env.REACT_APP_GENERAL_PRODUCT_NAME }}
                  />
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          ...exploreTestsWrapperJustifyCenter,
          ...(xsWindow && { ...exploreTestsWrapperSidePadding })
        }}
      >
        <Grid item xs={12} md={10} sx={exploreTestsWrapperTypeOfTests}>
          <Typography component='h6' sx={exploreTestsWrapperTypeOfTestsHeader}>
            {t('exploreTestsPage.exploreTestsWrapperTypeOfTestsHeader')}
          </Typography>
          <Grid container sx={{ mt: 4 }}>
            <Grid xs={12} item>
              <TypeOfTest
                {...testTypes}
                handleStartPractice={handleStartPractice}
                handleBuyNow={addCartItem}
                condition={!!eCommerceProducts?.length}
              />
            </Grid>
            <Grid xs={12} item>
              <Box sx={exploreTestsWrapperHorizontalLineContainer}>
                <Box sx={exploreTestsWrapperHorizontalLine} />
                <Box sx={exploreTestsWrapperHorizontalLineText}>
                  {t('exploreTestsPage.twoSkillsTests')}
                </Box>
                <Box sx={exploreTestsWrapperHorizontalLine} />
              </Box>
            </Grid>
            <Grid container spacing={5} sx={exploreTestsWrapperJustifyCenter}>
              {testTypesSmall.map((testType, index) => (
                <Grid
                  sx={index === 0 ? SmallTestsAlignEnd : SmallTestsAlignStart}
                  item
                  xs={tabletBreakpoint ? 12 : 6}
                  md={6}
                  key={`test-${index}`}
                >
                  <TypeOfTest
                    {...testType}
                    handleStartPractice={handleStartPractice}
                    vertical
                    handleBuyNow={addCartItem}
                    condition={!!eCommerceProducts?.length}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          ...exploreTestsExploreResources,
          ...(xsWindow && { ...exploreTestsWrapperSidePadding })
        }}
      >
        <Grid xs={12} md={10} item sx={sectionWrapper}>
          <CallToActionTop {...propsCallToActionExploreResources}>
            <Box
              onClick={() => navigate('../getting-ready')}
              sx={{
                display: 'flex',
                ...(xsWindow && { marginBottom: '2rem' })
              }}
            >
              <Button sx={buttonDark}>{t('exploreTestsPage.exploreResourcesButton')}</Button>
            </Box>
          </CallToActionTop>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          ...exploreTestsWrapperCheckYourTestAndRequirements,
          ...(xsWindow && { ...exploreTestsWrapperSidePadding })
        }}
      >
        <Grid xs={12} md={10} item sx={sectionWrapper}>
          <RequirementsVerticalRow
            requirementsRows={requirements}
            title={t('exploreTestsPage.checkYourTest')}
            subtitle={t('exploreTestsPage.forSuccessfulTest')}
            tag={t('exploreTestsPage.takeTest')}
          />
        </Grid>
      </Grid>

      <FooterGetInTouch />
      <GettingReadyPageFooter navigate={handleNavigate} footerLinks={footerLinks} />
      <DialogWrapper
        key='beforeStartDownload'
        gridModifications={{ width: '980px' }}
        open={beforeStartOpen}
        handleClose={() => setBeforeStartOpen(false)}
        scrollable
        subtitle={t('dashboardPage.practiceSubtitle')}
        title={t('dashboardPage.practiceTitle')}
        subtitleColor={theme.palette.common.violet}
        closeable
      >
        <PracticeDownloadAppPopup
          MSI_URL={process.env.REACT_APP_PESC_DOWNLOAD_MSI_PATH}
          MAC_URL={process.env.REACT_APP_PESC_DOWNLOAD_DMG_MAC_PATH}
          closeDialog={() => setBeforeStartOpen(false)}
          practice
        />
      </DialogWrapper>
      <DialogWrapper
        gridModifications={{ maxWidth: '980px', width: 'unset' }}
        key='beforeStartDownloadMobile'
        open={beforeStartOpenMobile}
        handleClose={() => setBeforeStartOpenMobile(false)}
        scrollable
        subtitle={t('dashboardPage.practiceSubtitle')}
        title={t('dashboardPage.practiceTitle')}
      >
        <PracticeDownloadAppPopup
          mobile
          MSI_URL={process.env.REACT_APP_PESC_DOWNLOAD_MSI_PATH}
          MAC_URL={process.env.REACT_APP_PESC_DOWNLOAD_DMG_MAC_PATH}
          closeDialog={() => setBeforeStartOpenMobile(false)}
          practice
        />
      </DialogWrapper>
      <LimitReachedModal
        open={limitReached}
        handleClose={() => setLimitReached(false)}
        message={message}
      />
    </ScalableWrapper>
  );
};
