import { theme } from '../../styles/theme';

export const BadgeContainer = {
  padding: '40px',
  backgroundColor: theme.palette.common.lightGrey4,
  borderRadius: '8px',
  border: `1px solid ${theme.palette.common.lightGrey3}`,
  height: '100%',
  '@media screen and (max-width: 1270px)': {
    padding: '30px'
  },
  '@media screen and (max-width: 880px)': {
    height: 'unset',
    padding: '20px'
  }
};

export const BadgeTitle = {
  fontWeight: 700,
  fontSize: '25px',
  color: theme.palette.common.darkGrey2,
  paddingBottom: '25px'
};

export const CheckRow = {
  display: 'flex',
  alignItems: 'center',
  paddingBottom: '10px',
  gap: '10px'
};

export const PracticeButton = {
  backgroundColor: theme.palette.common.white,
  width: '215px',
  color: theme.palette.common.black,
  fontWeight: 600,
  height: '32px',
  fontSize: '14px',
  textTransform: 'none',
  marginTop: '25px',
  border: `2px solid ${theme.palette.common.darkGrey2}`,
  borderRadius: '40px',
  minWidth: 'fit-content',
  '&:hover': {
    backgroundColor: theme.palette.common.black,
    border: `1px solid ${theme.palette.common.white}`,
    color: theme.palette.common.white
  }
};

export const FlexCenter = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%'
};

export const CredlyDescriptionCenter = {
  marginTop: '20px',
  textAlign: 'center'
};

export const InsideStack = {
  flexFlow: 'column',
  '@media screen and (min-width: 428px) and (max-width: 1024px)': {
    flexFlow: 'row'
  }
};

export const StackNextToBadge = {
  marginTop: '20px',
  paddingBottom: '20px'
};

export const RedeemedStack = {
  marginTop: '20px',
  '@media screen and (min-width: 428px) and (max-width: 1024px)': {
    marginLeft: '30px'
  }
};
