import { Box, Skeleton, Typography } from '@mui/material';
import { CSSProperties, ReactNode } from 'react';

type SkeletonWrapperProps = {
  readonly condition: boolean;
  readonly children: ReactNode;
  readonly style?: CSSProperties;
  readonly rows?: number;
  readonly variant?: 'text' | 'rectangular' | 'rounded' | 'circular';
};
export const SkeletonWrapper = ({
  condition,
  children,
  style,
  variant,
  rows
}: SkeletonWrapperProps) => {
  const rowItems = Array(rows).fill(rows);
  const standardStyle = {
    width: '100%',
    height: '100%'
  };
  return (
    <Box sx={standardStyle}>
      {condition ? (
        children
      ) : (
        <Box sx={standardStyle}>
          {rows ? (
            <Box sx={standardStyle}>
              {rowItems.map((_, index) => (
                <Typography key={index} component='div' variant='h4'>
                  <Skeleton animation='wave' />
                </Typography>
              ))}
            </Box>
          ) : (
            <Skeleton animation='wave' width='100%' style={style} variant={variant || 'text'} />
          )}
        </Box>
      )}
    </Box>
  );
};
