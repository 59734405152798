import { FC, useContext, useEffect, useState } from 'react';
import { DialogWrapper, sopClient, WelcomePopup } from '@barracuda/shared/src';
import { AppContext } from '../../context/App.context';
import { SoftProfile } from '../SoftProfile/SoftProfile';
import { DashboardAccordionWrappers } from '../DashboardAccordionsWrapper/DashboardAccordionWrappers';
import { useTranslation } from 'react-i18next';

export const DashboardHome: FC<{ needsKoConsents: boolean }> = ({ needsKoConsents }) => {
  const { user, setUser, setResultsSurveyVariant, setResultsSurveyOpen, availableTests } =
    useContext(AppContext);
  const [opened, setOpened] = useState(!user?.profile?.WELCOME_DISPLAYED);
  const { t } = useTranslation();

  const surveyNotCompleted =
    user &&
    (!user.preferredName ||
      !user.countryOfResidence ||
      !user.cityOfResidence ||
      !user.profile.SURVEY_WHICH_ONE_BEST_DESCRIBES_YOU ||
      !user.profile.ACTIVATION_CODE_POPUP_DISPLAYED ||
      !user.profile.ALL_DONE_DISPLAYED);

  const handleClose = (value: string) => {
    if (value === 'welcome') {
      const payload = {
        WELCOME_DISPLAYED: 'true'
      };
      sopClient.addMetadataToProfile(payload).then((response) => {
        const { data } = response;
        setUser(data);
      });
    }
    setOpened(false);
  };

  useEffect(() => {
    if (
      availableTests &&
      availableTests >= 1 &&
      user?.profile.RESULTS_DETAILS_CERTIFIED_TEST_USER_FIRST_PURCHASE === 'true'
    ) {
      setResultsSurveyVariant?.('firstPurchase');
      setResultsSurveyOpen?.(true);
    }
  }, [user]);

  return (
    <>
      <DashboardAccordionWrappers />
      {!user?.profile?.WELCOME_DISPLAYED && !surveyNotCompleted && !needsKoConsents && (
        <DialogWrapper
          key='welcomePopup'
          handleClose={handleClose}
          open={opened}
          scrollable
          subtitle={t('welcomePopup.subtitle')}
          title={t('dashboardPage.welcomeDialogPopupTitle', {
            productName: process.env.REACT_APP_GENERAL_PRODUCT_NAME
          })}
        >
          <WelcomePopup handleOnDone={handleClose} />
        </DialogWrapper>
      )}
      {surveyNotCompleted && <SoftProfile user={user} />}
    </>
  );
};
