import { FC, useContext } from 'react';
import { Auth, sopUtils } from '@barracuda/shared/src';
import { AppContext } from '../../context/App.context';
import { Box, SxProps, Theme } from '@mui/material';
import { useLocalizedNavigate } from 'src/hooks/useLocalizedNavigate';
import { ELLCommerce } from '@pearson-ell/commerce-sdk';
import { ellCommerceInstanceConfig } from '../../utils/ell-commerce';

export const Logout: FC<{ children: JSX.Element | JSX.Element[]; sx?: SxProps<Theme> }> = ({
  children,
  sx = {}
}) => {
  const { user, setUser, setIsLoggedIn, eCommerceCountryISO2 } = useContext(AppContext);
  const navigate = useLocalizedNavigate();
  const eCommerce = ELLCommerce.getInstance({
    ...ellCommerceInstanceConfig,
    defaultCountryISO2: eCommerceCountryISO2 || 'US',
    getTokenFunction: () => `Bearer ${sopUtils.getSopToken()}`
  });

  const handleLogout = () => {
    Auth.logout(user?.profile?.loggedBefore);
    eCommerce
      .CustomerService()
      .postLogoutEvent(user?.externalId || '', user?.countryOfResidence?.isoCode);
    setUser(null);
    setIsLoggedIn(false);
    navigate('/login');
  };

  return (
    <Box component='span' sx={{ ...sx, cursor: 'pointer' }} onClick={handleLogout}>
      {children}
    </Box>
  );
};
