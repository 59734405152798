import { FC, useContext, useState } from 'react';
import { Box, Button, Checkbox, FormControlLabel, Link, Stack, Tooltip } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useLocalizedNavigate } from '../../hooks/useLocalizedNavigate';
import {
  managePreferencesCheckboxHeader,
  managePreferencesConsentCheckboxStyle,
  managePreferencesConsentLabel,
  managePreferencesContainer,
  managePreferencesForwardIcon,
  managePreferencesNavigation,
  managePreferencesNavigationMainLink,
  managePreferencesWhiteBox,
  managePreferencesWhiteBoxHeader
} from './ManagedPreferences.style';
import { buttonDark } from '@barracuda/shared/src/styles/button';
import { AppContext } from '../../context/App.context';
import { sopClient } from '@barracuda/shared/src';
import { whiteTooltip } from '../../../../shared/src/styles/tooltip';
import Img from '../../../../shared/src/components/Primitive/Img';
import InfoIcon from '../../../../shared/src/assets/images/info-icon-gray.svg';

export const ManagePreferences: FC = () => {
  const { t } = useTranslation();
  const navigate = useLocalizedNavigate();
  const { user, setUser } = useContext(AppContext);

  const [emailReceiveConsent, setEmailReceiveConsent] = useState(
    user?.profile['EMAIL_RECEIVE_CONSENT'] === 'true'
  );
  const [personalDataCollectingConsent, setPersonalDataCollectingConsent] = useState(
    user?.profile['marketingEmailConsent'] === 'true'
  );

  const savePreferences = async () => {
    const payload = {
      EMAIL_RECEIVE_CONSENT: emailReceiveConsent.toString(),
      marketingEmailConsent: personalDataCollectingConsent.toString()
    };
    const response = await sopClient.addMetadataToProfile(payload);
    setUser(response.data);
    navigate('/dashboard/account');
  };

  return (
    <Stack>
      <Box sx={managePreferencesNavigation}>
        <Link
          onClick={() => navigate('/dashboard/account')}
          sx={managePreferencesNavigationMainLink}
        >
          {t('account.account')}
        </Link>
        <ArrowForwardIosIcon sx={managePreferencesForwardIcon} />
        <Typography sx={{ fontWeight: 600 }}>{t('profilePage.managePreferencesHeader')}</Typography>
      </Box>
      <Box sx={managePreferencesContainer}>
        <Stack>
          <Stack justifyContent='center' alignItems='center'>
            <Stack sx={managePreferencesWhiteBox}>
              <Typography sx={managePreferencesWhiteBoxHeader}>
                {t('profilePage.managePreferencesHeader')}
              </Typography>
              <Typography>{t('profilePage.managePreferencesText')}</Typography>
              <Stack sx={{ marginTop: '50px' }}>
                <Typography sx={managePreferencesCheckboxHeader}>
                  {t('profilePage.managePreferencesPersonalInfoHeader')}
                </Typography>
                <FormControlLabel
                  sx={[managePreferencesConsentLabel, { marginBottom: '30px' }]}
                  checked={personalDataCollectingConsent}
                  onChange={() => setPersonalDataCollectingConsent(!personalDataCollectingConsent)}
                  label={
                    <Typography>
                      {t('signUp.marketingPurposesConsent')}
                      <Tooltip
                        componentsProps={{ tooltip: { sx: whiteTooltip } }}
                        sx={{ pointerEvents: 'auto' }}
                        arrow
                        placement='bottom-end'
                        title={t(`signUp.marketingPurposesConsentTooltip`)}
                      >
                        <Img
                          sx={{ width: '12px', pointerEvents: 'auto' }}
                          src={InfoIcon}
                          alt='jit notice'
                        />
                      </Tooltip>
                    </Typography>
                  }
                  control={
                    <Checkbox
                      defaultChecked={false}
                      sx={managePreferencesConsentCheckboxStyle}
                      size='small'
                      color='default'
                      id='piConsentCheckbox'
                    />
                  }
                />
                <Typography sx={[managePreferencesCheckboxHeader]}>
                  {t('profilePage.managePreferencesMarketingHeader')}
                </Typography>
                <FormControlLabel
                  sx={[managePreferencesConsentLabel, { marginBottom: '150px' }]}
                  checked={emailReceiveConsent}
                  onChange={() => setEmailReceiveConsent(!emailReceiveConsent)}
                  label={t('signUp.marketingEmailConsent')}
                  control={
                    <Checkbox
                      sx={managePreferencesConsentCheckboxStyle}
                      size='small'
                      color='default'
                      id='marketingConsentCheckbox'
                    />
                  }
                />
              </Stack>
              <Button sx={buttonDark} onClick={savePreferences}>
                {t('profilePage.managePreferencesSave')}
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
};
