import { Box, Button, Link, Stack, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { FC, useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import {
  GSEScoreStyle,
  LinkVisibility,
  OverallProficiency,
  OverallProficiencyDesc,
  OverallProficiencyTitle,
  ScoreData,
  ScoreTitle,
  ShieldContainer,
  ShieldScore,
  TakenOnStyle,
  TileBody,
  TileContainer,
  TileFooter,
  TileHead
} from './LatestTestDetailsTile.style';
import { License } from '../../interface/licenses.interfaces';
import shield from '../../assets/images/purple-shield.svg';
import grayShield from '../../assets/images/gray-shield.svg';
import { StatusBadge } from '../StatusBadge/StatusBadge';
import {
  PageableResponse,
  SignInStyles,
  SkillType,
  sopClient,
  V2CertificateSkill
} from '../../index';
import {
  ClockIconStyle,
  DashboardTestDetailsShareLink,
  DownloadButton,
  ExpirationStyles,
  ExpirationStylesExpired,
  HoverColorChange,
  RetakeCompletedContainerStyles,
  RetakeCompletedStyles,
  RetakeTestBtn,
  RetakeTestButtonContainer
} from '../DashboardTestDetails/DashboardTestDetails.styles';
import moment from 'moment';
import { hasSkills } from '../../utils/utils';
import ShareIcon from '@mui/icons-material/Share';
import { NavigateFunction } from 'react-router-dom';
import { SkillTypes } from '../../index';
import ClockIcon from '../../assets/images/clock-icon.svg';
import ClockRedIcon from '../../assets/images/clock-red.svg';
import { theme } from '../../styles/theme';
import { Img } from '../Primitive';
import { buttonDarkSecondary } from '../../styles/button';

export interface LatestTestDetailsTileProps {
  license: License | undefined;
  skills: Record<string, V2CertificateSkill>;
  findSkillByName?: (name: SkillType) => V2CertificateSkill | undefined;
  handleDownloadCertificate?: () => void;
  navigate: NavigateFunction;
  retakeTest?: () => void;
  daysToExpire?: number;
}

export const LatestTestDetailsTile: FC<LatestTestDetailsTileProps> = ({
  license,
  skills,
  findSkillByName,
  handleDownloadCertificate,
  navigate,
  retakeTest,
  daysToExpire
}) => {
  const { t } = useTranslation();
  const status = license?.status || '';
  const [showLatestTestHeader, setShowLatestTestHeader] = useState(false);
  const showFooter =
    license?.status === 'CERTIFIED'
      ? license?.product.code !== 'BCUDA_4S'
      : !['SCORED', 'ABORTED'].includes(status);
  const showShield = ![
    'CERTIFICATION_FAILED',
    'SPEAKING_UNSCORBALE',
    'PARTIALLY_SCORED',
    'LATE_HANGUP',
    'USER_HUNG_UP',
    'ERROR',
    'ABORTED'
  ].includes(status);
  const showRetake = [
    'CERTIFICATION_FAILED',
    'SPEAKING_UNSCORBALE',
    'PARTIALLY_SCORED',
    'LATE_HANGUP',
    'USER_HUNG_UP',
    'ERROR'
  ].includes(status);
  const showTestId = ['LATE_HANGUP', 'USER_HUNG_UP', 'ABORTED', 'ERROR'].includes(status);

  useEffect(() => {
    checkItsLatestLicense();
  }, []);
  const checkItsLatestLicense = async () => {
    const response = await sopClient.getLatestLicense();
    const data = response.data as PageableResponse<License>;
    const latestLicense = data.results.filter((l) => l.status !== 'VALIDATED')[0];
    setShowLatestTestHeader(latestLicense.id === license?.id);
  };

  const getCefrValue = () =>
    (license?.scores &&
      license?.scores?.find(
        (el) => el?.component === SkillTypes.OVERALL_SCORE && el?.scale === 'CEFR'
      )?.label) ||
    '';

  return (
    <Stack sx={TileContainer}>
      {!showRetake && showFooter && (
        <Link
          onClick={() => navigate(`share`)}
          sx={[DashboardTestDetailsShareLink, LinkVisibility]}
        >
          <ShareIcon />
          {t('results.shareResults')}
        </Link>
      )}
      {showLatestTestHeader && (
        <Box sx={TileHead}>
          <Typography component='span'>{t('results.latestTest').toUpperCase()}</Typography>
        </Box>
      )}
      <Box sx={ScoreData}>
        <Typography sx={ScoreTitle}>{license?.product?.name}</Typography>
        <Box sx={{ height: '37.5px', flexShrink: 0 }}>
          <StatusBadge type={license?.status} />
        </Box>
      </Box>
      <Box sx={TileBody}>
        {showShield && (
          <Box sx={ShieldContainer}>
            <Box sx={{ position: 'relative' }}>
              <Img
                width={100}
                alt='Shield icon'
                src={license?.status === 'CERTIFIED' ? shield : grayShield}
              />
              <Typography sx={ShieldScore}>
                {(license?.scores &&
                  license?.scores?.find(
                    (el) => el?.component === SkillTypes.OVERALL_SCORE && el?.scale === 'GSE'
                  )?.score) ||
                  ''}
              </Typography>
              <Typography sx={GSEScoreStyle}>{t('gseScore')}</Typography>
              <Typography sx={GSEScoreStyle}>
                {t('cefrScore')}:{' '}
                <Typography sx={{ fontWeight: 700 }} component='span'>
                  {getCefrValue()}
                </Typography>
              </Typography>
            </Box>
          </Box>
        )}
        <Stack sx={[OverallProficiency, !showShield ? { marginLeft: 0 } : {}]}>
          {!!findSkillByName?.(SkillTypes.OVERALL_SCORE)?.description && (
            <>
              <Typography sx={OverallProficiencyTitle}>{t('myOverallProficiency')}</Typography>
              <Typography sx={OverallProficiencyDesc}>
                {(hasSkills(skills) && findSkillByName?.(SkillTypes.OVERALL_SCORE)?.description) ||
                  ''}
              </Typography>
            </>
          )}
          {license?.status === 'LATE_HANGUP' ||
            (license?.status === 'USER_HUNG_UP' && (
              <Stack sx={{ gap: '25px' }}>
                <Typography sx={OverallProficiencyDesc}>{t('testTile.weAreUnable')}</Typography>
                <Typography sx={OverallProficiencyDesc}>
                  <Trans
                    i18nKey='testTile.ifNeedHelp'
                    components={{
                      a: <a target='_blank' href='https://pearson.tfaforms.net/3048' />
                    }}
                  />
                </Typography>
              </Stack>
            ))}
          {license?.status === 'ABORTED' && (
            <Stack sx={{ gap: '25px' }}>
              <Typography sx={OverallProficiencyDesc}>
                <Trans
                  i18nKey='testTile.weCantProvide'
                  components={{ a: <a target='_blank' href='https://pearson.tfaforms.net/3048' /> }}
                />
              </Typography>
            </Stack>
          )}
          {license?.status === 'ERROR' && (
            <Stack sx={{ gap: '25px' }}>
              <Typography sx={OverallProficiencyDesc}>{t('testTile.weAreUnable')}</Typography>
              <Typography sx={OverallProficiencyDesc}>
                <Trans
                  i18nKey='testTile.dueToProblem'
                  components={{ a: <a target='_blank' href='https://pearson.tfaforms.net/3048' /> }}
                />
              </Typography>
            </Stack>
          )}
          {(license?.endTime || license?.startTime) && (
            <Typography sx={TakenOnStyle}>
              {`${t('takenOn')}: `}
              <Typography component='span'>{`${moment(
                license?.endTime ? license?.endTime : license?.startTime
              ).format('DD MMMM yyyy')}`}</Typography>
            </Typography>
          )}
          {showTestId && (
            <Typography sx={TakenOnStyle}>
              {`${t('testTile.testId')}: `}
              <Typography component='span'>{license?.testCode}</Typography>
            </Typography>
          )}
        </Stack>
      </Box>
      <Box sx={TileFooter(license?.status !== 'CERTIFIED')}>
        {showFooter && (
          <>
            {!showRetake && (
              <>
                <Link
                  onClick={() => navigate(`share`)}
                  sx={{
                    ...DashboardTestDetailsShareLink
                  }}
                >
                  <ShareIcon />
                  {t('results.shareResults')}
                </Link>
                <Button
                  onClick={handleDownloadCertificate}
                  sx={[SignInStyles.SignInButton, DownloadButton]}
                >
                  {t('results.downloadCertificate')}
                  <svg
                    style={{ marginLeft: '7px' }}
                    width='15'
                    height='15'
                    viewBox='0 0 18 18'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M16.5 11.75C16.9142 11.75 17.25 12.0858 17.25 12.5V15.5C17.25 16.7426 16.2426 17.75 15 17.75H3C1.75736 17.75 0.75 16.7426 0.75 15.5V12.5C0.75 12.0858 1.08579 11.75 1.5 11.75C1.91421 11.75 2.25 12.0858 2.25 12.5V15.5C2.25 15.9142 2.58579 16.25 3 16.25H15C15.4142 16.25 15.75 15.9142 15.75 15.5V12.5C15.75 12.0858 16.0858 11.75 16.5 11.75ZM9 0.5C9.41421 0.5 9.75 0.835786 9.75 1.25V9.93934L12.2197 7.46967C12.5126 7.17678 12.9874 7.17678 13.2803 7.46967C13.5732 7.76256 13.5732 8.23744 13.2803 8.53033L9.53033 12.2803C9.38388 12.4268 9.19194 12.5 9 12.5C8.80806 12.5 8.61612 12.4268 8.46967 12.2803L4.71967 8.53033C4.42678 8.23744 4.42678 7.76256 4.71967 7.46967C5.01256 7.17678 5.48744 7.17678 5.78033 7.46967L8.25 9.93934V1.25C8.25 0.835786 8.58579 0.5 9 0.5Z'
                      fill={theme.palette.common.lightBlue00}
                    />
                  </svg>
                </Button>
              </>
            )}
            {showRetake && (
              <Box sx={RetakeTestButtonContainer}>
                {license?.status !== 'ABORTED' && (
                  <Button
                    onClick={() => retakeTest?.()}
                    sx={[buttonDarkSecondary, HoverColorChange, RetakeTestBtn]}
                    disabled={!license?.canBeRetaken}
                  >
                    {t('dashboardPage.retakeTestButton')}
                    <svg
                      style={{ marginLeft: '10px' }}
                      width='16'
                      height='19'
                      viewBox='0 0 18 19'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M5.34375 12.5938H1.96875M16.0312 10.3438C16.0312 13.7188 12.8835 16.5312 9 16.5312C4.78125 16.5312 1.96875 12.5938 1.96875 12.5938V16.5312M12.6562 6.40625H16.0312M1.96875 8.65625C1.96875 5.28125 5.1165 2.46875 9 2.46875C13.2188 2.46875 16.0312 6.40625 16.0312 6.40625V2.46875'
                        stroke={theme.palette.common.lightBlue00}
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </Button>
                )}
                {license?.canBeRetaken && license?.status !== 'ABORTED' && (
                  <Box sx={{ display: 'flex' }}>
                    <img
                      alt='Clock icon'
                      style={ClockIconStyle}
                      src={!!daysToExpire && daysToExpire > 0 ? ClockIcon : ClockRedIcon}
                    />
                    {!!daysToExpire && daysToExpire > 0 && (
                      <Typography sx={ExpirationStyles}>
                        {t('results.expiresIn', {
                          daysToExpire
                        })}
                      </Typography>
                    )}
                    {daysToExpire === 0 && (
                      <Typography sx={ExpirationStylesExpired}>
                        {t('results.expired', {
                          daysToExpire
                        })}
                      </Typography>
                    )}
                  </Box>
                )}
                {!license?.canBeRetaken && (
                  <Box sx={RetakeCompletedContainerStyles}>
                    <CheckCircleOutlineIcon />
                    <Typography sx={RetakeCompletedStyles}>
                      {t('results.retakeCompleted')}
                    </Typography>
                  </Box>
                )}
              </Box>
            )}
          </>
        )}
      </Box>
    </Stack>
  );
};
