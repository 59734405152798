import { V2CertificateSkill } from '../../interface/mts-report.interface';
import { theme } from '../../styles/theme';

export const GraphContainer = {
  width: '100%',
  height: '100%',
  position: 'relative'
};

export const GraphLabel = {
  paddingBottom: '15px',
  fontWeight: 600,
  fontSize: '10px',
  position: 'absolute',
  left: '-20px',
  top: '-30px'
};

export const GraphLines = {
  height: '100%',
  display: 'flex',
  flexFlow: 'column',
  position: 'relative'
};

export const GraphValueLine = {
  position: 'absolute',
  width: '100%',
  height: '0.5px',
  backgroundColor: theme.palette.common.lightGrey7
};

export const GraphValueLineLabel = {
  position: 'relative'
};

export const GraphValueLineLabelSpan = {
  position: 'absolute',
  top: '-7px',
  left: '-20px',
  fontWeight: 600,
  fontSize: '10px'
};

export const SkillBlocks = {
  position: 'absolute',
  width: '100%',
  height: '100%'
};

export const SkillBlocksContainer = {
  width: '100%',
  height: '100%',
  position: 'relative',
  display: 'flex'
};

export const SkillBlocksContainerBlock = {
  position: 'absolute',
  height: '100%',
  backgroundColor: 'rgba(243, 248, 251, 0.8)',
  borderRadius: '8px',
  marginLeft: '10px',
  marginRight: '10px'
};

export const SkillBlocksContainerBlockFillValue = (
  skill: V2CertificateSkill & { color: string },
  func: (num: number) => number
) => ({
  position: 'absolute',
  fontWeight: 700,
  left: '38%',
  zIndex: '999',
  width: '25%',
  fontSize: '12px',
  margin: '6px auto auto auto',
  textAlign: 'center',
  backgroundColor: skill?.color,
  color:
    skill.color === theme.palette.common.lightBlue3
      ? `${theme.palette.common.darkGrey2} !important`
      : theme.palette.common.lightGrey6,
  bottom: `${func(skill?.score) - 8}%`
});

export const SkillBlocksContainerBlockFillContainer = {
  width: '100%',
  height: '100%',
  position: 'relative'
};

export const SkillBlocksContainerBlockFillContainerFill = {
  position: 'absolute',
  bottom: 0,
  width: '100%',
  borderRadius: '8px'
};

export const SkillBlocksContainerBlockFillContainerFillSpan = {
  position: 'absolute',
  bottom: '-20px',
  width: '100%',
  textAlign: 'center',
  fontWeight: 600,
  fontSize: '12px',
  color: theme.palette.common.darkGrey3
};

export const ScorePointer = {
  position: 'absolute',
  height: '2px',
  width: '103%',
  backgroundColor: theme.palette.common.darkGrey8,
  bottom: 0,
  left: '-12px'
};

export const ScorePointerContainer = {
  position: 'relative'
};

export const ScorePointerContainerSpan = {
  position: 'absolute',
  fontWeight: 700,
  fontSize: '14px',
  left: '-20px',
  bottom: '-10px'
};
