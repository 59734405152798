import { createTheme } from '@mui/material';

// Fonts
const $fontFallback = 'Helvetica, Arial, sans-serif';
export const $fontOpenSans = `'Open Sans', ${$fontFallback}`;

export const theme = createTheme({
  palette: {
    common: {
      darkGrey0: '#e0e0e0',
      darkGrey1: '#1F1F1F',
      darkGrey2: '#151515',
      darkGrey3: '#333333',
      darkGrey4: '#343434',
      darkGrey5: '#353535',
      darkGrey6: '#363636',
      darkGrey7: '#6C727F',
      darkGrey8: '#919191',
      darkGrey9: '#c4c4c4',
      darkGrey10: '#D1D1D1',
      darkGrey11: '#262626',
      grey: '#dbdbdb',
      lightGrey1: '#DFE1E1',
      lightGrey2: '#E8E8E8',
      lightGrey3: '#ebebeb',
      lightGrey4: '#fafafa',
      lightGrey5: '#F5F5F5',
      lightGrey6: '#fefefe',
      lightGrey7: '#efefef',
      lightGrey8: '#908f92',
      lightGrey9: '#E6E6E6',
      lightGrey11: '#F9F9F9',
      lightGrey10: '#E1E9FB',
      darkGreen: '#008638',
      darkRed: '#D30018',
      lightRed1: '#F4D5D8',
      lightYellow: '#FFF3D9',
      lightBlue00: '#f7f9fd',
      lightBlue01: '#F1F5FE',
      lightBlue02: '#E0EEFF',
      lightBlue0: '#F0F6FF',
      lightBlue1: '#80b2ff',
      lightBlue2: '#A1C3FC',
      lightBlue3: '#b8d7ff',
      blue: '#0066FF',
      darkBlue1: '#05112A',
      darkBlue2: '#505759',
      darkBlue3: '#0182EE',
      darkBlue4: '#3B6BB3',
      darkBlue5: '#2B6BBE',
      darkBlue6: '#007FA3',
      darkBlue7: '#007A9C',
      darkBlue8: '#005A70',
      darkBlue9: '#23286B',
      darkBlue10: '#28293E',
      darkBlue11: '#0F1248',
      darkBlue12: '#23266F',
      darkBlue13: '#28293E',
      darkTeal: '#00C5E0',
      teal: '#36B0B0',
      lightTeal: '#E1FFFF',
      violetDark1: '#722351',
      violetDark2: '#830F6B',
      violet: '#9e007e',
      violetLight2: '#c226de',
      violetLight: '#F3E6F0',
      violetLight3: '#E6BCDD',
      white: '#ffffff',
      black: '#000000',
      borderGray: '#EDEDED',
      lightPink: '#F8E8EE',
      lightPurple: '#D9DAF2'
    }
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true
      },
      styleOverrides: {
        root: ({ ownerState: { variant, size, disabled } }) => ({
          textTransform: 'none',
          border: 'none',
          width: 'fit-content',
          minWidth: 'fit-content',
          fontWeight: 600,
          borderRadius: '40px',
          padding: '0 20px',
          height: 'unset',
          ...(disabled && {
            cursor: 'normal',
            pointerEvents: 'none'
          }),
          ...(variant === 'contained' && {
            backgroundColor: theme.palette.common.darkGrey4
          }),
          ...(variant === 'outlined' && {
            border: `2px solid theme.palette.common.black`,
            color: theme.palette.common.darkGrey5
          }),
          ...(size === 'small' && {
            minHeight: '32px',
            fontSize: '14px',
            borderRadius: '40px'
          }),
          ...(size === 'medium' && {
            minHeight: '36px',
            fontSize: '16px',
            borderRadius: '40px'
          }),
          ...(size === 'large' && {
            minHeight: '48px',
            fontSize: '16px',
            borderRadius: '40px'
          })
        })
      }
    },
    // Global LinearProgress overwrites. Docs: https://mui.com/material-ui/api/linear-progress/
    MuiLinearProgress: {
      styleOverrides: {
        root: () => ({
          backgroundColor: theme.palette.common.lightBlue01
        }),
        bar: {
          background: 'linear-gradient(90deg, #1F73DA, #3F8BF9)'
        }
      }
    }
  },
  typography: {
    fontFamily: $fontOpenSans
  }
});

export const mediaQuery = {
  minHeight900: '@media screen and (min-height: 900px)',
  minHeight980: '@media screen and (min-height: 980px)',
  minHeight1080: '@media screen and (min-height: 1080px)'
};
