import { NavigateFunction, useNavigate } from 'react-router-dom';
import { To } from '@remix-run/router';
import { NavigateOptions } from 'react-router/dist/lib/context';
import { AppContext } from '../context/App.context';
import { useContext } from 'react';
import { VALID_LANGUAGE_PREFIXES } from '../app.utils';

export const useLocalizedNavigate = (): NavigateFunction => {
  const navigate = useNavigate();
  const { language } = useContext(AppContext);

  function localizedNavigate(to: To, options?: NavigateOptions) {
    const fixedToPath =
      to.toString().startsWith('/') && !VALID_LANGUAGE_PREFIXES.includes(to.toString().slice(0, 5))
        ? `/${language}${to}`
        : to;
    return navigate(fixedToPath, options);
  }

  function localizedNavigate2(delta: number) {
    return navigate(delta);
  }

  return (localizedNavigate || localizedNavigate2) as NavigateFunction;
};
