import { FC, useContext, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SUPPORTED_LANGUAGES } from '../../app.utils';
import {
  downloadCertificatePopupLanguageRow,
  downloadCertificatePopupRadioViolet,
  downloadCertificatePopupResponsiveConditions
} from './DownloadCertificatePopup.styles';
import { Language, Loader } from '@barracuda/shared/src';
import { buttonDark } from '@barracuda/shared/src/styles/button';
import { AppContext } from '../../context/App.context';
import { theme } from '@theme';

interface DownloadCertificatePopupProps {
  handleDownload: (lang: Language) => void;
}
export const DownloadCertificatePopup: FC<DownloadCertificatePopupProps> = ({ handleDownload }) => {
  const { t } = useTranslation();
  const { language } = useContext(AppContext);
  const [selectedLang, setSelectedLang] = useState(language || SUPPORTED_LANGUAGES[0]);
  const [pending, setPending] = useState(false);

  const languageRows = SUPPORTED_LANGUAGES.map((item) => (
    <Box sx={downloadCertificatePopupLanguageRow} onClick={() => setSelectedLang(item)}>
      <FormControlLabel
        value={item}
        control={<Radio sx={downloadCertificatePopupRadioViolet} />}
        label={t(`downloadCertificatePopup.languages.${item.toLowerCase()}`)}
      />
    </Box>
  ));

  return (
    <Stack>
      <Typography sx={{ marginBottom: '40px' }}>
        {t('downloadCertificatePopup.subtitle')}
      </Typography>
      <FormControl sx={downloadCertificatePopupResponsiveConditions}>
        <RadioGroup
          aria-labelledby='language-radio-buttons'
          value={selectedLang}
          name='radio-buttons-group'
        >
          {languageRows}
        </RadioGroup>
      </FormControl>
      <Button
        size='medium'
        onClick={async () => {
          setPending(true);
          await handleDownload(selectedLang);
          setPending(false);
        }}
        sx={buttonDark}
        disabled={pending}
      >
        {t('downloadCertificatePopup.buttonText')}
        <Loader
          loading={pending}
          small
          size='20px'
          color={theme.palette.common.darkGrey8}
          sx={{ ml: 1 }}
        />
      </Button>
    </Stack>
  );
};
