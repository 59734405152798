import { Dispatch, FC, SetStateAction, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Stack, Typography } from '@mui/material';
import {
  dashboardUploadIdClickFrameText,
  dashboardUploadIdContentContainer,
  dashboardUploadIdContinueBtn,
  dashboardUploadIdFrontBackIdText,
  dashboardUploadIdHeader,
  dashboardUploadIdLottieStyle,
  dashboardUploadIdProcessContainer
} from './DashboardUploadId.styles';
import { Img } from '../../../../shared/src/components/Primitive';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import { success } from '../../assets/lottie/success';
import BackPhotoPlaceholder from '../../assets/images/back-photo-placeholder.svg';
import { ViewModeType } from './DashboardUploadId';
import { DashboardUploadIdResultMode } from './DashboardUploadIdResultMode';
import { SignInStyles } from '@barracuda/shared/src';
import { DashboardUploadIdPhotoTips } from './DashboardUploadIdPhotoTips';
import { DashboardUploadIdCameraFooterButtons } from './DashboardUploadIdCameraFooterButtons';
import { DashboardUploadIdPhotoWithLottie } from './DashboardUploadIdPhotoWithLottie';
import { DashboardUploadIdTitleSubtitle } from './DashboardUploadIdTitleSubtitle';

const displayPhotoTipsArray = ['normal', 'attempt-failed'];
const isResultModeArray = [
  'expired',
  'all-done',
  'already-submitted',
  'attempt-failed',
  'all-done-failed'
];

export interface DashboardUploadIdProcessModeProps {
  readonly viewMode: ViewModeType;
  readonly photo?: string;
  readonly backPhoto?: string;
  readonly status: 'accepted' | 'rejected' | null;
  readonly setViewMode: Dispatch<SetStateAction<ViewModeType>>;
  readonly use: () => void;
  readonly backUse: () => void;
  readonly sendSelectedIds: () => void;
  readonly retry: () => void;
  readonly backRetry: () => void;
  readonly manual: boolean;
  readonly flowRetry: () => void;
  readonly backIdSuccess: any;
}

export const DashboardUploadIdProcessMode: FC<DashboardUploadIdProcessModeProps> = ({
  viewMode,
  photo,
  backPhoto,
  status,
  setViewMode,
  use,
  backUse,
  sendSelectedIds,
  retry,
  backRetry,
  flowRetry,
  backIdSuccess,
  manual
}) => {
  const { t } = useTranslation();
  const frontIdSuccess = useRef<LottieRefCurrentProps>(null);
  const mobileImageRef = useRef(null);
  const isResultMode = isResultModeArray.includes(viewMode);
  const displayPhotoTips = displayPhotoTipsArray.includes(viewMode);
  const [title, subtitle, withBulb] =
    status !== 'accepted' || (photo && backPhoto)
      ? ['mobileUploadId.takeAPhoto', 'mobileUploadId.toBegin', false]
      : ['mobileUploadId.frontAccepted', 'mobileUploadId.takeAPhotoBack', true];

  useEffect(() => {
    if (status === 'accepted') {
      frontIdSuccess?.current?.play();
    }
  }, [status]);

  return (
    <Stack sx={dashboardUploadIdProcessContainer}>
      <Box sx={dashboardUploadIdContentContainer}>
        {viewMode === 'normal' && (
          <Box>
            <Box sx={{ marginTop: '50px' }}>
              <DashboardUploadIdTitleSubtitle
                title={title}
                subtitle={subtitle}
                withBulb={withBulb}
              />
              {!photo && (
                <Button
                  onClick={() => setViewMode('take-photo')}
                  sx={[SignInStyles.SignInButton, dashboardUploadIdContinueBtn]}
                >
                  {t('mobileUploadId.takePicture')}
                </Button>
              )}
            </Box>
            {photo && viewMode === 'normal' && (
              <Box
                sx={{
                  padding: '20px',
                  position: 'relative'
                }}
              >
                {photo && !backPhoto && (
                  <Stack>
                    <Stack justifyContent='center' sx={{ position: 'relative' }}>
                      <Box sx={dashboardUploadIdLottieStyle}>
                        <Lottie
                          animationData={success}
                          lottieRef={frontIdSuccess}
                          loop={false}
                          autoplay={false}
                        />
                      </Box>
                      <Img
                        ref={mobileImageRef}
                        sx={{ margin: '0 auto', width: '85%' }}
                        src={photo}
                      />
                      <Typography sx={dashboardUploadIdFrontBackIdText}>
                        {t('mobileUploadId.frontOfId')}
                      </Typography>
                    </Stack>
                    {status !== 'accepted' && (
                      <DashboardUploadIdCameraFooterButtons
                        useFunction={use}
                        retryFunction={retry}
                      />
                    )}
                    {status === 'accepted' && (
                      <Stack>
                        <Typography sx={dashboardUploadIdClickFrameText}>
                          {t('mobileUploadId.tapToContinue')}
                        </Typography>
                        <Img
                          sx={{ margin: 'auto' }}
                          width='85%'
                          onClick={() => setViewMode('take-photo')}
                          src={BackPhotoPlaceholder}
                        />
                        <Typography sx={dashboardUploadIdFrontBackIdText}>
                          {t('mobileUploadId.backOfId')}
                        </Typography>
                        {!backPhoto && photo && (
                          <Button
                            onClick={() => sendSelectedIds()}
                            sx={[SignInStyles.SignInButton, dashboardUploadIdContinueBtn]}
                          >
                            {t('mobileUploadId.skip')}
                          </Button>
                        )}
                      </Stack>
                    )}
                  </Stack>
                )}
                {photo && backPhoto && (
                  <Stack>
                    <Box sx={{ position: 'relative' }}>
                      <Img sx={{ margin: '0 auto', width: '85%' }} src={backPhoto} />
                    </Box>
                    <DashboardUploadIdCameraFooterButtons
                      useFunction={backUse}
                      retryFunction={backRetry}
                    />
                  </Stack>
                )}
              </Box>
            )}
          </Box>
        )}
        {viewMode === 'back-accepted' && (
          <Stack sx={{ gap: '40px', padding: '20px' }}>
            <Typography component='h1' sx={dashboardUploadIdHeader}>
              {t('mobileUploadId.backCaptured')}
            </Typography>
            <DashboardUploadIdPhotoWithLottie
              type='front'
              photo={photo}
              frontIdSuccess={frontIdSuccess}
            />
            <DashboardUploadIdPhotoWithLottie
              type='back'
              backPhoto={backPhoto}
              backIdSuccess={backIdSuccess}
            />
            <Button
              onClick={() => sendSelectedIds()}
              sx={[SignInStyles.SignInButton, dashboardUploadIdContinueBtn]}
            >
              {t('mobileUploadId.done')}
            </Button>
          </Stack>
        )}
        {isResultMode && (
          <DashboardUploadIdResultMode viewMode={viewMode} manual={manual} flowRetry={flowRetry} />
        )}
        {displayPhotoTips && <DashboardUploadIdPhotoTips />}
      </Box>
    </Stack>
  );
};
