import { theme } from '@theme';

export const questionItem = {
  marginBottom: '10px',
  padding: '10px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
};

const questionItemDetailsContent = {
  color: theme.palette.common.darkBlue2,
  fontSize: '14px',
  display: 'block'
};

export const questionItemDetails = {
  '>p, p': {
    ...questionItemDetailsContent,
    margin: '0 0 15px'
  },
  'ul, ol': {
    paddingLeft: '20px'
  },
  li: {
    ...questionItemDetailsContent,
    marginBottom: '5px',
    display: 'list-item',
    '> p': {
      marginBottom: '0'
    }
  },
  a: {
    ...questionItemDetailsContent,
    textDecoration: 'underline',
    cursor: 'pointer',
    display: 'inline'
  }
};
