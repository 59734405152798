import { theme } from '../../styles/theme';

export const AvailableTestsNotificationContainer = {
  borderRadius: '4px',
  border: `1px solid ${theme.palette.common.lightGrey1}`,
  background: theme.palette.common.white,
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'pre-wrap',
  position: 'relative',
  zIndex: '100',
  a: {
    color: theme.palette.common.darkBlue7,
    fontWeight: 600,
    textDecoration: 'underline',
    cursor: 'pointer'
  }
};

export const AvailableTestsNotificationPipe = {
  width: '3px',
  marginLeft: '1px',
  backgroundColor: theme.palette.common.darkGreen,
  borderTopLeftRadius: '4px',
  borderBottomLeftRadius: '4px',
  height: '40px'
};
