import {
  Box,
  Button,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  activationCodePopupBoxStyle,
  activationCodePopupDiscountBanner,
  activationCodePopupInputBoxStyle,
  activationCodePopupInputStyle,
  activationCodePopupPurpleBar,
  activationCodePopupSmallScreenStyles,
  activationCodePopupTextIcon,
  activationCodePopupValidationResultStyle
} from './ActivationCodePopup.styles';
import { useForm } from 'react-hook-form';
import { buttonDark, buttonOutlineDark } from '../../styles/button';
import InfoIcon from '../../assets/images/info-icon-outlinted.svg';
import { Img } from '../Primitive';
import checkedGreenIcon from '../../assets/images/checked-green-icon.svg';
import alreadyUsedImage from '../../assets/images/activation-code-already-used.svg';
import { ActivationCodeStatus } from '../../interface/activation-code.interface';
import { purpleTooltip } from '../../styles/tooltip';
import DiscountLabelImage from '../../assets/images/activation-code-popup-label.svg';

export interface ActivationCodePopupProps {
  closeDialog?: () => void;
  onSubmit: (activationCode: string) => void;
  status: ActivationCodeStatus;
  setStatus: Dispatch<SetStateAction<ActivationCodeStatus>>;
  cancelButton?: boolean;
  errorMessage: string;
}

export const ActivationCodePopup: FC<ActivationCodePopupProps> = ({
  closeDialog,
  onSubmit,
  status,
  setStatus,
  cancelButton,
  errorMessage
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [tooltipOpened, setTooltipOpened] = useState(false);
  const showMessage = ['INVALID', 'VALID', 'ALREADY_USED', 'EXPIRED'].includes(status);
  const modifyStatusOnChange = ['INVALID', 'ALREADY_USED', 'EXPIRED'].includes(status);
  const showButton = ['DEFAULT', 'INVALID', 'ALREADY_USED', 'EXPIRED'].includes(status);
  const matchesSmallWidth = useMediaQuery(theme.breakpoints.down('sm'));
  const { register, formState, getValues, setValue, trigger } = useForm({
    criteriaMode: 'all',
    mode: 'all',
    reValidateMode: 'onChange'
  });

  const handleActivationCodeChange = (e: any) => {
    setValue('activationCode', e.target.value);
    trigger('activationCode');
    if (modifyStatusOnChange) {
      setStatus('DEFAULT');
    }
  };

  const obtainButtonText = () => {
    if (cancelButton) {
      return status === 'VALID' ? 'done' : 'cancel';
    }
    return status === 'VALID' ? 'next' : 'skip';
  };

  return status === 'TOO_MANY_ATTEMPTS' ? (
    <Stack>
      <Typography sx={{ paddingBottom: '30px' }}>
        {t('activationCodePopup.tooManyAttempts')}
      </Typography>
      <Img
        sx={{ alignSelf: 'center', width: { xs: '250px', sm: '350px' } }}
        src={alreadyUsedImage}
      />
    </Stack>
  ) : (
    <Stack>
      <Typography>{t('activationCodePopup.description')}</Typography>
      <Box sx={activationCodePopupBoxStyle}>
        <Box sx={activationCodePopupInputBoxStyle}>
          <Box sx={{ display: 'flex', paddingBottom: '11px' }}>
            <Typography sx={{ fontWeight: 600, paddingRight: '5px' }}>
              {t('activationCodePopup.inputTitle')}
            </Typography>
            <Tooltip
              componentsProps={{ tooltip: { sx: purpleTooltip } }}
              placement='bottom-start'
              open={tooltipOpened}
              arrow
              title={<Trans i18nKey='activationCodePopup.tooltip' />}
            >
              <Img
                width={18}
                src={InfoIcon}
                alt='Tooltip hint icon'
                onMouseLeave={() => setTooltipOpened(false)}
                onMouseOver={() => setTooltipOpened(true)}
                onClick={() => setTooltipOpened(!tooltipOpened)} //For mobile purposes, since there is tap instead of hover functionality.
              />
            </Tooltip>
          </Box>
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              {...register('activationCode', {
                required: true
              })}
              InputProps={{
                readOnly: status === 'VALID'
              }}
              onChange={handleActivationCodeChange}
              placeholder={t('activationCodePopup.inputPlaceholder')}
              type='text'
              id='activationCodeInput'
              sx={activationCodePopupInputStyle(status)}
            />
            {status === 'VALID' && !matchesSmallWidth && (
              <Img width={24} height={24} sx={{ marginLeft: '15px' }} src={checkedGreenIcon} />
            )}
          </Box>
          {showMessage && (
            <Box sx={activationCodePopupValidationResultStyle(status)}>
              {status === 'VALID' && matchesSmallWidth && (
                <Img width={18} height={18} src={checkedGreenIcon} />
              )}
              <Typography sx={{ marginLeft: '5px' }}>
                {t(`activationCodePopup.${errorMessage}`)}
              </Typography>
            </Box>
          )}
        </Box>

        {showButton && !matchesSmallWidth && (
          <Button
            type='submit'
            disabled={
              !formState.isValid ||
              status === 'INVALID' ||
              status === 'ALREADY_USED' ||
              status === 'EXPIRED'
            }
            sx={[buttonDark, { alignSelf: 'flex-end' }]}
            id='activationCodeSubmit'
            onClick={() => onSubmit(getValues('activationCode'))}
          >
            {t('activationCodePopup.buttonText')}
          </Button>
        )}
      </Box>
      {showButton && matchesSmallWidth && (
        <Button
          type='submit'
          disabled={
            !formState.isValid ||
            status === 'INVALID' ||
            status === 'ALREADY_USED' ||
            status === 'EXPIRED'
          }
          sx={[buttonDark, activationCodePopupSmallScreenStyles]}
          id='activationCodeSubmit'
          onClick={() => onSubmit(getValues('activationCode'))}
        >
          {t('activationCodePopup.buttonText')}
        </Button>
      )}
      <Box sx={activationCodePopupDiscountBanner}>
        <Box sx={activationCodePopupPurpleBar} />
        <Box sx={activationCodePopupTextIcon}>
          <Img sx={{ paddingRight: '8px' }} src={DiscountLabelImage} width={24} height={24} />
          <Trans
            components={{ bold: <strong style={{ paddingRight: '8px' }} /> }}
            i18nKey='activationCodePopup.gotADiscountCodeInstead'
          />
        </Box>
      </Box>
      <Button
        sx={[
          status === 'VALID' ? buttonDark : buttonOutlineDark,
          { alignSelf: 'flex-end', marginTop: '100px' }
        ]}
        id='activationCodeSkip'
        onClick={closeDialog}
      >
        {t(`activationCodePopup.${obtainButtonText()}`)}
      </Button>
    </Stack>
  );
};
