export const WelcomePopupImageCenter = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingBottom: 0,
  img: {
    width: '100%',
    maxWidth: '278px'
  }
};
export const WelcomePopupButtonContainer = {
  width: '100%',
  justifyContent: 'center',
  display: 'flex'
};
export const MarginAlign = {
  marginBottom: 0,
  marginTop: '20px'
};
