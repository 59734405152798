import { theme } from '@theme';
import { alpha } from '@mui/material';

export const Navbar = {
  display: 'flex',
  height: '90px !important',
  width: '100%',
  boxShadow: `0 0 5px 0 ${alpha(theme.palette.common.black, 0.15)}`,
  justifyContent: 'space-between',
  '@media only screen and (minWidth: 320px) and (maxWidth: 900px)': {
    height: '60px'
  },
  borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  position: 'fixed',
  background: 'white',
  zIndex: '1200',
  top: 0,
  left: 0
};

export const NavbarDesktop = {
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  '@media only screen and (minWidth: 320px) and (maxWidth: 900px)': {
    display: 'none'
  },
  '@media only screen and (minWidth: 901px)': {
    display: 'flex'
  },
  '@media only screen and (max-width: 1240px)': {
    justifyContent: 'space-between'
  }
};

export const NavbarLogo = {
  marginLeft: '50px',
  marginRight: '10px',
  height: '45px',
  '@media only screen and (max-width: 540px)': {
    display: 'none'
  },
  '@media only screen and (max-width: 1420px)': {
    marginLeft: '20px'
  }
};

export const NavbarLogoMobile = {
  width: '115px',
  marginLeft: '30px',
  '@media only screen and (min-width: 541px)': {
    display: 'none'
  }
};

export const NavbarList = {
  alignItems: 'center',
  display: 'flex',
  flex: 'none',
  listStyle: 'none',
  '@media only screen and (max-width: 1080px)': {
    display: 'none'
  },
  '@media only screen and (max-width: 1260px)': {
    paddingLeft: '15px'
  }
};

export const NavbarListRight = {
  justifyContent: 'flex-end',
  marginRight: '130px',
  paddingLeft: 0,
  gap: '15px',
  '@media only screen and (max-width: 1360px)': {
    marginRight: '30px'
  }
};

export const LinkStyle = {
  color: theme.palette.common.darkGrey6,
  fontSize: '16px',
  fontWeight: 800,
  marginRight: '50px',
  textDecoration: 'none',
  cursor: 'pointer',
  marginTop: '27px',
  marginBottom: '18px',
  '&:hover': {
    color: theme.palette.common.violet
  },
  '@media only screen and (max-width: 1370px)': {
    marginRight: '20px'
  },
  '@media only screen and (max-width: 1200px)': {
    marginRight: '10px'
  }
};

export const LinkActiveStyle = (active: boolean) =>
  active ? { color: `${theme.palette.common.violet} !important` } : null;

export const RouteLink = {
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '23px',
  color: theme.palette.common.darkGrey3
};

export const SignInUpButtonsContainer = {
  display: 'flex',
  alignItems: 'center'
};

export const SignInButtonNavbar = {
  color: theme.palette.common.darkGrey2,
  border: 'none',
  background: 'none',
  marginLeft: '10px',
  marginRight: '50px',
  fontSize: '16px',
  fontWeight: 600,
  width: '90px',
  minWidth: 'fit-content',
  textTransform: 'none !important',
  ':hover': {
    background: 'none'
  }
};

export const LogoLink = {
  display: 'flex',
  alignItems: 'center',
  '@media only screen and (max-width: 1470px)': {
    marginRight: '0 !important'
  }
};

export const LogOutStyle = {
  cursor: 'pointer',
  fontWeight: 800,
  minWidth: '82px',
  textTransform: 'uppercase'
};

export const NavbarRouteBoxStyle = {
  height: '40px',
  marginTop: '90px',
  background: '#E1E9FB',
  display: 'flex',
  width: '100%'
};

export const NavbarRouteBoxContainer = {
  display: 'flex',
  marginLeft: '55px',
  '@media screen and (max-width: 540px)': {
    marginLeft: '25px'
  },
  '@media screen and (max-width: 400px)': {
    marginLeft: '5px'
  }
};

export const NavbarRouteBoxLinks = {
  display: 'flex',
  padding: '8px',
  gap: '15px'
};

export const HamburgerMenuStyle = {
  marginRight: '30px',
  '@media only screen and (min-width: 1080px)': {
    display: 'none'
  }
};

export const UsernameStyle = {
  fontWeight: 600,
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  maxWidth: '110px',
  '@media screen and (min-width: 1520px)': {
    maxWidth: '160px'
  },
  '@media screen and (max-width: 1244px)': {
    display: 'none'
  },
  cursor: 'pointer'
};

export const languageSwitcher = {
  alignSelf: 'center',
  marginLeft: 'auto',
  marginRight: 1,
  '@media only screen and (max-width: 1200px)': {
    marginRight: 0
  }
};

export const CartContainer = {
  marginRight: '15px',
  '@media only screen and (max-width: 1200px)': {
    marginRight: 0
  }
};
