import { FC } from 'react';
import { Box, Typography, Dialog, Link } from '@mui/material';
import closeIcon from '../../assets/images/x-black.svg';
import checkCredly from '../../assets/images/check-credly.svg';
import {
  badgeContainer,
  closeButton,
  contentButton,
  contentHeader,
  contentWrapper,
  dialogContainer,
  headerText,
  headerText2,
  badgeImage,
  itemContainer,
  itemText,
  subheaderText
} from './style';
import { useTranslation } from 'react-i18next';
import { Img } from '../Primitive';

interface CredlyBadgeDialogProps {
  readonly onCloseDialogClick: () => void;
  readonly isOpen: boolean;
  readonly badgeImageUrl?: string;
  readonly redeemed: boolean;
  readonly acceptBadgeUrl: string;
}

export const CredlyBadgeDialog: FC<CredlyBadgeDialogProps> = ({
  onCloseDialogClick,
  isOpen,
  badgeImageUrl,
  redeemed,
  acceptBadgeUrl
}) => {
  const { t } = useTranslation();

  const localBadgeImageUrl = () => {
    if (redeemed) {
      return badgeImageUrl
        ? badgeImageUrl
        : `${process.env.REACT_APP_PESC_URL}${process.env.REACT_APP_PESC_GENERIC_BADGE_IMAGE}`;
    }

    return `${process.env.REACT_APP_PESC_URL}${process.env.REACT_APP_PESC_GENERIC_BADGE_IMAGE}`;
  };

  const credlyBadgeDialogSteps = Object.values(
    t('results.credlyBadgeDialogSteps', { returnObjects: true })
  );

  return (
    <Dialog open={isOpen} fullWidth maxWidth='md'>
      <Box sx={dialogContainer}>
        <Img alt='Close icon' src={closeIcon} sx={closeButton} onClick={onCloseDialogClick} />
        <Typography sx={headerText}>{t('results.shareYourResults')}</Typography>
        <Typography sx={headerText2}>
          {redeemed
            ? t('results.credlyBadgeDialogRedeemedTitle')
            : t('results.credlyBadgeDialogTitle')}
        </Typography>
        <Typography sx={subheaderText}>
          {redeemed
            ? t('results.credlyBadgeDialogRedeemedDescription')
            : t('results.credlyBadgeDialogDescription', {
                productName: process.env.REACT_APP_GENERAL_PRODUCT_NAME
              })}
        </Typography>
        <Box sx={contentWrapper}>
          <Box>
            <Typography sx={contentHeader}>
              {redeemed
                ? t('results.credlyBadgeDialogRedeemedListDescription')
                : t('results.credlyBadgeDialogListDescription')}
            </Typography>
            {!redeemed && (
              <Box>
                {credlyBadgeDialogSteps.map((item) => (
                  <Box sx={itemContainer}>
                    <Img alt='Check icon' src={checkCredly} sx={{ marginRight: '14px' }} />
                    <Typography sx={itemText}>{item as string}</Typography>
                  </Box>
                ))}
              </Box>
            )}
            <Link
              variant='button'
              target='_blank'
              href={
                redeemed ? acceptBadgeUrl : `${process.env.REACT_APP_PESC_CREDLY_LEARN_MORE_LINK}`
              }
              sx={contentButton}
            >
              {redeemed
                ? t('results.credlyBadgeDialogRedeemedButton')
                : t('results.credlyBadgeDialogButton')}
            </Link>
          </Box>
          <Box sx={badgeContainer}>
            <Img alt='Badge certificate' src={localBadgeImageUrl()} sx={badgeImage} />
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};
