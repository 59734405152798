import { FC } from 'react';
import { Typography } from '@mui/material';
import { categoryItem, categoryItemActive } from './CategoryItem.styles';

interface CategoryItemProps {
  onClick: () => void;
  active: boolean;
  label: string;
}

export const CategoryItem: FC<CategoryItemProps> = ({ onClick, active, label }) => {
  return (
    <Typography onClick={onClick} sx={[categoryItem, active && categoryItemActive]}>
      {label}
    </Typography>
  );
};
