import { theme } from '../../styles/theme';

const alignCenter = {
  display: 'flex',
  alignItems: 'center'
};

export const SkillScoresTableContainer = (insidePopup?: boolean) => ({
  background: theme.palette.common.white,
  padding: '35px',
  maxWidth: '1318px',
  '@media only screen and (max-width: 1024px)': {
    background: theme.palette.common.lightGrey6
  },
  '@media screen and (max-width: 1024px)': {
    width: insidePopup ? '100%' : '50%'
  },
  '@media screen and (max-width: 745px)': {
    width: '100%',
    paddingBottom: '50px'
  }
});
export const TestIdStyles = {
  fontWeight: 600,
  fontSize: '16px',
  paddingBottom: '13px'
};

export const SkillScoresTableAccordion = {
  '.MuiAccordionSummary-root': {
    padding: 0
  },
  marginBottom: '10px',
  boxShadow: 'none',
  '&:before': {
    top: 0
  }
};

export const SkillScoresTableAccordionSummary = {
  background: theme.palette.common.lightBlue0,
  borderRadius: '4px',
  border: 'none',
  ...alignCenter,
  justifyContent: 'space-between',
  width: '100%',
  '.MuiAccordionSummary-expandIconWrapper': {
    position: 'absolute',
    right: '10px'
  }
};

export const ScoreContainer = {
  display: 'flex',
  width: '100%'
};
export const scoreTextStyle = (value: boolean) => ({
  fontWeight: 700,
  color: value ? theme.palette.common.darkGrey8 : 'unset'
});
export const exceptionIcon = {
  marginLeft: '5px',
  width: '16px'
};

export const accordionMatchingRow = {
  width: '20%',
  display: 'flex',
  padding: '0 16px',
  '@media screen and (max-width: 1024px)': {
    width: '33%'
  }
};
