import { FC, useState, useMemo } from 'react';
import { Box, Button, Menu, MenuItem } from '@mui/material';
import { DashboardTestDetailsShareLink, MobileVisibility } from './style';
import { Language } from '../../interface/language.interface';
import { useTranslation } from 'react-i18next';
import { SystemStyleObject, Theme } from '@mui/system';
import { Img } from '../Primitive';
import USAFlagIcon from '../../assets/images/usa-flag.svg';
import JapanFlagIcon from '../../assets/images/japan-flag.svg';
import SouthKoreaFlagIcon from '../../assets/images/south-korea-flag.svg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export interface LanguageSwitcherGlobalProps {
  language: Language;
  languageChange: (lang: Language) => void;
  readonly sx?: SystemStyleObject<Theme>;
}

export const LanguageSwitcherGlobal: FC<LanguageSwitcherGlobalProps> = ({
  language,
  languageChange,
  sx
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslation();

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSelect = async (lang: Language) => {
    setAnchorEl(null);
    languageChange(lang);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const flagImage = useMemo(() => {
    switch (language) {
      case 'KOR':
        return SouthKoreaFlagIcon;
      case 'JPN':
        return JapanFlagIcon;
      case 'ENG':
        return USAFlagIcon;
    }
  }, [language]);

  return (
    <Box sx={{ flexShrink: 0, ...sx }}>
      <Button
        sx={DashboardTestDetailsShareLink}
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <Img width={24} src={flagImage} />
        <Box sx={[MobileVisibility, { marginLeft: '5px' }]}>
          {t(`languageSwitcher.${language}`)}
        </Box>
        <KeyboardArrowDownIcon sx={[MobileVisibility, { marginLeft: '5px', height: '14px' }]} />
      </Button>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
        disableScrollLock
      >
        <MenuItem onClick={() => handleSelect('ENG')}>{t('languageSwitcher.english')}</MenuItem>
        <MenuItem onClick={() => handleSelect('JPN')}>{t('languageSwitcher.japanese')}</MenuItem>
        <MenuItem onClick={() => handleSelect('KOR')}>{t('languageSwitcher.korean')}</MenuItem>
      </Menu>
    </Box>
  );
};
