import { FC } from 'react';
import { User } from '../../interface/user.interface';
import { Box, Button, Typography } from '@mui/material';
import {
  BasicInformationBoxContainer,
  BasicInformationBoxHeader,
  BasicInformationBoxHeaderContainer,
  BasicInformationBoxSectionContainer,
  BasicInformationEditButton,
  BasicInformationEllipsis,
  BasicInformationItem,
  BasicInformationItemLabel,
  Pile,
  PileLabel
} from './BasicInformationBox.styles';
import moment from 'moment';
import { TooltipWrapper } from '../TooltipWrapper/TooltipWrapper';
import { useTranslation } from 'react-i18next';

export interface BasicInformationBoxProps {
  user?: User;
  editAction: (type: 'personal' | 'demographic' | null) => void;
}

export const BasicInformationBox: FC<BasicInformationBoxProps> = ({ user, editAction }) => {
  const { t } = useTranslation();

  return (
    <Box sx={BasicInformationBoxContainer}>
      <Box sx={BasicInformationBoxSectionContainer}>
        <Box sx={BasicInformationBoxHeaderContainer}>
          <Typography sx={BasicInformationBoxHeader} component='h2'>
            {t('profilePage.basicInformation')}
          </Typography>
          <Button onClick={() => editAction('personal')} sx={BasicInformationEditButton}>
            {t('profilePage.edit')}
          </Button>
        </Box>
        <Box>
          <Box sx={BasicInformationItem}>
            <Typography sx={BasicInformationItemLabel}>{t('profilePage.fullName')}</Typography>
            <TooltipWrapper>
              <Typography sx={BasicInformationEllipsis}>
                {(user?.firstName || '') + ' ' + (user?.lastName || '')}
              </Typography>
            </TooltipWrapper>
          </Box>
          <Box sx={BasicInformationItem}>
            <Typography sx={BasicInformationItemLabel}>{t('profilePage.email')}</Typography>
            <TooltipWrapper>
              <Typography sx={BasicInformationEllipsis}>{user?.email}</Typography>
            </TooltipWrapper>
          </Box>
          <Box sx={BasicInformationItem}>
            <Typography sx={BasicInformationItemLabel}>{t('profilePage.dateOfBirth')}</Typography>
            <Typography>
              {user?.dateOfBirth ? moment(user?.dateOfBirth).format('DD MMM, yyyy') : ''}
            </Typography>
          </Box>
          <Box sx={BasicInformationItem}>
            <Typography sx={BasicInformationItemLabel}>{t('profilePage.gender')}</Typography>
            <Typography
              sx={{ textTransform: 'lowercase', ':first-letter': { textTransform: 'uppercase' } }}
            >
              {user?.gender}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: '40px',
          '@media screen and (max-width: 1023px)': {
            height: '40px'
          }
        }}
      />
      <Box sx={BasicInformationBoxSectionContainer}>
        <Box sx={BasicInformationBoxHeaderContainer}>
          <Typography sx={BasicInformationBoxHeader} component='h2'>
            {t('profilePage.demographicDetails')}
          </Typography>
          <Button onClick={() => editAction('demographic')} sx={BasicInformationEditButton}>
            {t('profilePage.edit')}
          </Button>
        </Box>
        <Box>
          <Box sx={BasicInformationItem}>
            <Typography sx={BasicInformationItemLabel}>
              {t('profilePage.countryOfResidence')}:
            </Typography>
            <Typography>{user?.countryOfResidence?.name}</Typography>
          </Box>
          <Box sx={BasicInformationItem}>
            <Typography sx={BasicInformationItemLabel}>
              {t('profilePage.cityOfResidence')}
            </Typography>
            <Typography>{user?.cityOfResidence?.name}</Typography>
          </Box>
          <Box sx={BasicInformationItem}>
            <Typography sx={BasicInformationItemLabel}>{t('profilePage.citizenship')}</Typography>
            <Typography>{user?.countryOfCitizenship?.name}</Typography>
          </Box>
          <Box sx={{ ...BasicInformationItem, border: 'none' }}>
            <Typography sx={BasicInformationItemLabel}>{t('profilePage.languages')}</Typography>
            <Box sx={{ marginTop: '14px' }}>
              {user?.nativeLanguage && (
                <Box sx={Pile}>
                  <Typography sx={PileLabel}>{user?.nativeLanguage?.name}</Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
