import { ForwardedRef, forwardRef, LegacyRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { RecaptchaChangeHandler } from '../../interface/recaptcha.interface';

export const RecaptchaWeb = forwardRef(
  (props: { onChange: RecaptchaChangeHandler }, ref: ForwardedRef<ReCAPTCHA | undefined>) => (
    <ReCAPTCHA
      ref={ref as LegacyRef<ReCAPTCHA> | undefined}
      sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
      {...props}
    />
  )
);
