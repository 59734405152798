import React, { FC, useEffect } from 'react';
import {
  Box,
  LabelDisplayedRowsArgs,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import {
  CellStyles,
  cellWidth,
  dynamicColumnsStyle,
  HideMobileColumnStyle,
  NoTestsStack,
  ProductNameOnly,
  scoreBadgeStyle,
  StatusHideCondition,
  TestsTableArrowCellImage,
  TestsTableContainer,
  TestsTableHeader,
  TestsTableOverallCell,
  TestsTableTBody,
  TestsTableTestCell,
  TestsTableTHead,
  TitleAndDate
} from './TestsTable.styles';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { StatusBadge } from '../StatusBadge';
import moment from 'moment';
import { License, Score, statusesWithoutDetailsPage } from '../../interface/licenses.interfaces';
import { TESTS_TABLE_PAGE_SIZE } from '@barracuda/sop-api-client';
import FourSkillsIcon from '../../assets/images/test--icon.svg';
import SpeakingListeningIcon from '../../assets/images/sl-icon.svg';
import ReadingWritingIcon from '../../assets/images/rw-icon.svg';
import { useTranslation } from 'react-i18next';
import { SkillType, SkillTypes } from '../../index';
import NoTestsTable from '../../assets/images/no-tests-table.svg';
import { Img } from '../Primitive';
import { myTopScoreTableCell } from '../MyTopScoreTable/MyTopScoreTable.style';

export interface ITestsTableProps {
  title: string;
  query?: string;
  setPage: (value: number) => void;
  getTests: (page: number, query?: string) => void;
  page: number;
  total: number;
  tableHeaders: string[];
  licenses: License[];
  handleRowClick: (t: License) => void;
  findScoreByName: (
    name: SkillType,
    scale: string,
    scores: Score[],
    retakeRedeemed?: boolean
  ) => string | null;
  generateEmptyRows: () => JSX.Element[];
  handleChangePage: (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
  handleStartTest?: (_: React.MouseEvent<HTMLElement> | null, licenseId: number) => void;
  practice?: boolean;
  getLabelForSkill: (skillname: SkillType, scale: string, scores: Score[]) => string;
}

export const TestsTable: FC<ITestsTableProps> = (props) => {
  const { t } = useTranslation();
  const skillTypes = [t('speaking'), t('listening'), t('reading'), t('writing')];
  const testIconMap = new Map([
    ['BCUDA_4S', FourSkillsIcon],
    ['BCUDA_2S_SL', SpeakingListeningIcon],
    ['BCUDA_2S_RW', ReadingWritingIcon]
  ]);

  const labelDisplayedRows = (props: LabelDisplayedRowsArgs): string => {
    return `${props.from}–${props.to} ${t('pagination.of')} ${props.count}`;
  };

  useEffect(() => {
    props.setPage(0);
    props.getTests(props.page, props.query);
  }, [props.query]);

  const getBadgeType = (status: string | null): 'at' | 'below' | 'above' | undefined => {
    if (!status) return undefined;
    switch (status) {
      case t('results.belowLevel'): {
        return 'below';
      }
      case t('results.atLevel'): {
        return 'at';
      }
      case t('results.aboveLevel'): {
        return 'above';
      }
      default: {
        return undefined;
      }
    }
  };

  return (
    <>
      <Box sx={TestsTableContainer}>
        <Typography sx={TestsTableHeader} component='h2'>
          {props.title}
        </Typography>
        <TableContainer>
          <Table>
            <TableHead sx={TestsTableTHead}>
              <TableRow>
                {props.tableHeaders.map((el, index) => (
                  <TableCell
                    key={`${el}${index}`}
                    sx={[dynamicColumnsStyle(el, skillTypes), cellWidth(index)]}
                  >
                    {el}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody sx={TestsTableTBody}>
              {props.licenses.length > 0 &&
                props.licenses.map((t, index) => (
                  <TableRow
                    key={`${t.id}${index}`}
                    sx={{
                      cursor:
                        ['NEW', 'STARTED', 'COMPLETED', 'VALIDATED'].includes(t.status) ||
                        t.product.practice
                          ? 'default'
                          : 'pointer'
                    }}
                    onClick={!t.product.practice ? () => props.handleRowClick(t) : () => null}
                  >
                    <TableCell sx={myTopScoreTableCell}>
                      <Stack>
                        <Box sx={TestsTableTestCell}>
                          <Img src={testIconMap.get(t.product.code)} />
                          <Box sx={ProductNameOnly}>{t.product.name}</Box>
                          <Box sx={TitleAndDate}>
                            <Typography sx={{ fontWeight: 600 }}>{t.product.name}</Typography>
                          </Box>
                        </Box>
                        <Box sx={[TitleAndDate, { marginTop: '5px', marginLeft: '40px' }]}>
                          <StatusBadge type={t.status} />
                        </Box>
                      </Stack>
                    </TableCell>
                    <TableCell sx={myTopScoreTableCell}>
                      {t.endTime ? moment(t.endTime).format('DD MMM, yyyy') : ''}
                    </TableCell>
                    {!props.practice && (
                      <TableCell sx={StatusHideCondition}>
                        <StatusBadge type={t.status} />
                      </TableCell>
                    )}
                    <TableCell
                      sx={[
                        CellStyles,
                        HideMobileColumnStyle,
                        { width: !props.practice ? '120px' : '170px' }
                      ]}
                    >
                      {props.practice && (
                        <Box
                          sx={scoreBadgeStyle(
                            getBadgeType(
                              props.getLabelForSkill(
                                SkillTypes.SPEAKING,
                                !props.practice ? 'GSE' : 'LEVEL',
                                t.scores
                              )
                            )
                          )}
                        ></Box>
                      )}
                      {!props.practice
                        ? props.findScoreByName(
                            'Speaking',
                            !props.practice ? 'GSE' : 'LEVEL',
                            t.scores,
                            t.retakeRedeemed
                          )
                        : props.getLabelForSkill(
                            SkillTypes.SPEAKING,
                            !props.practice ? 'GSE' : 'LEVEL',
                            t.scores
                          )}
                    </TableCell>
                    <TableCell
                      sx={[
                        CellStyles,
                        HideMobileColumnStyle,
                        { width: !props.practice ? '120px' : '170px' }
                      ]}
                    >
                      {props.practice && (
                        <Box
                          sx={scoreBadgeStyle(
                            getBadgeType(
                              props.getLabelForSkill(
                                SkillTypes.LISTENING,
                                !props.practice ? 'GSE' : 'LEVEL',
                                t.scores
                              )
                            )
                          )}
                        ></Box>
                      )}
                      {!props.practice
                        ? props.findScoreByName(
                            'Listening',
                            !props.practice ? 'GSE' : 'LEVEL',
                            t.scores,
                            t.retakeRedeemed
                          )
                        : props.getLabelForSkill(
                            SkillTypes.LISTENING,
                            !props.practice ? 'GSE' : 'LEVEL',
                            t.scores
                          )}
                    </TableCell>
                    <TableCell
                      sx={[
                        CellStyles,
                        HideMobileColumnStyle,
                        { width: !props.practice ? '120px' : '170px' }
                      ]}
                    >
                      {props.practice && (
                        <Box
                          sx={scoreBadgeStyle(
                            getBadgeType(
                              props.getLabelForSkill(
                                SkillTypes.READING,
                                !props.practice ? 'GSE' : 'LEVEL',
                                t.scores
                              )
                            )
                          )}
                        ></Box>
                      )}
                      {!props.practice
                        ? props.findScoreByName(
                            SkillTypes.READING,
                            !props.practice ? 'GSE' : 'LEVEL',
                            t.scores,
                            t.retakeRedeemed
                          )
                        : props.getLabelForSkill(
                            SkillTypes.READING,
                            !props.practice ? 'GSE' : 'LEVEL',
                            t.scores
                          )}
                    </TableCell>
                    <TableCell
                      sx={[
                        CellStyles,
                        HideMobileColumnStyle,
                        { width: !props.practice ? '120px' : '170px' }
                      ]}
                    >
                      {props.practice && (
                        <Box
                          sx={scoreBadgeStyle(
                            getBadgeType(
                              props.getLabelForSkill(
                                SkillTypes.WRITING,
                                !props.practice ? 'GSE' : 'LEVEL',
                                t.scores
                              )
                            )
                          )}
                        ></Box>
                      )}
                      {!props.practice
                        ? props.findScoreByName(
                            SkillTypes.WRITING,
                            !props.practice ? 'GSE' : 'LEVEL',
                            t.scores,
                            t.retakeRedeemed
                          )
                        : props.getLabelForSkill(
                            SkillTypes.WRITING,
                            !props.practice ? 'GSE' : 'LEVEL',
                            t.scores
                          )}
                    </TableCell>
                    <TableCell
                      sx={[TestsTableOverallCell, { width: props.practice ? '170px' : '140px' }]}
                    >
                      {props.findScoreByName(
                        SkillTypes.OVERALL_SCORE,
                        !props.practice ? 'GSE' : 'LEVEL',
                        t.scores,
                        t.retakeRedeemed
                      )}
                    </TableCell>
                    {!props.practice && (
                      <TableCell sx={TestsTableOverallCell}>
                        {props.findScoreByName(
                          SkillTypes.OVERALL_SCORE,
                          'CEFR',
                          t.scores,
                          t.retakeRedeemed
                        )}
                      </TableCell>
                    )}
                    <TableCell sx={{ width: '50px' }}>
                      {!statusesWithoutDetailsPage.includes(t.status) && !t.product.practice && (
                        <ArrowForwardIosIcon sx={[TestsTableArrowCellImage]} />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              {props.licenses.length > 0 && props.generateEmptyRows().map((el) => el)}
            </TableBody>
            <TableFooter>
              {!!props.licenses.length && (
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[1]}
                    rowsPerPage={TESTS_TABLE_PAGE_SIZE}
                    colSpan={9}
                    count={props.total}
                    page={props.page}
                    labelDisplayedRows={labelDisplayedRows}
                    SelectProps={{
                      inputProps: {
                        'aria-label': t('rowsPerPage') as string
                      },
                      native: true
                    }}
                    onPageChange={props.handleChangePage}
                  />
                </TableRow>
              )}
            </TableFooter>
          </Table>
        </TableContainer>
        {!props.licenses.length && (
          <Stack sx={NoTestsStack} alignItems='center' justifyContent='center'>
            <Img width={168} src={NoTestsTable} />
            <Typography>{t('results.noTestsDescription')}</Typography>
          </Stack>
        )}
      </Box>
    </>
  );
};
