import { $fontOpenSans, theme } from '../../styles/theme';

export const SharedOrderHistoryTableContainer = {
  background: theme.palette.common.white,
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.25)',
  marginTop: '30px',
  minHeight: '558px',
  borderRadius: '8px'
};

export const SharedOrderHistoryTableContainerInner = {
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
};

export const SharedOrderHistoryTableHeader = {
  fontSize: '20px',
  fontWeight: 700,
  paddingLeft: '24px',
  paddingTop: '36px',
  paddingBottom: '36px'
};

export const SharedOrderHistoryTableThead = {
  background: theme.palette.common.lightGrey5,
  border: `1px solid ${theme.palette.common.lightGrey1}`,
  borderLeft: 'none',
  borderRight: 'none',
  maxWidth: '100%'
};

export const SharedOrderHistoryTableTHeadCell = {
  fontWeight: 600,
  textAlign: 'left',
  ':first-of-type': {
    paddingLeft: '44px'
  }
};

export const SharedOrderHistoryTableCellWidth = (index: number) => {
  const widths = ['30%', '20%', '20%', '30%', '50%', '10%'];

  return {
    width: widths[index]
  };
};

export const OrderHistoryAccordionSummaryStyles = {
  flexDirection: 'row-reverse',
  padding: '17px 0',
  position: 'relative',
  '.MuiAccordionSummary-expandIconWrapper': {
    position: 'absolute',
    left: '10px'
  },
  '@media screen and (max-width: 750px) and (min-width: 430px)': {
    padding: '12px 0'
  },
  '@media screen and (max-width: 429px)': {
    padding: '10px 0'
  }
};

export const SharedOrderHistoryTableAccordionContainer = {
  maxHeight: '400px',
  minHeight: '400px',
  overflow: 'auto'
};

export const OrderHistoryAccordionRow = {
  display: 'flex',
  width: '100%'
};

export const OrderHistoryAccordionRowProductNameContainer = {
  paddingLeft: '44px',
  display: 'flex'
};

export const OrderHistoryAccordionRowProductName = {
  flex: 1,
  fontWeight: 700
};

export const OrderHistoryStatusInformation = {
  flex: '1',
  fontFamily: $fontOpenSans,
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: 1.5,
  letterSpacing: 0
};

export const OrderHistoryStatusIcon = {
  display: 'flex',
  alignItems: 'center',
  img: {
    marginRight: '5px'
  }
};

export const OrderHistoryAccordionColumn = {
  padding: '0 20px',
  '> *': {
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
};

export const OrderHistoryTableFooterStyle = {
  display: 'flex',
  flexFlow: 'row-reverse'
};

export const SharedOrderHistoryTablePaddings = {
  padding: '0 40px 60px 40px',
  '@media screen and (max-width: 600px)': {
    padding: '0 15px 20px 15px'
  }
};

export const OrderHistorySummaryOfCosts = { flex: '1', fontWeight: 700, marginLeft: '5px' };

export const OrderHistoryFifteenPercentage = {
  '@media screen and (min-width: 834px)': {
    width: '15%'
  }
};

export const SharedOrderHistoryAccordionContentRequestRefund = {
  marginLeft: '5px',
  lineHeight: '24px',
  fontWeight: 600,
  pointerEvents: 'auto',
  textTransform: 'none',
  paddingRight: '5px',
  color: theme.palette.common.darkBlue7
};

export const SharedViewedBox = {
  display: 'flex',
  alignItems: 'center',
  fontFamily: $fontOpenSans,
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: 0,
  color: theme.palette.common.darkBlue7
};

export const FlexCenter = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

export const sharedOrderHistoryTableDownloadReport = {
  cursor: 'pointer',
  pointerEvents: 'auto',
  marginLeft: '15px'
};

export const sharedOrderHistoryTableRemovePaddingAndMargins = {
  margin: 0,
  padding: 0
};

export const sharedOrderHistoryTableDownloadReceiptStyle = {
  fontWeight: 600,
  marginLeft: '5px',
  color: theme.palette.common.darkBlue7
};

export const sharedOrderHistoryTableJitStyle = (visible: boolean) => ({
  pointerEvents: 'auto',
  marginLeft: '5px',
  cursor: 'default',
  visibility: visible ? 'visible' : 'hidden'
});
