import { FC, useEffect, useState } from 'react';
import {
  GraphContainer,
  GraphLabel,
  GraphLines,
  GraphValueLine,
  GraphValueLineLabel,
  GraphValueLineLabelSpan,
  ScorePointer,
  ScorePointerContainer,
  ScorePointerContainerSpan,
  SkillBlocks,
  SkillBlocksContainer,
  SkillBlocksContainerBlock,
  SkillBlocksContainerBlockFillContainer,
  SkillBlocksContainerBlockFillContainerFill,
  SkillBlocksContainerBlockFillContainerFillSpan,
  SkillBlocksContainerBlockFillValue
} from './CertificateGraph.style';
import { V2CertificateSkill } from '../../interface/mts-report.interface';
import { Box } from '@mui/material';

interface CertificateGraphProps {
  min: number;
  max: number;
  score: number;
  subSkills: (V2CertificateSkill & { color: string })[];
  mode: 'two-skills' | 'mts';
}

export const CertificateGraph: FC<CertificateGraphProps> = ({
  score,
  min,
  max,
  subSkills,
  mode
}) => {
  const [pointers, setPointers] = useState<number[]>([]);
  const valueInterval = 10;

  useEffect(() => {
    prepareRulerData();
  }, [score]);

  const prepareRulerData = () => {
    createPointersArrays();
  };

  const createPointersArrays = () => {
    const points = [];
    for (let i = max; i >= min; i = i - valueInterval) {
      points.push(i);
    }
    dropClosestPointerToTheScore(score, points);
    setPointers([...points]);
  };

  const dropClosestPointerToTheScore = (value: number, points: number[]) => {
    const clonedArray = [...points];
    const closestPointer = clonedArray.sort((a, b) => Math.abs(value - a) - Math.abs(value - b))[0];
    const closestPointerIndex = pointers.indexOf(closestPointer);
    points.splice(closestPointerIndex, 1);
    setPointers([...points]);
  };

  const calculateStylePercentageValue = (num: number) => {
    if (isNaN(num) || num === min) {
      return 1;
    }
    return ((num - min) / (max - min)) * 100;
  };

  const calculateStylePercentageValueFromString = (value?: string) => {
    if (value === undefined) {
      return mode === 'two-skills' ? 0 : 1;
    }
    const num = parseInt(value);
    return calculateStylePercentageValue(num);
  };

  const valueLineTopPosition = (value: number) => {
    return (Math.abs(value - max) / (max - min)) * 100;
  };

  const skillBlockValue = (parameter: string, index?: number) => {
    return parameter === 'width'
      ? 100 / subSkills?.length - 5
      : index && index * (100 / subSkills?.length);
  };

  const skillBlocksData = (subSkills as (V2CertificateSkill & { color: string })[])?.map(
    (skill: V2CertificateSkill & { color: string }, index) => {
      return (
        <Box
          key={`block-${index}`}
          sx={{
            ...SkillBlocksContainerBlock,
            width: `${skillBlockValue('width')}%`,
            left: `${skillBlockValue('left', index)}%`
          }}
        >
          <Box sx={SkillBlocksContainerBlockFillContainer}>
            <Box
              sx={{
                ...SkillBlocksContainerBlockFillContainerFill,
                backgroundColor: skill?.color,
                height: `${calculateStylePercentageValueFromString(skill?.score?.toString())}%`
              }}
            ></Box>
            <Box component='span' sx={SkillBlocksContainerBlockFillContainerFillSpan}>
              {skill?.label}
            </Box>
          </Box>
          <Box sx={SkillBlocksContainerBlockFillValue(skill, calculateStylePercentageValue)}>
            {skill?.score}
          </Box>
        </Box>
      );
    }
  );

  const pointersData = pointers.map((point, index) => {
    return (
      <Box
        key={`point-${index}`}
        sx={{ ...GraphValueLine, top: `${valueLineTopPosition(point)}%` }}
      >
        <Box sx={GraphValueLineLabel}>
          <Box component='span' sx={GraphValueLineLabelSpan}>
            {point}
          </Box>
        </Box>
      </Box>
    );
  });

  return (
    <>
      <Box sx={GraphContainer}>
        <Box sx={GraphLabel}>GSE</Box>
        <Box sx={GraphLines}>
          {pointersData}
          <Box sx={SkillBlocks}>
            <Box sx={SkillBlocksContainer}>{skillBlocksData}</Box>
            <Box sx={{ ...ScorePointer, bottom: `${calculateStylePercentageValue(score)}%` }}>
              <Box sx={ScorePointerContainer}>
                <Box component='span' sx={ScorePointerContainerSpan}>
                  {score}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
