import { FC, useContext } from 'react';
import { Drawer, List } from '@mui/material';
import { SideBarNavigationMobile } from './SideBarNavigation.styles';
import { AppContext } from '../../context/App.context';
import { useTranslation } from 'react-i18next';
import { MobileList, MobileListItemInterface } from '../MobileList/MobileList';

export const SideBarNavigation: FC = () => {
  const { isDrawerOpen, setDrawerOpen, isLoggedIn } = useContext(AppContext);
  const { t } = useTranslation();
  const dashboardMobileItems: MobileListItemInterface[] = [
    ...(isLoggedIn
      ? [
          { link: '/dashboard', label: t('menuLabels.dashboard') },
          { link: `/dashboard/results`, label: t('menuLabels.results') }
        ]
      : []),
    {
      link: `/${isLoggedIn ? 'dashboard/' : ''}explore-tests`,
      label: t('menuLabels.exploreTests')
    },
    {
      link: `/${isLoggedIn ? 'dashboard/' : ''}getting-ready`,
      label: t('menuLabels.gettingReady')
    },
    { link: `/${isLoggedIn ? 'dashboard/' : ''}help-center`, label: t('menuLabels.helpCenter') },
    { link: 'https://pearson.tfaforms.net/3048', label: t('menuLabels.contactUs'), replace: true }
  ];

  const createMobileListItems = () => MobileList(dashboardMobileItems, setDrawerOpen);
  return (
    <Drawer
      sx={SideBarNavigationMobile}
      open={isDrawerOpen}
      onClose={() => setDrawerOpen?.(false)}
      variant='temporary'
      anchor='right'
    >
      <List>{createMobileListItems()}</List>
    </Drawer>
  );
};
