import { ChangeEvent, FC, useState } from 'react';
import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import {
  buttonStyle,
  headerCloseIcon,
  headerStyle,
  modalStyle,
  scaleRadioGroup,
  subHeader2Style,
  subHeaderStyle,
  textFieldLabel
} from './FeedbackSurveyModal.styles';

interface ReasonSurveyExtendedProps {
  readonly isOpen: boolean;
  readonly onSubmit: (question1Answer: string, comment: string, question3Answer: string) => void;
}

export const ReasonSurveyExtended: FC<ReasonSurveyExtendedProps> = ({ isOpen, onSubmit }) => {
  const [comment, setComment] = useState('');
  const [radioValue, setRadioValue] = useState<string>();
  const [radio3Value, setRadio3Value] = useState<string>();

  const { t } = useTranslation();

  const radioOptions = [
    t('webResultsSurvey.incomplete.question1.option1'),
    t('webResultsSurvey.incomplete.question1.option2'),
    t('webResultsSurvey.incomplete.question1.option3')
  ];

  const radioOptions3 = [
    t('webResultsSurvey.incomplete.question3.option1'),
    t('webResultsSurvey.incomplete.question3.option2')
  ];

  const handleSubmit = () => {
    onSubmit(radioValue || '', comment, radio3Value || '');
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setRadioValue(event.target.value);
  };

  const onChange3 = (event: ChangeEvent<HTMLInputElement>): void => {
    setRadio3Value(event.target.value);
  };

  const disableSubmitButton = () => {
    if (!radio3Value || !radioValue) return true;
    if (radioValue === t('webResultsSurvey.incomplete.question1.option3') && !comment.length)
      return true;
    return false;
  };

  return (
    <Modal
      open={isOpen}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={modalStyle}>
        <IconButton aria-label='close' onClick={handleSubmit} sx={headerCloseIcon}>
          <CloseIcon />
        </IconButton>
        <Typography sx={subHeaderStyle} id='modal-modal-description'>
          {t('survey.reason.subheader')}
        </Typography>
        <Typography sx={headerStyle} id='modal-modal-title'>
          {t('survey.reason.header')}
        </Typography>
        <Typography sx={subHeader2Style}>
          {t('webResultsSurvey.incomplete.question1.title')}
        </Typography>
        <Box>
          <RadioGroup onChange={onChange} sx={[{ paddingTop: '1rem' }, scaleRadioGroup]}>
            {radioOptions.map((o) => (
              <FormControlLabel
                key={o}
                value={o}
                control={<Radio />}
                label={o}
                checked={radioValue === o}
              />
            ))}
          </RadioGroup>

          <Box sx={{ maxWidth: '370px', marginTop: '25px' }}>
            <Typography sx={textFieldLabel}>
              {t('webResultsSurvey.incomplete.question2')}
            </Typography>
            <TextField
              sx={{ marginTop: '8px', marginBottom: '25px' }}
              fullWidth
              minRows={3}
              multiline
              maxRows={3}
              value={comment}
              onChange={(e) => {
                setComment(e.target.value);
              }}
            />
          </Box>

          <Typography sx={subHeader2Style}>
            {t('webResultsSurvey.incomplete.question3.title')}
          </Typography>
          <RadioGroup
            onChange={onChange3}
            sx={[
              {
                paddingTop: '1rem'
              },
              scaleRadioGroup
            ]}
          >
            {radioOptions3.map((o) => (
              <FormControlLabel
                key={o}
                value={o}
                control={<Radio />}
                label={o}
                checked={radio3Value === o}
              />
            ))}
          </RadioGroup>

          <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: '2rem' }}>
            <Button
              id='survey-submit'
              sx={{ ...buttonStyle, margin: 'unset' }}
              disabled={disableSubmitButton()}
              onClick={handleSubmit}
            >
              {t('survey.button')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
