import { ChangeEvent, FC, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  bottomBorderStyle,
  buttonStyle,
  headerStyle,
  modalStyle,
  questionConainer,
  scaleButtonsContainer,
  scaleContainer,
  scaleLabel,
  scaleLabelContainerMobile,
  scaleLabelMobile,
  scaleLabelWrapper,
  scaleLabelsContainer,
  scaleQuestionLabel,
  scaleRadioGroup,
  scaleSurveyRow,
  scaleTitleContainer,
  subHeader2Style,
  subHeaderStyle,
  textFieldLabel,
  headerCloseIcon
} from './FeedbackSurveyModal.styles';
import CloseIcon from '@mui/icons-material/Close';

interface ScaleSurveyProps {
  readonly isOpen: boolean;
  readonly onSubmit: (
    radioValue: string,
    radio2Value: string,
    radio3Value: string,
    comment: string
  ) => void;
}

export const ScaleSurvey: FC<ScaleSurveyProps> = ({ isOpen, onSubmit }) => {
  const [comment, setComment] = useState('');
  const [radioValue, setRadioValue] = useState<string>();
  const [radio2Value, setRadio2Value] = useState<string>();
  const [radio3Value, setRadio3Value] = useState<string>();

  const { t } = useTranslation();

  const radioOptions = ['1', '2', '3', '4', '5'];

  const handleSubmit = () => {
    onSubmit(radioValue || '', radio2Value || '', radio3Value || '', comment);
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setRadioValue(event.target.value);
  };

  const onChange2 = (event: ChangeEvent<HTMLInputElement>): void => {
    setRadio2Value(event.target.value);
  };

  const onChange3 = (event: ChangeEvent<HTMLInputElement>): void => {
    setRadio3Value(event.target.value);
  };

  const disableSubmitButton = () => {
    if (!radio3Value || !radioValue || !radio2Value) return true;
    return false;
  };

  return (
    <Modal
      open={isOpen}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={modalStyle}>
        <IconButton aria-label='close' onClick={handleSubmit} sx={headerCloseIcon}>
          <CloseIcon />
        </IconButton>
        <Typography sx={subHeaderStyle} id='modal-modal-description'>
          {t('webResultsSurvey.certified.upperTitle')}
        </Typography>
        <Typography sx={headerStyle} id='modal-modal-title'>
          {t('webResultsSurvey.certified.title')}
        </Typography>
        <Typography sx={subHeader2Style}>{t('webResultsSurvey.uncertified.subTitle')}</Typography>
        <Box sx={scaleContainer}>
          <Box sx={scaleSurveyRow}>
            <Box sx={{ flex: 1 }} />
            <Box sx={scaleTitleContainer}>
              <Box>
                <Typography sx={scaleLabel}>
                  {t('webResultsSurvey.uncertified.scaleMaxLabel')}
                </Typography>
              </Box>
              <Box>
                <Typography sx={scaleLabel}>
                  {t('webResultsSurvey.uncertified.scaleMinLabel')}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box sx={scaleSurveyRow}>
            <Box sx={{ flex: 1 }} />
            <Box sx={scaleLabelsContainer}>
              {radioOptions.map((o) => (
                <Box sx={scaleLabelWrapper}>
                  <Typography sx={scaleLabel}>{o}</Typography>
                </Box>
              ))}
            </Box>
          </Box>
          <Box sx={[scaleSurveyRow, bottomBorderStyle]}>
            <Box sx={questionConainer}>
              <Typography sx={scaleQuestionLabel}>
                {t('webResultsSurvey.uncertified.q1')}
              </Typography>
            </Box>
            <Box sx={scaleButtonsContainer}>
              <RadioGroup onChange={onChange} sx={scaleRadioGroup} row>
                {radioOptions.map((o) => (
                  <Radio key={o} value={o} checked={radioValue === o} />
                ))}
              </RadioGroup>
            </Box>
            <Box sx={scaleLabelContainerMobile}>
              <Box>
                <Typography sx={scaleLabelMobile}>
                  {t('webResultsSurvey.uncertified.scaleMaxLabel')}
                </Typography>
              </Box>
              <Box>
                <Typography sx={scaleLabelMobile}>
                  {t('webResultsSurvey.uncertified.scaleMinLabel')}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box sx={[scaleSurveyRow, bottomBorderStyle]}>
            <Box sx={questionConainer}>
              <Typography sx={scaleQuestionLabel}>
                {t('webResultsSurvey.uncertified.q2')}
              </Typography>
            </Box>
            <Box sx={scaleButtonsContainer}>
              <RadioGroup onChange={onChange2} sx={scaleRadioGroup} row>
                {radioOptions.map((o) => (
                  <Radio key={o} value={o} checked={radio2Value === o} />
                ))}
              </RadioGroup>
            </Box>
            <Box sx={scaleLabelContainerMobile}>
              <Box>
                <Typography sx={scaleLabelMobile}>
                  {t('webResultsSurvey.uncertified.scaleMaxLabel')}
                </Typography>
              </Box>
              <Box>
                <Typography sx={scaleLabelMobile}>
                  {t('webResultsSurvey.uncertified.scaleMinLabel')}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box sx={scaleSurveyRow}>
            <Box sx={questionConainer}>
              <Typography sx={scaleQuestionLabel}>
                {t('webResultsSurvey.uncertified.q3')}
              </Typography>
            </Box>
            <Box sx={scaleButtonsContainer}>
              <RadioGroup onChange={onChange3} sx={scaleRadioGroup} row>
                {radioOptions.map((o) => (
                  <Radio key={o} value={o} checked={radio3Value === o} />
                ))}
              </RadioGroup>
            </Box>
            <Box sx={scaleLabelContainerMobile}>
              <Box>
                <Typography sx={scaleLabelMobile}>
                  {t('webResultsSurvey.uncertified.scaleMaxLabel')}
                </Typography>
              </Box>
              <Box>
                <Typography sx={scaleLabelMobile}>
                  {t('webResultsSurvey.uncertified.scaleMinLabel')}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box sx={{ maxWidth: '370px', marginTop: '25px' }}>
            <Typography sx={textFieldLabel}>
              {t('webResultsSurvey.incomplete.question2')}
            </Typography>
            <TextField
              sx={{ marginTop: '8px', marginBottom: '25px' }}
              fullWidth
              minRows={3}
              multiline
              maxRows={3}
              value={comment}
              onChange={(e) => {
                setComment(e.target.value);
              }}
            />
          </Box>
        </Box>
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: '2rem' }}>
            <Button
              id='survey-submit'
              sx={{ ...buttonStyle, margin: 'unset' }}
              disabled={disableSubmitButton()}
              onClick={handleSubmit}
            >
              {t('survey.button')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
