import { alpha } from '@mui/material';
import { $fontOpenSans, theme } from '../../styles/theme';
const isWindows = /Win/i.test(navigator.userAgent);

export const ScaleStyles = {
  '@media screen and (min-resolution: 1.25dppx) and (max-resolution: 1.49dppx)': {
    scale: isWindows ? '0.8' : 'unset',
    transformOrigin: isWindows ? '0 0' : 'unset',
    maxHeight: 'unset'
  },
  '@media screen and (min-resolution: 1.5dppx)': {
    scale: isWindows ? '0.67' : 'unset',
    transformOrigin: isWindows ? '0 0' : 'unset',
    maxHeight: 'unset'
  }
};
export const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
  maxHeight: '100%',
  overflow: 'auto',

  '@media only screen and (min-width: 1500px)': {
    width: '980px'
  },
  '@media only screen and (max-width: 1499px) and (min-width: 1024px)': {
    width: '855px'
  },
  '@media only screen and (max-width: 1499px) and (max-width: 1023px)': {
    minWidth: '80%'
  },
  '@media only screen and (max-width: 500px)': {
    minWidth: '90%',
    height: '100%'
  },
  ...ScaleStyles
};

export const buttonStyle = {
  background: theme.palette.common.darkGrey3,
  border: `1px solid ${theme.palette.common.darkGrey3}`,
  borderRadius: '40px',
  color: theme.palette.common.white,
  fontSize: '16px',
  fontWeight: 600,
  fontFamily: $fontOpenSans,
  padding: '6px 20px',
  textTransform: 'initial',
  '&:hover': {
    background: theme.palette.common.white,
    color: theme.palette.common.darkGrey3
  },
  '&[disabled]': {
    color: theme.palette.common.white,
    border: `1px solid ${alpha(theme.palette.common.darkGrey3, 0.1)}`,
    background: `${alpha(theme.palette.common.darkGrey3, 0.3)}`
  },
  margin: 'auto'
};
export const headerCloseIcon = {
  position: 'absolute',
  right: 8,
  top: 8
};
export const subHeaderStyle = {
  fontFamily: $fontOpenSans,
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '29px',
  letterSpacing: '2.67px',
  textTransform: 'uppercase',
  color: theme.palette.common.violet
};

export const headerStyle = {
  fontFamily: $fontOpenSans,
  fontWeight: 800,
  fontSize: '36px',
  lineHeight: '43px',
  letterSpacing: '-0.89px',
  color: theme.palette.common.darkGrey1,
  '@media only screen and (max-width: 500px)': {
    fontSize: '30px'
  }
};

export const subHeader2Style = {
  fontFamily: $fontOpenSans,
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: 1.6,
  color: theme.palette.common.darkGrey2,
  marginTop: '10px'
};

export const ratingWrapper = {
  paddingTop: '30px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
};

export const ratingContainer = {
  display: 'flex',
  flexDirection: 'row',
  marginTop: '15px',
  marginBottom: '15px'
};

export const ratingCaption = {
  fontFamily: $fontOpenSans,
  fontWeight: 400,
  fontSize: '20px',
  lineHeight: 1.6,
  color: theme.palette.common.darkGrey2
};

export const textFieldLabel = {
  fontFamily: $fontOpenSans,
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: 1.5,
  color: theme.palette.common.darkGrey3
};

export const scaleLabel = {
  fontSize: '14px',
  fontWeight: 700,
  lineHeight: '22px'
};

export const scaleLabelMobile = {
  fontSize: '12px',
  fontWeight: 700,
  lineHeight: '12px',
  maxWidth: '70px',
  width: 'min-content'
};

export const scaleSurveyRow = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  '@media only screen and (max-width: 780px)': {
    flexDirection: 'column'
  }
};

export const scaleTitleContainer = {
  flex: 1,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  '@media only screen and (max-width: 780px)': {
    display: 'none'
  }
};

export const scaleContainer = {
  backgroundColor: theme.palette.common.lightGrey4,
  borderRadius: '8px',
  border: `1px solid ${theme.palette.common.lightGrey3}`,
  padding: '40px 30px',
  marginTop: '30px'
};

export const scaleButtonsContainer = {
  flex: 1,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%'
};

export const scaleLabelsContainer = {
  ...scaleButtonsContainer,
  marginTop: '10px',
  '@media only screen and (max-width: 780px)': {
    display: 'none'
  }
};

export const scaleRadioGroup = {
  justifyContent: 'space-between',
  width: '100%',
  '@media only screen and (min-width: 781px)': {
    paddingBottom: '20px'
  },
  '@media screen and (min-resolution: 1.25dppx) and (max-resolution: 1.49dppx)': {
    '.MuiTouchRipple-root': {
      scale: isWindows ? '1.25' : 'unset'
    }
  },
  '@media screen and (min-resolution: 1.5dppx)': {
    '.MuiTouchRipple-root': {
      scale: isWindows ? '1.5' : 'unset'
    }
  }
};

export const scaleLabelWrapper = { width: '42px', textAlign: 'center' };

export const questionConainer = {
  flex: 1,
  paddingBottom: '20px',
  '@media only screen and (max-width: 780px)': {
    marginTop: '30px'
  }
};

export const bottomBorderStyle = { borderBottom: `1px solid ${theme.palette.common.lightGrey3}` };

export const scaleQuestionLabel = {
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '21px'
};

export const scaleLabelContainerMobile = {
  flex: 1,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  paddingBottom: '15px',
  '@media only screen and (min-width: 781px)': {
    display: 'none'
  }
};
