import { FC } from 'react';
import { Box, Grid, Link, Typography } from '@mui/material';
import {
  FooterSectionContainer,
  FooterLink,
  FooterCopyright,
  FooterContainer,
  FooterLinksContainer,
  SectionContainer
} from '../GettingReadyPage/GettingReadyPage.styles';
import { useTranslation } from 'react-i18next';
import { theme } from '@theme';

export interface GettingReadyPageFooterProps {
  footerLinks: { label: string; to: string; external?: boolean }[];
  navigate: (to: string) => void;
}

export const GettingReadyPageFooter: FC<GettingReadyPageFooterProps> = ({
  footerLinks,
  navigate
}) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ background: theme.palette.common.darkGrey2, padding: '23px' }}>
      <Grid sx={[SectionContainer, FooterSectionContainer]} container>
        <Grid item xs={12} sx={FooterContainer}>
          <Box sx={FooterLinksContainer}>
            {footerLinks.map((el, index) => {
              return (
                <Link
                  sx={FooterLink}
                  key={`link${index}`}
                  onClick={() => (el.external ? window.open(el.to, '_blank') : navigate(el.to))}
                >
                  {el.label}
                </Link>
              );
            })}
            <Typography sx={FooterCopyright}>{t('allRightsReserved')}</Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
