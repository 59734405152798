import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AnchorStyle,
  CefrAnchor,
  CefrLabel,
  FillStyle,
  GSELabel,
  PipeLabel,
  ProgressBar,
  ProgressBarPipe,
  ScoreAnchor,
  ScoreLabel
} from './ScoreProgressBar.style';

interface ScoreProgressBarProps {
  readonly color: string;
  readonly minValue: number;
  readonly maxValue: number;
  readonly score: number;
  readonly isMts: boolean;
  readonly cefrScore?: string;
}

export const ScoreProgressBar: FC<ScoreProgressBarProps> = ({
  score,
  maxValue,
  minValue,
  isMts,
  color,
  cefrScore
}) => {
  const { t } = useTranslation();

  return (
    <Box sx={ProgressBar}>
      <Box sx={ProgressBarPipe}>
        <Typography sx={GSELabel}>GSE</Typography>
        <Typography sx={[PipeLabel, { left: 0 }]}>{minValue}</Typography>
        <Typography sx={[PipeLabel, { right: 0 }]}>{maxValue}</Typography>
        <Box
          sx={[
            PipeLabel,
            ScoreLabel,
            { left: `${((score - minValue) / (maxValue - minValue)) * 100 - 7}%` }
          ]}
        >
          <Box sx={ScoreAnchor}>
            <Box sx={AnchorStyle}>&#9650;</Box>
            {score}
          </Box>
          {cefrScore && (
            <Box sx={CefrAnchor(isMts)}>
              <Typography sx={CefrLabel(isMts)}>
                {t('cefrScore')}: {cefrScore}
              </Typography>
            </Box>
          )}
        </Box>
        <Box
          sx={[
            FillStyle,
            {
              width: `${((score - minValue) / (maxValue - minValue)) * 100}%`,
              backgroundColor: color
            }
          ]}
        ></Box>
      </Box>
    </Box>
  );
};
