import { Box, Stack, Typography } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import {
  scoreReportViewCertificateTitle,
  scoreReportViewCertificateTitleText,
  scoreReportViewCertificateValueText,
  scoreReportViewDocumentContainer,
  scoreReportViewMobileBoxShadow
} from './ScoreReportView.style';
import {
  DialogWrapper,
  Language,
  Score,
  SkillType,
  SkillTypes,
  sopClient,
  V2Certificate,
  V2CertificateSkill
} from '@barracuda/shared/src';
import { useLocation } from 'react-router-dom';
import { ScoreReportDTO } from '@barracuda/shared/src/interface/score-report.interface';
import { AppContext } from '../../context/App.context';
import { changeLanguage } from '@i18n';
import { theme } from '@theme';
import { ScoreReportViewHeader } from './ScoreReportViewHeader';
import { ScoreReportViewOverallHeader } from './ScoreReportViewOverallHeader';
import { ScoreReportViewProficiencySection } from './ScoreReportViewProficiencySection';
import { ScoreReportViewSampleResponses } from './ScoreReportViewSampleResponses';
import {
  ScoreReportMode,
  ScoreReportViewSkillScoresSection
} from './ScoreReportViewSkillScoresSection';
import { DownloadCertificatePopup } from '../DownloadCertificatePopup/DownloadCertificatePopup';
import { useTranslation } from 'react-i18next';
import { saveAxiosResponseFile } from '../../app.utils';
import { ScaleStyles } from '../DashboardTestDetails/DashboardTestDetails.styles';

export type ModeType = 'listen' | 'read';

export interface ScoreReportViewProps {
  code: string | undefined;
  mode: ScoreReportMode;
}

export const ScoreReportView: FC<ScoreReportViewProps> = ({ code, mode: reportMode }) => {
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState<string | false>('subskill-0');
  const [speakingResource, setSpeakingResource] = useState(null);
  const [writingResource, setWritingResource] = useState(null);
  const [mode, setMode] = useState<ModeType>('listen');
  const [mtsSkills, setMtsSkills] = useState<(V2CertificateSkill & { color: string })[]>([]);
  const [certificateData, setCertificateData] = useState<ScoreReportDTO | null>(null);
  const [subSkills, setSubskills] = useState<(V2CertificateSkill & { color: string })[]>([]);
  const [overallScore, setOverallScore] = useState<number>(0);
  const [mtsData, setMtsData] = useState<V2Certificate>();
  const [twoSkillsData, setTwoSkillsData] = useState<V2Certificate>();
  const state: string = useLocation().state;
  const { setError, language } = useContext(AppContext);
  const [testType, setTestType] = useState<'speaking' | 'reading' | null>(null);
  const [locationState, setLocationState] = useState<string>(state);
  const [downloadCertificateOpened, setDownloadCertificateOpened] = useState(false);
  const { t } = useTranslation();

  const getDownloadCertificatePopupSubtitle = () => {
    if (reportMode === 'mts') return 'downloadCertificatePopup.myTopScore';
    else
      return testType === 'speaking'
        ? 'downloadCertificatePopup.speakingListening'
        : 'downloadCertificatePopup.readingWriting';
  };

  useEffect(() => {
    if (state && state !== locationState) {
      setLocationState(state);
    }
    loadCertificateData();
    reportMode === 'mts' ? loadMtsDescriptions(language) : loadCertificateDescriptions(language);
  }, [language, state]);

  const loadCertificateData = () => {
    sopClient.displayCertificate(code, locationState).then((r) => {
      const { data } = r;
      const speakingSample = data.find((item: { type: string }) => item.type === 'AUDIO');
      const writingSample = data.find((item: { type: string }) => item.type === 'TEXT');
      if (speakingSample) {
        downloadResource(speakingSample.responseId, speakingSample.responseKey, 'audio');
      }
      if (writingSample) {
        downloadResource(writingSample.responseId, writingSample.responseKey, 'text');
      }
    });
    sopClient.displayCertificateData(code, locationState).then((response) => {
      const { data } = response;
      setCertificateData(data);
      setOverallScore(findScoreByName('Overall Score', data));
    });
  };
  const loadCertificateDescriptions = (lang?: Language) => {
    sopClient.loadCertificate(code, locationState, lang).then((response) => {
      const { data } = response;
      setTwoSkillsData(data);
      const mapped: V2CertificateSkill[] = Object.values(data.skills);
      Object.keys(data.skills).includes('Speaking')
        ? setTestType('speaking')
        : setTestType('reading');
      const subSkills = transformTwoSkills(mapped);
      setSubskills(prepareSubskills(subSkills));
    });
  };
  const loadMtsDescriptions = (lang?: Language) => {
    sopClient.loadCertificate(code, locationState, lang).then((response) => {
      const { data } = response;
      setMtsData(data);
      const transformedSkills = transformSkills(data.skills);
      setMtsSkills(prepareSubskills(transformedSkills));
    });
  };

  const transformSkills = (skills: { [key: string]: V2CertificateSkill }) => {
    const sortOrder = ['Speaking', 'Listening', 'Reading', 'Writing'];
    return Object.keys(skills)
      .filter((key) => key !== SkillTypes.OVERALL_SCORE)
      .map((key) => {
        return { ...skills[key], color: '' };
      })
      .sort((a, b) => {
        return sortOrder.indexOf(a.label) - sortOrder.indexOf(b.label);
      });
  };
  const transformTwoSkills = (skills: V2CertificateSkill[]) => {
    const sortOrder = ['Speaking', 'Listening', 'Reading', 'Writing'];
    let mappedSkills = skills?.filter(
      (el: V2CertificateSkill) => !el?.label?.includes('Overall Score')
    );
    mappedSkills = mappedSkills.sort((a, b) => {
      return sortOrder.indexOf(a.label) - sortOrder.indexOf(b.label);
    });
    if (!mappedSkills.find((skill) => skill.label?.includes('Speaking'))) {
      mappedSkills.push(
        ...[
          { label: 'Speaking' } as V2CertificateSkill,
          { label: 'Listening' } as V2CertificateSkill
        ]
      );
    } else if (!mappedSkills.find((skill) => skill.label === 'Reading')) {
      mappedSkills.push(
        ...[{ label: 'Reading' } as V2CertificateSkill, { label: 'Writing' } as V2CertificateSkill]
      );
    }
    return mappedSkills as (V2CertificateSkill & { color: string })[];
  };

  const openPopup = (mode: ModeType) => {
    setMode(mode);
    setOpen(true);
  };
  const downloadCertificate = async (lang: Language) => {
    try {
      const response = await sopClient.downloadSharesCertificate(lang, code, locationState);
      saveAxiosResponseFile(response);
      setDownloadCertificateOpened(false);
    } catch (e: any) {
      const {
        response: {
          data: { message }
        }
      } = e;
      setError(true, message);
    }
  };
  const downloadResource = (responseId: string, responseKey: string, type: 'text' | 'audio') => {
    sopClient
      .downloadResourceData(responseId, type, code, locationState, responseKey)
      .then((response) => {
        const { data } = response;
        type === 'audio' ? setSpeakingResource(data) : setWritingResource(data);
      });
  };
  const findScoreByName = (name: string, data: ScoreReportDTO | null): number => {
    const score = data?.license?.scores?.find(
      (el: Score) => el?.component === name && el?.scale === 'GSE'
    );

    return score && score.score ? +score.score : 0;
  };
  const prepareSubskills = (skills: (V2CertificateSkill & { color: string })[]) => {
    skills.forEach((skill) => {
      switch (skill?.label) {
        case 'Speaking':
        case 'スピーキング': {
          skill.color = theme.palette.common.darkBlue9;
          break;
        }
        case 'Listening':
        case 'リスニング': {
          skill.color = theme.palette.common.violet;
          break;
        }
        case 'Reading':
        case 'リーディング': {
          skill.color = theme.palette.common.lightBlue3;
          break;
        }
        case 'Writing':
        case 'ライティング': {
          skill.color = theme.palette.common.darkBlue4;
          break;
        }
      }
    });
    return [...skills];
  };

  const resetLanguage = async () => {
    await changeLanguage('en');
  };

  useEffect(() => {
    return () => {
      resetLanguage();
    };
  }, []);
  const findSkillByTagMTS = (name: SkillType): V2CertificateSkill | undefined => {
    return mtsData?.skills[name];
  };

  return (
    <Stack sx={scoreReportViewDocumentContainer}>
      {certificateData && (
        <Stack>
          <ScoreReportViewHeader downloadCertificate={() => setDownloadCertificateOpened(true)} />
          <Stack sx={scoreReportViewMobileBoxShadow}>
            <ScoreReportViewOverallHeader
              reportMode={reportMode}
              certificateData={certificateData}
              mtsData={mtsData}
              testType={testType}
            />
            <Box sx={scoreReportViewCertificateTitle}>
              <Typography sx={scoreReportViewCertificateTitleText}>
                {process.env.REACT_APP_GENERAL_PRODUCT_NAME}
              </Typography>
              <Typography sx={scoreReportViewCertificateValueText}>
                {certificateData?.license?.product?.name}
              </Typography>
            </Box>
            <ScoreReportViewProficiencySection
              mtsData={mtsData}
              reportMode={reportMode}
              twoSkillsData={twoSkillsData}
              overallScore={overallScore}
              findSkillByTagMTS={findSkillByTagMTS}
            />
            <ScoreReportViewSkillScoresSection
              overallScore={overallScore}
              reportMode={reportMode}
              mtsData={mtsData}
              setExpanded={setExpanded}
              subSkills={subSkills}
              expanded={expanded}
              mtsSkills={mtsSkills}
            />
            <ScoreReportViewSampleResponses
              mode={mode}
              certificateData={certificateData}
              open={open}
              openPopup={openPopup}
              speakingResource={speakingResource}
              setOpen={setOpen}
              writingResource={writingResource}
            />
          </Stack>
        </Stack>
      )}
      <DialogWrapper
        key='downloadCertificatePopup'
        title={t('downloadCertificatePopup.wrapperTitle')}
        subtitle={t(getDownloadCertificatePopupSubtitle())}
        open={downloadCertificateOpened}
        handleClose={() => setDownloadCertificateOpened(false)}
        paperRootModifications={ScaleStyles}
        closeable
      >
        <DownloadCertificatePopup handleDownload={downloadCertificate} />
      </DialogWrapper>
    </Stack>
  );
};
