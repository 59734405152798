import { FC } from 'react';
import { Trans } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import yellowBulbIcon from '@sharedImages/yellow-bulb-icon.svg';
import { UserDataFormTipsContainer, UserDataFormTipsParagraph } from './CompleteProfileTips.styles';
import { Img } from '@barracuda/shared/src/components/Primitive';

export const CompleteProfileTips: FC = () => (
  <Box sx={UserDataFormTipsContainer}>
    <Img sx={{ marginRight: '15px' }} src={yellowBulbIcon} alt='light bulb' />
    <Box>
      <Typography sx={UserDataFormTipsParagraph}>
        <Trans
          i18nKey='profilePage.editProfileWebTips'
          components={{
            p: <Typography fontSize='14px' component='p' />,
            bold: <Typography fontSize='14px' component='strong' fontWeight={600} />
          }}
        />
      </Typography>
    </Box>
  </Box>
);
