import { theme } from '../../styles/theme';

export const SetPasswordHints = {
  fontSize: '13px',
  color: theme.palette.common.black,
  lineHeight: '16px'
};

export const PasswordHintColor = (
  password: string,
  passwordErrors: string[] | undefined,
  errorType: string
) => {
  let color = theme.palette.common.black;
  if (password.length) {
    color =
      !passwordErrors || passwordErrors.includes(errorType)
        ? theme.palette.common.darkRed
        : theme.palette.common.darkGreen;
  }

  return { color };
};

export const SetPasswordHintsContainer = {
  width: '100%',
  maxWidth: '370px',
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '5px'
};

export const SetPasswordCounter = {
  width: '150px',
  fontSize: '12px',
  textAlign: 'right'
};
export const ValidationMessage = {
  position: 'absolute',
  bottom: '-20px',
  fontSize: '12px',
  '@media screen and (max-width: 400px)': {
    fontSize: '10px'
  },
  color: 'red',
  left: 0,
  paddingLeft: '45px'
};
