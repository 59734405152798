import { FC } from 'react';
import {
  dashboardUploadIdFrontBackIdText,
  dashboardUploadIdLottieStyle
} from './DashboardUploadId.styles';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Lottie from 'lottie-react';
import { success } from '../../assets/lottie/success';
import { Img } from '../../../../shared/src/components/Primitive';

export interface DashboardUploadIdPhotoWithLottieProps {
  readonly photo?: string;
  readonly backPhoto?: string;
  readonly type: 'back' | 'front';
  readonly frontIdSuccess?: any;
  readonly backIdSuccess?: any;
}

export const DashboardUploadIdPhotoWithLottie: FC<DashboardUploadIdPhotoWithLottieProps> = ({
  type,
  photo,
  backPhoto,
  backIdSuccess,
  frontIdSuccess
}) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ position: 'relative' }}>
      <Box sx={dashboardUploadIdLottieStyle}>
        <Lottie
          animationData={success}
          loop={false}
          lottieRef={type === 'front' ? frontIdSuccess : backIdSuccess}
          autoplay
        />
      </Box>
      <Img sx={{ margin: '0 auto', width: '85%' }} src={type === 'front' ? photo : backPhoto} />
      <Typography sx={dashboardUploadIdFrontBackIdText}>
        {t(type === 'front' ? 'mobileUploadId.frontOfId' : 'mobileUploadId.backOfId')}
      </Typography>
    </Box>
  );
};
