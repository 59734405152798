import { useState } from 'react';
import { Box, Tooltip } from '@mui/material';
import { whiteTooltip } from '../../styles/tooltip';
import InfoIcon from '../../assets/images/info-icon.svg';
import { t } from 'i18next';

export const AutomaticShareTooltip = () => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  return (
    <Box sx={{ margin: '5px 0 0 20px' }}>
      <Tooltip
        placement='bottom'
        open={tooltipOpen}
        componentsProps={{ tooltip: { sx: whiteTooltip } }}
        arrow
        title={t('profilePage.automaticShareInfoTooltip')}
      >
        <img
          onMouseLeave={() => setTooltipOpen(false)}
          onMouseOver={() => setTooltipOpen(true)}
          src={InfoIcon}
        />
      </Tooltip>
    </Box>
  );
};
