import { Language } from '@barracuda/shared/src';
import { FC, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { AppContext } from 'src/context/App.context';
import { useSwitchLanguage } from '../../hooks/useSwitchLanguage';
import { useLocalizedNavigate } from '../../hooks/useLocalizedNavigate';

export const Initializer: FC = () => {
  const { pathname } = useLocation();
  const { language } = useContext(AppContext);
  const navigate = useLocalizedNavigate();
  const switchLanguage = useSwitchLanguage(navigate);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    switchLanguage(language as Language);
  }, []);

  return <></>;
};
