import { Navigate, useSearchParams } from 'react-router-dom';
import { useContext } from 'react';
import { AppContext } from '../../context/App.context';

export const ProtectedLoggedInRoute: ({ children }: { children: JSX.Element }) => JSX.Element = ({
  children
}) => {
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get('redirectUrl');
  const { isLoggedIn, language } = useContext(AppContext);
  return isLoggedIn ? <Navigate to={`/${language}${redirectUrl || '/dashboard'}`} /> : children;
};
