import { Stack, Typography, Button } from '@mui/material';
import { FC } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { redeemActivationCodeContainer } from './RedeemActivationCodeTile.style';
import { buttonOutlineDarkSecondary } from '../../styles/button';
import { theme } from '../../styles/theme';

interface WhatCanDoNextTileProps {
  handleRedeemCode: () => void;
}

export const RedeemActivationCodeTile: FC<WhatCanDoNextTileProps> = ({ handleRedeemCode }) => {
  const { t } = useTranslation();

  return (
    <Stack sx={redeemActivationCodeContainer} alignItems='center'>
      <Typography sx={{ color: theme.palette.common.darkGrey2, textAlign: 'center' }}>
        <Trans
          i18nKey='dashboardPage.redeemActivationCodeTile.text'
          components={{
            bold: <Typography component='span' sx={{ fontWeight: 600 }} />
          }}
        />
      </Typography>
      <Button
        size='small'
        sx={[{ mt: '15px' }, buttonOutlineDarkSecondary]}
        onClick={handleRedeemCode}
      >
        {t('dashboardPage.redeemActivationCodeTile.buttonText')}
      </Button>
    </Stack>
  );
};
