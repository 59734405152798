import { BaseSyntheticEvent, FC, useContext, useState } from 'react';
import {
  extractAxiosErrorMessage,
  LanguageInterface,
  User,
  sopClient
} from '@barracuda/shared/src';
import { AppContext } from '../../context/App.context';
import { DemographicInfoPopup } from '@barracuda/shared/src/components/DemographicInfoPopup/DemographicInfoPopup';
import { Country } from '@barracuda/shared/src/interface/dictionary.interfaces';

interface DemographicInfoPopupWrapperProps {
  handleCloseDialog: (user?: User) => void;
}
export const DemographicInfoPopupWrapper: FC<DemographicInfoPopupWrapperProps> = ({
  handleCloseDialog
}) => {
  const { user, setError } = useContext(AppContext);
  const [countries, setCountries] = useState<Country[] | []>([]);
  const [cities, setCities] = useState<Country[] | []>([]);
  const [languageOptions, setLanguageOptions] = useState<LanguageInterface[]>([]);

  const [country, setCountry] = useState<Country | null>(
    user?.countryOfResidence ? user?.countryOfResidence : null
  );
  const [citizenship, setCitizenship] = useState<Country | null>(
    user?.countryOfCitizenship ? user?.countryOfCitizenship : null
  );
  const [city, setCity] = useState<Country | null>(user?.cityOfResidence || null);
  const [language, setLanguage] = useState<LanguageInterface | null>(
    user?.nativeLanguage ? user?.nativeLanguage : null
  );

  const updateUserProfile = async (values: { [p: string]: any }) => {
    try {
      const response = await sopClient.updateProfile(values);
      const { data } = response;
      handleCloseDialog(data);
    } catch (e) {
      setError({ open: true, message: extractAxiosErrorMessage(e) });
    }
  };
  const handleInputChange = (
    event: BaseSyntheticEvent,
    resources: 'countries' | 'languages' | 'cities'
  ) => {
    const value = event?.target.value;
    getResources(value, resources);
  };
  const getResources = async (query: string, resources: 'countries' | 'languages' | 'cities') => {
    switch (resources) {
      case 'countries': {
        const { data } = await sopClient.getCountries(query);
        setCountries(data?.results);
        break;
      }
      case 'languages': {
        const { data } = await sopClient.getLanguages(query);
        setLanguageOptions(data?.results);
        break;
      }
      case 'cities': {
        const { data } = await sopClient.getCities(query, country?.id as number);
        setCities(data?.results);
        break;
      }
    }
  };

  return (
    <DemographicInfoPopup
      closeDialog={handleCloseDialog}
      user={user}
      formSubmit={updateUserProfile}
      countries={countries}
      cities={cities}
      languages={languageOptions}
      country={country}
      citizenship={citizenship}
      city={city}
      setCity={setCity}
      setCountry={setCountry}
      setLanguage={setLanguage}
      setCitizenship={setCitizenship}
      language={language}
      handleInputChange={handleInputChange}
    />
  );
};
